import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { TextInput, Provider, Snackbar } from "react-native-paper";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class salesReportViewScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorId: "",
      purchaseId: "",
      //
      date: "",
      time: "",
      item: "",
      addQty: "",
      UOM: "",
      otherAmount: "",
      paymentMode: "",
      entryBy: "", //
      vendorName: "",
      gst: "",
      address: "", //
      checkedByAuthPerson: false,
      isMarkforLedgerGen: false,
      isLedgerEntryDone: false,
      note: "",
      uoid: "",
      receiptNo: "",
      error: "",
      SnackbarVisible: false,
    };
  }
  componentDidMount() {
    const { vendorId, purchaseId } = this.props.route.params;

    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState({ vendorId, purchaseId }, () => {
              this.getDataFromDb();
              this.getVendorDetails();
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getVendorDetails = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this.setState({
          vendorName: data.vendorName,
          gst: data.gst,
          address: data.address,
        });
      }
    });
  };
  getDataFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${this.state.purchaseId}`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this.setState({
          date: data.date,
          time: data.time,
          receiptNo: data.receiptNo,
          item: data.item,
          addQty: data.addQty,
          UOM: data.uom,
          note: data.note,
          paymentMode: data.paymentMode,
          otherAmount: data.otherAmount,

          entryBy: data.addedby,
          checkedByAuthPerson: data.checkedByAuthPerson,

          isMarkforLedgerGen: data.isMarkforLedgerGen,
          isLedgerEntryDone: data.isLedgerEntryDone,
        });
      }
    });
  };
  upDateAmount = () => {
    if (this.state.receivedBalance != "") {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${this.state.purchaseId}`
        );
      dbRef.update({
        otherAmount: parseFloat(this.state.otherAmount)
          ? parseFloat(this.state.otherAmount)
          : "",
      });
    }
  };
  markAsChecked = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${this.state.purchaseId}`
      );
    dbRef
      .update({
        checkedByAuthPerson: !this.state.checkedByAuthPerson,
      })
      .then(() => {
        this.getunCheckedPurchase();
      });
  };
  getunCheckedPurchase = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22`
      )
      .orderByChild("checkedByAuthPerson")
      .equalTo(false);
    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val()).length;
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
          );
        dbref1
          .update({
            unCheckedPurchase: data,
          })
          .catch(() => {});
      } else {
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
          );
        dbref1
          .update({
            unCheckedPurchase: 0,
          })
          .catch(() => {});
      }
    });
  };
  markForLedgerGen = () => {
    if (this.state.checkedByAuthPerson) {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${this.state.purchaseId}`
        );
      dbRef.update({
        isMarkforLedgerGen: !this.state.isMarkforLedgerGen,
      });
    }
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Purchase Details" />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" vendor="underline" />
            <View style={styles.palyArea}>
              <View
                style={{
                  flexDirection: "row",
                  marginHorizontal: 55,
                  marginTop: 10,
                }}
              >
                <Ionicons
                  name="arrow-back"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                  size={24}
                  color="black"
                  style={{ marginRight: 30 }}
                />
                <View style={{ flexDirection: "column", flex: 3 }}>
                  <Text style={{ fontSize: 18 }}>
                    {this.state.vendorName}, {this.state.address}
                  </Text>
                  <Text style={{ marginTop: 5, fontSize: 12 }}>
                    {this.state.gst}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("ledgerVendorScreen", {
                        vendorId: this.state.vendorId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("vendorEdit", {
                        vendorId: this.state.vendorId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Profile</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#c1c1c1",
                  borderRadius: 5,
                  marginHorizontal: 65,
                  marginTop: 50,
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    padding: 20,
                    borderBottomWidth: 1,
                    borderColor: "#c1c1c1",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 20 }}>
                    {this.state.vendorName}, Receipt No. {this.state.receiptNo}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 450,
                    }}
                  >
                    <AntDesign
                      name="checkcircle"
                      size={20}
                      color={
                        this.state.checkedByAuthPerson ? "#009C44" : "#c1c1c1"
                      }
                      style={{ alignSelf: "center" }}
                    />
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          flexDirection: "row",
                          backgroundColor: this.state.checkedByAuthPerson
                            ? "#c1c1c1"
                            : "#0a60ff",
                        },
                      ]}
                      onPress={() => {
                        this.markAsChecked();
                      }}
                    >
                      <Text style={{ color: "#fff", alignSelf: "center" }}>
                        Mark as Checked
                      </Text>
                    </TouchableOpacity>
                    {this.state.isLedgerEntryDone ? (
                      <View style={{ flexDirection: "row" }}>
                        <MaterialCommunityIcons
                          name="book-account-outline"
                          style={{ alignSelf: "center", padding: 5 }}
                          size={24}
                          color="#009C44"
                        />

                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#c1c1c1",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Ledger Entry Done
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={{ flexDirection: "row" }}>
                        <MaterialCommunityIcons
                          name="book-account-outline"
                          style={{ alignSelf: "center", padding: 5 }}
                          size={24}
                          color={
                            this.state.isMarkforLedgerGen
                              ? "#F4B400"
                              : "#c1c1c1"
                          }
                        />

                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: this.state.isMarkforLedgerGen
                                ? "#c1c1c1"
                                : "#0a60ff",
                            },
                          ]}
                          onPress={() => {
                            if (this.state.otherAmount == "") {
                              this.setState(
                                {
                                  SnackbarVisible: true,
                                  error: "Update Bill Amount !",
                                },
                                () => {
                                  setTimeout(() => {
                                    this.setState({ SnackbarVisible: false });
                                  }, 2500);
                                }
                              );
                            } else {
                              this.markForLedgerGen();
                            }
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Mark for Ledger
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
                <View style={{ maxHeight: 500, overflow: "scroll" }}>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Basic Info
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Date"
                      value={this.state.date}
                      style={styles.inputField}
                      placeholder="Date"
                    />
                    <TextInput
                      mode="outlined"
                      label="Time"
                      value={this.state.time}
                      style={styles.inputField}
                      placeholder="Time"
                    />
                    <TextInput
                      mode="outlined"
                      label="Receipt No."
                      value={this.state.receiptNo}
                      style={styles.inputField}
                      placeholder="Receipt No."
                    />
                  </View>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Particular
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Item"
                      value={this.state.item}
                      style={styles.inputField}
                    />
                    <TextInput
                      mode="outlined"
                      label="Qty Added in Stock"
                      value={this.state.addQty}
                      style={styles.inputField}
                    />
                    <TextInput
                      mode="outlined"
                      label="Note"
                      value={this.state.note}
                      style={styles.inputField}
                      multiline
                    />
                  </View>

                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Payment Info
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Payment Mode"
                      value={this.state.paymentMode}
                      style={styles.inputField}
                    />

                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: 20,
                          fontSize: 16,
                          fontWeight: "bold",
                          width: 110,
                        }}
                      >
                        Bill Amount With GST
                      </Text>
                      <TextInput
                        mode="outlined"
                        label="Amount"
                        value={this.state.otherAmount}
                        style={{
                          width: 140,
                          marginTop: 20,
                        }}
                        onChangeText={(text) => {
                          this.setState({ otherAmount: text });
                        }}
                      />
                      {this.state.isLedgerEntryDone ? null : (
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            { height: 40, marginTop: 30, marginLeft: 10 },
                          ]}
                          onPress={() => {
                            this.upDateAmount();
                          }}
                        >
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            Update Amount
                          </Text>
                        </TouchableOpacity>
                      )}
                    </View>

                    <TextInput
                      mode="outlined"
                      label="Entry By"
                      value={this.state.entryBy}
                      style={styles.inputField}
                    />
                  </View>
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
