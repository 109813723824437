import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import firebase from "firebase";
import {
  Modal,
  Provider,
  Portal,
  Searchbar,
  Checkbox,
  Snackbar,
} from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

class EmplyAttend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emplyData: [],
      attendanceData: [],
      newArray: [],
      acceptCheckBox: false,
      columns: [
        "Name",
        "Post",
        "Contact No.",
        <View>
          <Text
            style={{
              alignSelf: "center",
              justifyContent: "center",
              width: 130,
            }}
          >
            Attendance
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text>P1</Text>
            <Text>P2</Text>
            <Text>Ab</Text>
          </View>
        </View>,
      ],
      postSorterAce: true,
      uoid: "",
      isLoading: true,
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.getEmplyListFromDb();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getEmplyListFromDb = () => {
    this.setState({ isLoading: true });
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/employeeData/vitalInfo`)
      .orderByChild("active")
      .equalTo(true);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState({ emplyData: data }, () => {
          this.getAttendancefromDB();
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnHeader]}
              onPress={() => {
                column == "Post"
                  ? this.setState(
                      { postSorterAce: !this.state.postSorterAce },
                      () => {
                        this.tableRowSorter();
                      }
                    )
                  : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Post" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this.setState(
      {
        newArray: this.state.newArray.sort(function (a, b) {
          if (a.post > b.post) {
            return direction ? 1 : -1;
          }
          if (a.post < b.post) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        // this.setState({ searchData: this.state.mainData });
      }
    );
  };

  saveAttendanceToDB = (emplyId, p1) => {
    let DBRef = firebase
      .database()
      .ref(
        `${
          this.state.uoid
        }/factoryData/employeeData/attendance/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}/${emplyId}`
      );
    DBRef.update({
      empId: `${emplyId}`,
      p1: p1,

      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time1: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timestamp: Date.parse(new Date()),
      paid: false,
    })
      .then(() => {
        let DBRef2 = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/employeeData/attendancePerEmploy/${emplyId}/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`
          );
        DBRef2.update({
          empId: `${emplyId}`,
          p1: p1,

          date: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()}`,
          time1: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          timestamp: Date.parse(new Date()),
          paid: false,
        });
      })
      .catch((e) => console.log(e.message));
  };
  saveAttendance2ToDB = (emplyId, p2) => {
    let DBRef = firebase
      .database()
      .ref(
        `${
          this.state.uoid
        }/factoryData/employeeData/attendance/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}/${emplyId}`
      );
    DBRef.update({
      empId: `${emplyId}`,
      p2: p2,

      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timestamp: Date.parse(new Date()),
      paid: false,
    })
      .then(() => {
        let DBRef = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/employeeData/attendancePerEmploy/${emplyId}/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`
          );
        DBRef.update({
          empId: `${emplyId}`,
          p2: p2,

          date: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()}`,
          time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          timestamp: Date.parse(new Date()),
          paid: false,
        });
      })
      .catch((e) => console.log(e.message));
  };
  saveAttendanceAbsentToDB = (emplyId) => {
    let DBRef = firebase
      .database()
      .ref(
        `${
          this.state.uoid
        }/factoryData/employeeData/attendance/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}/${emplyId}`
      );
    DBRef.update({
      empId: `${emplyId}`,

      p1: false,
      p2: false,
      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timestamp: Date.parse(new Date()),
      paid: false,
    })
      .then(() => {
        let DBRef = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/employeeData/attendancePerEmploy/${emplyId}/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`
          );
        DBRef.update({
          empId: `${emplyId}`,
          p1: false,
          p2: false,

          date: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()}`,
          time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          timestamp: Date.parse(new Date()),
          paid: false,
        });
      })
      .catch((e) => console.log(e.message));
  };
  getAttendancefromDB = () => {
    let DBRef = firebase
      .database()
      .ref(
        `${
          this.state.uoid
        }/factoryData/employeeData/attendance/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`
      );
    DBRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this.setState({ attendanceData: data }, () => {
          this.combineArray();
        });
      } else {
        this.combineArray();
      }
    });
  };

  combineArray = () => {
    let newArray = this.state.emplyData.map((item1) => {
      this.state.attendanceData.map((item2) => {
        if (item1.id == item2.empId) {
          item1 = {
            p1: item2.p1 ? item2.p1 : false,
            p2: item2.p2 ? item2.p2 : false,
            name: item1.name,
            post: item1.post,
            contact: item1.contact,
            timestamp: Date.parse(new Date()),
            id: item1.id,
            paid: false,
            time1: item2.time1 ? item2.time1 : "",
            time2: item2.time2 ? item2.time2 : "",
          };
        }
      });
      return item1;
    });

    this.setState({ newArray }, () => {
      this.setState({ isLoading: false });
    });
  };

  // setHoliday = (emplyId) => {
  //   let DBRef = firebase
  //     .database()
  //     .ref(
  //       `factoryData/employeeData/attendance/${new Date().getMonth()}/${new Date().getDate()}/${emplyId}`
  //     );
  //   DBRef.update({
  //     empId: `${emplyId}`,
  //     holiday: true,
  //     date: `${new Date().getDate()}/${
  //       new Date().getMonth() + 1
  //     }/${new Date().getFullYear()}`,
  //     time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
  //     timestamp: Date.parse(new Date()),
  //   })
  //     .then(() => {
  //       let DBRef = firebase
  //         .database()
  //         .ref(
  //           `factoryData/employeeData/attendancePerEmploy/${emplyId}/${new Date().getMonth()}/${new Date().getDate()}`
  //         );
  //       DBRef.update({
  //         empId: `${emplyId}`,
  //         holiday: true,
  //         date: `${new Date().getDate()}/${
  //           new Date().getMonth() + 1
  //         }/${new Date().getFullYear()}`,
  //         time2: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
  //         timestamp: Date.parse(new Date()),
  //       });
  //     })
  //     .catch((e) => console.log(e.message));
  // };
  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={{ fontSize: "30", fontWeight: "bold", alignSelf: "center" }}
          >
            Attendance Sheet - {new Date().toDateString()}
          </Text>
          {/* <TouchableOpacity
            onPress={() => {
              for (
                let index = 0;
                index < this.state.emplyData.length;
                index++
              ) {
                this.setHoliday(this.state.emplyData[index].id);
              }
            }}
          >
            <Text>Set Today as Holiday</Text>
          </TouchableOpacity> */}
        </View>
        <View style={styles.playAreaHolder}>
          <FlatList
            data={this.state.newArray}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                  ]}
                >
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    {item.name}
                  </Text>
                  <Text style={styles.columnRowTxt}>{item.post}</Text>
                  <Text style={styles.columnRowTxt}>{item.contact}</Text>
                  <View
                    style={{
                      width: "25%",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <View>
                      <Checkbox
                        status={item.p1 ? "checked" : "unchecked"}
                        onPress={() => {
                          this.saveAttendanceToDB(item.id, !item.p1);
                          new Date().getDay() == 0
                            ? this.saveAttendance2ToDB(item.id, !item.p2)
                            : null;
                        }}
                        color="#0a60ff"
                      />
                      <Text style={{ fontSize: 12 }}>
                        {item.p1 ? item.time1 : ""}
                      </Text>
                    </View>
                    {new Date().getDay() == 0 ? null : (
                      <Checkbox
                        status={item.p2 ? "checked" : "unchecked"}
                        onPress={() => {
                          this.saveAttendance2ToDB(item.id, !item.p2);
                        }}
                        color="#0a60ff"
                      />
                    )}
                    <View
                      style={{
                        backgroundColor: "#f3bfba",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        status={
                          item.p1 == false && item.p2 == false
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this.saveAttendanceAbsentToDB(item.id, !item.absent);
                        }}
                        color="#de5246"
                      />
                    </View>
                  </View>
                </View>
              );
            }}
            keyExtractor={(item) => JSON.stringify(item.id)}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator size="large" color="#0a60ff" />
                ) : (
                  <Text>No Employee Added, Go Forward and add one !</Text>
                )}
              </View>
            }
            ListHeaderComponent={this.tableHeader}
            stickyHeaderIndices={[0]}
          />

          {/* <Snackbar
            visible={this.state.SnackbarVisible}
            onDismiss={() => {
              this.setState({ SnackbarVisible: false });
            }}
            action={{
              label: "OK",
              onPress: () => {
                this.setState({ SnackbarVisible: false });
              },
            }}
          >
            {this.state.error}
          </Snackbar> */}
        </View>
      </View>
    );
  }
}
export default function (props) {
  const navigation = useNavigation();

  return <EmplyAttend {...props} navigation={navigation} />;
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,

    backgroundColor: "#fff",
  },
  headerContainer: {},
  playAreaHolder: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#c1c1c1",
    borderRadius: 5,
    maxHeight: 500,
  },
  leftSideHolder: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
  },
  rightSideHolder: {
    flex: 1,
  },
  subText: {
    fontWeight: "normal",
    fontSize: 18,
    alignSelf: "center",
  },
  mainCard: {
    maxWidth: 740,
    alignItems: "center",
    padding: 20,
  },
  inputField: {
    padding: 20,
    height: 40,
  },

  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
    marginTop: 28,
    marginHorizontal: 20,
    width: 150,
    alignItems: "center",
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",

    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
  },
});
