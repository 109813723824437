import React from "react";
import { StyleSheet, View } from "react-native";
import { Provider, Snackbar } from "react-native-paper";

import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import EmployeeTable from "../src/components/EmployeeTable";

export default class employeeScreen extends React.Component {
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Employee Management" showBackArrow={false} />

          <View style={styles.mainConsole}>
            <LeftBar employee="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Model Start */}

              {/* Model Ends */}

              <View style={styles.table}>
                <View style={styles.tableBody}>
                  <EmployeeTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableBody: {
    maxHeight: 500,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
