import * as React from "react";
import { View, Text, Linking } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Screen Import
import loadupScreen from "./screens/loadupScreen";
import loginScreen from "./screens/loginScreen";
import dashBoardScreen from "./screens/dashBoardScreen";
import customerScreen from "./screens/customerScreen";
import vendorScreen from "./screens/vendorScreen";
import employeeScreen from "./screens/employeeScreen";
import employeeEdit from "./screens/employeeEdit";
import leadsScreen from "./screens/leadsScreen";
import viewLeads from "./screens/viewLeads";
import customerEdit from "./screens/customerEdit";
import vendorEdit from "./screens/vendorEdit";
import ledgerScreen from "./screens/ledgerScreen";
import productScreen from "./screens/productScreen";
import fleetScreen from "./screens/fleetScreen";
import salesReportScreen from "./screens/salesReportScreen";
import salesReportViewScreen from "./screens/salesReportViewScreen";
import ledgerVendorScreen from "./screens/ledgerVendorScreen";
import mroScreen from "./screens/mroScreen";
import purchaseReportScreen from "./screens/purchaseReportScreen";
import purchaseReportViewScreen from "./screens/purchaseReportViewScreen";
import mroView from "./screens/mroView";
import employeeView from "./screens/employeeView";
import orderScreen from "./screens/orderScreen";
import fleetViewScreen from "./screens/fleetViewScreen";
import productViewScreen from "./screens/productViewScreen";
import cashBookScreen from "./screens/cashBookScreen";
import companyDetails from "./screens/companyDetails";
import adminCashBook from "./screens/adminCashBook";
import reportScreen from "./screens/reportScreen";
import mobileVerification from "./screens/mobileVerification";
import newAdminUserScreen from "./screens/newAdminUserScreen";
import othersScreen from "./screens/othersScreen";
import bulkInvoice from "./screens/bulkInvoice";
import restrictedScreen from "./screens/restrictedScreen";
//

import firebase from "firebase";
// import * as Device from "expo-device";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
var firebaseConfig = {
  apiKey: "AIzaSyBXCHVpPcUaGLBf2d-CJMOAhBVTM5rh1Zc",
  authDomain: "erp-system-e5293.firebaseapp.com",
  databaseURL: "https://erp-system-e5293-default-rtdb.firebaseio.com",
  projectId: "erp-system-e5293",
  storageBucket: "erp-system-e5293.appspot.com",
  messagingSenderId: "1018452144309",
  appId: "1:1018452144309:web:19beeba515f68b311f4efc",
  measurementId: "G-RJ8NPP1MQS",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const Stack = createStackNavigator();
const UnAuthStack = createStackNavigator();
const MainStack = createStackNavigator();
const AuthStack = createStackNavigator();

const UnAuthStackScreen = () => (
  <UnAuthStack.Navigator
    screenOptions={{ headerShown: false, title: "SquareTech Apps|sERP " }}
    initialRouteName="loginScreen"
  >
    <UnAuthStack.Screen name="loginScreen" component={loginScreen} />
    <UnAuthStack.Screen
      name="mobileVerification"
      component={mobileVerification}
    />
  </UnAuthStack.Navigator>
);

const AuthStackScreen = () => (
  <AuthStack.Navigator screenOptions={{ headerShown: false, title: "sERP" }}>
    <AuthStack.Screen name="DashBoard" component={dashBoardScreen} />
    <AuthStack.Screen name="customerScreen" component={customerScreen} />
    <AuthStack.Screen name="vendorScreen" component={vendorScreen} />
    <AuthStack.Screen name="employeeScreen" component={employeeScreen} />
    <AuthStack.Screen name="employeeEdit" component={employeeEdit} />
    <AuthStack.Screen name="leadsScreen" component={leadsScreen} />
    <AuthStack.Screen name="viewLeads" component={viewLeads} />
    <AuthStack.Screen name="customerEdit" component={customerEdit} />
    <AuthStack.Screen name="ledgerScreen" component={ledgerScreen} />
    <AuthStack.Screen name="productScreen" component={productScreen} />
    <AuthStack.Screen name="fleetScreen" component={fleetScreen} />
    <AuthStack.Screen name="salesReportScreen" component={salesReportScreen} />
    <AuthStack.Screen name="vendorEdit" component={vendorEdit} />
    <AuthStack.Screen
      name="ledgerVendorScreen"
      component={ledgerVendorScreen}
    />

    <AuthStack.Screen
      name="salesReportViewScreen"
      component={salesReportViewScreen}
    />
    <AuthStack.Screen name="mroScreen" component={mroScreen} />
    <AuthStack.Screen
      name="purchaseReportScreen"
      component={purchaseReportScreen}
    />
    <AuthStack.Screen
      name="purchaseReportViewScreen"
      component={purchaseReportViewScreen}
    />
    <AuthStack.Screen name="mroView" component={mroView} />
    <AuthStack.Screen name="employeeView" component={employeeView} />
    <AuthStack.Screen name="orderScreen" component={orderScreen} />
    <AuthStack.Screen name="fleetViewScreen" component={fleetViewScreen} />
    <AuthStack.Screen name="productViewScreen" component={productViewScreen} />
    <AuthStack.Screen name="cashBookScreen" component={cashBookScreen} />
    <AuthStack.Screen name="companyDetails" component={companyDetails} />
    <AuthStack.Screen name="adminCashBook" component={adminCashBook} />
    <AuthStack.Screen name="reportScreen" component={reportScreen} />
    <AuthStack.Screen name="othersScreen" component={othersScreen} />
    <AuthStack.Screen name="bulkInvoice" component={bulkInvoice} />
  </AuthStack.Navigator>
);
const MainStackScreen = () => {
  const [isAuth, setIsAuth] = React.useState(null);
  const [authData, setAuthData] = React.useState(null);
  const [isNewUser, setIsNewUser] = React.useState(null);
  const [isExpiry, setExpiry] = React.useState(null);
  const [isUserAllowed, setIsUserAllowed] = React.useState(null);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((auth) => {
      if (auth) {
        let loginId = firebase.auth().currentUser.email
          ? firebase.auth().currentUser.email
          : auth.phoneNumber;
        if (loginId != null) {
          let userid = loginId.split(".")[0];
          let dbRef = firebase.database().ref(`allUsers/${userid}`);
          dbRef.on("value", (snapShot) => {
            if (snapShot.val()) {
              try {
                AsyncStorage.setItem("uoid", snapShot.val().mobileNumber).then(
                  () => {
                    let dbRef = firebase
                      .database()
                      .ref(
                        `${snapShot.val().mobileNumber}/erpData/users/${userid}`
                      );
                    dbRef.on("value", (snapShot1) => {
                      if (snapShot.exists()) {
                        setIsUserAllowed(
                          snapShot1.val().isERPDesktopLoginAllowed
                        );
                      }
                    });
                  }
                );
              } catch (e) {
                // saving error
              }
            }
          });

          if (auth.phoneNumber) {
            let dbRef = firebase.database().ref(`${auth.phoneNumber}`);
            dbRef.on("value", (DataSnapShot) => {
              if (!DataSnapShot.exists()) {
                setIsNewUser(true);
              } else {
                setIsNewUser(false);
              }
            });
          } else {
            setIsNewUser(false);
          }
        }

        setAuthData(auth);
        setTimeout(() => {
          setIsAuth(true);
        }, 10);
      } else {
        try {
          AsyncStorage.getItem("asdlogq").then((logid) => {
            AsyncStorage.getItem("qwertypasz").then((passcode) => {
              if (logid && passcode !== null) {
                firebase
                  .auth()
                  .createUserWithEmailAndPassword(logid, passcode)
                  .then(() => {
                    const keys = ["asdlogq", "qwertypasz"];
                    try {
                      AsyncStorage.multiRemove(keys);
                    } catch (e) {
                      // remove error
                    }
                  });
              }
            });
          });
        } catch (e) {
          console.log(e.message);
        }
        setTimeout(() => {
          setIsAuth(false);
        }, 10);
      }
    });
  }, []);

  if (isAuth == null) {
    return (
      <MainStack.Navigator
        screenOptions={{ headerShown: false, title: "SquareTech Apps" }}
      >
        {isBrowser ? (
          <UnAuthStack.Screen name="loadupScreen" component={loadupScreen} />
        ) : (
          Linking.openURL("https://squaretechapps.in/directToApp.html")
        )}
      </MainStack.Navigator>
    );
  }
  return (
    <MainStack.Navigator screenOptions={{ headerShown: false }}>
      {isBrowser ? (
        isAuth ? (
          isNewUser == true && authData.email == null ? (
            <MainStack.Screen
              name="newAdminUserScreen"
              component={newAdminUserScreen}
              options={{ title: "Welcome" }}
            />
          ) : isUserAllowed == null ? (
            <MainStack.Screen name="loadupScreen" component={loadupScreen} />
          ) : isUserAllowed == true ? (
            <MainStack.Screen
              name="AuthStackScreen"
              component={AuthStackScreen}
            />
          ) : (
            <MainStack.Screen
              name="restrictedScreen"
              component={restrictedScreen}
              options={{ title: "User Restricted" }}
            />
          )
        ) : (
          <MainStack.Screen
            name="UnAuthStackScreen"
            component={UnAuthStackScreen}
          />
        )
      ) : (
        Linking.openURL("https://squaretechapps.in/directToApp.html")
      )}
    </MainStack.Navigator>
  );
};
function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="MainStackScreen" component={MainStackScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
