import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import {
  Searchbar,
  Modal,
  Portal,
  TextInput,
  RadioButton,
  Snackbar,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Picker } from "@react-native-picker/picker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalenderWField from "../basic_Components/CalenderWField";
class EmployeeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: ["Sr. no.", "Name", "Contact No.", "Post/Designation", "Action"],
      searchQuery: "",
      postSorterAce: true,
      uoid: "",
      //
      SnackbarVisible: false,
      setVisible: false,
      post: "",
      name: "",
      address: "",
      contact: "",
      docSubmitted: "",
      note: "",
      joiningDate: new Date(),
      viewCalender: false,
      error: "",
      postcolor: "",
      namecolor: "",
      addresscolor: "",
      contactcolor: "",
      buttonLoading: false,
      checked: null,
      openingCredit: "",
      openingDebit: "",
      check: null,
      message: null,
      modalMRO: false,
      employeeId: "",
      mroData: [],
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.callDataFromDB();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/employeeData/vitalInfo`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.post > b.post) {
                return 1;
              }
              if (a.post < b.post) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.setState({ searchData: this.state.mainData });
          }
        );
      } else {
        this.setState({ isLoading: false, searchData: [] });
      }
    });
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item ? item.name.toUpperCase() : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchData: newdata });
    } else {
      this.setState({ searchData: this.state.mainData });
    }
  };
  checkifItemExist = () => {
    let finalArray = this.state.mainData.map((item) => item.name.toUpperCase());
    let check = finalArray.includes(this.state.name.toUpperCase());
    if (check) {
      this.setState({ message: "Employee Already Exists", check });
    } else {
      this.setState({ message: null, check });
    }
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                column == "Post/Designation"
                  ? this.setState(
                      { postSorterAce: !this.state.postSorterAce },
                      () => {
                        this.tableRowSorter();
                      }
                    )
                  : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Post/Designation" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this.setState(
      {
        mainData: this.state.mainData.sort(function (a, b) {
          if (a.post > b.post) {
            return direction ? 1 : -1;
          }
          if (a.post < b.post) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        this.setState({ searchData: this.state.mainData });
      }
    );
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveToDb = async () => {
    if (
      this.state.name !== "" &&
      this.state.contact !== "" &&
      this.state.post !== "" &&
      this.state.address !== ""
    ) {
      this.setState({ buttonLoading: true });
      var rString = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let dbRef = firebase
        .database()
        .ref()
        .child(
          `${this.state.uoid}/factoryData/employeeData/vitalInfo/${rString}`
        );
      await dbRef.set(
        {
          post: this.state.post,
          name: this.state.name,
          address: this.state.address,
          contact: this.state.contact,
          docSubmitted: this.state.docSubmitted,
          note: this.state.note,
          id: rString,
          joiningDate:
            this.state.joiningDate == ""
              ? `${new Date().getDate()}/${
                  new Date().getMonth() + 1
                }/${new Date().getFullYear()}`
              : this.state.joiningDate,
          bankName: "",
          accountNo: "",
          ifsCode: "",
          branch: "",
          joiningDateTimeStamp: Date.parse(
            this.state.joiningDate.toDateString()
          ),
          active: true,
        },
        (error) => {
          if (!error) {
            this.setState({ setVisible: false });
            let ledgerTime = new Date();
            let ledgerRef = firebase
              .database()
              .ref(
                `${this.state.uoid}/factoryData/employeeData/ledger/${rString}/${ledgerTime}`
              );
            ledgerRef
              .set({
                dateNumber: Date.parse(new Date()),
                transDate: new Date().toDateString(),
                discription: "Opening Balance",
                credit: this.state.openingDebit ? this.state.openingDebit : "0",
                debit: this.state.openingCredit
                  ? this.state.openingCredit
                  : "0",
                balance: this.state.openingDebit
                  ? this.state.openingDebit
                  : this.state.openingCredit
                  ? 0 - parseFloat(this.state.openingCredit)
                  : 0,
                ledgerId: Date.parse(new Date()),
                timeStampId: `${ledgerTime}`,
                isCashEntry: false,
              })
              .then(() => {
                this.setState({
                  buttonLoading: false,
                  post: "",
                  name: "",
                  address: "",
                  contact: "",
                  docSubmitted: "",
                  note: "",
                  joiningDate: new Date(),
                  error: "",
                  postcolor: "",
                  namecolor: "",
                  addresscolor: "",
                  contactcolor: "",
                  SnackbarVisible: true,
                  checked: null,
                  openingCredit: "",
                  openingDebit: "",
                });
              });
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 5000);
          }
        }
      );
    }
  };
  getEmployeeMROList = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/mroInventoryIssued/${this.state.employeeId}`
      );

    dbref.on("value", (DataSnapShot) => {
      if (DataSnapShot.exists()) {
        let data = Object.values(DataSnapShot.val());
        this.setState({ mroData: data });
      } else {
        this.setState({ mroData: [] });
      }
    });
  };
  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.container}>
        <Portal>
          <Modal
            visible={this.state.setVisible}
            onDismiss={() => {
              this.setState({ setVisible: false, buttonLoading: false });
              this.setState({
                buttonLoading: false,
                post: "",
                name: "",
                address: "",
                contact: "",
                docSubmitted: "",
                note: "",
                joiningDate: new Date(),
                error: "",
                postcolor: "",
                namecolor: "",
                addresscolor: "",
                contactcolor: "",
                checked: null,
                openingCredit: "",
                openingDebit: "",
              });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 850,
              borderRadius: 5,
              alignSelf: "center",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>New Employee</Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                padding: 20,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Picker
                  selectedValue={this.state.post}
                  style={{
                    height: 40,
                    width: 360,
                    marginHorizontal: 22,
                    borderColor: this.state.postcolor,
                  }}
                  onValueChange={(itemValue, itemIndex) =>
                    this.setState({ post: itemValue })
                  }
                >
                  <Picker.Item label="Post / Designation" value="" />
                  <Picker.Item label="Manager" value="Manager" />
                  <Picker.Item
                    label="Sales Executive"
                    value="Sales Executive"
                  />
                  <Picker.Item
                    label="Head Office Staff"
                    value="Head Office Staff"
                  />
                  <Picker.Item label="Supervisor" value="Supervisor" />
                  <Picker.Item label="Driver" value="Driver" />
                  <Picker.Item
                    label="JCB/PC Operator"
                    value="JCB/PC Operator"
                  />
                  <Picker.Item
                    label="Factory Operator"
                    value="Factory Operator"
                  />
                  <Picker.Item label="Other" value="Other" />
                </Picker>
                <View style={{ padding: 20, width: 400 }}>
                  <TextInput
                    mode="outlined"
                    label="Name of Employee"
                    value={this.state.name}
                    style={[styles.inputField, { padding: 0, width: 350 }]}
                    onChangeText={(text) => {
                      this.setState({ name: text }, () => {
                        this.checkifItemExist();
                      });
                    }}
                    placeholder="Name"
                  />
                  <Text style={{ color: "#de5246" }}>{this.state.message}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  mode="outlined"
                  label="Address"
                  value={this.state.address}
                  style={[styles.inputField]}
                  onChangeText={(text) => {
                    this.setState({ address: text });
                  }}
                  placeholder="Address"
                />
                <TextInput
                  mode="outlined"
                  label="Mobile Number"
                  value={this.state.contact}
                  style={[styles.inputField]}
                  onChangeText={(text) => {
                    let regex = /^[0-9]*$/;
                    if (text.match(regex)) {
                      this.setState({ contact: text });
                    }
                  }}
                  placeholder="Mobile Number"
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  mode="outlined"
                  label="Document Submitted"
                  value={this.state.docSubmitted}
                  style={styles.inputField}
                  onChangeText={(text) => {
                    this.setState({ docSubmitted: text });
                  }}
                  placeholder="Document"
                />
                <TextInput
                  mode="outlined"
                  label="Note"
                  value={this.state.note}
                  style={styles.inputField}
                  onChangeText={(text) => {
                    this.setState({ note: text });
                  }}
                  placeholder="Note"
                />
              </View>
              <View style={{ flexDirection: "row",zIndex:1 }}>
                <CalenderWField
                title="Joining Date"
                width={300}
                dateToShow={this.state.joiningDate}
                onChange={(value)=>this.setState({joiningDate: value})}

                />
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ padding: 30 }}>Opening Balance</Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Credit(+)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Debit"
                        status={
                          this.state.checked === "first"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this.setState({ checked: "first" });
                          this.setState({ openingCredit: 0 });
                        }}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Debit (-)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Credit"
                        status={
                          this.state.checked === "second"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this.setState({ checked: "second" });
                          this.setState({ openingDebit: 0 });
                        }}
                      />
                    </View>
                  </View>

                  <TextInput
                    mode="outlined"
                    label="Amount"
                    value={
                      this.state.checked === "first"
                        ? this.state.openingDebit
                        : this.state.openingCredit
                    }
                    style={[styles.inputField, { width: 170 }]}
                    onChangeText={(text) => {
                      this.state.checked === "first"
                        ? this.setState({ openingDebit: text })
                        : this.setState({ openingCredit: text });
                    }}
                    placeholder="Amount"
                  />
                </View>
              </View>
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      {
                        height: 40,
                        marginTop: 28,
                        marginHorizontal: 20,
                        width: 150,
                        alignItems: "center",
                      },
                    ]}
                    disabled={this.state.buttonLoading}
                    onPress={() => {
                      if (this.state.check == false) {
                        this.saveToDb();
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator size="small" color="#fff" />
                    ) : (
                      <Text style={{ color: "#fff" }}>Add Employee</Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      {
                        backgroundColor: "#fff",
                        height: 40,
                        marginTop: 28,
                        width: 100,
                        alignItems: "center",
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        setVisible: false,
                        buttonLoading: false,
                      });
                      this.setState({
                        buttonLoading: false,
                        post: "",
                        name: "",
                        address: "",
                        contact: "",
                        docSubmitted: "",
                        note: "",
                        joiningDate: new Date(),
                        error: "",
                        postcolor: "",
                        namecolor: "",
                        addresscolor: "",
                        contactcolor: "",
                        checked: null,
                        openingCredit: "",
                        openingDebit: "",
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    padding: 20,

                    color: "red",
                  }}
                >
                  {this.state.error}
                </Text>
              </View>
            </View>
          </Modal>
        </Portal>

        <Portal>
          <Modal
            visible={this.state.modalMRO}
            onDismiss={() => {
              this.setState({ modalMRO: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 850,
              borderRadius: 5,
              alignSelf: "center",
            }}
          >
            <FlatList
              data={this.state.mroData}
              renderItem={({ item, index }) => {
                return (
                  <View
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white",
                      },
                    ]}
                  >
                    <Text style={styles.columnRowTxt}>{item.date}</Text>
                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      {item.productName}
                    </Text>
                    <Text style={styles.columnRowTxt}>
                      {item.pendingQty} {item.uom}
                    </Text>
                    <Text style={styles.columnRowTxt}>{item.purpose}</Text>

                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      Action
                    </Text>
                  </View>
                );
              }}
              keyExtractor={(item) => JSON.stringify(item.productId)}
              ListEmptyComponent={
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 50,
                  }}
                >
                  <Text>No store-inventory has issued</Text>
                </View>
              }
            />
          </Modal>
        </Portal>

        <View style={styles.header}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "500",
              alignSelf: "center",
            }}
          >
            Employee Records
          </Text>

          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              this.setState({ setVisible: true });
            }}
          >
            <Text style={{ color: "#fff" }}>Add New Employee</Text>
          </TouchableOpacity>
        </View>
        <View stle={styles.searchbarBody}>
          <Searchbar
            placeholder="Search by Name"
            onChangeText={(text) => {
              this.setState({ searchQuery: text }, () => {
                this.searchStringInArray(this.state.searchQuery);
              });
            }}
            value={this.state.searchQuery}
          />
        </View>

        <FlatList
          data={this.state.searchData}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                ]}
              >
                <Text style={styles.columnRowTxt}>{index + 1}</Text>
                <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                  {item.name}
                </Text>
                {/* <Text style={styles.columnRowTxt}>{item.contact}</Text> */}
                <Text style={styles.columnRowTxt}>--</Text>
                <Text style={styles.columnRowTxt}>{item.post}</Text>
                <View
                  style={{
                    width: "20%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("employeeView", {
                        employeeId: item.id,
                        employeeName: item.name,
                        salary: item.salary,
                        joiningDateTimeStamp: item.joiningDateTimeStamp,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>View</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() =>
                      navigation.navigate("employeeEdit", {
                        dataObject: item,
                      })
                    }
                  >
                    <Text style={{ color: "#075e54" }}>Edit</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() => {
                      this.setState({ employeeId: item.id }, () => {
                        this.getEmployeeMROList();
                        this.setState({ modalMRO: true });
                      });
                    }}
                  >
                    <Text style={{ color: "#075e54" }}>MRO Issued</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
          ListHeaderComponent={this.tableHeader}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
              }}
            >
              {this.state.isLoading ? (
                <ActivityIndicator size="large" color="#0a60ff" />
              ) : (
                <Text>No Employee Added, Go Forward and add one !</Text>
              )}
            </View>
          }
          keyExtractor={(item) => JSON.stringify(item.id)}
        />
        <Snackbar
          visible={this.state.SnackbarVisible}
          onDismiss={() => {
            this.setState({ SnackbarVisible: false });
          }}
          action={{
            label: "OK",
            onPress: () => {
              this.setState({ SnackbarVisible: false });
            },
          }}
        >
          Employee Added Successfully !
        </Snackbar>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <EmployeeTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableBody: {
    maxHeight: 500,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
