import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Modal, Portal, Button, Provider } from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import VendorTable from "../src/components/VendorTable";

export default class vendorScreen extends React.Component {
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Vendor Management" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" vendor="underline" />
            <View style={styles.palyArea}>
              {/* Model Start */}

              {/* Model Ends */}
              <View style={styles.table}>
                <View style={styles.tableBody}>
                  <VendorTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    width: 1316,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
