import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import {
  Modal,
  Provider,
  Portal,
  Searchbar,
  TextInput,
  RadioButton,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";

class VendorTable extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: ["Sr. no.", "Name", "Unchecked Purchases", "Action"],
      searchQuery: "",
      postSorterAce: true,
      uoid: "",
      //
      setVisible: false,
      vendorName: "",
      address: "",
      number: "",
      email: "",
      gst: "",
      checked: null,
      openingCredit: "",
      openingDebit: "",
      //
      buttonLoading: false,
      operatorName: "",
      operatorId: "",
      message: null,
      check: null,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.callDataFromDB();
              this.getLoginUserData();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    if (
      this.state.vendorName != "" &&
      this.state.address != "" &&
      this.state.number != ""
    ) {
      var rString = this.randomString(
        10,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      this._isMounted && this.setState({ buttonLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/vendorData/vendorBasics/${rString}`
        );

      dbRef
        .set({
          id: rString,
          vendorName: this.state.vendorName,
          address: this.state.address,
          number: this.state.number,
          email: this.state.email,
          gst: this.state.gst,
          // unCheckedDM: 0,
          addedByname: this.state.operatorName,
          addedByLoginId: this.state.operatorId,
          addedDate: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          bankName: "",
          accountNo: "",
          ifsCode: "",
          branch: "",
          note: "",
        })
        .then(() => {
          var ledgerId = this.randomString(
            10,
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          );
          let date = new Date();
          let ledgerRef = firebase
            .database()
            .ref(
              `${this.state.uoid}/factoryData/vendorData/ledger/${rString}/${date}`
            );
          ledgerRef
            .set({
              ledgerId: ledgerId,
              transDate: new Date().toDateString(),
              discription: "Opening Balance",
              credit: this.state.openingCredit
                ? parseFloat(this.state.openingCredit)
                : "0",
              debit: this.state.openingDebit
                ? parseFloat(this.state.openingDebit)
                : "0",
              balance: this.state.openingCredit - this.state.openingDebit,
              dateNumber: Date.parse(new Date()),
              timeStampId: date,
            })
            .then(() => {
              this._isMounted &&
                this.setState({
                  buttonLoading: false,
                  setVisible: false,
                  vendorName: "",
                  address: "",
                  number: "",
                  email: "",
                  gst: "",
                  checked: null,
                  openingCredit: "",
                  openingDebit: "",
                });
            });
        })
        .catch(
          (error) => this._isMounted && this.setState({ error: error.message })
        );
    }
  };
  getLoginUserData = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/`)
        .orderByChild("loginId")
        .equalTo(loginId);
      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          let data = Object.values(DataSnapShot.val());
          this._isMounted &&
            this.setState({
              operatorName: data[0].name,
              operatorId: data[0].loginId,
            });
        }
      });
    }
  };
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/vendorData/vendorBasics`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this._isMounted &&
          this.setState(
            {
              mainData: data.sort(function (a, b) {
                if (a.vendorName > b.vendorName) {
                  return 1;
                }
                if (a.vendorName < b.vendorName) {
                  return -1;
                }
                return 0;
              }),
              isLoading: false,
            },
            () => {
              this._isMounted &&
                this.setState({ searchData: this.state.mainData });
            }
          );
      } else {
        this._isMounted && this.setState({ isLoading: false });
      }
    });
  };
  checkifItemExist = () => {
    let finalArray = this.state.mainData.map((item) =>
      item.vendorName.toUpperCase()
    );
    let check = finalArray.includes(this.state.vendorName.toUpperCase());
    if (check) {
      this._isMounted &&
        this.setState({ message: "Vendor Already Exists", check });
    } else {
      this._isMounted && this.setState({ message: null, check });
    }
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.vendorName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchData: newdata });
    } else {
      this._isMounted && this.setState({ searchData: this.state.mainData });
    }
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                column == "Name"
                  ? this._isMounted &&
                    this.setState(
                      { postSorterAce: !this.state.postSorterAce },
                      () => {
                        this.tableRowSorter();
                      }
                    )
                  : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this._isMounted &&
      this.setState(
        {
          mainData: this.state.mainData.sort(function (a, b) {
            if (a.vendorName > b.vendorName) {
              return direction ? 1 : -1;
            }
            if (a.vendorName < b.vendorName) {
              return direction ? -1 : 1;
            }
            return 0;
          }),
          isLoading: false,
        },
        () => {
          this._isMounted && this.setState({ searchData: this.state.mainData });
        }
      );
  };

  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <Portal>
          <Modal
            visible={this.state.setVisible}
            onDismiss={() => {
              this._isMounted && this.setState({ setVisible: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 850,
              borderRadius: 5,
              alignSelf: "center",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>New Vendor</Text>
            </View>
            <View style={styles.modelBody}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 20 }}>
                  <TextInput
                    mode="outlined"
                    label="Name of Vendor"
                    value={this.state.vendorName}
                    style={[styles.inputField, { padding: 0 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ vendorName: text }, () => {
                          this.checkifItemExist();
                        });
                    }}
                    placeholder="Name"
                    autoCapitalize="words"
                  />
                  <Text style={{ color: "#de5246" }}>{this.state.message}</Text>
                </View>
                <TextInput
                  mode="outlined"
                  label="Address"
                  value={this.state.address}
                  style={styles.inputField}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ address: text });
                  }}
                  placeholder="Address"
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  mode="outlined"
                  label="GSTIN"
                  value={this.state.gst}
                  style={[styles.inputField, { width: 300 }]}
                  onChangeText={(text) => {
                    this._isMounted &&
                      this.setState({ gst: text.toUpperCase() });
                  }}
                  placeholder="GSTIN"
                />
                <TextInput
                  mode="outlined"
                  label="Contact Number"
                  value={this.state.number}
                  style={[styles.inputField, { width: 250 }]}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ number: text });
                  }}
                  placeholder="Number"
                />

                <TextInput
                  mode="outlined"
                  label="Email"
                  value={this.state.email}
                  style={[styles.inputField, { width: 300 }]}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ email: text });
                  }}
                  placeholder="email"
                />
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ padding: 30 }}>Opening Balance</Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Debit (-)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Debit"
                        status={
                          this.state.checked === "first"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ checked: "first" });
                          this._isMounted &&
                            this.setState({ openingCredit: 0 });
                        }}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Credit(+)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Credit"
                        status={
                          this.state.checked === "second"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ checked: "second" });
                          this._isMounted && this.setState({ openingDebit: 0 });
                        }}
                      />
                    </View>
                  </View>

                  <TextInput
                    mode="outlined"
                    label="Amount"
                    value={
                      this.state.checked === "first"
                        ? this.state.openingDebit
                        : this.state.openingCredit
                    }
                    style={[styles.inputField, { width: 170 }]}
                    onChangeText={(text) => {
                      this.state.checked === "first"
                        ? this._isMounted &&
                          this.setState({ openingDebit: text })
                        : this._isMounted &&
                          this.setState({ openingCredit: text });
                    }}
                    placeholder="Amount"
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <TouchableOpacity
                    disabled={this.state.buttonLoading}
                    style={[
                      styles.addNewButton,
                      {
                        width: 150,
                        marginHorizontal: 20,
                        height: 42,
                        marginTop: 26,
                      },
                    ]}
                    onPress={() => {
                      if (this.state.check == false) {
                        this.saveDataToDb();
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator
                        animating={true}
                        color="#fff"
                        size="small"
                      />
                    ) : (
                      <Text style={{ color: "#fff", alignSelf: "center" }}>
                        {" "}
                        Save Details
                      </Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      {
                        backgroundColor: "#fff",
                        width: 80,
                        marginHorizontal: 20,
                        height: 42,
                        marginTop: 26,
                      },
                    ]}
                    onPress={() => {
                      this._isMounted && this.setState({ setVisible: false });
                    }}
                  >
                    <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </Portal>
        <View style={styles.header}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "500",
              alignSelf: "center",
            }}
          >
            Vendor Records
          </Text>
          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              this._isMounted && this.setState({ setVisible: true });
            }}
          >
            <Text style={{ color: "#fff" }}>Add New Vendor</Text>
          </TouchableOpacity>
        </View>
        <View stle={styles.searchbarBody}>
          <Searchbar
            placeholder="Search by Name"
            onChangeText={(text) => {
              this._isMounted &&
                this.setState({ searchQuery: text }, () => {
                  this.searchStringInArray(this.state.searchQuery);
                });
            }}
            value={this.state.searchQuery}
          />
        </View>

        <FlatList
          data={this.state.searchData}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                ]}
              >
                <Text style={styles.columnRowTxt}>{index + 1}</Text>
                <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                  {item.vendorName}
                </Text>
                <Text style={styles.columnRowTxt}>
                  {item.unCheckedPurchase ? item.unCheckedPurchase : 0}
                </Text>
                <View
                  style={{
                    width: "25%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("purchaseReportScreen", {
                        vendorId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Purchase Report</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("ledgerVendorScreen", {
                        vendorId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() =>
                      navigation.navigate("vendorEdit", {
                        vendorId: item.id,
                      })
                    }
                  >
                    <Text style={{ color: "#075e54" }}>Edit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
          ListHeaderComponent={this.tableHeader}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
              }}
            >
              {this.state.isLoading ? (
                <ActivityIndicator size="large" color="#0a60ff" />
              ) : (
                <Text>No Vendors Added, Go Forward and add one !</Text>
              )}
            </View>
          }
          keyExtractor={(item) => JSON.stringify(item.id)}
        />
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <VendorTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
