import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { TextInput, Checkbox, Snackbar } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import firebase from "firebase";
import { Picker } from "@react-native-picker/picker";
import AsyncStorage from "@react-native-async-storage/async-storage";

class NewExpense extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      dateString: new Date(),
      searchData: [],
      searchDataLoading: true,
      mainData: [],
      searchQuery: "",
      address: "",

      vendorId: "",

      isInventoryItem: "",
      otherAmount: "",
      //
      viewDropDown: false,
      //
      viewDropDownItem: false,
      searchDataItem: [],
      itemSearch: "",
      productId: "",
      currentQty: "",
      mainDataItem: [],
      addQty: "",
      UOM: "",
      otherCheckBox: false,
      //
      viewDropDownDriver: false,
      searchDataDriver: [],
      mainDataDriver: [],
      driverSearch: "",
      //
      paymentMode: "",
      total: "",
      received: "",
      checked: false,
      //
      receiptNo: "",
      loginUser: {},
      note: "",
      error: "",
      SnackbarVisible: false,
      currentdayAvaliableQty: 0,
      currentdayUsedQty: 0,
      buttonLoading: false,
      lastEntry: {},
      uoid: "",
      fiscalyear: "",
      islastBalanceAvaliable: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.getVendorData();
              this.getItemData();
              this.getLastEntry();
              this.getLoginUser();
              this.getDmCount();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this._isMounted && this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  currentdayAvaliableQty = () => {
    let dbref1 = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryOverView/${
          this.state.productId
        }/${Date.parse(new Date().toDateString())}`
      );

    dbref1.on("value", (datasnapshot) => {
      if (datasnapshot.val()) {
        let data = datasnapshot.val();
        this._isMounted &&
          this.setState({
            currentdayAvaliableQty: data.qtyAdd,
            currentdayUsedQty: data.qtyUsed,
          });
      }
    });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    var rString = this.randomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${rString}`
      );
    if (
      this.state.searchQuery != "" &&
      this.state.itemSearch != "" &&
      this.state.paymentMode != "" &&
      this.state.otherCheckBox != false
    ) {
      this._isMounted && this.setState({ buttonLoading: true });
      dbRef
        .set({
          id: rString,
          receiptNo: this.state.receiptNo,
          stringDate: this.state.dateString.toDateString(),
          date: this.state.date,
          time: this.state.time,
          item: this.state.itemSearch,
          otherAmount: this.state.otherAmount,
          paymentMode: this.state.paymentMode, //
          addQty: this.state.addQty,
          uom: this.state.UOM,
          addedby: this.state.loginUser.loginId,
          checkedByAuthPerson: false, //
          isMarkforLedgerGen: this.state.paymentMode == "Cash" ? true : false, //
          isLedgerEntryDone: this.state.paymentMode == "Cash" ? true : false, //
          note: this.state.note,
          dateStamp: Date.parse(this.state.dateString.toDateString()),
        })
        .then(() => {
          let DMRef = firebase
            .database()
            .ref(
              `${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`
            );
          DMRef.update({
            receiptNo: this.state.receiptNo,
          })
            .then(() => {
              this.getunCheckedPurchase();

              if (this.state.paymentMode == "Cash") {
                if (this.state.lastEntry == {}) {
                  this._isMounted &&
                    this.setState({
                      error: "Cash Book Update Error",
                      SnackbarVisible: true,
                    });
                } else {
                  if (this.state.loginUser.isSiteLogin == true) {
                    this.updateOrgCashBook();
                    this.updateAdminCashBook();
                  } else if (this.state.loginUser.isSiteLogin == false) {
                    this.updateAdminCashBook();
                  }
                }
              } else {
                this.props.closeMROModal();
                this._isMounted &&
                  this.setState({
                    error: "Saved Sucessfully",
                    SnackbarVisible: true,
                    searchQuery: "",
                    otherCheckBox: false,
                    paymentMode: "",
                  });
              }
            })
            .catch((e) => {
              this._isMounted &&
                this.setState({ error: e.massage, SnackbarVisible: true });
              this._isMounted && this.setState({ buttonLoading: false });
            });

          //   this.dmPdfDownload();
        });
    }
  };

  getunCheckedPurchase = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22`
      )
      .orderByChild("checkedByAuthPerson")
      .equalTo(false);
    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val()).length;
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
          );
        dbref1
          .update({
            unCheckedPurchase: data,
          })
          .catch(() => {});
      } else {
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
          );
        dbref1
          .update({
            unCheckedPurchase: 0,
          })
          .catch(() => {});
      }
    });
  };
  saveDataToDbWithInventory = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
      );

    dbref
      .update({
        quantity: (
          parseFloat(this.state.addQty) + parseFloat(this.state.currentQty)
        ).toFixed(2),
        qtyUpdateDate: new Date().toDateString(),
      })
      .then(() => {
        let dbref1 = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/MROInventory/productHistoryOverView/${
              this.state.productId
            }/${Date.parse(new Date().toDateString())}`
          );
        dbref1
          .update({
            qtyAdd: (
              parseFloat(this.state.addQty) +
              parseFloat(this.state.currentdayAvaliableQty)
            ).toFixed(2),
            avaliableQty: (
              parseFloat(this.state.addQty) + parseFloat(this.state.currentQty)
            ).toFixed(2),
            dateNumber: Date.parse(new Date().toDateString()),
            date: new Date().toDateString(),
            productId: this.state.productId,
            qtyUsed: this.state.currentdayUsedQty,
          })
          .then(() => {
            let dbref2 = firebase
              .database()
              .ref(
                `${
                  this.state.uoid
                }/factoryData/MROInventory/productHistoryDetails/${
                  this.state.productId
                }/${Date.parse(new Date().toDateString())}/${Date.parse(
                  new Date()
                )}`
              );
            dbref2
              .set({
                timeStamp: Date.parse(new Date()),
                time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                particular: "Material Inward(Added)",
                date: new Date().toDateString(),
                qtySubtract: 0,
                qtyAdd: this.state.addQty,
                avaliableQty: (
                  parseFloat(this.state.addQty) +
                  parseFloat(this.state.currentQty)
                ).toFixed(2),
              })
              .then(() => {
                this._isMounted &&
                  this.setState(
                    {
                      error: "Saved Successfully",
                      SnackbarVisible: true,
                      buttonLoading: false,
                      searchQuery: "",
                      otherCheckBox: false,
                      itemSearch: "",
                      paymentMode: "",
                    },
                    () => {
                      setTimeout(() => {
                        this._isMounted &&
                          this.setState({ SnackbarVisible: false });
                      }, 5000);
                    }
                  );
              })
              .catch((e) => {
                this._isMounted &&
                  this.setState(
                    {
                      error: e.message,
                      SnackbarVisible: true,
                      buttonLoading: false,
                    },
                    () => {
                      setTimeout(() => {
                        this._isMounted &&
                          this.setState({ SnackbarVisible: false });
                      }, 5000);
                    }
                  );
              });
          })
          .catch((e) => {
            this._isMounted &&
              this.setState(
                {
                  error: e.message,
                  SnackbarVisible: true,
                  buttonLoading: false,
                },
                () => {
                  setTimeout(() => {
                    this._isMounted &&
                      this.setState({ SnackbarVisible: false });
                  }, 5000);
                }
              );
          });
      })
      .catch((e) => {
        this._isMounted &&
          this.setState(
            { error: e.message, SnackbarVisible: true, buttonLoading: false },
            () => {
              setTimeout(() => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              }, 5000);
            }
          );
      });
  };
  getLastEntry = () => {
    let ledgerRef1 = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/ledger/FY2021_22`)
      .orderByChild(`dateNumber`)
      .limitToLast(1);

    ledgerRef1.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this._isMounted &&
          this.setState({ lastEntry: data[0], islastBalanceAvaliable: true });
      } else {
        this._isMounted &&
          this.setState({
            lastEntry: { balance: 0 },
            islastBalanceAvaliable: true,
          });
      }
    });
  };
  updateOrgCashBook = () => {
    if (this.state.otherAmount != 0) {
      this._isMounted && this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${ledgerId}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateNumber: Date.parse(new Date()),
          dateStamp: Date.parse(new Date().toDateString()),
          transDate: new Date().toDateString(),
          discription: `${this.state.receiptNo}${this.state.itemSearch}`,
          credit: 0,
          debit: this.state.otherAmount,
          balance: parseFloat(
            parseFloat(this.state.lastEntry.balance) -
              parseFloat(this.state.otherAmount)
          ).toFixed(2),
        })
        .then(() => {
          this._isMounted &&
            this.setState({ buttonLoading: false }, () => {
              this._isMounted &&
                this.setState({
                  SnackbarVisible: true,
                  paymentMode: "",
                  itemSearch: "",
                  date: new Date(),
                  error: "Ledger Entry Successfully !",
                  buttonLoading: false,
                });
              setTimeout(() => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              }, 2000);
            });
        })
        .catch((error) => {
          this._isMounted && this.setState({ error: error.message });
        });
    }
  };
  updateAdminCashBook = () => {
    if (this.state.otherAmount != 0) {
      this._isMounted && this.setState({ buttonLoading: true });
      var transactionId = this.randomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${transactionId}`
        );
      ledgerRef
        .set({
          transactionId: transactionId,
          dateStamp: Date.parse(new Date().toDateString()),
          timestamp: Date.parse(new Date()),
          date: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
          discription: `${this.state.searchQuery} for ${this.state.itemSearch}`,
          cashIn: 0,
          cashOut: parseFloat(this.state.otherAmount),
          entryBy: this.state.loginUser.name,
        })
        .then(() => {
          this.props.closeMROModal();
          this._isMounted &&
            this.setState({ buttonLoading: false }, () => {
              this._isMounted &&
                this.setState({
                  setVisible: false,
                  SnackbarVisible: true,
                  particular: "",
                  creditAmt: 0,
                  debitAmt: 0,
                  date: new Date(),
                  error: "Ledger Entry Successfully !",
                  buttonLoading: false,
                });
              setTimeout(() => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              }, 2000);
            });
        })
        .catch((error) => {
          this._isMounted && this.setState({ error: error.message });
        });
    }
  };

  getVendorData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/vendorData/vendorBasics`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchData: data, mainData: data }, () => {
            this._isMounted && this.setState({ searchDataLoading: false });
          });
      }
    });
  };
  getDmCount = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }
    this._isMounted &&
      this.setState({ fiscalyear }, () => {
        let DMRef = firebase
          .database()
          .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);
        DMRef.once("value", (dataSnap) => {
          if (dataSnap.exists()) {
            this._isMounted &&
              this.setState({
                receiptNo: dataSnap.val().receiptNo + 1,
              });
          } else {
            DMRef.set({
              dmCount: 0,
              gstBillCount: 0,
              localBillCount: 0,
              nrdmCount: 0,
              quoteNo: 0,
              receiptNo: 0,
            }).then(() => {
              this.getDmCount();
            });
          }
        });
      });
  };
  getItemData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/MROInventory/products`);

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };

  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.vendorName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchData: newdata });
    } else {
      this._isMounted && this.setState({ searchData: this.state.mainData });
    }
  };

  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataItem: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  inventoryCheck = () => {
    let found = this.state.mainDataItem.find(
      (element) => element.productName == this.state.itemSearch
    );

    if (found) {
      this._isMounted &&
        this.setState({ isInventoryItem: true }, () => {
          // this.getDriverData();
        });
    } else {
      this._isMounted && this.setState({ isInventoryItem: false });
    }
  };

  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}
          >
            Receipt Generator
          </Text>
        </View>
        <View style={styles.playAreaHolder}>
          <View style={styles.leftSideHolder}>
            <Text style={styles.subText}>Receipt Review</Text>
          </View>
          <View style={styles.rightSideHolder}>
            <ScrollView
              horizontal
              pagingEnabled
              showsHorizontalScrollIndicator={false}
              ref={(scroller) => {
                this.scroller = scroller;
              }}
              scrollEnabled={false}
            >
              <TouchableWithoutFeedback
                onPress={() => {
                  this._isMounted && this.setState({ viewDropDownItem: false });
                }}
              >
                <View style={styles.mainCard}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: 310,
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={[
                        styles.subText,
                        { textDecorationLine: "underline" },
                      ]}
                    >
                      Vendor Info
                    </Text>
                    <Text>Receipt No. {this.state.receiptNo}</Text>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      disabled
                      mode="outlined"
                      label="Date"
                      value={this.state.date}
                      style={[styles.inputField, { width: 180 }]}
                    />
                    <TextInput
                      disabled
                      mode="outlined"
                      label="Time"
                      value={this.state.time}
                      style={[styles.inputField, { width: 180 }]}
                    />
                  </View>
                  <View style={{ zIndex: 5 }}>
                    <TextInput
                      mode="outlined"
                      label="Vendor Name"
                      value={this.state.searchQuery}
                      style={[styles.inputField, { width: 360 }]}
                      onChangeText={(text) => {
                        this._isMounted &&
                          this.setState({ searchQuery: text }, () => {
                            this.searchStringInArray(this.state.searchQuery);
                            this._isMounted &&
                              this.setState({ viewDropDown: true });
                          });
                      }}
                      placeholder="Vendor Name"
                    />
                    {this.state.viewDropDown ? (
                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: "#000",
                          borderRadius: 5,
                          position: "absolute",
                          marginTop: 70,
                          shadowOffset: {
                            width: 0,
                            height: 0,
                          },
                          shadowOpacity: 0.2,
                          shadowRadius: 5,
                          zIndex: 5,
                          marginLeft: 20,
                          maxHeight: 150,
                        }}
                      >
                        <FlatList
                          data={this.state.searchData}
                          renderItem={({ item }) => {
                            return (
                              <TouchableOpacity
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 10,
                                  borderWidth: 1,
                                  borderColor: "#c1c1c1",
                                  borderRadius: 5,
                                  width: 320,
                                  alignItems: "center",
                                }}
                                onPress={() => {
                                  this._isMounted &&
                                    this.setState(
                                      {
                                        searchQuery: item.vendorName,
                                        address: item.address,
                                        vendorId: item.id,
                                      },
                                      () => {
                                        this._isMounted &&
                                          this.setState({
                                            viewDropDown: false,
                                          });
                                      }
                                    );
                                }}
                              >
                                <Text style={{ color: "#000" }}>
                                  {item.vendorName},{item.address}
                                </Text>
                              </TouchableOpacity>
                            );
                          }}
                          keyExtractor={(item) => JSON.stringify(item.id)}
                        />
                      </View>
                    ) : null}
                  </View>
                  <TextInput
                    mode="outlined"
                    label="Product/Service Name"
                    value={this.state.itemSearch}
                    style={[styles.inputField, { width: 360, zIndex: -1 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ itemSearch: text }, () => {
                          this.searchItemStringInArray(this.state.itemSearch);
                          this._isMounted &&
                            this.setState({ viewDropDownItem: true });
                          this.inventoryCheck();
                        });
                    }}
                    placeholder="Product/Service Name"
                  />
                  {this.state.viewDropDownItem ? (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 5,
                        position: "absolute",
                        marginTop: 260,
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 5,
                        zIndex: 5,
                        marginLeft: 5,
                        maxHeight: 150,
                      }}
                    >
                      <FlatList
                        data={this.state.searchDataItem}
                        renderItem={({ item }) => {
                          return (
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#fff",
                                padding: 10,
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                width: 320,
                                alignItems: "center",
                              }}
                              onPress={() => {
                                this._isMounted &&
                                  this.setState(
                                    {
                                      itemSearch: item.productName,
                                      UOM: item.UOM,
                                      productId: item.id,
                                      currentQty: item.quantity,
                                    },
                                    () => {
                                      this._isMounted &&
                                        this.setState({
                                          viewDropDownItem: false,
                                        });
                                      this.inventoryCheck();
                                      this.currentdayAvaliableQty();
                                    }
                                  );
                              }}
                            >
                              <Text style={{ color: "#000" }}>
                                {item.productName}
                              </Text>
                            </TouchableOpacity>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  ) : null}
                  {this.state.isInventoryItem ? (
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Add Quantity"
                        value={this.state.addQty}
                        style={[styles.inputField, { width: 320, zIndex: -1 }]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this._isMounted && this.setState({ addQty: text });
                          } else {
                            this._isMounted && this.setState({ addQty: "" });
                          }
                        }}
                        placeholder="Add Quantity"
                      />
                      <Text style={{ paddingTop: 40 }}>{this.state.UOM}</Text>
                    </View>
                  ) : (
                    <View style={{ flexDirection: "column" }}>
                      <TextInput
                        mode="outlined"
                        label="Note"
                        value={this.state.note}
                        style={[styles.inputField, { width: 360, zIndex: -1 }]}
                        onChangeText={(text) => {
                          this._isMounted && this.setState({ note: text });
                        }}
                        placeholder="Note"
                        multiline
                      />
                      <Picker
                        selectedValue={this.state.paymentMode}
                        style={{
                          height: 40,
                          width: 320,
                          marginHorizontal: 22,
                          marginVertical: 25,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this._isMounted &&
                          this.setState({ paymentMode: itemValue })
                        }
                      >
                        <Picker.Item label="Payment Mode" value="" />
                        <Picker.Item label="Cash" value="Cash" />
                        <Picker.Item label="On Credit" value="Credit" />
                      </Picker>
                      {this.state.paymentMode == "Cash" ? (
                        <TextInput
                          mode="outlined"
                          label="Amount in Rs."
                          value={this.state.otherAmount}
                          style={[
                            styles.inputField,
                            { width: 360, zIndex: -1 },
                          ]}
                          onChangeText={(text) => {
                            let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ otherAmount: text });
                            } else {
                              this._isMounted &&
                                this.setState({ otherAmount: "" });
                            }
                          }}
                          placeholder="Amount in Rs."
                        />
                      ) : null}
                    </View>
                  )}

                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        this.state.isInventoryItem
                          ? null
                          : {
                              marginTop: 62,
                            },
                      ]}
                      onPress={() => {
                        navigation.navigate("vendorScreen");
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Add New Vendor</Text>
                    </TouchableOpacity>
                    <View>
                      {this.state.isInventoryItem ? null : (
                        <View style={{ flexDirection: "row" }}>
                          <Checkbox
                            status={
                              this.state.otherCheckBox ? "checked" : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  otherCheckBox: !this.state.otherCheckBox,
                                });
                            }}
                            color="#0a60ff"
                          />
                          <Text style={{ padding: 8 }}>
                            Above entry is true.
                          </Text>
                        </View>
                      )}
                      <TouchableOpacity
                        disabled={this.state.buttonLoading}
                        style={[
                          styles.addNewButton,
                          this.state.isInventoryItem
                            ? null
                            : {
                                backgroundColor: "#009C44",
                                borderColor: "#009C44",
                              },
                        ]}
                        onPress={() => {
                          if (this.state.isInventoryItem) {
                            if (this.state.searchQuery != "") {
                              this.scroller.scrollTo({
                                x: 430,
                                y: 0,
                                animated: true,
                              });
                            }
                          } else {
                            if (this.state.otherCheckBox) {
                              this.saveDataToDb();
                            }
                          }
                        }}
                      >
                        {this.state.isInventoryItem ? (
                          <Text style={{ color: "#fff" }}>Next</Text>
                        ) : this.state.buttonLoading ? (
                          <ActivityIndicator
                            size="small"
                            color="#fff"
                            animating
                          />
                        ) : (
                          <Text style={{ color: "#fff" }}>Save</Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
              {this.state.isInventoryItem ? (
                <View style={styles.mainCard}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: 310,
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={[
                        styles.subText,
                        { textDecorationLine: "underline" },
                      ]}
                    >
                      Other Info
                    </Text>
                    <Text>Receipt No. {this.state.receiptNo}</Text>
                  </View>
                  <View>
                    <View style={{ flexDirection: "column" }}>
                      <Picker
                        selectedValue={this.state.paymentMode}
                        style={{
                          height: 40,
                          width: 320,
                          marginHorizontal: 22,
                          marginVertical: 25,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this._isMounted &&
                          this.setState({ paymentMode: itemValue })
                        }
                      >
                        <Picker.Item label="Payment Mode" value="" />
                        <Picker.Item label="Cash" value="Cash" />
                        <Picker.Item label="On Credit" value="Credit" />
                      </Picker>
                      {this.state.paymentMode == "Cash" ? (
                        <TextInput
                          mode="outlined"
                          label="Amount in Rs."
                          value={this.state.otherAmount}
                          style={[
                            styles.inputField,
                            { width: 360, zIndex: -1 },
                          ]}
                          onChangeText={(text) => {
                            this._isMounted &&
                              this.setState({ otherAmount: text });
                          }}
                          placeholder="Amount in Rs."
                        />
                      ) : null}

                      <TextInput
                        mode="outlined"
                        label="Note"
                        value={this.state.note}
                        style={[styles.inputField, { width: 360, zIndex: -1 }]}
                        onChangeText={(text) => {
                          this._isMounted && this.setState({ note: text });
                        }}
                        placeholder="Note"
                        multiline
                      />
                    </View>

                    <View style={{ flexDirection: "row", zIndex: -1 }}>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          { zIndex: -1, marginTop: 62 },
                        ]}
                        onPress={() => {
                          this.scroller.scrollTo({
                            x: 0,
                            y: 0,
                            animated: true,
                          });
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Back</Text>
                      </TouchableOpacity>
                      <View style={{ flexDirection: "column" }}>
                        <View style={{ flexDirection: "row" }}>
                          <Checkbox
                            status={
                              this.state.otherCheckBox ? "checked" : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  otherCheckBox: !this.state.otherCheckBox,
                                });
                            }}
                            color="#0a60ff"
                          />
                          <Text style={{ padding: 8 }}>
                            Above entry is true.
                          </Text>
                        </View>
                        <TouchableOpacity
                          disabled={this.state.buttonLoading}
                          style={[
                            styles.addNewButton,
                            {
                              zIndex: -1,
                              backgroundColor: "#009C44",
                              borderColor: "#009C44",
                            },
                          ]}
                          onPress={() => {
                            if (
                              this.state.searchQuery != "" &&
                              this.state.itemSearch != "" &&
                              this.state.paymentMode != "" &&
                              this.state.otherCheckBox != false
                            ) {
                              if (this.state.islastBalanceAvaliable) {
                                this.saveDataToDb();
                                this.saveDataToDbWithInventory();
                              } else {
                                this._isMounted &&
                                  this.setState(
                                    {
                                      SnackbarVisible: true,
                                      error: "No Network",
                                      setVisible: false,
                                    },
                                    () => {
                                      setTimeout(() => {
                                        this._isMounted &&
                                          this.setState({
                                            SnackbarVisible: false,
                                          });
                                      }, 2000);
                                    }
                                  );
                              }
                            }
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator
                              size="small"
                              color="#fff"
                              animating
                            />
                          ) : (
                            <Text style={{ color: "#fff" }}>Save Details</Text>
                          )}
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null}
            </ScrollView>
          </View>
          <Snackbar
            visible={this.state.SnackbarVisible}
            onDismiss={() => {
              this._isMounted && this.setState({ SnackbarVisible: false });
            }}
            action={{
              label: "OK",
              onPress: () => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              },
            }}
          >
            {this.state.error}
          </Snackbar>
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <NewExpense {...props} navigation={navigation} />;
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,

    backgroundColor: "#fff",
  },
  headerContainer: {},
  playAreaHolder: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#c1c1c1",
    borderRadius: 5,
  },
  leftSideHolder: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
  },
  rightSideHolder: {
    flex: 1,
  },
  subText: {
    fontWeight: "normal",
    fontSize: 18,
    alignSelf: "center",
  },
  mainCard: {
    width: 430,
    borderWidth: 1,
    borderColor: "#c1c1c1",
    alignItems: "center",
    padding: 20,
  },
  inputField: {
    padding: 20,
    height: 40,
  },

  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
    marginTop: 28,
    marginHorizontal: 20,
    width: 150,
    alignItems: "center",
  },
});
