import { StatusBar } from "expo-status-bar";
import React, { createRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Linking,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { TextInput, Button, Divider } from "react-native-paper";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import * as WebBrowser from "expo-web-browser";
import logo from "../src/images/squaretechsmall.png";
import firebase from "firebase";
import { AntDesign } from "@expo/vector-icons";
export default class mobileVerification extends React.Component {
  constructor(props) {
    super(props);
    (this.recaptchaVerifier = createRef()),
      (this.state = {
        number: "",
        verificationCode: "",
        error: null,
        loadingButton: false,
        isOtpSend: false,
        verificationId: "",
        isOtpButtonLoading: false,
        otpError: "",
      });
  }

  //   userLogin = () => {
  //     firebase
  //       .auth()
  //       .signInWithEmailAndPassword(this.state.EID, this.state.code)
  //       .then((userCredential) => {})
  //       .catch((error) => {
  //         this.setState({ error: JSON.stringify(error.message) });
  //         this.setState({ loadingButton: false });
  //       });
  //   };
  validation = async () => {
    this.setState({ loadingButton: true });
    var number = this.state.number;
    if (number.length > 1 && number.match(/[0-9]/)) {
      //number.match(...)>>> is for language check like marathi digits are not allowed
      const regexpValidDigit = /[0-9]/g; //Validate Phone number contains only digit from 0-9
      const regexpFirstDigit = /^[6-9]/g; //Validate Phone number's first digit is btw 6-9
      if (
        number.match(regexpValidDigit).length == 10 &&
        number.match(regexpFirstDigit)
      ) {
        try {
          const phoneProvider = new firebase.auth.PhoneAuthProvider();
          const verificationId = await phoneProvider.verifyPhoneNumber(
            `+91${this.state.number}`,
            this.recaptchaVerifier.current
          );
          this.setState({
            verificationId,
            loadingButton: false,
            isOtpSend: true,
          });
        } catch (err) {
          console.log(err);
          this.setState({
            error: JSON.stringify(err.message),
            loadingButton: false,
          });
        }
      } else {
        this.setState({
          error: "Please Enter the valid Number",
          loadingButton: false,
        });
      }
    } else {
      this.setState({
        error: "Please Enter the valid Number",
        loadingButton: false,
      });
    }
  };

  checkOtp = async () => {
    this.setState({ isOtpButtonLoading: true });
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        this.state.verificationId,
        this.state.verificationCode
      );
      await firebase
        .auth()
        .signInWithCredential(credential)
        .then((result) => {})
        .catch((error) => {
          this.setState({
            otpError: "Please Enter a Valid OTP",
            isOtpButtonLoading: false,
          });
        });
    } catch (err) {
      this.setState({
        otpError: "Please Enter a Valid OTP",
        isOtpButtonLoading: false,
      });
    }
  };
  render() {
    return (
      <View style={styles.container}>
        <FirebaseRecaptchaVerifierModal
          ref={this.recaptchaVerifier}
          firebaseConfig={{
            apiKey: "AIzaSyBXCHVpPcUaGLBf2d-CJMOAhBVTM5rh1Zc",
            authDomain: "erp-system-e5293.firebaseapp.com",
            databaseURL: "https://erp-system-e5293-default-rtdb.firebaseio.com",
            projectId: "erp-system-e5293",
            storageBucket: "erp-system-e5293.appspot.com",
            messagingSenderId: "1018452144309",
            appId: "1:1018452144309:web:19beeba515f68b311f4efc",
            measurementId: "G-RJ8NPP1MQS",
          }}
          attemptInvisibleVerification={true}
        />
        <View style={styles.logoHolder}>
          <Image
            source={logo}
            style={{ width: 230, height: 90, resizeMode: "center" }}
          />
        </View>
        {this.state.isOtpSend == false ? (
          //for unsend otp
          <View style={styles.loginHolder}>
            <Text style={{ fontSize: 22, fontWeight: "400" }}>
              Sign up for a new account below
            </Text>
            <View style={{ flexDirection: "row" }}>
              <TextInput
                mode="outlined"
                label="Code"
                value="+91"
                style={[styles.inputField, { width: 80 }]}
                editable={false}
              />
              <TextInput
                mode="outlined"
                label="Mobile Number"
                value={this.state.number}
                style={[styles.inputField, {}]}
                onChangeText={(text) => {
                  this.setState({ number: text });
                }}
                placeholder="Mobile Number"
                maxLength={10}
              />
            </View>
            <Text style={{ color: "#de5246" }}>{this.state.error}</Text>
            <Text style={{ fontSize: 16, color: "#8c8c8c" }}>
              By signing up, you agree to our terms and condition
            </Text>

            <Button
              disabled={this.state.loadingButton}
              mode="contained"
              onPress={() => {
                //   this.userLogin();
                //   this.setState({ loadingButton: true });
                this.validation();
              }}
              style={styles.loginButton}
            >
              {this.state.loadingButton ? (
                <ActivityIndicator animating={true} color="#fff" />
              ) : (
                "Send OTP"
              )}
            </Button>
            <Divider style={{ width: 350 }} />
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate("loginScreen");
              }}
            >
              <View
                style={{ flexDirection: "row", justifyContent: "space-evenly" }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textDecorationLine: "underline",
                  }}
                >
                  or, Sign in to your existing Account
                </Text>
                <AntDesign name="arrowright" size={24} color="#0a60ff" />
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          //When otp is send
          <View style={styles.loginHolder}>
            <Text
              style={{ fontSize: 18, fontWeight: "500", textAlign: "center" }}
            >
              We have sent you a OTP on your mobile number {this.state.number}
            </Text>

            <TextInput
              mode="outlined"
              label="OTP"
              value={this.state.verificationCode}
              style={[styles.inputField, {}]}
              onChangeText={(text) => {
                this.setState({ verificationCode: text });
              }}
              placeholder="OTP"
              maxLength={6}
            />
            <Text>{this.state.otpError}</Text>
            <Button
              disabled={this.state.isOtpButtonLoading}
              mode="contained"
              onPress={() => {
                this.checkOtp();
              }}
              style={styles.loginButton}
            >
              {this.state.isOtpButtonLoading ? (
                <ActivityIndicator animating={true} color="#fff" />
              ) : (
                "Sign up"
              )}
            </Button>
            <Divider style={{ width: 350 }} />
            <TouchableOpacity
              onPress={() => {
                this.setState({ isOtpSend: false });
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#000",
                  textDecorationLine: "underline",
                  alignSelf: "flex-start",
                }}
              >
                Cancel and start again
              </Text>
            </TouchableOpacity>
          </View>
        )}

        <View style={styles.footerHolder}>
          <Divider style={styles.divider} />
          <Text style={{ paddingVertical: 60 }}>
            Developed by{" "}
            <Text
              style={{ fontStyle: "italic", textDecorationLine: "underline" }}
              onPress={() => {
                WebBrowser.openBrowserAsync("https://squaretechapps.in");
              }}
            >
              SquareTech Apps.
            </Text>{" "}
            All Rights Reserved {new Date().getFullYear()}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  logoHolder: {
    flex: 1,
  },
  loginHolder: {
    flex: 3,
    width: 420,
    backgroundColor: "#EFEFEF",
    borderWidth: 1,
    borderColor: "#c0c0c0",
    borderRadius: 5,
    justifyContent: "space-around",
    alignItems: "center",
  },
  footerHolder: {
    flex: 2,
    paddingVertical: 20,
    alignItems: "center",
  },
  inputField: {
    width: 300,
  },
  loginButton: {
    backgroundColor: "#0a60ff",
    width: 400,
    padding: 5,
  },
  divider: {
    width: 750,
  },
});
