import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Modal, Portal, Provider, TextInput } from "react-native-paper";
import firebase from "firebase";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { Picker } from "@react-native-picker/picker";
import ProductTable from "../src/components/ProductTable";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class productScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setVisible: false,
      productName: "",
      hsn: "2517",
      gstCode: "5",
      availableQtyCft: "",
      uom: "",
      productDensity: "",
      buttonLoading: false,
      loginUser: "",
      uoid: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getLoginUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let loginId = firebase.auth().currentUser.email;
        if (loginId) {
          this.setState({ loginUser: loginId });
        }
      }
    });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    var rString = this.randomString(
      10,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    if (
      this.state.productName != "" &&
      this.state.hsn != "" &&
      this.state.gstCode != "" &&
      this.state.productDensity != "" &&
      this.state.uom != ""
    ) {
      this.setState({ buttonLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/productData/productVital/${rString}`
        );
      dbRef
        .set({
          productName: this.state.productName,
          uom: this.state.uom,
          hsn: this.state.hsn,
          gstCode: this.state.gstCode,
          productDensity: this.state.productDensity,
          availableQtyCft: this.state.availableQtyCft,
          addedAt: `${new Date()}`,
          addedBy: this.state.loginUser,
          id: rString,
        })
        .then(() => {
          this.setState({ buttonLoading: false, setVisible: false });
        });
    }
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Product Management" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar product="#c1c1c1" />
            <View style={styles.palyArea}>
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 750,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>New Product</Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Product Name"
                        value={this.state.productName}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          let regex = /^[a-zA-Z0-9_]*$/;
                          if (text.match(regex)) {
                            this.setState({ productName: text });
                          }
                        }}
                        placeholder="Product Name"
                      />
                      <View style={{ padding: 22 }}>
                        <Picker
                          selectedValue={this.state.uom}
                          style={{
                            padding: 12,
                            width: 150,
                            height: 40,
                            borderWidth: 1,
                            borderRadius: 5,
                          }}
                          onValueChange={(itemValue) =>
                            this.setState({ uom: itemValue })
                          }
                        >
                          <Picker.Item label="UOM" value="" />
                          <Picker.Item label="Brass" value="Brass" />
                          <Picker.Item label="cft" value="cft" />
                          <Picker.Item label="MT" value="MT" />
                          <Picker.Item label="m³" value="m³" />
                          <Picker.Item label="Unit" value="Unit" />
                        </Picker>
                      </View>
                      <TextInput
                        mode="outlined"
                        label="HSN Code"
                        value={this.state.hsn}
                        style={[styles.inputField, { width: 190 }]}
                        onChangeText={(text) => {
                          let regex = /^[0-9]*$/;
                          if (text.match(regex)) {
                            this.setState({ hsn: text });
                          }
                        }}
                        placeholder="HSN Code"
                      />
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="GST Code"
                        value={this.state.gstCode}
                        style={[styles.inputField, { width: 160 }]}
                        onChangeText={(text) => {
                          let regex = /^[0-9]*$/;
                          if (text.match(regex)) {
                            this.setState({ gstCode: text });
                          }
                        }}
                        placeholder="GST Code"
                      />
                      <TextInput
                        mode="outlined"
                        label="Density(kg/cft)"
                        value={this.state.productDensity}
                        style={[styles.inputField, { width: 200 }]}
                        onChangeText={(text) => {
                          let regex = /[0-9]+(\.[0-9][0-9]?)?/;
                          if (text.match(regex)) {
                            this.setState({ productDensity: text });
                          } else {
                            this.setState({ productDensity: "" });
                          }
                        }}
                        placeholder="Density(kg/cft)"
                      />

                      <TextInput
                        mode="outlined"
                        label="Avaliable(cft)"
                        value={this.state.availableQtyCft}
                        style={[styles.inputField, { width: 200 }]}
                        onChangeText={(text) => {
                          let regex = /^[0-9]*$/;
                          if (text.match(regex)) {
                            this.setState({ availableQtyCft: text });
                          }
                        }}
                        placeholder="Avaliable(cft)"
                      />
                      <TextInput
                        mode="outlined"
                        label="Avaliable(tonne)"
                        value={(
                          (this.state.availableQtyCft *
                            this.state.productDensity) /
                          1000
                        ).toFixed(2)}
                        style={[styles.inputField, { width: 200 }]}
                        placeholder="Avaliable(tonne)"
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: 30,
                      }}
                    >
                      <TouchableOpacity
                        disabled={this.state.buttonLoading}
                        style={[
                          styles.addNewButton,
                          {
                            width: 150,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          this.saveDataToDb();
                        }}
                      >
                        {this.state.buttonLoading ? (
                          <ActivityIndicator
                            animating={true}
                            color="#fff"
                            size="small"
                          />
                        ) : (
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            {" "}
                            Save Details
                          </Text>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            backgroundColor: "#fff",
                            width: 80,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ setVisible: false });
                        }}
                      >
                        <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                          Cancel
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>
              {/* Model Ends */}
              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Inventory Records
                  </Text>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.getLoginUser();
                      this.setState({ setVisible: true });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Add New Product</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.tableBody}>
                  <ProductTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 350,
    padding: 20,
    height: 40,
  },
});
