import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import NewSales from "./NewSales";
import { Picker } from "@react-native-picker/picker";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import {
  Modal,
  Provider,
  Portal,
  Searchbar,
  TextInput,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";

class OrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: [
        "Date",
        "Customer Name",
        "Item",
        "Quantity",
        "Order No.",
        "Status",
        "Action",
      ],
      searchQuery: "", //To Be Search in searchBar

      capacityInCft: "",

      postSorterAce: true,
      setVisible: false,

      uoid: "",
      setVisibleEditOrder: false, //////////////////

      customerName: "", //
      address: "", //
      customerId: "", //
      itemSearch: "", //
      qty: "", //
      total: "", //
      paymentMode: "", //
      lorrySearch: "", //
      isOurLorry: false, //
      orderId: "", //

      itemId: "", //
      itemUOM: "", //,
      lorryId: "",
      searchDataItem: [],
      mainDataItem: [],
      viewDropDownItem: false,
      ratePerUnit: "", ////////
      id: "",
      entryby: "",
      changesby: "",
      approved: null,
      approvedBy: "",
      approvedatTime: "",
      orderNo: "N/a", //
      loaded: false,
      loadedBy: "",
      loadedAt: "",
      currentOrderNo: 0,
      dispatched: false,
      dispatchedBy: "",
      dispatchAt: "",
      loginUser: {},
      setVisibleLoaded: false,
      searchDataLorry: [],
      mainDataLorry: [],
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.callDataFromDB();
            this.getLoginUser();
            this.getOrderIdCount();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getLorryData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/fleetData/vehicleVital`)
      .orderByChild("type")
      .equalTo("Truck");
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());

        this.setState({ searchDataLorry: data, mainDataLorry: data });
      }
    });
  };
  getOrderIdCount = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }

    this.setState({ fiscalyear }, () => {
      let DMRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}/orderNo`
        );
      DMRef.once("value", (dataSnap) => {
        if (dataSnap.exists()) {
          this.setState({
            currentOrderNo: dataSnap.val() + 1,
          });
        } else {
          DMRef.update({
            orderNo: 0,
          }).then(() => {
            this.getDmCount();
          });
        }
      });
    });
  };
  approveRejectOrder = (approvedStatus) => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/ordersData/ordersbasics/${this.state.orderId}`
      );

    dbRef
      .update({
        approved: approvedStatus,
        approvedBy: this.state.loginUser.name,
        approvedatTime: `${new Date()}`,
        status: approvedStatus ? "Approved" : "Rejected",
        orderNo: approvedStatus ? this.state.currentOrderNo : "-",
      })
      .then(() => {
        this.setState({ setVisibleEditOrder: false });
        if (approvedStatus) {
          let DMRef = firebase
            .database()
            .ref(
              `${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`
            );

          DMRef.update({
            orderNo: this.state.currentOrderNo,
          });
        }
      });
  };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  lorrycheck = () => {
    let found = this.state.mainDataLorry.find(
      (element) => element.plateNumber == this.state.lorrySearch
    );

    if (found) {
      this.setState({ isOurLorry: true }, () => {});
    } else {
      this.setState({ isOurLorry: false });
    }
  };
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/ordersData/ordersbasics`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.dateNumber > b.dateNumber) {
                return -1;
              }
              if (a.dateNumber < b.dateNumber) {
                return 1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.setState({ searchData: this.state.mainData });
          }
        );
      } else {
        this.setState({ isLoading: false, searchData: [] });
      }
    });
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.customerName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchData: newdata });
    } else {
      this.setState({ searchData: this.state.mainData });
    }
  };
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchDataItem: newdata });
    } else {
      this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  getItemData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());

        this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                column == "Status"
                  ? this.setState(
                      { postSorterAce: !this.state.postSorterAce },
                      () => {
                        this.tableRowSorter();
                      }
                    )
                  : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Status" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  searchLorryStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataLorry.filter((item) => {
        const itemData = item
          ? item.plateNumber.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchDataLorry: newdata });
    } else {
      this.setState({ searchDataLorry: this.state.mainDataLorry });
    }
  };
  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this.setState(
      {
        mainData: this.state.mainData.sort(function (a, b) {
          if (a.status > b.status) {
            return direction ? 1 : -1;
          }
          if (a.status < b.status) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        this.setState({ searchData: this.state.mainData });
      }
    );
  };
  closeMROModalFun = () => {
    this.setState({
      setVisible: false,
    });
  };
  saveDataToDb = () => {
    if (
      this.state.itemSearch != "" &&
      this.state.itemId != "" &&
      this.state.qty != "" &&
      this.state.address != "" &&
      this.state.paymentMode != ""
    ) {
      this.setState({ buttonLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/ordersData/ordersbasics/${this.state.orderId}`
        );
      dbRef
        .update({
          qty: this.state.qty,
          item: this.state.itemSearch,
          itemId: this.state.itemId,
          address: this.state.address,
          itemUOM: this.state.itemUOM,
          paymentMode: this.state.paymentMode,
          changesby: this.state.loginUser.name,
          ratePerUnit:
            this.state.paymentMode == "Cash" ? this.state.ratePerUnit : "-",
          total:
            this.state.paymentMode == "Cash"
              ? parseFloat(this.state.qty) * parseFloat(this.state.ratePerUnit)
              : "-",
        })
        .then(() => {
          this.setState({ buttonLoading: false, setVisible: false });
        });
    }
  };
  markAsLoaded = () => {
    this.setState({ buttonLoading: true });
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/ordersData/ordersbasics/${this.state.orderId}`
      );

    dbRef
      .update({
        qty: this.state.qty,
        total:
          this.state.paymentMode == "Cash"
            ? parseFloat(this.state.qty) * parseFloat(this.state.ratePerUnit)
            : "-",
        status: "Loaded",
        lorryNo: this.state.lorrySearch,
        lorryId: this.state.lorryId ? this.state.lorryId : "",
        loadedBy: this.state.loginUser.name,
        loadedAt: `${new Date()}`,
        isOurLorry: this.state.isOurLorry,
        loaded: true,
      })
      .then(() => {
        this.setState({ buttonLoading: false });
      });
  };
  buttonApperarLogic = () => {
    if (this.state.approved == null) {
      return (
        <View style={{ alignSelf: "flex-end" }}>
          <TouchableOpacity
            disabled={this.state.buttonLoading}
            style={[styles.addNewButton, { width: 175, margin: 10 }]}
            onPress={() => {
              if (this.state.loginUser.canAddOrdersToSalesAndroid) {
                if (this.state.paymentMode == "Cash") {
                  if (this.state.ratePerUnit != "") {
                    this.saveDataToDb();
                  }
                } else {
                  this.saveDataToDb();
                }
              }
            }}
          >
            {this.state.buttonLoading ? (
              <ActivityIndicator size={"small"} color="#fff" />
            ) : (
              <Text
                style={{
                  color: "#fff",
                  fontSize: 16,
                  textAlign: "center",
                }}
              >
                {this.state.loginUser.canAddOrdersToSalesAndroid
                  ? "Save Changes"
                  : "Not Allowed"}
              </Text>
            )}
          </TouchableOpacity>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              style={[
                styles.addNewButton,
                {
                  width: 175,
                  backgroundColor: "#009C44",
                  borderColor: "#009c44",
                  margin: 10,
                },
              ]}
              onPress={() => {
                if (this.state.loginUser.canApproveOrders) {
                  this.approveRejectOrder(true);
                }
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 16,
                  textAlign: "center",
                }}
              >
                {this.state.loginUser.canApproveOrders
                  ? "Approve"
                  : "Not Allowed"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.addNewButton,
                {
                  width: 175,
                  backgroundColor: "#DE5246",
                  borderColor: "#DE5246",
                  margin: 10,
                },
              ]}
              onPress={() => {
                if (this.state.loginUser.canApproveOrders) {
                  this.approveRejectOrder(false);
                }
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 16,
                  textAlign: "center",
                }}
              >
                {this.state.loginUser.canApproveOrders
                  ? "Reject"
                  : "Not Allowed"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.approved == false) {
      return (
        <View
          style={{
            alignItems: "center",
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: "#DE5246",
            backgroundColor: "#fbebe9",
          }}
        >
          <MaterialIcons name="cancel" size={24} color="#DE5246" />

          <Text style={{ fontSize: 14 }}>Order Rejected</Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            {this.state.approvedBy} {this.state.approvedatTime}
          </Text>
        </View>
      );
    } else if (this.state.approved == true && !this.state.loaded) {
      return (
        <View>
          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
            }}
          >
            <View>
              <MaterialIcons name="verified" size={24} color="#009C44" />
            </View>
            <Text style={{ fontSize: 14 }}>Order Approved</Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.approvedBy} {this.state.approvedatTime}
            </Text>
          </View>
          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              if (this.state.loginUser.canLoadLorry) {
                this.getLorryData();
                this.setState({
                  setVisibleLoaded: true,
                  setVisibleEditOrder: false,
                  lorrySearch: "",
                });
              }
            }}
          >
            {this.state.buttonLoading ? (
              <ActivityIndicator size={"small"} color="#fff" />
            ) : (
              <Text
                style={{
                  color: "#fff",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                {this.state.loginUser.canLoadLorry
                  ? "Mark as Loaded"
                  : "Not Allowed"}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      );
    } else if (
      this.state.approved == true &&
      this.state.loaded == true &&
      !this.state.dispatched
    ) {
      return (
        <View>
          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
              marginBottom: 10,
              height: 64,
              justifyContent: "center",
            }}
          >
            <View>
              <MaterialIcons name="verified" size={24} color="#009C44" />
            </View>
            <Text style={{ fontSize: 14 }}>Order Approved</Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.approvedBy} {this.state.approvedatTime}
            </Text>
          </View>

          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
              marginBottom: 10,
              height: 64,
              justifyContent: "center",
            }}
          >
            <View>
              <MaterialCommunityIcons
                name="truck-check"
                size={25}
                color="#009C44"
              />
            </View>
            <Text style={{ fontSize: 14 }}>
              Order Loaded {this.state.lorrySearch}
            </Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.loadedBy} {this.state.loadedAt}
            </Text>
          </View>
          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              if (this.state.loginUser.canGenerateDM) {
                this.setState({ setVisible: true, setVisibleEditOrder: false });
              }
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 18,
                textAlign: "center",
              }}
            >
              {this.state.loginUser.canGenerateDM
                ? "Generate D.M"
                : "Not Allowed"}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else if (
      this.state.approved == true &&
      this.state.loaded == true &&
      this.state.dispatched == true
    ) {
      return (
        <View>
          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
              marginBottom: 10,
              height: 64,
              justifyContent: "center",
            }}
          >
            <View>
              <MaterialIcons name="verified" size={24} color="#009C44" />
            </View>
            <Text style={{ fontSize: 14 }}>Order Approved</Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.approvedBy} {this.state.approvedatTime}
            </Text>
          </View>

          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
              marginBottom: 10,
              height: 64,
              justifyContent: "center",
            }}
          >
            <View>
              <MaterialCommunityIcons
                name="truck-check"
                size={25}
                color="#009C44"
              />
            </View>
            <Text style={{ fontSize: 14 }}>
              Order Loaded {this.state.lorrySearch}
            </Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.loadedBy} {this.state.loadedAt}
            </Text>
          </View>
          <View
            style={{
              alignItems: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderColor: "#009C44",
              backgroundColor: "#e6fff1",
              height: 64,
              justifyContent: "center",
            }}
          >
            <View>
              <MaterialCommunityIcons
                name="truck-fast"
                size={24}
                color="#009C44"
              />
            </View>
            <Text style={{ fontSize: 14 }}>Order Dispatched</Text>
            <Text style={{ fontSize: 14, textAlign: "center" }}>
              {this.state.dispatchedBy} {this.state.dispatchAt}
            </Text>
          </View>
        </View>
      );
    }
  };
  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <View stle={styles.searchbarBody}>
          <Searchbar
            placeholder="Search by Customer"
            onChangeText={(text) => {
              this.setState({ searchQuery: text }, () => {
                this.searchStringInArray(this.state.searchQuery);
              });
            }}
            value={this.state.searchQuery}
          />
        </View>
        {/* Model starts  */}
        <Portal>
          <Modal
            visible={this.state.setVisible}
            onDismiss={() => {
              this.setState({ setVisible: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              width: 900,

              position: "absolute",
              top: 80,
              alignSelf: "center",
            }}
          >
            <NewSales
              closeMROModal={this.closeMROModalFun}
              searchQuery={this.state.customerName}
              address={this.state.address}
              customerId={this.state.customerId}
              itemSearch={this.state.itemSearch}
              qty={this.state.qty} /////////////////////////
              total={this.state.total}
              paymentMode={this.state.paymentMode}
              lorrySearch={this.state.lorrySearch}
              isOurLorry={this.state.isOurLorry}
              orderId={this.state.orderId}
              orderNo={this.state.orderNo}
              itemId={this.state.itemId}
              itemUOM={this.state.itemUOM}
              editable={false}
              lorryId={this.state.lorryId}
            />
          </Modal>
        </Portal>
        <Portal>
          {" "}
          {/* Edit Model Start*/}
          <Modal
            visible={this.state.setVisibleLoaded}
            onDismiss={() => {
              this.setState({ setVisibleLoaded: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 300,
              borderRadius: 5,
              position: "absolute",
              top: 80,
              alignSelf: "center",
              zIndex: 2,
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Confirm Loading Order No {this.state.orderNo}
              </Text>
            </View>
            <View style={{ paddingTop: 10 }}>
              <View>
                <Text style={styles.fieldLable}>Confirm Quantity</Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    style={[styles.inputField, { width: 160 }]}
                    onChangeText={(text) => {
                      this.setState({ qty: text });
                    }}
                    mode="outlined"
                    label="Quantity"
                    value={this.state.qty}
                    placeholder="Quantity"
                  />
                  <Text style={{ alignSelf: "center", fontSize: 28 }}>
                    {this.state.itemUOM}
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.fieldLable}>Vehicle Number</Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    style={[styles.inputField, { width: 170 }]}
                    onChangeText={(text) => {
                      this.setState({ lorrySearch: text }, () => {
                        this.searchLorryStringInArray(this.state.lorrySearch);

                        this.setState({ viewDropDownLorry: true });
                        this.lorrycheck();
                      });
                    }}
                    mode="outlined"
                    label="Vehicle Number"
                    value={this.state.lorrySearch}
                    placeholder="Vehicle Number"
                  />
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      { height: 40, alignSelf: "center", width: 110 },
                    ]}
                    onPress={() => {
                      if (this.state.lorrySearch != "") {
                        this.setState({ setVisibleLoaded: false });
                        this.markAsLoaded();
                      }
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      Save
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              {this.state.viewDropDownLorry ? (
                <FlatList
                  data={this.state.searchDataLorry}
                  renderItem={({ item }) => {
                    return (
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#fff",
                          padding: 10,
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          width: 160,
                          alignItems: "center",
                          maxHeight: 300,
                        }}
                        onPress={() => {
                          this.setState(
                            {
                              lorrySearch: item.plateNumber,
                              lorryId: item.id,
                            },
                            () => {
                              this.lorrycheck();

                              this.setState({
                                viewDropDownLorry: false,
                              });
                            }
                          );
                        }}
                      >
                        <Text style={{ color: "#000", fontSize: 18 }}>
                          {item.plateNumber}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                  keyExtractor={(item) => JSON.stringify(item.id)}
                />
              ) : null}
            </View>
          </Modal>
        </Portal>
        <Portal>
          <Modal
            visible={this.state.setVisibleEditOrder}
            onDismiss={() => {
              this.setState({
                buttonLoading: false,
                setVisibleEditOrder: false,
                customerName: "",
                customerId: "",
                itemId: "",
                itemSearch: "",
                itemUOM: "",
                paymentMode: "",
                qty: "",
                ratePerUnit: "",
                address: "",
              });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 750,
              borderRadius: 5,

              position: "absolute",
              top: 80,
              alignSelf: "center",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Edit Order{" "}
                {this.state.approved == true
                  ? `Order No.${this.state.orderNo}`
                  : null}
              </Text>
            </View>
            <View style={styles.modelBody}>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  editable={this.state.approved == null ? true : false}
                  mode="outlined"
                  label="Customer Name"
                  value={this.state.customerName}
                  style={styles.inputField}
                  placeholder="Customer Name"
                />

                <TextInput
                  editable={this.state.approved == null ? true : false}
                  mode="outlined"
                  label="Delivery Address"
                  value={this.state.address}
                  style={styles.inputField}
                  onChangeText={(text) => {
                    this.setState({ address: text });
                  }}
                  placeholder="Delivery Address"
                />
              </View>
              <View style={{ flexDirection: "row", zIndex: -1 }}>
                <TextInput
                  editable={this.state.approved == null ? true : false}
                  mode="outlined"
                  label="Select Item"
                  value={this.state.itemSearch}
                  style={[styles.inputField]}
                  onChangeText={(text) => {
                    this.setState({ itemSearch: text }, () => {
                      this.searchItemStringInArray(this.state.itemSearch);
                      this.setState({ viewDropDownItem: true });
                    });
                  }}
                  placeholder="Select Item"
                />

                {this.state.viewDropDownItem ? (
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: "#000",
                      borderRadius: 5,
                      position: "absolute",
                      marginTop: 70,
                      shadowOffset: {
                        width: 0,
                        height: 0,
                      },
                      shadowOpacity: 0.2,
                      shadowRadius: 5,
                      zIndex: 5,
                      marginLeft: 20,
                      maxHeight: 150,
                    }}
                  >
                    <FlatList
                      data={this.state.searchDataItem}
                      renderItem={({ item }) => {
                        return (
                          <TouchableOpacity
                            style={{
                              backgroundColor: "#fff",
                              padding: 10,
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              width: 210,
                              alignItems: "center",
                            }}
                            onPress={() => {
                              this.setState(
                                {
                                  itemSearch: item.productName,
                                  itemId: item.id,
                                },
                                () => {
                                  // this.getUOM();
                                  this.setState({
                                    viewDropDownItem: false,
                                  });
                                }
                              );
                            }}
                          >
                            <Text style={{ color: "#000" }}>
                              {item.productName}
                            </Text>
                          </TouchableOpacity>
                        );
                      }}
                      keyExtractor={(item) => JSON.stringify(item.id)}
                    />
                  </View>
                ) : null}
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    editable={this.state.approved == null ? true : false}
                    mode="outlined"
                    label="Quantity"
                    value={this.state.qty}
                    style={[styles.inputField, { width: 175 }]}
                    onChangeText={(text) => {
                      this.setState({ qty: text });
                    }}
                    placeholder="Quantity"
                  />
                  <Text style={{ padding: 20, marginTop: 16, fontSize: 22 }}>
                    {this.state.itemUOM}
                  </Text>
                </View>
                {/* Add here more Fields */}
              </View>
              <View style={{ flexDirection: "row", zIndex: -2 }}>
                <Picker
                  selectedValue={this.state.paymentMode}
                  enabled={this.state.approved == null ? true : false}
                  style={{
                    height: 40,
                    width: 310,
                    marginHorizontal: 22,
                    marginVertical: 25,
                  }}
                  onValueChange={(itemValue, itemIndex) =>
                    this.setState({ paymentMode: itemValue })
                  }
                >
                  <Picker.Item label="Payment Mode" value="" />
                  <Picker.Item label="Credit" value="Credit" />
                  <Picker.Item label="Cash" value="Cash" />
                </Picker>
                {this.state.paymentMode == "Cash" ? (
                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      mode="outlined"
                      editable={this.state.approved == null ? true : false}
                      label="Rate per Unit"
                      value={this.state.ratePerUnit}
                      style={[styles.inputField, { width: 175 }]}
                      onChangeText={(text) => {
                        this.setState({ ratePerUnit: text });
                      }}
                      placeholder="Rate per Unit"
                    />

                    <Text
                      style={{
                        marginHorizontal: 20,
                        height: 42,
                        marginTop: 26,
                        fontSize: 22,
                      }}
                    >
                      Total Rs.{" "}
                      {parseFloat(this.state.qty) *
                      parseFloat(this.state.ratePerUnit)
                        ? parseFloat(this.state.qty) *
                          parseFloat(this.state.ratePerUnit)
                        : "0.0"}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={{ flexDirection: "row", zIndex: -2 }}>
                <TextInput
                  mode="outlined"
                  label="Entry By"
                  value={this.state.entryby}
                  style={[styles.inputField]}
                  placeholder="Entry By"
                />
                {this.state.changesby != "" ? (
                  <TextInput
                    mode="outlined"
                    label="Last Edited By"
                    value={this.state.changesby}
                    style={[styles.inputField, { width: 175 }]}
                    placeholder="Last Edited By"
                  />
                ) : null}

                {/* Add here more Fields */}
              </View>
              {this.buttonApperarLogic()}
            </View>
          </Modal>
        </Portal>
        {/* Model Ends */}
        <FlatList
          data={this.state.searchData}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor:
                      item.approved == null
                        ? "#FBEBE9"
                        : item.approved == true && !item.loaded
                        ? "#E6FFF1"
                        : item.approved == false
                        ? "#fff"
                        : item.loaded && !item.dispatched
                        ? "#fff8e6"
                        : item.dispatched
                        ? "#8af4c1"
                        : "#fff",
                  },
                ]}
              >
                <Text style={styles.columnRowTxt}>
                  {new Date(item.dateNumber).toDateString()}
                </Text>
                <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                  {item.customerName}
                </Text>

                <Text style={styles.columnRowTxt}>{item.item}</Text>
                <Text style={styles.columnRowTxt}>
                  {item.qty} {item.itemUOM}
                </Text>
                <Text style={styles.columnRowTxt}>
                  {item.orderNo ? item.orderNo : "-"}
                </Text>
                <Text style={styles.columnRowTxt}>{item.status}</Text>
                <View
                  style={{
                    width: "14.28%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() => {
                      this.getItemData();
                      this.setState({
                        setVisibleEditOrder: true,
                        customerName: item.customerName,
                        customerId: item.customerId,
                        address: item.address,
                        itemSearch: item.item,
                        qty: item.qty,
                        itemUOM: item.itemUOM,
                        paymentMode: item.paymentMode,
                        ratePerUnit: item.ratePerUnit,
                        orderId: item.id,
                        itemId: item.itemId,
                        entryby: item.addedBy,
                        total: item.total,
                        changesby: item.changesby ? item.changesby : "",
                        approvedBy: item.approvedBy ? item.approvedBy : "",
                        approvedatTime: item.approvedatTime,
                        orderNo: item.orderNo ? item.orderNo : "N/a",
                        approved: item.approved,
                        loaded: item.loaded,
                        loadedBy: item.loadedBy,
                        loadedAt: item.loadedAt,
                        dispatched: item.dispatched,
                        dispatchedBy: item.dispatchedBy,
                        dispatchAt: item.dispatchAt,
                        lorrySearch: item.lorryNo,
                        lorryId: item.lorryId,
                        isOurLorry: item.isOurLorry,
                      });
                    }}
                    // TODO:
                  >
                    <Text style={{ color: "#075e54" }}>View</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
          ListHeaderComponent={this.tableHeader}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
              }}
            >
              {this.state.isLoading ? (
                <ActivityIndicator size="large" color="#0a60ff" />
              ) : (
                <Text>No Order Found</Text>
              )}
            </View>
          }
          keyExtractor={(item) => JSON.stringify(item.dateNumber)}
        />
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <OrderTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "14.28%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "14.28%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 350,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
