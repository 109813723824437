import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Linking,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { TextInput, Button, Divider } from "react-native-paper";
import * as WebBrowser from "expo-web-browser";
import logo from "../src/images/squaretechsmall.png";
import firebase from "firebase";
import { AntDesign } from "@expo/vector-icons";
export default class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EID: "",
      code: "",
      error: null,
      loadingButton: false,
    };
  }

  userLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.EID, this.state.code)
      .then((userCredential) => {})
      .catch((error) => {
        this.setState({ error: JSON.stringify(error.message) });
        this.setState({ loadingButton: false });
      });
  };
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.logoHolder}>
          <Image
            source={logo}
            style={{ width: 230, height: 90, resizeMode: "center" }}
          />
        </View>
        <View style={styles.loginHolder}>
          <Text style={{ fontSize: 30, fontWeight: "500" }}>Sign In</Text>
          <TextInput
            mode="outlined"
            label="EID"
            value={this.state.EID}
            style={styles.inputField}
            onChangeText={(eid) => {
              this.setState({ EID: eid });
            }}
            placeholder="EID"
          />
          <TextInput
            mode="outlined"
            label="Code"
            value={this.state.code}
            onChangeText={(code) => {
              this.setState({ code: code });
            }}
            secureTextEntry
            style={styles.inputField}
            placeholder="Code"
          />
          <Text>{this.state.error}</Text>
          <Button
            disabled={this.state.loadingButton}
            mode="contained"
            onPress={() => {
              this.userLogin();
              this.setState({ loadingButton: true });
            }}
            style={styles.loginButton}
          >
            {this.state.loadingButton ? (
              <ActivityIndicator animating={true} color="#fff" />
            ) : (
              "Login"
            )}
          </Button>
          <Divider style={{ width: 350 }} />
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate("mobileVerification");
            }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "#000",
                  textDecorationLine: "underline",
                }}
              >
                Don’t have an account with us? Create Now
              </Text>
              <AntDesign name="arrowright" size={24} color="#0a60ff" />
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.footerHolder}>
          <Divider style={styles.divider} />
          <Text style={{ paddingVertical: 60 }}>
            Developed by{" "}
            <Text
              style={{ fontStyle: "italic", textDecorationLine: "underline" }}
              onPress={() => {
                WebBrowser.openBrowserAsync("https://squaretechapps.in");
              }}
            >
              SquareTech Apps.
            </Text>{" "}
            All Rights Reserved {new Date().getFullYear()}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  logoHolder: {
    flex: 1,
  },
  loginHolder: {
    flex: 3,
    width: 420,
    backgroundColor: "#EFEFEF",
    borderWidth: 1,
    borderColor: "#c0c0c0",
    borderRadius: 5,
    justifyContent: "space-around",
    alignItems: "center",
  },
  footerHolder: {
    flex: 2,
    paddingVertical: 20,
    alignItems: "center",
  },
  inputField: {
    width: 350,
  },
  loginButton: {
    backgroundColor: "#0a60ff",
    width: 400,
    padding: 5,
  },
  divider: {
    width: 750,
  },
});
