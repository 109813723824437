import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
} from "react-native";
import {
  TextInput,
  Provider,
  ActivityIndicator,
  Modal,
  Portal,
  Checkbox,
  Snackbar,
} from "react-native-paper";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { jsPDF } from "jspdf";
export default class salesReportViewScreen extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      date: new Date(),
      customerId: "",
      salesId: "",
      //

      time: "",
      lorryNo: "",
      royaltyNo: "",
      dmNo: "",
      item: "",
      sellQtyCft: "",
      totalAmount: "",
      receivedAmount: "",
      paymentMode: "",
      balanceAmount: "",
      driverName: "",
      deliveryAdd: "",
      entryBy: "", //
      customerName: "",
      gst: "",
      address: "", //
      receivedBalance: "",
      checkedByAuthPerson: false,
      isMarkforBillGen: false,
      isBillGenertd: false,
      isMarkforLedgerGen: false,
      isLedgerEntryDone: false,
      error: "",
      loginUser: {},
      lastEntry: [],
      dateStamp: "",
      stringDate: "",
      uoid: "",
      itemUOM: "",
      sellQtyButLoading: false,
      //
      convertModal: false,
      total: "",
      received: "",
      poNumber: "",
      isactive: true,
      SnackbarVisible: false,
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
      isUpdateloading: false,
      isConvertLoading: false,
      transportIncluded: false,
      modalCancelDM: false,
      fiscalyearForDMCall: "",
      DownloadImageURL: "empty",
      setVisibleViewImage: false,
      extensionType: "",
      dmDownloadImageURL: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    const { customerId, salesId, fiscalyearForDMCall } =
      this.props.route.params;
    this._isMounted &&
      this.setState({ customerId, salesId, fiscalyearForDMCall });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid, customerId, salesId }, () => {
              this._isMounted && this.getDataFromDb();
              this._isMounted && this.getCustomerDetails();
              this._isMounted && this.getLoginUser();
              this._isMounted && this.getLastEntry();
              this._isMounted && this.getCompanyData();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  downloadURL = () => {
    const ref = firebase
      .storage()
      .ref()
      .child(
        `close/${this.state.uoid}/DmPhoto/${this.state.customerId}/${this.state.fiscalyearForDMCall}`
      )
      .child(this.state.salesId + "." + this.state.extensionType);

    // Get the download URL
    ref.getDownloadURL().then((url) => {
      this._isMounted &&
        this.setState({ DownloadImageURL: url, isLoading: false });
    });
  };

  makeEntryInCustomerLEdger = (creditAmout) => {
    let date = new Date();
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    // let ledgerTime = new Date();
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/ledger/${this.state.customerId}/${date}`
      );
    ledgerRef.set({
      ledgerId: ledgerId,
      transDate: date.toDateString(),
      dateNumber: Date.parse(date),
      discription: `Received Pending DM-${this.state.dmNo}`,
      credit: creditAmout,
      debit: 0,
      isEditable: false,
      timeStampId: `${date}`,
      isCashEntry: true,
      // balance: parseFloat(
      //   parseFloat(this.state.lastEntry.balance) +
      //     parseFloat(this.state.debitAmt) -
      //     parseFloat(this.state.creditAmt)
      // ).toFixed(2),
    });
  };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;

    if (loginId != null) {
      let userid = loginId
        ? loginId.split(".")[0]
        : firebase.auth().currentUser.phoneNumber;
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this._isMounted && this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  getCustomerDetails = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this._isMounted &&
          this.setState({
            customerName: data.customerName,
            gst: data.gst,
            address: data.address,
          });
      }
    });
  };
  getDataFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this._isMounted &&
          this.setState({
            date: data.date,
            time: data.time,
            lorryNo: data.lorryNo,
            royaltyNo: data.royaltyNo,
            dateStamp: data.dateStamp,
            stringDate: data.dateString,
            dmNo: data.dmNo,
            item: data.item,
            sellQtyCft: data.sellQtyCft,
            totalAmount: data.totalAmount,
            receivedAmount: data.receivedAmount,
            paymentMode: data.paymentMode,
            balanceAmount: data.balanceAmount,
            driverName: data.driverName,
            deliveryAdd: data.deliveryAddress,
            entryBy: data.addedby,
            checkedByAuthPerson: data.checkedByAuthPerson,
            isMarkforBillGen: data.isMarkforBillGen,
            isBillGenertd: data.isBillGenertd,
            isMarkforLedgerGen: data.isMarkforLedgerGen,
            isLedgerEntryDone: data.isLedgerEntryDone,
            itemUOM: data.itemUOM,
            poNumber: data.poNumber,
            transportIncluded: data.transportIncluded,
            isactive: data.isactive,
            extensionType: data.extensionType,
            dmDownloadImageURL: data.dmDownloadImageURL
              ? data.dmDownloadImageURL
              : false,
          });
      }
    });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  upDateBalance = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );
    dbRef
      .update({
        receivedAmount:
          parseFloat(this.state.receivedAmount) +
          parseFloat(this.state.receivedBalance),
        balanceAmount:
          parseFloat(this.state.totalAmount) -
          (parseFloat(this.state.receivedAmount) +
            parseFloat(this.state.receivedBalance)),
      })
      .then(() => {
        this.makeEntryInCustomerLEdger(this.state.receivedBalance);
        if (this.state.loginUser.isSiteLogin == true) {
          this.updateOrgCashBook();
        } else if (this.state.loginUser.isSiteLogin == false) {
          this.updateAdminCashBook();
        }
      })
      .catch(); //TRy console.log
  };
  convertToCash = () => {
    this._isMounted && this.setState({ isConvertLoading: true });
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );

    dbRef
      .update({
        paymentMode: "Cash",
        totalAmount: this.state.total,
        receivedAmount: this.state.received,
        balanceAmount:
          parseFloat(this.state.total) - parseFloat(this.state.received),
      })
      .then(() => {
        this.makeEntryInCustomerLEdger(this.state.received);
        if (this.state.loginUser.isSiteLogin == true) {
          this.updateOrgCashBookbyConvert();
        } else if (this.state.loginUser.isSiteLogin == false) {
          this.updateAdminCashBookbyConvert();
        }
      });
  };
  ctcrfi = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );

    dbRef
      .update({
        paymentMode: this.state.paymentMode == "Credit" ? "Cash" : "Credit",
      })
      .then(() => {});
  };
  updateAllSalesReport = () => {
    if (this.state.receivedBalance != "") {
      this._isMounted && this.setState({ isUpdateloading: true });
      let dbref = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/productData/allSalesData/${Date.parse(
            new Date(this.state.stringDate)
          )}/${this.state.dateStamp}`
        );
      dbref
        .update({
          receivedAmount:
            parseFloat(this.state.receivedAmount) +
            parseFloat(this.state.receivedBalance),
        })
        .then(() => {
          this.upDateBalance();
        })
        .catch((error) => {
          this._isMounted &&
            this.setState({ error: error.message, isUpdateloading: false });
        });
    }
  };
  updateAllSalesReportbyConvert = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/allSalesData/${Date.parse(
          new Date(this.state.stringDate)
        )}/${this.state.dateStamp}`
      );
    dbref
      .update({
        receivedAmount: parseFloat(this.state.received),
      })
      .then(() => {
        this._isMounted &&
          this.setState({ isConvertLoading: false }, () => {
            this._isMounted &&
              this.setState({
                SnackbarVisible: true,

                date: new Date(),
                error: "Successfully !",
                buttonLoading: false,
                convertModal: false,
              });
            setTimeout(() => {
              this._isMounted && this.setState({ SnackbarVisible: false });
            }, 2000);
          });
      });
  };
  getLastEntry = () => {
    let ledgerRef1 = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/ledger/FY2021_22`)
      .orderByChild(`dateNumber`)
      .limitToLast(1);

    ledgerRef1.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this._isMounted && this.setState({ lastEntry: data[0] });
      }
    });
  };
  updateOrgCashBook = () => {
    let date = new Date();
    this._isMounted && this.setState({ buttonLoading: true });
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${date}`
      );
    ledgerRef
      .set({
        ledgerId: ledgerId,
        dateNumber: Date.parse(date),
        dateStamp: Date.parse(new Date().toDateString()),
        transDate: new Date().toDateString(),
        discription: `${this.state.dmNo}-${this.state.customerName} Pending Received`,
        credit: this.state.receivedBalance,
        debit: 0,
        balance: parseFloat(
          parseFloat(this.state.lastEntry.balance) +
            parseFloat(this.state.receivedBalance)
        ).toFixed(2),
      })
      .then(() => {
        this.updateAdminCashBook();
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error.message });
      });
  };
  updateAdminCashBook = () => {
    let date = new Date();
    this._isMounted && this.setState({ buttonLoading: true });
    var transactionId = this.randomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${date}`
      );
    ledgerRef
      .set({
        transactionId: transactionId,
        dateStamp: Date.parse(new Date().toDateString()),
        timestamp: Date.parse(date),
        date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
        discription: `${this.state.dmNo}-${this.state.customerName} Pending Received`,
        cashIn: parseFloat(this.state.receivedBalance),
        cashOut: 0,
        entryBy: this.state.loginUser.name,
      })
      .then(() => {
        this._isMounted &&
          this.setState(
            {
              buttonLoading: false,
              isUpdateloading: false,
              receivedBalance: "",
            },
            () => {
              this._isMounted &&
                this.setState({
                  SnackbarVisible: true,

                  date: new Date(),
                  error: "Successfully !",
                  buttonLoading: false,
                });
              setTimeout(() => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              }, 2000);
            }
          );
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error.message });
      });
  };
  updateOrgCashBookbyConvert = () => {
    let date = new Date();
    this._isMounted && this.setState({ buttonLoading: true });
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${date}`
      );
    ledgerRef
      .set({
        ledgerId: ledgerId,
        dateNumber: Date.parse(date),
        dateStamp: Date.parse(date.toDateString()),
        transDate: date.toDateString(),
        discription: `${this.state.dmNo}-${this.state.customerName} CC Pending Received`,
        credit: this.state.received,
        debit: 0,
        balance: parseFloat(
          parseFloat(this.state.lastEntry.balance) +
            parseFloat(this.state.received)
        ).toFixed(2),
      })
      .then(() => {
        this.updateAdminCashBookbyConvert();
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error.message });
      });
  };
  updateAdminCashBookbyConvert = () => {
    let date = new Date();
    this._isMounted && this.setState({ buttonLoading: true });
    var transactionId = this.randomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${date}`
      );
    ledgerRef
      .set({
        transactionId: transactionId,
        dateStamp: Date.parse(date.toDateString()),
        timestamp: Date.parse(date),
        date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
        discription: `${this.state.dmNo}-${this.state.customerName} CC Pending Received`,
        cashIn: parseFloat(this.state.received),
        cashOut: 0,
        entryBy: this.state.loginUser.name,
      })
      .then(() => {
        this.updateAllSalesReportbyConvert();
      })
      .catch((error) => {
        this._isMounted && this.setState({ error: error.message });
      });
  };
  markAsChecked = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );
    dbRef
      .update({
        checkedByAuthPerson: !this.state.checkedByAuthPerson,
      })
      .then(() => {
        this.getunCheckedDm();
      });
  };
  getunCheckedDm = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}`
      )
      .orderByChild("checkedByAuthPerson")
      .equalTo(false);
    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        let finalData = data.filter((item) => item.isactive != false).length;

        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
          );
        dbref1.update({
          unCheckedDM: finalData,
        });
      } else {
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
          );
        dbref1.update({
          unCheckedDM: 0,
        });
      }
    });
  };
  markForBillGen = () => {
    if (this.state.checkedByAuthPerson) {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
        );
      dbRef.update({
        isMarkforBillGen: !this.state.isMarkforBillGen,
      });
    }
  };
  markForLedgerGen = () => {
    if (this.state.checkedByAuthPerson) {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
        );
      dbRef.update({
        isMarkforLedgerGen: !this.state.isMarkforLedgerGen,
      });
    }
  };
  updateQty = () => {
    if (this.state.sellQtyCft != "") {
      this._isMounted && this.setState({ sellQtyButLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
        );
      dbRef
        .update({
          sellQtyCft: this.state.sellQtyCft,
        })
        .then(() => {
          let dbref = firebase
            .database()
            .ref(
              `${
                this.state.uoid
              }/factoryData/productData/allSalesData/${Date.parse(
                new Date(this.state.stringDate)
              )}/${this.state.dateStamp}`
            );
          dbref
            .update({
              totalQtySales:
                this.state.itemUOM == "m³"
                  ? parseFloat(this.state.sellQtyCft) * 35.315
                  : this.state.itemUOM == "MT"
                  ? parseFloat(this.state.sellQtyCft) * 23.24
                  : this.state.sellQtyCft,
            })
            .then(() => {
              this._isMounted && this.setState({ sellQtyButLoading: false });
            });
        })
        .catch(); //TRy console.log
    }
  };
  inactiveEntry = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );
    dbRef
      .update({
        isactive: false,
      })
      .then(() => {
        let dbref = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/productData/allSalesData/${Date.parse(
              new Date(this.state.stringDate)
            )}/${this.state.dateStamp}`
          );
        dbref.remove().then(() => {
          this.getunCheckedDm();
        });
      });
  };
  isTransportIncluded = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${this.state.salesId}`
      );
    dbRef.update({
      transportIncluded: !this.state.transportIncluded,
    });
  };
  dmPdfDownload = () => {
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.rect(5, 42, 135, 135);

    doc.setFont("times", "italic");
    doc.setFontSize(20);
    doc.text(
      74.25,
      12,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text(
      74.25,
      18,
      `Plant Address:- ${this.state.companyData.addressOne}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(
      74.25,
      24,
      `Office:- ${this.state.companyData.addressTwo}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(74.25, 30, "(Registered Under Small Scale Industries)", "center");
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(
      5,
      38,
      `Mobile No.: ${
        this.state.companyData.contactNo != ""
          ? this.state.companyData.contactNo
          : this.state.uoid
      }`
    );
    doc.text(138, 38, `D.M. no.: ${this.state.dmNo}`, "right");
    doc.setFont("times");
    doc.setFontSize(14);

    doc.rect(5, 50, 135, 0, "F");
    doc.rect(5, 82, 135, 0, "F");
    doc.rect(5, 89, 135, 0, "F");
    doc.rect(20, 82, 0, 95, "F");
    doc.rect(103, 82, 0, 95, "F");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(50, 48, `DELIVERY CHALLAN`);

    doc.setFontSize(12);
    doc.text(7, 58, `Date`);
    doc.setFontSize(12);
    doc.text(28, 58, `: ${this.state.date}`);
    doc.text(78, 58, `Time`);
    doc.text(100, 58, `: ${this.state.time}`);

    doc.setFontSize(12);
    doc.text(7, 65, `Vehicle No.`);
    doc.text(28, 65, `: ${this.state.lorryNo}`);
    doc.text(78, 65, `Royalty No.`);
    doc.text(100, 65, doc.splitTextToSize(`${this.state.royaltyNo}`, 40));

    doc.setFontSize(13);
    doc.setFont("times", "bold");
    doc.text(7, 72, `Name`);
    doc.text(28, 72, `: ${this.state.customerName}`);
    doc.text(78, 79, `P.O.No.`);
    doc.text(100, 79, `: ${this.state.poNumber}`);

    doc.text(7, 79, `Address`);
    doc.text(28, 79, `: ${this.state.address}`);
    doc.setFont("times", "normal");

    doc.text(7, 87, "Sr.No.");
    doc.text(45, 87, "Particulars");
    doc.text(115, 87, "Quantity");

    doc.text(11, 98, "1");
    doc.text(24, 98, `${this.state.item} Metal`);
    doc.text(
      131,
      98,
      `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
      "right"
    );

    doc.text(7, 195, `Receiver's Signature`);
    doc.text(
      140,
      195,
      `For, ${this.state.companyData.organizationName}`,
      "right"
    );

    doc.rect(153, 42, 135, 135);

    doc.setFont("times", "italic");
    doc.setFontSize(20);
    doc.text(
      222.75,
      12,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text(
      222.75,
      18,
      `Plant Address:- ${this.state.companyData.addressOne}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(
      222.75,
      24,
      `Office:- ${this.state.companyData.addressTwo}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(222.75, 30, "(Registered Under Small Scale Industries)", "center");
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(
      153,
      38,
      `Mobile No.: ${
        this.state.companyData.contactNo != ""
          ? this.state.companyData.contactNo
          : this.state.uoid
      }`
    );
    doc.text(287, 38, `D.M. no.: ${this.state.dmNo}`, "right");
    doc.setFont("times");
    doc.setFontSize(14);

    doc.rect(153, 50, 135, 0, "F");
    doc.rect(153, 82, 135, 0, "F");
    doc.rect(153, 89, 135, 0, "F");
    doc.rect(169, 82, 0, 95, "F");
    doc.rect(250, 82, 0, 95, "F");

    doc.setFontSize(12);
    doc.text(155, 58, `Date`);
    doc.setFontSize(12);
    doc.text(176, 58, `:  ${this.state.date}`);
    doc.text(226, 58, `Time`);
    doc.text(248, 58, `:  ${this.state.time}`);

    doc.setFontSize(12);
    doc.text(155, 65, `Vehicle No.`);
    doc.text(176, 65, `: ${this.state.lorryNo}`);
    doc.text(226, 65, `Royalty No.`);
    doc.text(248, 65, doc.splitTextToSize(`${this.state.royaltyNo}`, 40));

    doc.setFontSize(13);
    doc.setFont("times", "bold");
    doc.text(155, 72, `Name`);
    doc.text(176, 72, `: ${this.state.customerName}`);
    doc.text(226, 79, `P.O.No.`);
    doc.text(248, 79, `: ${this.state.poNumber}`);

    doc.text(155, 79, `Address`);
    doc.text(176, 79, `: ${this.state.address}`);
    doc.setFont("times", "normal");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(198, 48, `DELIVERY CHALLAN`);

    doc.text(155, 87, "Sr.No.");
    doc.text(193, 87, "Particulars");
    doc.text(263, 87, "Quantity");

    doc.text(159, 98, "1");
    doc.text(174, 98, `${this.state.item} Metal`);
    doc.text(
      279,
      98,
      `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
      "right"
    );

    doc.text(155, 195, `Receiver's Signature`);
    doc.text(
      287,
      195,
      `For, ${this.state.companyData.organizationName}`,
      "right"
    );

    doc.rect(147, 0, 0, 3, "F");
    doc.rect(147, 6, 0, 3, "F");
    doc.rect(147, 12, 0, 3, "F");
    doc.rect(147, 18, 0, 3, "F");
    doc.rect(147, 24, 0, 3, "F");
    doc.rect(147, 30, 0, 3, "F");
    doc.rect(147, 36, 0, 3, "F");
    doc.rect(147, 42, 0, 3, "F");
    doc.rect(147, 48, 0, 3, "F");
    doc.rect(147, 54, 0, 3, "F");
    doc.rect(147, 60, 0, 3, "F");
    doc.rect(147, 66, 0, 3, "F");
    doc.rect(147, 72, 0, 3, "F");
    doc.rect(147, 78, 0, 3, "F");
    doc.rect(147, 84, 0, 3, "F");
    doc.rect(147, 90, 0, 3, "F");
    doc.rect(147, 96, 0, 3, "F");
    doc.rect(147, 102, 0, 3, "F");
    doc.rect(147, 108, 0, 3, "F");
    doc.rect(147, 114, 0, 3, "F");
    doc.rect(147, 120, 0, 3, "F");
    doc.rect(147, 126, 0, 3, "F");
    doc.rect(147, 132, 0, 3, "F");
    doc.rect(147, 138, 0, 3, "F");
    doc.rect(147, 144, 0, 3, "F");
    doc.rect(147, 150, 0, 3, "F");
    doc.rect(147, 156, 0, 3, "F");
    doc.rect(147, 162, 0, 3, "F");
    doc.rect(147, 168, 0, 3, "F");
    doc.rect(147, 174, 0, 3, "F");
    doc.rect(147, 180, 0, 3, "F");
    doc.rect(147, 186, 0, 3, "F");
    doc.rect(147, 192, 0, 3, "F");
    doc.rect(147, 198, 0, 3, "F");
    doc.rect(147, 204, 0, 3, "F");
    doc.rect(147, 210, 0, 3, "F");
    doc.rect(147, 216, 0, 3, "F");

    if (this.state.paymentMode == "Cash") {
      doc.text(174, 134, "Total");
      doc.text(
        279,
        134,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.text(174, 143, "Cash Payment Details");
      doc.text(263, 143, "Amount");
      doc.text(174, 152, "Total Amount");
      doc.text(
        279,
        152,
        `${parseFloat(this.state.totalAmount).toFixed(2)}`,
        "right"
      );
      doc.text(174, 158, "Received Amount");
      doc.text(
        279,
        158,
        `${parseFloat(this.state.receivedAmount).toFixed(2)}`,
        "right"
      );
      doc.text(174, 164, "Balance Amount");
      doc.text(
        279,
        164,
        `${parseFloat(
          this.state.totalAmount - this.state.receivedAmount
        ).toFixed(2)}`,
        "right"
      );
      doc.rect(153, 128, 135, 0, "F");
      doc.rect(153, 137, 135, 0, "F");
      doc.rect(153, 145, 135, 0, "F");
      doc.rect(153, 168, 135, 0, "F");

      //----------
      doc.rect(5, 128, 135, 0, "F");
      doc.rect(5, 137, 135, 0, "F");
      doc.rect(5, 145, 135, 0, "F");
      doc.rect(5, 168, 135, 0, "F");

      doc.text(24, 134, "Total");
      doc.text(
        131,
        134,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.text(24, 143, "Cash Payment Details");
      doc.text(114, 143, "Amount");
      doc.text(24, 152, "Total Amount");
      doc.text(
        131,
        152,
        `${parseFloat(this.state.totalAmount).toFixed(2)}`,
        "right"
      );
      doc.text(24, 158, "Received Amount");
      doc.text(
        131,
        158,
        `${parseFloat(this.state.receivedAmount).toFixed(2)}`,
        "right"
      );
      doc.text(24, 164, "Balance Amount");
      doc.text(
        131,
        164,
        `${parseFloat(
          this.state.totalAmount - this.state.receivedAmount
        ).toFixed(2)}`,
        "right"
      );
    } else if (this.state.paymentMode == "Credit") {
      doc.rect(153, 168, 135, 0, "F");
      doc.text(174, 174, "Total");
      doc.text(
        279,
        174,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.rect(5, 168, 135, 0, "F");
      doc.text(24, 174, "Total");
      doc.text(
        131,
        174,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
    }

    doc.save(`${this.state.dmNo}_DM.pdf`);
  };

  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this._isMounted && this.setState({ companyData: data });
      }
    });
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Sales Details" />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" customer="underline" />
            <View
              style={[
                styles.palyArea,
                {
                  backgroundColor:
                    this.state.isactive == false ? "#ffe6e6" : null,
                },
              ]}
            >
              <Portal>
                <Modal
                  visible={this.state.convertModal}
                  onDismiss={() => {
                    if (this.state.isConvertLoading == false) {
                      this._isMounted && this.setState({ convertModal: false });
                    }
                  }}
                  contentContainerStyle={{
                    backgroundColor: "#fff",
                    width: 550,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                    borderWidth: 3,
                    alignSelf: "center",
                  }}
                >
                  <View>
                    <Text style={{ alignSelf: "center", fontSize: 20 }}>
                      Convert the Order to Cash
                    </Text>
                    <View style={{ alignItems: "flex-start", margin: 20 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          width: 300,
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Total"
                          value={this.state.total}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          onChangeText={(text) => {
                            let regex = /^[0-9]*$/;
                            if (text.match(regex)) {
                              this._isMounted && this.setState({ total: text });
                            }
                          }}
                          placeholder="Total"
                        />
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          width: 300,
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Received
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Received Amount"
                          value={`${this.state.received}`}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          onChangeText={(text) => {
                            let regex = /^[0-9]*$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ received: text });
                            }
                          }}
                          placeholder="Received Amount"
                        />
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          width: 300,
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Balance
                        </Text>
                        <TextInput
                          disabled
                          mode="outlined"
                          label="Balance Amount"
                          value={`${this.state.total - this.state.received}`}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          placeholder="Balance Amount"
                        />
                      </View>
                      <TouchableOpacity
                        style={[styles.addNewButton, { alignSelf: "flex-end" }]}
                        onPress={() => {
                          if (
                            this.state.total != "" &&
                            this.state.received != "" &&
                            this.state.isConvertLoading == false
                          ) {
                            this.convertToCash();
                          }
                        }}
                      >
                        {this.state.isConvertLoading ? (
                          <ActivityIndicator size="small" color="#fff" />
                        ) : (
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            Convert to Cash
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>
              <Portal>
                <Modal
                  visible={this.state.setVisibleViewImage}
                  onDismiss={() => {
                    this._isMounted &&
                      this.setState({
                        setVisibleViewImage: false,
                        DownloadImageURL: "",
                      });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    width: 800,
                    alignSelf: "center",
                  }}
                >
                  {this.state.DownloadImageURL != "empty" ? (
                    <ImageBackground
                      resizeMode="contain"
                      style={{ alignSelf: "center", height: 500, width: 375 }}
                      source={{ uri: this.state.DownloadImageURL }}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 18,
                          fontWeight: "800",
                        }}
                      >
                        DM {this.state.dmNo}
                      </Text>
                    </ImageBackground>
                  ) : (
                    <ActivityIndicator size="large" color="#0a60ff" />
                  )}
                </Modal>
              </Portal>
              <Portal>
                <Modal
                  visible={this.state.modalCancelDM}
                  onDismiss={() => {
                    this._isMounted && this.setState({ modalCancelDM: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "#fff",
                    width: 550,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                    borderWidth: 3,
                    alignSelf: "center",
                  }}
                >
                  <View style={{ padding: 10 }}>
                    <Text style={{ fontSize: 16 }}>
                      Are You Sure, you want to cancle this DM ? This can NOT be
                      undo !
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            width: 100,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                            backgroundColor: "#B50706",
                            borderColor: "#B50706",
                          },
                        ]}
                        onPress={() => {
                          if (this.state.loginUser.admin == true) {
                            if (
                              this.state.checkedByAuthPerson != true &&
                              this.state.isMarkforBillGen != true &&
                              this.state.isMarkforLedgerGen != true
                            ) {
                              if (this.state.isactive != false) {
                                this.inactiveEntry();
                              } else {
                                this._isMounted &&
                                  this.setState({
                                    SnackbarVisible: true,

                                    error: "Cancelled DM ! Not Allowed !",
                                  });
                                setTimeout(() => {
                                  this._isMounted &&
                                    this.setState({ SnackbarVisible: false });
                                }, 2000);
                              }
                            } else {
                              this._isMounted &&
                                this.setState({
                                  SnackbarVisible: true,

                                  error:
                                    "Please mark as unchecked, unbilled and unledgered",
                                });
                              setTimeout(() => {
                                this._isMounted &&
                                  this.setState({ SnackbarVisible: false });
                              }, 2000);
                            }
                          }
                        }}
                      >
                        <Text style={{ color: "#fff", alignSelf: "center" }}>
                          Yes, Cancel.
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            width: 125,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                            backgroundColor: "#0a60ff",
                            borderColor: "#0a60ff",
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ modalCancelDM: false });
                        }}
                      >
                        <Text style={{ color: "#fff", alignSelf: "center" }}>
                          Do Not Cancel.
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>

              <View
                style={{
                  flexDirection: "row",
                  marginHorizontal: 55,
                  marginTop: 10,
                }}
              >
                <Ionicons
                  name="arrow-back"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                  size={24}
                  color="black"
                  style={{ marginRight: 30 }}
                />
                <View style={{ flexDirection: "column", flex: 3 }}>
                  <Text style={{ fontSize: 18 }}>
                    {this.state.customerName}, {this.state.address}
                  </Text>
                  <Text style={{ marginTop: 5, fontSize: 12 }}>
                    {this.state.gst}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("ledgerScreen", {
                        customerId: this.state.customerId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("customerEdit", {
                        customerId: this.state.customerId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Profile</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#c1c1c1",
                  borderRadius: 5,
                  marginHorizontal: 65,
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    padding: 20,
                    borderBottomWidth: 1,
                    borderColor: "#c1c1c1",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 20 }}>
                    {this.state.customerName}, D.M. No. {this.state.dmNo}
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 750,
                    }}
                  >
                    {this.state.dmDownloadImageURL == true ? (
                      <TouchableOpacity
                        style={{
                          borderColor: "#B50706",
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        onPress={() => {
                          this.downloadURL();
                          this._isMounted &&
                            this.setState({
                              setVisibleViewImage: true,
                            });
                        }}
                      >
                        <Text style={{ color: "#B50706" }}>Received DM</Text>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        disabled
                        style={{
                          borderColor: "#B50706",
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                      >
                        <Text style={{ color: "#B50706" }}>Received N/a</Text>
                      </TouchableOpacity>
                    )}

                    <TouchableOpacity
                      style={{
                        borderColor: "#B50706",
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderWidth: 1,
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        if (this.state.isactive != false) {
                          this.dmPdfDownload();
                        } else {
                          this._isMounted &&
                            this.setState({
                              SnackbarVisible: true,

                              error: "Cancelled DM ! Not Allowed !",
                            });
                          setTimeout(() => {
                            this._isMounted &&
                              this.setState({ SnackbarVisible: false });
                          }, 2000);
                        }
                      }}
                    >
                      <Text style={{ color: "#B50706" }}>PDF Download</Text>
                    </TouchableOpacity>

                    <AntDesign
                      name="checkcircle"
                      size={20}
                      color={
                        this.state.checkedByAuthPerson ? "#009C44" : "#c1c1c1"
                      }
                      style={{ alignSelf: "center" }}
                    />
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          flexDirection: "row",
                          backgroundColor: this.state.checkedByAuthPerson
                            ? "#c1c1c1"
                            : "#0a60ff",
                        },
                      ]}
                      onPress={() => {
                        if (this.state.isactive != false) {
                          this.markAsChecked();
                        } else {
                          this._isMounted &&
                            this.setState({
                              SnackbarVisible: true,

                              error: "Cancelled DM ! Not Allowed !",
                            });
                          setTimeout(() => {
                            this._isMounted &&
                              this.setState({ SnackbarVisible: false });
                          }, 2000);
                        }
                      }}
                    >
                      <Text style={{ color: "#fff", alignSelf: "center" }}>
                        Mark as Checked
                      </Text>
                    </TouchableOpacity>
                    {this.state.isBillGenertd ? (
                      <Text>Bill Generated</Text>
                    ) : (
                      <View style={{ flexDirection: "row" }}>
                        <FontAwesome5
                          name="receipt"
                          size={22}
                          style={{ alignSelf: "center", padding: 5 }}
                          color={
                            this.state.isMarkforBillGen ? "#F4B400" : "#c1c1c1"
                          }
                        />
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: this.state.isMarkforBillGen
                                ? "#c1c1c1"
                                : "#0a60ff",
                            },
                          ]}
                          onPress={() => {
                            if (this.state.isactive != false) {
                              this.markForBillGen();
                            } else {
                              this._isMounted &&
                                this.setState({
                                  SnackbarVisible: true,

                                  error: "Cancelled DM ! Not Allowed !",
                                });
                              setTimeout(() => {
                                this._isMounted &&
                                  this.setState({ SnackbarVisible: false });
                              }, 2000);
                            }
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Mark for Bill
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                    {this.state.isLedgerEntryDone ? (
                      <View style={{ flexDirection: "row" }}>
                        <MaterialCommunityIcons
                          name="book-account-outline"
                          style={{ alignSelf: "center", padding: 5 }}
                          size={24}
                          color="#009C44"
                        />

                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#c1c1c1",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Ledger Entry Done
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={{ flexDirection: "row" }}>
                        <MaterialCommunityIcons
                          name="book-account-outline"
                          style={{ alignSelf: "center", padding: 5 }}
                          size={24}
                          color={
                            this.state.isMarkforLedgerGen
                              ? "#F4B400"
                              : "#c1c1c1"
                          }
                        />

                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: this.state.isMarkforLedgerGen
                                ? "#c1c1c1"
                                : "#0a60ff",
                            },
                          ]}
                          onPress={() => {
                            if (this.state.isactive != false) {
                              this.markForLedgerGen();
                            } else {
                              this._isMounted &&
                                this.setState({
                                  SnackbarVisible: true,

                                  error: "Cancelled DM ! Not Allowed !",
                                });
                              setTimeout(() => {
                                this._isMounted &&
                                  this.setState({ SnackbarVisible: false });
                              }, 2000);
                            }
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Mark for Ledger
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
                <View style={{ maxHeight: 500, overflow: "scroll" }}>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Basic Info
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Date"
                      value={this.state.date}
                      style={styles.inputField}
                      placeholder="Particular"
                    />
                    <TextInput
                      mode="outlined"
                      label="Time"
                      value={this.state.time}
                      style={styles.inputField}
                      placeholder="Particular"
                    />
                    <TextInput
                      mode="outlined"
                      label="DM No."
                      value={this.state.dmNo}
                      style={[styles.inputField, { width: 150 }]}
                      placeholder="Particular"
                    />
                    <TextInput
                      mode="outlined"
                      label="P.O. No./ Note"
                      value={this.state.poNumber ? this.state.poNumber : ""}
                      style={styles.inputField}
                      placeholder="Particular"
                    />
                  </View>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Particular
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Item"
                      value={this.state.item}
                      style={styles.inputField}
                    />
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Qty"
                        value={this.state.sellQtyCft}
                        style={{ width: 110 }}
                        onChangeText={(text) => {
                          this._isMounted &&
                            this.setState({ sellQtyCft: text });
                        }}
                      />
                      <Text
                        style={{
                          padding: 5,
                          alignSelf: "center",
                          fontSize: 16,
                        }}
                      >
                        {this.state.itemUOM}
                      </Text>
                      <TouchableOpacity
                        disabled={this.state.sellQtyButLoading}
                        style={[
                          styles.addNewButton,
                          { height: 40, alignSelf: "center" },
                        ]}
                        onPress={() => {
                          if (this.state.isactive != false) {
                            this.updateQty();
                          } else {
                            this._isMounted &&
                              this.setState({
                                SnackbarVisible: true,

                                error: "Cancelled DM ! Not Allowed !",
                              });
                            setTimeout(() => {
                              this._isMounted &&
                                this.setState({ SnackbarVisible: false });
                            }, 2000);
                          }
                        }}
                      >
                        {this.state.sellQtyButLoading ? (
                          <ActivityIndicator size="small" color="#fff" />
                        ) : (
                          <Text
                            style={{
                              color: "#fff",
                              alignSelf: "center",
                            }}
                          >
                            Update
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>

                    <TextInput
                      mode="outlined"
                      label="Royalty No."
                      value={this.state.royaltyNo}
                      style={styles.inputField}
                    />
                  </View>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Transport Details
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      mode="outlined"
                      label="Lorry No."
                      value={this.state.lorryNo}
                      style={styles.inputField}
                    />
                    <View style={{ flexDirection: "row", padding: 20 }}>
                      <Checkbox
                        status={
                          this.state.transportIncluded ? "checked" : "unchecked"
                        }
                        onPress={() => {
                          if (this.state.isactive != false) {
                            this.isTransportIncluded();
                          } else {
                            this._isMounted &&
                              this.setState({
                                SnackbarVisible: true,

                                error: "Cancelled DM ! Not Allowed !",
                              });
                            setTimeout(() => {
                              this._isMounted &&
                                this.setState({ SnackbarVisible: false });
                            }, 2000);
                          }
                        }}
                        color="#0a60ff"
                      />
                      <Text style={{ paddingVertical: 5 }}>
                        Is Transportation Included ?
                      </Text>
                    </View>

                    <TextInput
                      mode="outlined"
                      label="Driver"
                      value={this.state.driverName}
                      style={styles.inputField}
                    />
                    <TextInput
                      mode="outlined"
                      label="Delivery Address"
                      value={this.state.deliveryAdd}
                      style={styles.inputField}
                    />
                  </View>
                  <View>
                    <Text
                      style={{
                        padding: 20,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      Payment Info
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <TextInput
                        mode="outlined"
                        label="Payment Mode"
                        value={this.state.paymentMode}
                        style={[styles.inputField, { width: 110 }]}
                      />
                      {this.state.paymentMode == "Credit" ? (
                        <TouchableOpacity
                          disabled={this.state.sellQtyButLoading}
                          style={[
                            styles.addNewButton,
                            { height: 40, alignSelf: "center", marginLeft: 10 },
                          ]}
                          onPress={() => {
                            if (this.state.isactive != false) {
                              this._isMounted &&
                                this.setState({ convertModal: true });
                            } else {
                              this._isMounted &&
                                this.setState({
                                  SnackbarVisible: true,

                                  error: "Cancelled DM ! Not Allowed !",
                                });
                              setTimeout(() => {
                                this._isMounted &&
                                  this.setState({ SnackbarVisible: false });
                              }, 2000);
                            }
                          }}
                        >
                          {this.state.sellQtyButLoading ? (
                            <ActivityIndicator size="small" color="#fff" />
                          ) : (
                            <Text
                              style={{
                                color: "#fff",
                                alignSelf: "center",
                              }}
                            >
                              convert
                            </Text>
                          )}
                        </TouchableOpacity>
                      ) : null}
                      <TouchableOpacity
                        disabled={this.state.sellQtyButLoading}
                        style={[
                          styles.addNewButton,
                          {
                            height: 40,
                            alignSelf: "center",
                            marginLeft: 10,
                            marginTop: 10,
                          },
                        ]}
                        onPress={() => {
                          if (this.state.isactive != false) {
                            this.ctcrfi();
                          } else {
                            this._isMounted &&
                              this.setState({
                                SnackbarVisible: true,

                                error: "Cancelled DM ! Not Allowed !",
                              });
                            setTimeout(() => {
                              this._isMounted &&
                                this.setState({ SnackbarVisible: false });
                            }, 2000);
                          }
                        }}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            alignSelf: "center",
                          }}
                        >
                          CTCrFI
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {this.state.paymentMode == "Cash" || "Credit" ? (
                      <View>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              padding: 20,
                              fontSize: 16,
                              fontWeight: "bold",
                              width: 110,
                            }}
                          >
                            Total
                          </Text>
                          <TextInput
                            mode="outlined"
                            label="Total Amount"
                            value={this.state.totalAmount}
                            style={{ width: 140 }}
                          />
                        </View>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              padding: 20,
                              fontSize: 16,
                              fontWeight: "bold",
                              width: 110,
                            }}
                          >
                            Received
                          </Text>
                          <TextInput
                            mode="outlined"
                            label="Received Amount"
                            value={`${this.state.receivedAmount}`}
                            style={{ width: 140, marginTop: 20 }}
                          />
                          {parseFloat(this.state.totalAmount) !=
                          parseFloat(this.state.receivedAmount) ? (
                            <View style={{ marginLeft: 15 }}>
                              <Text>Balance Received</Text>
                              <TextInput
                                mode="outlined"
                                label="Balance Received"
                                value={this.state.receivedBalance}
                                style={{ width: 140 }}
                                onChangeText={(text) => {
                                  this._isMounted &&
                                    this.setState({ receivedBalance: text });
                                }}
                              />
                              <TouchableOpacity
                                disabled={
                                  this.state.isUpdateloading ||
                                  this.state.paymentMode == "Credit"
                                    ? true
                                    : false
                                }
                                style={styles.addNewButton}
                                onPress={() => {
                                  if (
                                    this.state.isUpdateloading == false &&
                                    this.state.isactive != false
                                  ) {
                                    this.updateAllSalesReport();
                                  }
                                }}
                              >
                                {this.state.isUpdateloading ? (
                                  <ActivityIndicator
                                    size="small"
                                    color="#fff"
                                  />
                                ) : (
                                  <Text
                                    style={{
                                      color: "#fff",
                                      alignSelf: "center",
                                    }}
                                  >
                                    Update Balance
                                  </Text>
                                )}
                              </TouchableOpacity>
                            </View>
                          ) : null}
                        </View>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              padding: 20,
                              fontSize: 16,
                              fontWeight: "bold",
                              width: 110,
                            }}
                          >
                            Balance
                          </Text>
                          <TextInput
                            mode="outlined"
                            label="Balance"
                            value={this.state.balanceAmount}
                            style={{ width: 140 }}
                          />
                        </View>
                      </View>
                    ) : null}
                    <View>
                      <TextInput
                        mode="outlined"
                        label="Entry By"
                        value={this.state.entryBy}
                        style={styles.inputField}
                      />
                      {this.state.loginUser.admin == true &&
                      this.state.paymentMode == "Credit" ? (
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              width: 100,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                              backgroundColor: "#B50706",
                              borderColor: "#B50706",
                            },
                          ]}
                          onPress={() => {
                            if (this.state.isactive != false) {
                              this._isMounted &&
                                this.setState({ modalCancelDM: true });
                            }
                          }}
                        >
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            Cancel D.M.
                          </Text>
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  </View>
                </View>
                <Snackbar
                  visible={this.state.SnackbarVisible}
                  onDismiss={() => {
                    this._isMounted &&
                      this.setState({ SnackbarVisible: false });
                  }}
                  action={{
                    label: "OK",
                    onPress: () => {
                      this._isMounted &&
                        this.setState({ SnackbarVisible: false });
                    },
                  }}
                >
                  {this.state.error}
                </Snackbar>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  inputField: {
    padding: 20,
    height: 40,
  },
});
