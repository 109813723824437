import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  Picker,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
} from "react-native";
import { jsPDF } from "jspdf";
import {
  TextInput,
  Snackbar,
  ActivityIndicator,
  RadioButton,
  Modal,
  Portal,
  Provider,
  Switch,
} from "react-native-paper";
// import { Picker } from "@react-native-picker/picker";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalenderWField from "../src/basic_Components/CalenderWField";
const stateList = [
  { stateName: "Andaman and Nicobar Islands", code: 25 },
  { stateName: "Andhra Pradesh", code: 28 },
  { stateName: "Arunachal Pradesh ", code: 12 },
  { stateName: "Assam", code: 28 },
  { stateName: "Bihar", code: 10 },
  { stateName: "Chandigarh", code: 4 },
  { stateName: "Chattisgarh", code: 22 },
  { stateName: "Dadra and Nagar Haveli ", code: 26 },
  { stateName: "Daman and Diu", code: 25 },
  { stateName: "Delhi", code: 7 },
  { stateName: "Goa", code: 30 },
  { stateName: "Gujarat", code: 24 },
  { stateName: "Haryana", code: 6 },
  { stateName: "Himachal Pradesh", code: 2 },
  { stateName: "Jammu and Kashmir", code: 1 },
  { stateName: "Jharkhand", code: 20 },
  { stateName: "Karnataka", code: 29 },
  { stateName: "Kerala", code: 32 },
  { stateName: "Lakshadweep Islands", code: 31 },
  { stateName: "Madhya Pradesh", code: 23 },
  { stateName: "Maharashtra", code: 27 },
  { stateName: "Manipur", code: 14 },
  { stateName: "Meghalaya", code: 17 },
  { stateName: "Mizoram", code: 15 },
  { stateName: "Nagaland", code: 13 },
  { stateName: "Odisha", code: 21 },
  { stateName: "Pondicherry", code: 34 },
  { stateName: "Punjab", code: 3 },
  { stateName: "Rajasthan", code: 8 },
  { stateName: "Sikkim", code: 11 },
  { stateName: "Tamil Nadu", code: 33 },
  { stateName: "Telangana", code: 36 },
  { stateName: "Tripura", code: 16 },
  { stateName: "Uttar Pradesh", code: 9 },
  { stateName: "Uttarakhand", code: 5 },
  { stateName: "West Bengal", code: 19 },
];
export default class customerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      customerName: "",
      address: "",
      supplyState: "",
      stateCode: null,
      number: "",
      gst: "",
      addedDate: "",
      addedByname: "",
      email: "",
      //product Rate data//
      productArray: [],
      productRate: [],
      rateHolder: "",
      //
      dataLoading: true,
      buttonLoading: false,
      //Bill Data
      billdata: [],
      columns: ["Date", "Quantity", "Unit of Measure", "Action"],
      setVisibleBill: false,
      billno: "",
      billDate: "",
      billArray: [],
      billCalculations: {},
      itemSplit: [],
      columnsBill: [
        "Sr no.",
        "Date",
        "Lorry No.",
        "Royalty No.",
        "D.M. No.",
        "Size in mm",
        "Total cft",
        "Pay. Mode",
        "Amount",
      ],
      productRateArray: [],
      setVisibleProductRate: false,
      selectedProductName: "",
      selectedProductUnit: "",
      selectedProductRate: "",
      selectedProductUnit2: "",
      selectedProductRate2: "",
      uoid: "",
      isbillLoading: true,
      fiscalyear: "",
      stateCode: null,
      supplyState: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
        stateCode: null,
        supplyState: "",
      },
      poManageModul: false,
      isPOdispatch: false,
      modalViewOnly: false,
      poDate: new Date(),
      poEndDate: new Date(),
      poNumber: "",
      viewDropDownItem: false,
      itemSearch: "",
      searchDataItem: [],
      uom: "",
      itemQty: "",
      itemData: {},
      poArrayFromDB:[],
      error:"",
      poListProductId:""
    };
  }
  componentDidMount() {
    const { customerId } = this.props.route.params;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ customerId, uoid }, () => {
            this.getDataFromDb();
            // this.getProductList();
            var fiscalyear = "";
            var today = new Date();
            if (today.getMonth() + 1 <= 3) {
              fiscalyear =
                "FY" +
                (today.getFullYear() - 1) +
                "_" +
                today.getFullYear().toString().substring(2);
            } else {
              fiscalyear =
                "FY" +
                today.getFullYear() +
                "_" +
                (today.getFullYear() + 1).toString().substring(2);
            }

            this.setState({ fiscalyear }, () => {
              this.getbills();
              this.getPoData()
            });

            this.getCompanyData();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this.setState({ companyData: data });
      }
    });
  };
  getbills = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/bills/${this.state.customerId}/${this.state.fiscalyear}`
      );

    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this.setState(
          {
            billdata: data.sort(function (a, b) {
              if (a.billno < b.billno) {
                return 1;
              }
              if (a.billno > b.billno) {
                return -1;
              }
              return 0;
            }),
          },
          () => {
            this.setState({ isbillLoading: false });
          }
        );
      } else {
        this.setState({ isbillLoading: false, billdata: [] });
      }
    });
  };
  getPoData=()=>{
    let dbRef = firebase
    .database()
    .ref(
      `${this.state.uoid}/factoryData/customerData/purchaseOrder/${this.state.customerId}/${this.state.fiscalyear}`
    );

    dbRef.on("value",DataSnapShot=>{
      if (DataSnapShot.exists()){
        let data = Object.values(DataSnapShot.val())
        this.setState({poArrayFromDB:data})
      }
    })
  }
  getDataFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();

        this.setState(
          {
            customerName: data.customerName,
            address: data.address,
            supplyState: data.supplyState ? data.supplyState : "",
            stateCode: data.stateCode ? data.stateCode : null,
            number: data.number,
            email: data.email,
            gst: data.gst,
            addedDate: data.addedDate,
            addedByname: data.addedByname,
            productRate: data.productRate
              ? Object.values(data.productRate)
              : [],
            isPOdispatch:
              data.isPOdispatch
                ? data.isPOdispatch
                : false,
          },
          () => {
            this.setState({ dataLoading: false });
            this.getProductList();
          }
        );
      }
    });
  };
  updateDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
      );

    dbRef
      .update({
        customerName: this.state.customerName,
        address: this.state.address,
        supplyState: this.state.supplyState,
        stateCode: this.state.stateCode,
        gst: this.state.gst,
        number: this.state.number,
        email: this.state.email,
        productRate: this.state.productRate,
        isPOdispatch:(this.state.isPOdispatch  === 'true'),
      })
      .then(() => {
        this.setState({ buttonLoading: false });
      });
  };
  getProductList = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ productArray: data }, () => {
          this.productRateField();
        });
      }
    });
  };
  addItemToArray = () => {
    let objectToAdd = {
      item: this.state.itemSearch,
      uom: this.state.uom,
      itemQty: this.state.itemQty,
      id: this.state.poListProductId,
    };
    // let currentArray = this.state.itemData.concat(objectToAdd);
     let currentArray = {...this.state.itemData,[this.state.poListProductId]:objectToAdd};
    this.setState({ itemData: currentArray }, () => {
      this.setState({
        itemSearch: "",
        itemQty: "",
        uom: "",
      });
    });
  };
  productRateField = () => {
    let productRateArray = this.state.productArray.map((item1, index1) => {
      this.state.productRate.map((item2, index2) => {
        if (item1.productName == item2.productName) {
          item1 = {
            productName: item1.productName,
            itemRate: item2.itemRate,
            uom: item2.uom,
            itemRate2: item2.itemRate2,
            uom2: item2.uom2,
          };
        }
      });
      return item1;
    });
    this.setState({ productRateArray });
  };
  updateItemRate = () => {
    this.setState({ buttonLoading: true });
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}/productRate/${this.state.selectedProductName}`
      );

    dbRef
      .update({
        productName: this.state.selectedProductName,
        itemRate: this.state.selectedProductRate,
        uom: this.state.selectedProductUnit,
        itemRate2: this.state.selectedProductRate2
          ? this.state.selectedProductRate2
          : 0,
        uom2: this.state.selectedProductUnit2
          ? this.state.selectedProductUnit2
          : "cft",
      })
      .then(() => {
        this.setState({ setVisibleProductRate: false, buttonLoading: false });
      })
      .catch((e) => {
        this.setState({ buttonLoading: false });
      });
  };
  generatePurchaseOrder=()=>{
    let dbRef=firebase.database().ref(`${this.state.uoid}/factoryData/customerData/purchaseOrder/${this.state.customerId}/${this.state.fiscalyear}/${this.state.poDate}`)

    dbRef.set({
      poNumber:this.state.poNumber,
      poDate:this.state.poDate.toDateString(),
      poEndDate:this.state.poEndDate.toDateString(),
      itemData:this.state.itemData,
      status:"On Going",
      location:`${this.state.poDate}`
    }).then(()=>{
      this.setState({buttonLoading:false,poManageModul:false,itemData:[]})
    })
  }
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.productArray.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchDataItem: newdata });
    } else {
      this.setState({ searchDataItem: this.state.productArray });
    }
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderPO = () => {
    const columns = ["Date", "PO number", "Status", "Action"];
    return (
      <View style={styles.tableHeader}>
        {columns.map((column, index) => {
          {
            return (
              <TouchableOpacity key={index} style={styles.columnHeader}>
                <Text style={styles.columnHeaderTxt}>{column}</Text>
              </TouchableOpacity>
            );
          }
        })}
      </View>
    );
  };
  tableHeaderBill = () => (
    <View style={styles.tableHeader}>
      {this.state.columnsBill.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnHeader, { width: "11.11%" }]}
            >
              <Text style={[styles.columnHeaderTxt]}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  
  tableFooterForBill = () => {
    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      "Total",
      `Total DM : ${this.state.billArray.length}`,
      <View>
        {this.state.itemSplit.map((item1) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.billArray.reduce(reducer, 0)}</Text>
        </View>
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>
            {parseFloat(this.state.billCalculations.billTotal).toFixed(2)}
          </Text>
        </View>
        {this.state.stateCode == this.state.companyData.stateCode &&
        this.state.stateCode != null ? (
          <View>
            <View
              style={{
                flexDirection: "row",

                justifyContent: "space-between",
              }}
            >
              <Text>2.5% SGST</Text>

              <Text>{this.state.billCalculations.sgst}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",

                justifyContent: "space-between",
              }}
            >
              <Text>2.5% CGST</Text>

              <Text>{this.state.billCalculations.cgst}</Text>
            </View>
          </View>
        ) : this.state.stateCode !== this.state.companyData.stateCode &&
          this.state.stateCode != null ? (
          <View
            style={{
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Text>5% IGST</Text>

            <Text>{this.state.billCalculations.igst}</Text>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                padding: 8,
                fontWeight: "bold",
                backgroundColor: "#DE5246",
              }}
            >
              Please Select Supply State
            </Text>
          </View>
        )}

        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total GST</Text>

          <Text>
            {parseFloat(
              this.state.billCalculations.cgst +
                this.state.billCalculations.sgst
            ).toFixed(2)}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Grand Total</Text>

          <Text>
            {parseFloat(this.state.billCalculations.billGrandTotal).toFixed(2)}
          </Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 120 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { paddingTop: 10 }]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={[styles.columnHeaderTxt]}>{item}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  printPDF = (copyType) => {
    var doc = new jsPDF();
    doc.rect(10, 10, 190, 277);

    var doc = new jsPDF();
    doc.rect(10, 45, 190, 235);

    doc.setFont("times", "normal");
    doc.setFontSize(11);
    doc.text(198, 9, `${copyType}`, "right");
    doc.setFont("times", "italic");
    doc.setFontSize(25);
    doc.text(
      105,
      20,
      `${this.state.companyData.organizationName},${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(105, 26, `${this.state.companyData.addressTwo}`, "center");

    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(105, 31, `GSTIN- ${this.state.companyData.gstNo}`, "center");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(198, 40, `Invoice No.: ${this.state.billno}`, "right");
    doc.rect(10, 55, 190, 0, "F");
    doc.rect(10, 80, 190, 0, "F");
    doc.rect(10, 87, 190, 0, "F");
    doc.rect(105, 87, 0, 35, "F");
    doc.rect(10, 122, 190, 0, "F");
    doc.rect(10, 132, 190, 0, "F");
    doc.rect(20, 122, 0, 88, "F");
    doc.rect(80, 122, 0, 88, "F");
    doc.rect(95, 122, 0, 88, "F");
    doc.rect(110, 122, 0, 88, "F");
    doc.rect(135, 122, 0, 88, "F");
    doc.rect(155, 122, 0, 133, "F");
    doc.rect(105, 210, 0, 45, "F");
    doc.rect(10, 210, 190, 0, "F");
    doc.rect(105, 219, 95, 0, "F");
    doc.rect(105, 228, 95, 0, "F");
    doc.rect(105, 237, 95, 0, "F");
    doc.rect(105, 246, 95, 0, "F");
    doc.rect(10, 255, 190, 0, "F");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(91, 52, `TAX-INVOICE`);

    doc.setFontSize(12);
    doc.text(12, 62, `Date of Supply`);
    doc.setFontSize(12);
    doc.text(
      48,
      62,
      `: ${this.state.billArray[0].date} to ${
        this.state.billArray[this.state.billArray.length - 1].date
      }`
    );
    doc.text(115, 62, `Transport Mode`);
    doc.text(145, 62, `: Road`);

    doc.setFontSize(12);
    doc.text(12, 68, `Invoice Date`);
    doc.text(48, 68, `: ${this.state.billDate}`);
    doc.text(115, 68, `Vehicle Number`);
    doc.text(
      145,
      68,
      `: ${this.state.billArray[0].lorryNo},${
        this.state.billArray[this.state.billArray.length - 1].lorryNo
      }`
    );

    doc.setFontSize(12);
    doc.text(12, 74, `State`);
    doc.text(48, 74, `: ${this.state.companyData.supplyState}`);
    doc.text(78, 74, `State Code ${this.state.companyData.stateCode}`);
    doc.text(115, 74, `Place of Supply`);
    doc.text(145, 74, `: ${this.state.billArray[0].deliveryAddress}`);

    doc.text(38, 85, `Details of Receiver Bill to`);
    doc.text(129, 85, `Drafts of Consginee Shipped to`);

    doc.text(12, 95, `Name`);
    doc.text(38, 95, `: ${this.state.customerName}`);
    doc.text(12, 103, `Address`);
    doc.text(38, 103, `: ${this.state.address}`);
    doc.text(12, 111, `GST IN`);
    doc.text(38, 111, `: ${this.state.gst}`);
    doc.text(12, 119, `State`);
    doc.text(38, 119, `: ${this.state.supplyState}`);
    doc.text(68, 119, `State Code ${this.state.stateCode}`);

    doc.text(107, 95, `Name`);
    doc.text(133, 95, `: `);
    doc.text(107, 103, `Address`);
    doc.text(133, 103, `: `);
    doc.text(107, 111, `GST IN`);
    doc.text(133, 111, `: `);
    doc.text(107, 119, `State`);
    doc.text(133, 119, `: ${this.state.supplyState}`);
    doc.text(163, 119, `State Code ${this.state.stateCode}`);

    doc.text(13, 126, `Sr\nNo.`);
    doc.text(29, 128, `Name of Product/Service`);
    doc.text(83, 128, `HSN`);
    doc.text(96, 128, `GST %`);
    doc.text(116, 128, `Quantity`);
    doc.text(140, 128, `Rate`);
    doc.text(190, 128, `Amount`, "right");
    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.totalAmount);
    for (let index = 0; index < this.state.itemSplit.length; index++) {
      doc.text(14, 142 + index * 10, `${index + 1}`);
      doc.text(
        25,
        142 + index * 10,
        `${this.state.itemSplit[index][0].item} Metal`
      );
      doc.text(83, 142 + index * 10, "2517");
      doc.text(99, 142 + index * 10, "5%");
      doc.text(
        134,
        142 + index * 10,
        `${this.state.itemSplit[index].reduce(reducer, 0).toFixed(2)} ${
          this.state.itemSplit[index][0].itemUOM
        }`,
        "right"
      );
      doc.text(
        140,
        142 + index * 10,
        `${(
          this.state.itemSplit[index].reduce(reducer1, 0) /
          this.state.itemSplit[index].reduce(reducer, 0)
        ).toFixed(2)}`
      );
      doc.text(
        190,
        142 + index * 10,
        `${this.state.itemSplit[index].reduce(reducer1, 0).toFixed(2)}`,
        "right"
      );
    }
    doc.text(14, 220, "Company Bank Details :");
    doc.text(14, 227, `Bank Name  : ${this.state.companyData.bank}`);
    doc.text(14, 233, `Account No. : ${this.state.companyData.account}`);
    doc.text(14, 239, `IFS Code      : ${this.state.companyData.ifsc}`);
    doc.text(14, 245, `Branch          : ${this.state.companyData.branch}`);

    doc.text(107, 217, "Total Amount Before Tax");

    doc.setFontSize(12);
    if (this.state.companyData.stateCode == this.state.stateCode) {
      doc.text(118, 226, "Add @2.5% CGST");
      doc.text(118, 235, "Add @2.5% SGST");
      doc.text(113, 244, "Total Amount of GST");

      doc.text(109, 253, "Total Amount After Tax");
      doc.text(190, 217, `${this.state.billCalculations.billTotal}`, "right");
      doc.text(190, 226, `${this.state.billCalculations.cgst}`, "right");
      doc.text(190, 235, `${this.state.billCalculations.sgst}`, "right");
      doc.text(
        190,
        244,
        `${(
          parseFloat(this.state.billCalculations.cgst) +
          parseFloat(this.state.billCalculations.sgst)
        ).toFixed(2)}`,
        "right"
      );
      doc.setFont("times", "bold");
      doc.text(
        190,
        253,
        `${parseFloat(this.state.billCalculations.billGrandTotal).toFixed(2)}`,
        "right"
      );
      doc.setFont("times", "italic");
    } else if (this.state.companyData.stateCode != this.state.stateCode) {
      doc.text(118, 226, "Add @5% IGST");
      // doc.text(118, 235, "Add @2.5% SGST");
      doc.text(113, 235, "Total Amount of GST");

      doc.text(109, 244, "Total Amount After Tax");
      doc.text(190, 217, `${this.state.billCalculations.billTotal}`, "right");
      doc.text(190, 226, `${this.state.billCalculations.igst}`, "right");
      // doc.text(190, 235, `${this.state.billCalculations.sgst}`, "right");
      doc.text(
        190,
        235,
        `${parseFloat(this.state.billCalculations.igst).toFixed(2)}`,
        "right"
      );
      doc.setFont("times", "bold");
      doc.text(
        190,
        244,
        `${parseFloat(this.state.billCalculations.billGrandTotal).toFixed(2)}`,
        "right"
      );
      doc.setFont("times", "italic");
    }

    doc.text(
      190,
      262,
      `For- ${this.state.companyData.organizationName}`,
      "right"
    );
    doc.text(190, 276, "Authorised Signatory", "right");
    doc.save(`invoice_${this.state.billno}_${this.state.customerName}.pdf`);
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.customerName} />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" customer="underline" />
            <View style={styles.palyArea}>
              {/* ///////////////////////////////////////////////// */}
              <Portal>
                <Modal
                  visible={this.state.setVisibleBill}
                  onDismiss={() => {
                    this.setState({ setVisibleBill: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    borderWidth: 1,
                    alignSelf: "center",
                    width: 850,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Invoice Number {this.state.billno}
                    </Text>
                  </View>
                  <View>
                    <View
                      style={{
                        padding: 8,
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            padding: 10,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Customer Name : {this.state.customerName}
                        </Text>
                        <Text
                          style={{
                            padding: 10,
                            fontSize: 16,
                          }}
                        >
                          Invoice no:{this.state.billno}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          Customer Address: {this.state.address}
                        </Text>
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          Total DM in Invoice:
                          {this.state.billArray.length}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          GSTIN: {this.state.gst}
                        </Text>

                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          Invoice Date {this.state.billDate}
                        </Text>
                      </View>
                      <View style={{ height: 300 }}>
                        <FlatList
                          data={this.state.billArray.sort(function (a, b) {
                            if (a.dmNo > b.dmNo) {
                              return 1;
                            }
                            if (a.dmNo < b.dmNo) {
                              return -1;
                            }
                            return 0;
                          })}
                          renderItem={({ item, index }) => {
                            return (
                              <View style={{ zIndex: -5 }}>
                                <View
                                  style={[
                                    styles.tableRow,
                                    {
                                      backgroundColor:
                                        index % 2 == 1 ? "#F0FBFC" : "white",
                                    },
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {index + 1}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {item.date}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { fontWeight: "bold", width: "11.11%" },
                                    ]}
                                  >
                                    {item.lorryNo}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {item.royaltyNo}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {item.dmNo}
                                  </Text>

                                  {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { fontWeight: "bold", width: "11.11%" },
                                    ]}
                                  >
                                    {item.item}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {item.sellQtyCft}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {item.paymentMode}
                                  </Text>

                                  <Text
                                    style={[
                                      styles.columnRowTxt,
                                      { width: "11.11%" },
                                    ]}
                                  >
                                    {parseFloat(item.totalAmount).toFixed(2)}
                                  </Text>
                                </View>
                              </View>
                            );
                          }}
                          ListHeaderComponent={this.tableHeaderBill}
                          ListFooterComponent={this.tableFooterForBill}
                          stickyHeaderIndices={[0]}
                          ListEmptyComponent={
                            <View style={{ padding: 50 }}>
                              <ActivityIndicator size="large" color="#0a60ff" />
                            </View>
                          }
                          keyExtractor={(item) => JSON.stringify(item.id)}
                        />
                      </View>
                    </View>
                  </View>
                </Modal>
              </Portal>
              <Portal>
                <Modal
                  visible={this.state.setVisibleProductRate}
                  onDismiss={() => {
                    this.setState({ setVisibleProductRate: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    borderWidth: 1,
                    alignSelf: "center",
                    width: 550,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Product : {this.state.selectedProductName}
                    </Text>
                  </View>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: 450,
                      }}
                    >
                      {/* <TextInput
                        mode="outlined"
                        label="Product"
                        value={this.state.selectedProductName}
                        editable={false}
                        style={[styles.inputField, { width: 150 }]}
                        placeholder="Product"
                      /> */}
                      <Text style={{ padding: 20 }}>
                        Rate WITHOUT Transportation
                      </Text>
                      <TextInput
                        mode="outlined"
                        label="Rate per Unit"
                        value={this.state.selectedProductRate} //TODO:
                        onChangeText={(text) => {
                          this.setState({ selectedProductRate: text });
                        }}
                        style={[styles.inputField, { width: 200 }]}
                        placeholder="Rate"
                      />
                      <Picker
                        selectedValue={this.state.selectedProductUnit}
                        style={{
                          height: 40,
                          width: 170,
                          marginHorizontal: 10,
                          marginTop: 28,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ selectedProductUnit: itemValue })
                        }
                      >
                        <Picker.Item label="Unit" value="" />
                        <Picker.Item label="cft" value="cft" />
                        <Picker.Item label="Brass" value="Brass" />
                        <Picker.Item label="m³" value="m³" />
                        <Picker.Item label="MT" value="MT" />
                      </Picker>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: 450,
                      }}
                    >
                      {/* <TextInput
                        mode="outlined"
                        label="Product"
                        value={this.state.selectedProductName}
                        editable={false}
                        style={[styles.inputField, { width: 150 }]}
                        placeholder="Product"
                      /> */}
                      <Text style={{ padding: 20 }}>
                        Rate WITH Transportation
                      </Text>
                      <TextInput
                        mode="outlined"
                        label="Rate per Unit"
                        value={this.state.selectedProductRate2}
                        onChangeText={(text) => {
                          this.setState({ selectedProductRate2: text });
                        }}
                        style={[styles.inputField, { width: 200 }]}
                        placeholder="Rate"
                      />
                      <Picker
                        selectedValue={this.state.selectedProductUnit2}
                        style={{
                          height: 40,
                          width: 170,
                          marginHorizontal: 10,
                          marginTop: 28,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ selectedProductUnit2: itemValue })
                        }
                      >
                        <Picker.Item label="Unit" value="" />
                        <Picker.Item label="cft" value="cft" />
                        <Picker.Item label="Brass" value="Brass" />
                        <Picker.Item label="m³" value="m³" />
                        <Picker.Item label="MT" value="MT" />
                      </Picker>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        width: 100,
                        justifyContent: "space-between",
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 35,
                            backgroundColor: "#fff",
                            borderColor: "#0a60ff",
                            width: 80,
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 30,
                            marginHorizontal: 10,
                          },
                        ]}
                        disabled={this.state.buttonLoading}
                        onPress={() => {
                          this.setState({ setVisibleProductRate: false });
                        }}
                      >
                        <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 35,
                            backgroundColor: "#fff",
                            borderColor: "#009C44",
                            width: 80,
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 30,
                            marginHorizontal: 10,
                          },
                        ]}
                        disabled={this.state.buttonLoading}
                        onPress={() => {
                          this.updateItemRate();
                        }}
                      >
                        {this.state.buttonLoading ? (
                          <ActivityIndicator size="small" color="#009C44" />
                        ) : (
                          <Text style={{ color: "#009C44" }}>Save</Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>

              <Portal>
                <Modal
                  visible={this.state.poManageModul}
                  onDismiss={() => {
                    this.setState({ poManageModul: false }, () => {
                      this.setState({
                        itemData: [],
                        itemSearch: "",
                        itemQty: "",
                        uom: "",
                        poDate:new Date,poEndDate:new Date,poNumber:"" 
                      });
                    });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    width: 750,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Add Purchase Order
                    </Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row", zIndex: 1 }}>
                      <CalenderWField
                        title="PO Date"
                        dateToShow={this.state.poDate}
                        width={200}
                        onChange={(value) => this.setState({ poDate: value })}
                        disable={this.state.modalViewOnly}
                      />
                      <CalenderWField
                        title="PO End Date"
                        dateToShow={this.state.poEndDate}
                        width={200}
                        onChange={(value) =>
                          this.setState({ poEndDate: value })
                        }
                        disable={this.state.modalViewOnly}
                      />
                      <TextInput
                        mode="outlined"
                        label="PO Number"
                        value={this.state.poNumber}
                        style={[styles.inputField, { width: 200 }]}
                        editable={!this.state.modalViewOnly}
                        placeholder="PO Number"
                        onChangeText={(text) => {
                          this.setState({ poNumber: text });
                        }}
                      />
                    </View>
                    {this.state.modalViewOnly == false ? (
                      <View style={{ flexDirection: "row" }}>
                        <TextInput
                          mode="outlined"
                          label=" Select Item"
                          value={this.state.itemSearch}
                          style={[styles.inputField, { width: 200 }]}
                          onChangeText={(text) => {
                            if (this.state.modalViewOnly == false) {
                              this.setState({ itemSearch: text }, () => {
                                this.searchItemStringInArray(
                                  this.state.itemSearch
                                );
                                this.setState({ viewDropDownItem: true });
                              });
                            }
                          }}
                          editable={!this.state.modalViewOnly}
                          placeholder="Item"
                        />
                        {this.state.viewDropDownItem ? (
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#000",
                              borderRadius: 5,
                              position: "absolute",
                              marginTop: 70,
                              shadowOffset: {
                                width: 0,
                                height: 0,
                              },
                              shadowOpacity: 0.2,
                              shadowRadius: 5,
                              zIndex: 5,
                              marginLeft: 20,
                              maxHeight: 150,
                            }}
                          >
                            <FlatList
                              data={this.state.searchDataItem}
                              renderItem={({ item }) => {
                                return (
                                  <TouchableOpacity
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: 10,
                                      borderWidth: 1,
                                      borderColor: "#c1c1c1",
                                      borderRadius: 5,
                                      width: 200,
                                      alignItems: "center",
                                    }}
                                    onPress={() => {
                                      this.setState(
                                        {
                                          itemSearch: item.productName,
                                          poListProductId:item.id
                                        },
                                        () => {
                                          this.setState({
                                            viewDropDownItem: false,
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    <Text style={{ color: "#000" }}>
                                      {item.productName}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }}
                              keyExtractor={(item) => JSON.stringify(item.id)}
                            />
                          </View>
                        ) : null}
                        <TextInput
                          mode="outlined"
                          label="Quantity"
                          value={this.state.itemQty}
                          style={[styles.inputField, { width: 200 }]}
                          onChangeText={(text) => {
                            if (this.state.modalViewOnly == false) {
                              this.setState({ itemQty: text });
                            }
                          }}
                          placeholder="Quantity"
                          editable={!this.state.modalViewOnly}
                        />
                        <Picker
                          selectedValue={this.state.uom}
                          style={{
                            height: 40,
                            width: 170,
                            marginHorizontal: 10,
                            marginTop: 28,
                          }}
                          onValueChange={(itemValue, itemIndex) =>
                            this.setState({ uom: itemValue })
                          }
                          editable={!this.state.modalViewOnly}
                        >
                          <Picker.Item label="Unit of Measurement" value="" />
                          <Picker.Item label="cft" value="cft" />
                          <Picker.Item label="m³" value="m³" />
                          <Picker.Item label="MT" value="MT" />
                        </Picker>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,
                              marginTop: 28,
                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            if (
                              this.state.itemSearch != "" &&
                              this.state.uom != "" &&
                              this.state.itemQty != "" &&
                              this.state.modalViewOnly == false
                            ) {
                              this.addItemToArray();
                            }
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Add Item</Text>
                        </TouchableOpacity>
                      </View>
                    ) : null}

                    <View style={{ zIndex: -1 }}>
                      <FlatList
                        data={Object.values(this.state.itemData)}
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={[
                                styles.tableRow,
                                {
                                  backgroundColor:
                                    index % 2 == 1 ? "#F0FBFC" : "white",
                                  zIndex: -1,
                                },
                              ]}
                            >
                              <Text style={styles.columnRowTxt}>
                                {item.item}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.itemQty}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.uom}
                              </Text>
                              <View
                                style={{
                                  width: "25%",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <TouchableOpacity
                                  style={[
                                    styles.actionbtn,
                                    { borderColor: "#DE5246" },
                                  ]}
                                  onPress={() => {
                                    if (this.state.modalViewOnly == false) {
                                      let array = this.state.itemData;
                                      array.splice(index, 1);
                                      this.setState({ itemData: array });
                                    }
                                  }}
                                >
                                  <Text style={{ color: "#DE5246" }}>
                                    Delete
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                        ListHeaderComponent={this.tableHeader}
                        ListFooterComponent={
                          <View
                            style={{
                              justifyContent: "space-around",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <TouchableOpacity
                              style={[
                                styles.addNewButton,
                                {
                                  backgroundColor: "#fff",
                                  height: 40,
                                  marginTop: 28,
                                  width: 100,
                                  alignItems: "center",
                                },
                              ]}
                              onPress={() => {
                                this.setState({ poManageModul: false }, () => {
                                  this.setState({
                                    itemData: [],
                                    itemSearch: "",
                                    itemQty: "",
                                    uom: "",
                                    poDate:new Date,poEndDate:new Date,poNumber:"" 
                                  });
                                });
                              }}
                            >
                              <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                            </TouchableOpacity>
                            {this.state.modalViewOnly == false ? (
                              <TouchableOpacity
                                style={[
                                  styles.addNewButton,
                                  {
                                    backgroundColor: "#009C44",
                                    height: 40,
                                    marginTop: 28,
                                    width: 100,
                                    alignItems: "center",
                                    borderColor: "#009C44",
                                  },
                                ]}
                                onPress={() => {
                                  if (Object.values(this.state.itemData).length >= 1&&this.state.poNumber!="") {
                                    this.setState({ buttonLoading: true });
                                    this.generatePurchaseOrder();
                                  }
                                }}
                              >
                                {this.state.buttonLoading ? (
                                  <ActivityIndicator
                                    color="#fff"
                                    size={"small"}
                                  />
                                ) : (
                                  <Text style={{ color: "#fff" }}>
                                    Generate
                                  </Text>
                                )}
                              </TouchableOpacity>
                            ) : null}
                          </View>
                        }
                        ListEmptyComponent={
                          <Text style={{ textAlign: "center" }}>
                            No Item Added
                          </Text>
                        }
                      />
                    </View>
                  </View>
                </Modal>
              </Portal>
              {/* ///////////////////////////////////////////////// */}
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text>Basic Info</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TextInput
                    mode="outlined"
                    label="Customer Name"
                    value={this.state.customerName}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ customerName: text });
                    }}
                    placeholder="Customer Name"
                  />
                  <TextInput
                    mode="outlined"
                    label="Address"
                    value={this.state.address}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ address: text });
                    }}
                    placeholder="Address"
                  />
                  <View style={{ padding: 24 }}>
                    <Picker
                      selectedValue={this.state.supplyState}
                      style={{
                        height: 40,
                        width: 200,
                      }}
                      onValueChange={(itemValue, itemIndex) => {
                        this.setState({
                          supplyState: itemValue,
                          stateCode: stateList[itemIndex - 1].code,
                        });
                      }}
                    >
                      <Picker.Item label="Select State" value="" />

                      <Picker.Item
                        label={stateList[0].code + "-" + stateList[0].stateName}
                        value={stateList[0].stateName}
                      />

                      <Picker.Item
                        label={stateList[1].code + "-" + stateList[1].stateName}
                        value={stateList[1].stateName}
                      />
                      <Picker.Item
                        label={stateList[2].code + "-" + stateList[2].stateName}
                        value={stateList[2].stateName}
                      />
                      <Picker.Item
                        label={stateList[3].code + "-" + stateList[3].stateName}
                        value={stateList[3].stateName}
                      />
                      <Picker.Item
                        label={stateList[4].code + "-" + stateList[4].stateName}
                        value={stateList[4].stateName}
                      />
                      <Picker.Item
                        label={stateList[5].code + "-" + stateList[5].stateName}
                        value={stateList[5].stateName}
                      />
                      <Picker.Item
                        label={stateList[6].code + "-" + stateList[6].stateName}
                        value={stateList[6].stateName}
                      />
                      <Picker.Item
                        label={stateList[7].code + "-" + stateList[7].stateName}
                        value={stateList[7].stateName}
                      />
                      <Picker.Item
                        label={stateList[8].code + "-" + stateList[8].stateName}
                        value={stateList[8].stateName}
                      />
                      <Picker.Item
                        label={stateList[9].code + "-" + stateList[9].stateName}
                        value={stateList[9].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[10].code + "-" + stateList[10].stateName
                        }
                        value={stateList[10].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[11].code + "-" + stateList[11].stateName
                        }
                        value={stateList[11].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[12].code + "-" + stateList[12].stateName
                        }
                        value={stateList[12].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[13].code + "-" + stateList[13].stateName
                        }
                        value={stateList[13].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[14].code + "-" + stateList[14].stateName
                        }
                        value={stateList[14].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[15].code + "-" + stateList[15].stateName
                        }
                        value={stateList[15].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[16].code + "-" + stateList[16].stateName
                        }
                        value={stateList[16].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[17].code + "-" + stateList[17].stateName
                        }
                        value={stateList[17].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[18].code + "-" + stateList[18].stateName
                        }
                        value={stateList[18].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[19].code + "-" + stateList[19].stateName
                        }
                        value={stateList[19].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[20].code + "-" + stateList[20].stateName
                        }
                        value={stateList[20].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[21].code + "-" + stateList[21].stateName
                        }
                        value={stateList[21].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[22].code + "-" + stateList[22].stateName
                        }
                        value={stateList[22].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[23].code + "-" + stateList[23].stateName
                        }
                        value={stateList[23].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[24].code + "-" + stateList[24].stateName
                        }
                        value={stateList[24].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[25].code + "-" + stateList[25].stateName
                        }
                        value={stateList[25].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[26].code + "-" + stateList[26].stateName
                        }
                        value={stateList[26].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[27].code + "-" + stateList[27].stateName
                        }
                        value={stateList[27].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[28].code + "-" + stateList[28].stateName
                        }
                        value={stateList[28].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[29].code + "-" + stateList[29].stateName
                        }
                        value={stateList[29].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[30].code + "-" + stateList[30].stateName
                        }
                        value={stateList[30].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[31].code + "-" + stateList[31].stateName
                        }
                        value={stateList[31].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[32].code + "-" + stateList[32].stateName
                        }
                        value={stateList[32].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[33].code + "-" + stateList[33].stateName
                        }
                        value={stateList[33].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[34].code + "-" + stateList[34].stateName
                        }
                        value={stateList[34].stateName}
                      />
                      <Picker.Item
                        label={
                          stateList[35].code + "-" + stateList[35].stateName
                        }
                        value={stateList[35].stateName}
                      />
                    </Picker>
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="GSTIN"
                    value={this.state.gst}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      let regex = /^[a-zA-Z0-9]*$/;
                      if (text.match(regex)) {
                        this.setState({ gst: text.toUpperCase() });
                      }
                    }}
                    placeholder="GSTIN"
                  />
                  <TextInput
                    mode="outlined"
                    label="Contact Number"
                    value={this.state.number}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ number: text }, () => {});
                    }}
                    placeholder="Contact Number"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="Email"
                    value={this.state.email}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ email: text });
                    }}
                    placeholder="Email"
                  />
                  <TextInput
                    mode="outlined"
                    label="Since"
                    disabled
                    value={this.state.addedDate}
                    style={styles.inputField}
                    placeholder="Since"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    disabled
                    label="Created By"
                    value={this.state.addedByname}
                    style={styles.inputField}
                    placeholder="Created By"
                  />
                  <View style={{ padding: 24 }}>
                    <Picker
                      selectedValue={this.state.isPOdispatch}
                      style={{
                        height: 40,
                        width: 180,
                        borderWidth: 1,
                        borderRadius: 5,
                      }}
                      onValueChange={(itemValue) =>
                        this.setState({ isPOdispatch: itemValue })
                      }
                    >
                      <Picker.Item label="Direct Dispatch" value={false} />
                      <Picker.Item label="PO Dispatch" value={true} />
                      {/* <Picker.Item label="Both" value="FY2020_21" /> */}
                    </Picker>
                  </View>
                </View>

                {/*////////////////////  */}
                <Text style={{ textDecorationLine: "underline" }}>
                  Product Rate
                </Text>
                {/* TODO:Product list */}
                <FlatList
                  data={this.state.productRateArray}
                  renderItem={({ item }) => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          width: 380,

                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                        }}
                      >
                        <TextInput
                          mode="outlined"
                          label={item.productName}
                          value={item.itemRate}
                          style={[styles.inputField, { width: 150 }]}
                          placeholder={item.productName}
                        />
                        <TextInput
                          mode="outlined"
                          label="Unit"
                          value={item.uom}
                          style={[styles.inputField, { width: 100 }]}
                          placeholder="Unit"
                        />
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 35,
                              backgroundColor: "#fff",
                              borderColor: "#009C44",
                              width: 80,
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 30,
                              marginHorizontal: 10,
                            },
                          ]}
                          disabled={this.state.buttonLoading}
                          onPress={() => {
                            this.setState(
                              {
                                setVisibleProductRate: true,
                              },
                              () => {
                                this.setState({
                                  selectedProductName: item.productName, //TODO:
                                  selectedProductUnit: item.uom,
                                  selectedProductRate: item.itemRate,
                                  selectedProductUnit2: item.uom2,
                                  selectedProductRate2: item.itemRate2,
                                });
                              }
                            );
                          }}
                        >
                          <Text style={{ color: "#009C44" }}>Edit</Text>
                        </TouchableOpacity>
                      </View>
                    );
                  }}
                  keyExtractor={(item) => JSON.stringify(item.productName)}
                  numColumns={3}
                  ListEmptyComponent={
                    <View>
                      <ActivityIndicator size="small" color="#0a60ff" />
                    </View>
                  }
                />
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.props.navigation.navigate("ledgerScreen", {
                          customerId: this.state.customerId,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>View Ledger</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.setState({ buttonLoading: true }, () => {
                          this.updateDb();
                        });
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator
                          size="small"
                          animating={true}
                          color="#fff"
                        />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save Changes</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.navigate("customerScreen");
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>

                    <Snackbar
                      visible={this.state.SnackbarVisible}
                      onDismiss={() => {
                        this.setState({ SnackbarVisible: false });
                      }}
                      action={{
                        label: "OK",
                        onPress: () => {
                          this.setState({ SnackbarVisible: false });
                        },
                      }}
                    >
                      Changes Saved Successfully !
                    </Snackbar>
                  </View>
                  <Text
                    style={{
                      padding: 20,

                      color: "red",
                    }}
                  >
                    {this.state.error}
                  </Text>
                </View>
                <Text
                  style={{
                    padding: 20,
                    textDecorationLine: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Invoice Generated
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Picker
                      selectedValue={this.state.fiscalyear}
                      style={{
                        padding: 12,
                        width: 180,
                        borderWidth: 1,
                        borderRadius: 5,
                      }}
                      onValueChange={(itemValue) =>
                        this.setState({ fiscalyear: itemValue })
                      }
                    >
                      <Picker.Item label="FY2022_23" value="FY2022_23" />
                      <Picker.Item label="FY2021_22" value="FY2021_22" />
                      <Picker.Item label="FY2020_21" value="FY2020_21" />
                      {/* <Picker.Item
                          label={new Date().getUTCFullYear() - 2}
                          value={new Date().getUTCFullYear() - 2}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 3}
                          value={new Date().getUTCFullYear() - 3}
                        /> */}
                    </Picker>
                    <TouchableOpacity
                      style={[
                        styles.actionbtn,
                        {
                          borderColor: "#0a60ff",
                          marginHorizontal: 10,
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          isButtonLoading: true,
                        });
                        this.getbills();
                      }}
                    >
                      {/* {this.state.isButtonLoading ? (
                        <ActivityIndicator size="small" color="#0a60ff" />
                      ) : ( */}
                      <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                        View
                      </Text>
                      {/* )} */}
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <View style={{ width: "55%" }}>
                    <View
                      style={{
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        borderColor: "#c1c1c1",
                      }}
                    >
                      <Text
                        style={{
                          padding: 12,
                          fontSize: 22,
                          fontWeight: "500",
                          justifyContent: "center",
                        }}
                      >
                        Invoices
                      </Text>
                    </View>
                    <FlatList
                      data={this.state.billdata}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.billDate}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.billno}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {parseFloat(
                                item.billCalculations.billGrandTotal
                              ).toFixed(2)}
                            </Text>
                            <View
                              style={{
                                width: "20%",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#0a60ff" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      billno: item.billno,
                                      billDate: item.billDate,
                                      billArray: item.billArray,
                                      billCalculations: item.billCalculations,
                                      itemSplit: item.items,
                                    },
                                    () => {
                                      this.setState({ setVisibleBill: true });
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#0a60ff" }}>View</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#B50706" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      billno: item.billno,
                                      billDate: item.billDate,
                                      billArray: item.billArray,
                                      billCalculations: item.billCalculations,
                                      itemSplit: item.items,
                                    },
                                    () => {
                                      this.printPDF("(Original for Recipient)");
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#B50706" }}>PDF 1°</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#B50706" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      billno: item.billno,
                                      billDate: item.billDate,
                                      billArray: item.billArray,
                                      billCalculations: item.billCalculations,
                                      itemSplit: item.items,
                                    },
                                    () => {
                                      this.printPDF("(Duplicate)");
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#B50706" }}>PDF 2°</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#B50706" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      billno: item.billno,
                                      billDate: item.billDate,
                                      billArray: item.billArray,
                                      billCalculations: item.billCalculations,
                                      itemSplit: item.items,
                                    },
                                    () => {
                                      this.printPDF("(Triplicate)");
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#B50706" }}>PDF 3°</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        );
                      }}
                      ListHeaderComponent={this.tableHeader}
                      stickyHeaderIndices={[0]}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isbillLoading ? (
                            <ActivityIndicator size="large" color="#0a60ff" />
                          ) : (
                            <Text>No Invoices Generated Yet!</Text>
                          )}
                        </View>
                      }
                      keyExtractor={(item) => JSON.stringify(item.billId)}
                    />
                  </View>
                  <View
                    style={{
                      width: "45%",
                      borderLeftWidth: 1,
                      borderLeftColor: "#c1c1c1",
                    }}
                  >
                    <View
                      style={{
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        borderColor: "#c1c1c1",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          padding: 12,
                          fontSize: 22,
                          fontWeight: "500",
                          justifyContent: "center",
                        }}
                      >
                        Purchases Order
                      </Text>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 40,
                            alignSelf: "center",
                            width: 150,
                            alignItems: "center",
                          },
                        ]}
                        disabled={this.state.buttonLoading}
                        onPress={() => {
                          if (this.state.isPOdispatch==true){
                            this.setState({itemData:[]})
                          this.setState({poManageModul:true,modalViewOnly:false})
                        }
                          else{
                            this.setState({error:"Please switch Dispatch Mode to PO Dispatch"})
                          }
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Add P.O.</Text>
                      </TouchableOpacity>
                    </View>
                    <FlatList
                      data={this.state.poArrayFromDB}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.poDate}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.poNumber}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.status ? item.status : "N/a"}
                            </Text>
                            <View
                              style={{
                                width: "25%",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#0a60ff" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      poNumber: item.poNumber,
                                      poDate: item.poDate,
                                      poEndDate: item.poEndDate,
                                      modalViewOnly: true,
                                      itemData:item.itemData

                                    },
                                    () => {
                                      this.setState({ poManageModul: true });
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#0a60ff" }}>View</Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        );
                      }}
                      keyExtractor={(item, index) => JSON.stringify(index)}
                      ListHeaderComponent={this.tableHeaderPO}
                      stickyHeaderIndices={[0]}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isbillLoading ? (
                            <ActivityIndicator size="large" color="#0a60ff" />
                          ) : (
                            <Text>No PO found!</Text>
                          )}
                        </View>
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    // maxHeight: Dimensions.get("window").height - 55,
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
});
