import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { Picker } from "@react-native-picker/picker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class productViewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      productName: "",
      productDensity: "",
      hsn: "",
      gstCode: "",
      availableQtyCft: "",
      uom: "",
      //
      buttonLoading: false,
      error: "",
      disabled: false,
      isLoading: true,
      isModalLoading: true,
      ///
      viewCalender: false,
      setVisible: false,
      SnackbarVisible: false,
      historyOverView: [],
      historyDetails: [],
      setVisibleAbstract: false,
      columns: ["Sr. No.", "Date", "Qty Sales (-)", "Avaliable Qty", "Action"],
      abstractColumns: [
        "Time",
        "Particular",
        "Qty Sales (-)",
        "DM No.",
        "Issued by",
      ],
      viewDate: "",
      statementArray: [],
      dateFrom: new Date(),
      viewCalenderFrom: false,
      dateTo: new Date(),
      viewCalenderTo: false,
      isViewButtonLoading: false,
      uoid: "",
    };
  }
  componentDidMount() {
    const { productId } = this.props.route.params;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState(
              {
                productId,
              },
              () => {
                this.getProductData();

                var msInDay = 86400000;
                var daysToSub = new Date().getDate() - 1;
                var now = new Date();
                var milliseconds = now.getTime();
                var newMillisecods = milliseconds - msInDay * daysToSub;
                var newDate = new Date(newMillisecods);
                this.setState({ dateFrom: newDate }, () => {
                  this.getItemOverView();
                });
              }
            );
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getProductData = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productVital/${this.state.productId}`
      );

    dbref.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this.setState({
          productName: data.productName,
          productDensity: data.productDensity,
          hsn: data.hsn,
          gstCode: data.gstCode,
          availableQtyCft: data.availableQtyCft,
          uom: data.uom,
        });
      }
    });
  };
  updateDB = () => {
    this.setState({ buttonLoading: true });
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productVital/${this.state.productId}`
      );

    dbref
      .update({
        productName: this.state.productName,
        productDensity: this.state.productDensity,
        hsn: this.state.hsn,
        gstCode: this.state.gstCode,
        availableQtyCft: this.state.availableQtyCft,
        uom: this.state.uom,
      })
      .then(() => {
        this.setState(
          {
            buttonLoading: false,
            SnackbarVisible: true,
            error: "saved successfully",
          },
          () => {
            setTimeout(() => {
              this.setState({
                SnackbarVisible: false,
              });
            }, 2000);
          }
        );
      })
      .catch((e) =>
        this.setState(
          { buttonLoading: false, error: e.message, SnackbarVisible: true },
          () => {
            setTimeout(() => {
              this.setState({
                SnackbarVisible: false,
              });
            }, 2000);
          }
        )
      );
  };
  getItemOverView = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productHistoryOverView/${this.state.productId}`
      )
      .orderByChild("dateNumber")
      .startAt(Date.parse(this.state.dateFrom.toDateString()))
      .endAt(Date.parse(this.state.dateTo.toDateString()));

    dbref.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ historyOverView: data }, () => {
          this.setState({ isLoading: false, isViewButtonLoading: false });
        });
      } else {
        this.setState({
          isLoading: false,
          historyOverView: [],
          isViewButtonLoading: false,
        });
      }
    });
  };
  getItemDetailsHistory = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productHistoryDetails/${this.state.productId}/${this.state.viewDate}`
      );
    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this.setState(
          {
            historyDetails: data.sort(function (a, b) {
              if (a.timeStamp < b.timeStamp) {
                return -1;
              }
              if (a.timeStamp > b.timeStamp) {
                return 1;
              }
              return 0;
            }),
          },
          () => {
            this.setState({ isModalLoading: false });
          }
        );
      } else {
        this.setState({ isModalLoading: false, historyDetails: [] });
      }
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderAbstract = () => (
    <View style={styles.tableHeader}>
      {this.state.abstractColumns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  getDocData = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleDocument/${this.state.id}`
      );

    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this.setState({
          documentArray: data.sort(function (a, b) {
            if (a.expiryDate > b.expiryDate) {
              return 1;
            }
            if (a.expiryDate < b.expiryDate) {
              return -1;
            }
            return 0;
          }),
          isLoading: false,
        });
      }
    });
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Product Details" />
          <View style={styles.mainConsole}>
            <LeftBar product="#c1c1c1" />
            <View style={styles.palyArea}>
              <Portal>
                <Modal
                  visible={this.state.setVisibleAbstract}
                  onDismiss={() => {
                    this.setState(
                      {
                        //   States to reset on dismiss TODO:
                      },
                      () => {
                        this.setState({ setVisibleAbstract: false });
                      }
                    );
                  }}
                  contentContainerStyle={{
                    backgroundColor: "#ffffff",
                    width: 850,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                    borderWidth: 3,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      {this.state.productName} Abstract - Date:
                      {new Date(this.state.viewDate).toDateString()}
                    </Text>
                  </View>
                  <View style={styles.modelBody}>
                    <FlatList
                      data={this.state.historyDetails}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text style={styles.columnRowTxt}>{item.time}</Text>
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.particular}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.qtySales}
                            </Text>
                            <Text style={styles.columnRowTxt}>{item.dmNo}</Text>
                            <Text style={styles.columnRowTxt}>
                              {item.issuedBy ? item.issuedBy : ""}
                            </Text>
                          </View>
                        );
                      }}
                      keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                      ListHeaderComponent={this.tableHeaderAbstract}
                      stickyHeaderIndices={[0]}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isModalLoading ? (
                            <ActivityIndicator size="large" color="#0a60ff" />
                          ) : (
                            <Text>No Product History !</Text>
                          )}
                        </View>
                      }
                    />
                  </View>
                </Modal>
              </Portal>
              {/* Model Ends */}
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    mode="outlined"
                    label="Product Name"
                    value={this.state.productName}
                    style={[styles.inputField]}
                    editable={false}
                    placeholder="Product Name"
                  />
                  <View style={{ padding: 24 }}>
                    <Picker
                      selectedValue={this.state.uom}
                      style={{
                        padding: 12,
                        width: 150,
                        height: 40,
                        borderWidth: 1,
                        borderRadius: 5,
                      }}
                      onValueChange={(itemValue) =>
                        this.setState({ uom: itemValue })
                      }
                    >
                      <Picker.Item label="UOM" value="" />
                      <Picker.Item label="Brass" value="Brass" />
                      <Picker.Item label="cft" value="cft" />
                      <Picker.Item label="MT" value="MT" />
                      <Picker.Item label="m³" value="m³" />
                      <Picker.Item label="Unit" value="Unit" />
                    </Picker>
                  </View>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="HSN Code"
                    value={this.state.hsn}
                    style={[styles.inputField, { width: 190 }]}
                    onChangeText={(text) => {
                      this.setState({ hsn: text });
                    }}
                    placeholder="HSN Code"
                  />
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="GST Code in %"
                    value={this.state.gstCode}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ gstCode: text });
                    }}
                    placeholder="GST Code in %"
                  />
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Item Density"
                    value={this.state.productDensity}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ productDensity: text });
                    }}
                    placeholder="Item Density"
                  />
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Avaliable Qty(cft)"
                    value={this.state.availableQtyCft}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ availableQtyCft: text });
                    }}
                    placeholder="Avaliable Qty(cft)"
                  />
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      zIndex: -1,
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.updateDB();
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.goBack();
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: "column" }}>
                    <Text
                      style={{ padding: 10, fontWeight: "bold", fontSize: 16 }}
                    >
                      Product-Wise Sales History
                    </Text>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <Text style={{ padding: 20 }}>From :</Text>
                      <View style={{ flexDirection: "row", zIndex: 5 }}>
                        <View>
                          <Text
                            style={[
                              styles.inputField,
                              {
                                backgroundColor: "#EAEAEA",
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                margin: 14,
                                alignSelf: "center",
                                padding: 10,
                                width: 200,
                                textAlignVertical: "center",
                              },
                            ]}
                            onPress={() => {
                              this.setState({
                                viewCalenderFrom: !this.state.viewCalenderFrom,
                              });
                            }}
                          >
                            {/* {this.state.dateFrom.getDate()}/
                  {this.state.dateFrom.getMonth() + 1}/
                  {this.state.dateFrom.getFullYear()} */}
                            {this.state.dateFrom.toDateString()}
                          </Text>

                          <View
                            style={{
                              paddingLeft: 20,
                              marginTop: 75,
                              zIndex: 5,
                              position: "absolute",
                            }}
                          >
                            {this.state.viewCalenderFrom ? (
                              <Calendar
                                value={this.state.dateFrom}
                                onChange={(value) => {
                                  this.setState({ dateFrom: value }, () => {
                                    this.setState({ viewCalenderFrom: false });
                                  });
                                }}
                              />
                            ) : null}
                          </View>
                        </View>
                      </View>
                      {/* to */}
                      <Text style={{ padding: 20 }}>To :</Text>
                      <View style={{ flexDirection: "row", zIndex: 5 }}>
                        <View>
                          <Text
                            style={[
                              styles.inputField,
                              {
                                backgroundColor: "#EAEAEA",
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                margin: 14,
                                alignSelf: "center",
                                padding: 10,
                                width: 200,
                                textAlignVertical: "center",
                              },
                            ]}
                            onPress={() => {
                              this.setState({
                                viewCalenderTo: !this.state.viewCalenderTo,
                              });
                            }}
                          >
                            {/* {this.state.dateTo.getDate()}/
                  {this.state.dateTo.getMonth() + 1}/
                  {this.state.dateTo.getFullYear()} */}
                            {this.state.dateTo.toDateString()}
                          </Text>

                          <View
                            style={{
                              paddingLeft: 20,
                              marginTop: 75,
                              zIndex: 5,
                              position: "absolute",
                            }}
                          >
                            {this.state.viewCalenderTo ? (
                              <Calendar
                                value={this.state.dateTo}
                                onChange={(value) => {
                                  this.setState({ dateTo: value }, () => {
                                    this.setState({ viewCalenderTo: false });
                                  });
                                }}
                              />
                            ) : null}
                          </View>
                        </View>
                      </View>

                      <TouchableOpacity
                        style={[
                          styles.actionbtn,
                          {
                            borderColor: "#0a60ff",
                            margin: 15,
                            width: 100,
                            paddingVertical: 10,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ isViewButtonLoading: true });
                          this.getItemOverView();
                        }}
                      >
                        {this.state.isViewButtonLoading ? (
                          <ActivityIndicator size="small" color="#0a60ff" />
                        ) : (
                          <Text
                            style={{ color: "#0a60ff", textAlign: "center" }}
                          >
                            View
                          </Text>
                        )}
                      </TouchableOpacity>
                      {/* <TouchableOpacity
              style={[styles.actionbtn, { borderColor: "#B50706", margin: 15 }]}
              onPress={() => {
                if (this.state.paymentType) {
                  this.filterArrayForStatement();
                } else {
                  this.setState({
                    errorColor: "#B50706",
                  });
                }
              }}
            >
              <Text
                style={{
                  color: "#B50706",
                }}
              >
                PDF Download
              </Text>
            </TouchableOpacity> */}
                    </View>
                  </View>
                  <View style={{ zIndex: -1 }}>
                    <FlatList
                      data={this.state.historyOverView}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text style={styles.columnRowTxt}>{index + 1}</Text>
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.date}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.totalQtySales}
                            </Text>
                            <Text style={styles.columnRowTxt}>"Error"</Text>
                            <View
                              style={{
                                width: "20%",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <TouchableOpacity
                                style={[
                                  styles.actionbtn,
                                  { borderColor: "#0a60ff" },
                                ]}
                                onPress={() => {
                                  this.setState(
                                    {
                                      setVisibleAbstract: true,
                                      viewDate: item.dateNumber,
                                    },
                                    () => {
                                      this.getItemDetailsHistory();
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#0a60ff" }}>View</Text>
                              </TouchableOpacity>
                              {/* <TouchableOpacity
                            style={[
                              styles.actionbtn,
                              { borderColor: "#B50706" },
                            ]}
                            onPress={() => {
                              this.setState(
                                {
                                
                                },
                                () => {
                                   }
                              );
                            }}
                          >
                            <Text style={{ color: "#B50706" }}>
                              PDF Download
                            </Text>
                          </TouchableOpacity> */}
                            </View>
                          </View>
                        );
                      }}
                      ListHeaderComponent={this.tableHeader}
                      stickyHeaderIndices={[0]}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isLoading ? (
                            <ActivityIndicator size="large" color="#0a60ff" />
                          ) : (
                            <Text>No Product History !</Text>
                          )}
                        </View>
                      }
                      keyExtractor={(item) => JSON.stringify(item.productId)}
                    />
                  </View>
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
    alignSelf: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
});
