import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import profilPic from "../src/images/defaultPic.jpg";
import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class restrictedScreen extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      employeeName: "",
      employeeId: "",
      admin: null,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.getUserData();
  }

  getUserData = () => {
    AsyncStorage.getItem("uoid")
      .then((uoid) => {
        if (uoid != null) {
          let loginId = firebase.auth().currentUser.email
            ? firebase.auth().currentUser.email
            : user.phoneNumber;
          if (loginId != null) {
            let userid = loginId.split(".")[0];
            let dbRef = firebase
              .database()
              .ref(`${uoid}/erpData/users/${userid}`);

            dbRef.on("value", (DataSnapShot) => {
              if (DataSnapShot.exists()) {
                let data = DataSnapShot.val();
                this._isMounted &&
                  this.setState({
                    employeeName: data.name,
                    employeeId: data.loginId,
                    admin: data.admin,
                  });
              }
            });
          }
        }
      })
      .catch((e) => console.log(e));
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.profileHolder}>
          <Image
            source={profilPic}
            style={{
              width: 95,
              height: 95,
              resizeMode: "center",
              borderWidth: StyleSheet.hairlineWidth,
              borderRadius: 50,
              borderColor: "#fff",
              marginTop: 18,
            }}
          />
          <View style={{ marginVertical: 10, alignItems: "center" }}>
            <Text style={styles.textStyle}>{this.state.employeeName}</Text>
            <Text style={styles.textStyle}>{this.state.employeeId}</Text>
            <Text style={styles.textStyle}>
              {this.state.admin ? "Admin" : "Non-Admin"}
            </Text>
            <Text
              style={[styles.textStyle, { color: "#FF5757", fontSize: 22 }]}
            >
              User Restricted
            </Text>
          </View>
        </View>
        <View style={styles.buttonHolder}>
          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  AsyncStorage.removeItem("uoid");
                });
            }}
          >
            <Text style={{ color: "#fff", fontSize: 18 }}>Log Out</Text>
          </TouchableOpacity>
        </View>

        <StatusBar style="auto" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  profileHolder: {
    flex: 3,
    alignItems: "center",
  },
  buttonHolder: {
    flex: 2,
    justifyContent: "center",
    alignSelf: "center",
  },
  textStyle: {
    fontSize: 18,
    marginVertical: 3,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 20,
    borderRadius: 5,
    margin: 15,
  },
});
