import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import {
  Modal,
  Portal,
  Provider,
  RadioButton,
  TextInput,
  Snackbar,
} from "react-native-paper";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import LedgerTable from "../src/components/LedgerTable";
import EmployeeLedger from "../src/components/EmployeeLedger";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class ledgerScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      customerName: "",
      address: "",
      customerData: {},
      gst: "",
      date: new Date(),
      //
      loadingBasic: true,
      setVisible: false,
      modalOpened: null,
      viewCalender: false,
      buttonloading: false,
      //
      particular: "",
      creditAmt: 0,
      debitAmt: 0,
      //
      lastEntry: {},
      SnackbarVisible: false,
      error: "Ledger Entry Successfully !",
      loginUser: {},

      uoid: "",
    };
  }
  componentDidMount() {
    const { customerId } = this.props.route.params;
    this.setState({ customerId });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState({ customerId }, () => {
              this.getBasicsFromDb();
              // this.getLastEntry();
              this.getloginUser();
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getloginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;

    if (loginId != null) {
      let userid = loginId
        ? loginId.split(".")[0]
        : firebase.auth().currentUser.phoneNumber;
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  // getLastEntry = () => {
  //   let ledgerRef1 = firebase
  //     .database()
  //     .ref(
  //       `${this.state.uoid}/factoryData/customerData/ledger/${this.state.customerId}/FY2021_22`
  //     )
  //     .orderByChild(`dateNumber`)
  //     .limitToLast(1);

  //   ledgerRef1.on("value", (snapshot) => {
  //     if (snapshot.val()) {
  //       let data = Object.values(snapshot.val());
  //       this.setState({ lastEntry: data[0], islastBalanceAvaliable: true });
  //     } else {
  //       this.setState({
  //         lastEntry: { balance: 0 },
  //         islastBalanceAvaliable: true,
  //       });
  //     }
  //   });
  // };
  getBasicsFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();
        this.setState(
          {
            customerName: data.customerName,
            address: data.address,
            gst: data.gst,
            customerData: data,
          },
          () => {
            this.setState({ loadingBasic: false });
          }
        );
      }
    });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  makeEntryInLedger = () => {
    if (!this.state.debitAmt == 0 || !this.state.creditAmt == 0) {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      // let ledgerTime = new Date();
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/ledger/${this.state.customerId}/${this.state.date}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          transDate: `${this.state.date.toDateString()}`,
          dateNumber: Date.parse(this.state.date),
          discription: this.state.particular,
          credit: this.state.creditAmt,
          debit: this.state.debitAmt,
          timeStampId: `${this.state.date}`,
          isCashEntry: true,
          // balance: parseFloat(
          //   parseFloat(this.state.lastEntry.balance) +
          //     parseFloat(this.state.debitAmt) -
          //     parseFloat(this.state.creditAmt)
          // ).toFixed(2),
        })
        .then(() => {
          if (this.state.loginUser.isSiteLogin == true) {
            this.updateOrgCashBook();
            this.updateAdminCashBook();
          } else if (this.state.loginUser.isSiteLogin == false) {
            this.updateAdminCashBook();
          }
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  // getCashBookLastEntry = () => {
  //   let ledgerRef1 = firebase
  //     .database()
  //     .ref(`${this.state.uoid}/erpData/organizationData/ledger/FY2021_22`)
  //     .orderByChild(`dateNumber`)
  //     .limitToLast(1);

  //   ledgerRef1.on("value", (snapshot) => {
  //     if (snapshot.val()) {
  //       let data = Object.values(snapshot.val());
  //       this.setState({
  //         cashBooklastEntry: data[0],
  //         iscashBooklastEntryAvaliable: true,
  //       });
  //     } else {
  //       this.setState({
  //         cashBooklastEntry: { balance: 0 },
  //         iscashBooklastEntryAvaliable: true,
  //       });
  //     }
  //   });
  // };
  updateOrgCashBook = () => {
    if (!this.state.creditAmt == 0 || !this.state.debitAmt == 0) {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${this.state.date}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateStamp: Date.parse(this.state.date.toDateString()),
          dateNumber: Date.parse(this.state.date),
          transDate: this.state.date.toDateString(),
          discription: `${this.state.modalOpened} by ${this.state.customerName}`,
          credit: this.state.creditAmt,
          debit: this.state.debitAmt,
          // balance: parseFloat(
          //   parseFloat(this.state.cashBooklastEntry.balance) +
          //     parseFloat(this.state.creditAmt) -
          //     this.state.debitAmt
          // ).toFixed(2),
          timeStampId: `${this.state.date}`,
        })
        .then(() => {
          // this.setState({ buttonLoading: false }, () => {
          //   this.setState({
          //     setVisible: false,
          //     SnackbarVisible: true,
          //     particular: "",
          //     creditAmt: 0,
          //     debitAmt: 0,
          //     date: new Date(),
          //     error: "Ledger Entry Successfully !",
          //     buttonLoading: false,
          //   });
          //   setTimeout(() => {
          //     this.setState({ SnackbarVisible: false });
          //   }, 2000);
          // });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  updateAdminCashBook = () => {
    if (!this.state.creditAmt == 0 || !this.state.debitAmt == 0) {
      this.setState({ buttonLoading: true });
      var transactionId = this.randomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${this.state.date}`
        );
      ledgerRef
        .set({
          transactionId: transactionId,
          dateStamp: Date.parse(this.state.date.toDateString()),
          timestamp: Date.parse(new Date()),
          date: `${this.state.date.getFullYear()}-${this.state.date.getMonth()}-${this.state.date.getDate()}`,
          discription: `${this.state.modalOpened} by ${this.state.customerName}`,
          cashIn: parseFloat(this.state.creditAmt),
          cashOut: parseFloat(this.state.debitAmt),
          entryBy: this.state.loginUser.name,
          timeStampId: `${this.state.date}`,
        })
        .then(() => {
          this.setState({ buttonLoading: false }, () => {
            this.setState({
              setVisible: false,
              SnackbarVisible: true,
              particular: "",
              creditAmt: 0,
              debitAmt: 0,
              date: new Date(),
              error: "Ledger Entry Successfully !",
              buttonLoading: false,
            });
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 2000);
          });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.customerName + ", " + this.state.address} />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" customer="underline" />
            {/* Modal Section Start------------------------ */}

            <Portal>
              <Modal
                visible={this.state.setVisible}
                onDismiss={() => {
                  this.setState(
                    {
                      particular: "",
                      creditAmt: 0,
                      debitAmt: 0,
                      date: new Date(),
                    },
                    () => {
                      this.setState({ setVisible: false });
                    }
                  );
                }}
                contentContainerStyle={{
                  backgroundColor:
                    this.state.modalOpened == "Credit" ? "#B3E4D0" : "#FFDDDD",
                  width: 850,
                  borderRadius: 5,
                  borderColor:
                    this.state.modalOpened === "Credit" ? "#009C44" : "#de5246",
                  borderWidth: 3,
                  alignSelf: "center",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    Amount {this.state.modalOpened} by {this.state.customerName}
                  </Text>
                </View>
                <View style={styles.modelBody}>
                  <View style={{ flexDirection: "row", zIndex: 5 }}>
                    <View>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 22,
                            alignSelf: "center",
                            padding: 10,
                            width: 300,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalender: !this.state.viewCalender,
                          });
                        }}
                      >
                        {this.state.date.getDate()}/
                        {this.state.date.getMonth() + 1}/
                        {this.state.date.getFullYear()}
                      </Text>

                      <View
                        style={{
                          paddingLeft: 20,
                          marginTop: 75,
                          zIndex: 5,
                          position: "absolute",
                        }}
                      >
                        {this.state.viewCalender ? (
                          <Calendar
                            value={this.state.date}
                            onChange={(value) => {
                              this.setState({ date: value }, () => {
                                this.setState({ viewCalender: false });
                              });
                            }}
                          />
                        ) : null}
                      </View>
                    </View>

                    <TextInput
                      mode="outlined"
                      label="Particular"
                      value={this.state.particular}
                      style={styles.inputField}
                      onChangeText={(text) => {
                        this.setState({ particular: text });
                      }}
                      placeholder="Particular"
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View style={{ flexDirection: "row", zIndex: -1 }}>
                      <Text style={{ alignSelf: "center" }}>
                        Amount in Rs. to {this.state.modalOpened}
                      </Text>
                      <TextInput
                        mode="outlined"
                        label="Amount"
                        value={
                          this.state.modalOpened == "Credit"
                            ? this.state.creditAmt
                            : this.state.debitAmt
                        }
                        style={[styles.inputField, { width: 170 }]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.state.modalOpened == "Credit"
                              ? this.setState({ creditAmt: text })
                              : this.setState({ debitAmt: text });
                          }
                        }}
                        placeholder="Amount"
                        autoCompleteType="tel"
                      />
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        disabled={this.state.buttonLoading}
                        style={[
                          styles.addNewButton,
                          {
                            width: 150,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ data: new Date() }, () => {
                            this.makeEntryInLedger();
                          });
                        }}
                      >
                        {this.state.buttonLoading ? (
                          <ActivityIndicator
                            animating={true}
                            color="#fff"
                            size="small"
                          />
                        ) : (
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            {" "}
                            Save {this.state.modalOpened}
                          </Text>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            backgroundColor: "#fff",
                            width: 80,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ setVisible: false }, () => {
                            this.setState({
                              particular: "",
                              creditAmt: 0,
                              debitAmt: 0,
                              date: new Date(),
                            });
                          });
                        }}
                      >
                        <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                          Cancel
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Modal>
            </Portal>

            {/* Modal Section Ends------------------------- */}
            <View style={styles.palyArea}>
              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Ledger
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.addNewButton, { marginRight: 20 }]}
                      onPress={() => {
                        this.props.navigation.navigate("customerEdit", {
                          customerId: this.state.customerId,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Profile</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.addNewButton, { marginRight: 20 }]}
                      onPress={() => {
                        this.props.navigation.navigate("salesReportScreen", {
                          customerId: this.state.customerId,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Sales Report</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          marginRight: 20,
                          backgroundColor: "#009C44",
                          borderColor: "#009C44",
                          paddingHorizontal: 30,
                        },
                      ]}
                      onPress={() => {
                        this.setState({ modalOpened: "Credit" }, () => {
                          this.setState({ setVisible: true });
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Credit</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#de5246",
                          borderColor: "#de5246",
                          paddingHorizontal: 20,
                        },
                      ]}
                      onPress={() => {
                        this.setState({ modalOpened: "Debit" }, () => {
                          this.setState({ setVisible: true });
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Debit</Text>
                    </TouchableOpacity> */}
                  </View>
                </View>

                {/* <LedgerTable
                  customerId={this.state.customerId}
                  closingBalance={this.state.lastEntry.balance}
                  customerData={this.state.customerData}
                /> */}
                <EmployeeLedger
                  id={this.state.customerId}
                  employeeName={this.state.customerData.customerName}
                  address={this.state.customerData.address}
                  number={this.state.customerData.number}
                  admin={this.state.loginUser.admin}
                  dataType="customerData"
                  isDetailsAvaliable={true}
                  // dbLocation={`factoryData/customerData/ledger/${this.state.customerId}`}
                />
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
    minWidth: 750,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },

  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
});
