import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Linking,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { TextInput, Button, Divider } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as WebBrowser from "expo-web-browser";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
export default class newAdminUserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: "",
      number: "",
      atPost: "",
      name: "",
      loginId: "",
      passcode: "",
      error: null,
      nextBack: false,
      loadingSaveButton: false,
      fiscalyear: "",
    };
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((auth) => {
      if (auth) {
        this.setState({ number: auth.phoneNumber });
      }
    });
    this.getCurrentFinancialYear();

    const keys = ["asdlogq", "qwertypasz"];
    try {
      AsyncStorage.multiRemove(keys);
    } catch (e) {
      // remove error
    }
  }
  getCurrentFinancialYear = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }
    this.setState({ fiscalyear });
  };
  generateAdminAndCompany = () => {
    this.storeDataToLocalStorage();
    this.setState({ loadingSaveButton: true, error: "Generating User" });
    let uniqueId = this.state.loginId.split(".")[0];
    let dbref = firebase.database().ref(`allUsers/${uniqueId}`);
    let dbref0 = firebase.database().ref(`allUsers/${this.state.number}`);
    dbref0.set({
      mobileNumber: this.state.number,
      isUnique: true,
      timeStamp: new Date(),
    });
    dbref
      .set({
        mobileNumber: this.state.number,
        isUnique: false,
        timeStamp: new Date(),
      })
      .then(() => {
        this.setState({ loadingSaveButton: true, error: "Generating Company" });
        let dbref2 = firebase
          .database()
          .ref(`${this.state.number}/erpData/organizationData/basics`);
        let dbref21 = firebase
          .database()
          .ref(
            `${this.state.number}/factoryData/extra/${this.state.fiscalyear}`
          );
        dbref21.set({
          dmCount: 0,
          gstBillCount: 0,
          localBillCount: 0,
          nrdmCount: 0,
          quoteNo: 0,
          receiptNo: 0,
          orderNo: 0,
        });
        let dbref303 = firebase
          .database()
          .ref(
            `${this.state.number}/erpData/organizationData/notifications/16235987458656518`
          );

        dbref303.set({
          id: "16235987458656518",
          dismiss: false,
          notice:
            "We trust that your experience with our sERP will bring you the utmost satisfaction. Happy Business !",
          title: "Hello, Welcome!",
        });

        dbref2
          .set({
            organizationName: this.state.companyName,
            date: new Date().toDateString(),
            atPost: this.state.atPost,
            expiryDate: new Date().getTime() + 86400000 * 31,
          })
          .then(() => {
            this.setState({ loadingSaveButton: true, error: "Final TouchUp" });
            let dbref3 = firebase
              .database()
              .ref(`${this.state.number}/erpData/users/${uniqueId}`);
            dbref3
              .set({
                admin: true,
                createdAt: new Date(),
                createdBy: "Self",
                entryinAuth: true,
                loginId: this.state.loginId,
                passcode: this.state.passcode,
                name: this.state.name,
                phoneNumber: this.state.number, //
                canAddOrdersToSalesAndroid: true,
                canApproveOrders: true,
                canLoadLorry: true,
                isERPSalesAndroidMobileLoginAllowed: true,
                isERPMobileAndroidLoginAllowed: true, ////////
                isERPDesktopLoginAllowed: true,
                canGenerateLeadsToSalesAndroid: true,
                canGenerateDM: true,
                canViewDashBoard: true,
                canViewCustomerManagement: true,
                canViewVendorManagement: true,
                canViewMRO: true,
                canViewOrderManagement: true,
                canViewProductManagement: true,
                canViewEmployeeManagement: true,
                canViewLeadsManagement: true,
                canViewFleetManagement: true,
                isSiteLogin: false,
              })
              .then(() => {
                let dbref4 = firebase
                  .database()
                  .ref(
                    `${this.state.number}/erpData/users/${this.state.number}`
                  );
                dbref4
                  .set({
                    admin: true,
                    createdAt: new Date(),
                    createdBy: "Self",
                    entryinAuth: true,
                    loginId: this.state.loginId,
                    passcode: this.state.passcode,
                    name: this.state.name,
                    phoneNumber: this.state.number,
                    canAddOrdersToSalesAndroid: true,
                    canApproveOrders: true,
                    canLoadLorry: true,
                    isERPSalesAndroidMobileLoginAllowed: true,
                    isERPMobileAndroidLoginAllowed: true, ////////
                    isERPDesktopLoginAllowed: true,
                    canGenerateLeadsToSalesAndroid: true,
                    canGenerateDM: true,
                    canViewDashBoard: true,
                    canViewCustomerManagement: true,
                    canViewVendorManagement: true,
                    canViewMRO: true,
                    canViewOrderManagement: true,
                    canViewProductManagement: true,
                    canViewEmployeeManagement: true,
                    canViewLeadsManagement: true,
                    canViewFleetManagement: true,
                    isSiteLogin: false,
                  })
                  .then(() => {
                    this.setState({ loadingSaveButton: false, error: "Done" });
                    firebase.auth().signOut();
                    this.sendSMSViaMSG91();
                  });
              });
          })
          .catch();
      })
      .catch((e) => console.log(e));
  };

  sendSMSViaMSG91 = () => {
    const data = `{\n  \"flow_id\": \"6263ce3683da0b41b74836e2\",\n  \"mobiles\": \"91${
      this.state.number
    }\",\n  \"ClientCompany\": \"${this.state.companyName.toUpperCase()}\"\n \n}`;

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });

    xhr.open(
      "POST",
      "https://cors-anywhere.herokuapp.com/https://api.msg91.com/api/v5/flow/"
    );
    xhr.setRequestHeader("authkey", "374421AhLkYQBV622ef928P1");
    xhr.setRequestHeader("content-type", "application/JSON");

    xhr.send(data);
  };

  loginGenerator = () => {
    let fName = this.state.name.split(" ");
    let companyName = this.state.companyName.split(" ");
    let companyInitialArray = companyName.map(
      (item) => (item = item[0].toLowerCase())
    );
    let companyInitial = companyInitialArray.join("");
    let loginid =
      fName[0].toLocaleLowerCase() +
      Math.floor(Math.random() * 99) +
      `@${companyInitial}.in`;
    let passcode = Math.floor(Math.random() * -100000 + 999999);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(loginid)
      .then((value) => {
        if (value.length > 0) {
          this.loginGenerator();
        } else {
          this.setState({ loginId: loginid, passcode });
        }
      });
  };
  storeDataToLocalStorage = async () => {
    try {
      await AsyncStorage.setItem("asdlogq", this.state.loginId).then(() => {
        AsyncStorage.setItem("qwertypasz", this.state.passcode).then(() => {});
      });
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.welcomeTextHolder}>
          <Text style={{ fontSize: 38, fontWeight: "500" }}>Welcome!</Text>
        </View>
        {this.state.nextBack == false ? (
          <View style={styles.loginHolder}>
            <Text style={{ fontSize: 22, fontWeight: "400" }}>
              Start with your company Information
            </Text>
            {/* {this.state.companyName ? (
            <Text style={{ fontSize: 16, color: "#000" }}>
              {this.state.companyName}, {this.state.atPost}
            </Text>
          ) : null} */}
            <View>
              <TextInput
                mode="outlined"
                label="Company Name *"
                value={this.state.companyName}
                style={[styles.inputField, {}]}
                onChangeText={(text) => {
                  this.setState({ companyName: text });
                }}
                placeholder="Company Name *"
              />
              <Text style={{ fontSize: 12, color: "#808080" }}>
                Can be changed later
              </Text>
            </View>
            <View>
              <TextInput
                mode="outlined"
                label="Plant Addresss"
                value={this.state.atPost}
                style={[styles.inputField, {}]}
                onChangeText={(text) => {
                  let expression = /^\S*$/;
                  if (text.match(expression)) {
                    this.setState({ atPost: text });
                  }
                }}
                placeholder="At. Post(City/Town/Village only)"
              />
              <Text style={{ fontSize: 12, color: "#808080" }}>
                Can be changed later
              </Text>
            </View>
            <Text style={{ color: "#000" }}>
              {"Company name will be printed on Invoice, D.M., etc"}
            </Text>

            <Button
              disabled={this.state.loadingButton}
              mode="contained"
              onPress={() => {
                if (this.state.companyName != "" && this.state.atPost != "") {
                  this.setState({ nextBack: true });
                }
              }}
              style={styles.loginButton}
            >
              {this.state.loadingButton ? (
                <ActivityIndicator animating={true} color="#fff" />
              ) : (
                "NEXT"
              )}
            </Button>

            <TouchableOpacity
              onPress={() => {
                firebase.auth().signOut();
              }}
            >
              <View
                style={{ flexDirection: "row", justifyContent: "space-evenly" }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#000",
                    textDecorationLine: "underline",
                    marginRight: 6,
                  }}
                >
                  I will do it later, Logout now !
                </Text>
                <MaterialIcons name="logout" size={22} color="#000" />
              </View>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.loginHolder}>
            <Text
              style={{
                fontSize: 22,
                fontWeight: "400",
                textAlign: "center",
                width: 380,
              }}
            >
              As a admin, generate yourself a loginid and password
            </Text>

            <TextInput
              mode="outlined"
              label="Your Full Name"
              value={this.state.name}
              style={[styles.inputField, {}]}
              onChangeText={(text) => {
                this.setState({ name: text });
              }}
              placeholder="Full Name"
            />
            <View
              style={{
                flexDirection: "row",
                width: 350,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity onPress={() => this.loginGenerator()}>
                <TextInput
                  mode="outlined"
                  label="Login Id"
                  value={this.state.loginId}
                  style={[styles.inputField, { width: 300 }]}
                  placeholder="Login Id"
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ alignSelf: "center" }}
                onPress={() => this.loginGenerator()}
              >
                <Ionicons name="refresh" size={26} color="black" />
              </TouchableOpacity>
            </View>

            <TextInput
              mode="outlined"
              label="Passcode"
              value={this.state.passcode}
              style={[styles.inputField, {}]}
              placeholder="Passcode"
            />
            <View
              style={{
                flexDirection: "row",
                width: 400,
                justifyContent: "space-between",
              }}
            >
              <Button
                mode="contained"
                onPress={() => {
                  this.setState({ nextBack: false });
                }}
                style={[
                  styles.loginButton,
                  { width: 180, backgroundColor: "#fff" },
                ]}
              >
                <Text style={{ color: "#0a60ff" }}>Back</Text>
              </Button>
              <Button
                disabled={this.state.loadingSaveButton}
                mode="contained"
                onPress={() => {
                  if (
                    this.state.companyName != "" &&
                    this.state.atPost != "" &&
                    this.state.name != "" &&
                    this.state.loginId != "" &&
                    this.state.passcode != ""
                  ) {
                    this.generateAdminAndCompany();
                  }
                }}
                style={[styles.loginButton, { width: 180 }]}
              >
                {this.state.loadingSaveButton ? (
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ color: "#fff", fontSize: 10 }}>
                      {this.state.error}
                    </Text>
                    <ActivityIndicator
                      animating={true}
                      color="#fff"
                      size="small"
                    />
                  </View>
                ) : (
                  "Save"
                )}
              </Button>
            </View>
          </View>
        )}

        <View style={styles.footerHolder}>
          <Divider style={styles.divider} />
          <Text style={{ paddingVertical: 60 }}>
            Developed by{" "}
            <Text
              style={{ fontStyle: "italic", textDecorationLine: "underline" }}
              onPress={() => {
                WebBrowser.openBrowserAsync("https://squaretechapps.in");
              }}
            >
              SquareTech Apps.
            </Text>{" "}
            All Rights Reserved {new Date().getFullYear()}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  welcomeTextHolder: {
    flex: 0.5,
    marginTop: 50,
    marginBottom: 30,
  },
  loginHolder: {
    flex: 4,
    width: 420,
    backgroundColor: "#EFEFEF",
    borderWidth: 1,
    borderColor: "#c0c0c0",
    borderRadius: 5,
    justifyContent: "space-around",
    alignItems: "center",
  },
  footerHolder: {
    flex: 2,
    paddingVertical: 20,
    alignItems: "center",
  },
  inputField: {
    width: 350,
    height: 45,
  },
  loginButton: {
    backgroundColor: "#0a60ff",
    width: 400,
    padding: 5,
  },
  divider: {
    width: 750,
  },
});
