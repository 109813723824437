import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import EmployeeLedger from "../src/components/EmployeeLedger";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class employeeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SnackbarVisible: false,
      employeeId: "", //
      employeeName: "",
      post: "",
      name: "",
      address: "",
      contact: "",
      joiningDate: "",
      salary: "",
      error: "",
      isModalOpening: false,
      buttonLoading: false,
      //Model

      modelloading: false,
      //
      attendanceMonthShow: new Date().getMonth(),
      attendanceYearShow: new Date().getFullYear(),
      monthArray: [],
      attendenceList: [],
      finalCalenderArray: [],
      columns: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: [
        "	January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Augest",
        "September",
        "October",
        "November",
        "December",
      ],
      refreshing: false,
      particular: "",
      creditAmt: 0,
      debitAmt: 0,
      setVisible: false,
      viewCalender: false,
      date: new Date(),
      modalOpened: null,
      lastEntry: {},
      // cashBooklastEntry: {},
      loginUser: {},
      salary: "",
      unpaidAmount: 0,
      unpaidDays: 0,
      joiningDateTimeStamp: 0,
      paidOnly: 0,
      subLedgerlastEntry: [],
      commitsData: [],
      uoid: "",
      isLoading: true,
    };
  }
  componentDidMount() {
    const { employeeId, employeeName, salary, joiningDateTimeStamp } =
      this.props.route.params;
    this.setState({
      employeeId,
      joiningDateTimeStamp,
    });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState(
              {
                employeeId,
                employeeName,
                salary,
                joiningDateTimeStamp,
              },
              () => {
                // this.getLastEntry();
                this.startUpFunction();
                this.getloginUser();
              }
            );
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getloginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;

    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  makeEntryInLedger = () => {
    if (!this.state.debitAmt == 0 || !this.state.creditAmt == 0) {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      // let ledgerTime = new Date();
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/employeeData/ledger/${this.state.employeeId}/${this.state.date}`
        );

      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateNumber: Date.parse(this.state.date),
          transDate: this.state.date.toDateString(),
          discription: this.state.particular,
          credit: this.state.creditAmt,
          debit: this.state.debitAmt,
          timeStampId: `${this.state.date}`,

          isCashEntry: true,
        })
        .then(() => {
          if (this.state.loginUser.isSiteLogin == true) {
            this.updateOrgCashBook();
            this.updateAdminCashBook();
          } else if (this.state.loginUser.isSiteLogin == false) {
            this.updateAdminCashBook();
          }
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };

  updateOrgCashBook = () => {
    if (!this.state.creditAmt == 0 || !this.state.debitAmt == 0) {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${this.state.date}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateNumber: Date.parse(new Date()),
          dateStamp: Date.parse(new Date().toDateString()),
          transDate: this.state.date.toDateString(),
          discription: `${this.state.modalOpened} by ${this.state.employeeName}`,
          credit: this.state.creditAmt,
          debit: this.state.debitAmt,
          // balance: parseFloat(
          //   parseFloat(this.state.cashBooklastEntry.balance) +
          //     parseFloat(this.state.creditAmt) -
          //     this.state.debitAmt
          // ).toFixed(2),
          timeStampId: `${this.state.date}`,
        })
        .then(() => {})
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  updateAdminCashBook = () => {
    if (!this.state.creditAmt == 0 || !this.state.debitAmt == 0) {
      this.setState({ buttonLoading: true });
      var transactionId = this.randomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${this.state.date}`
        );
      ledgerRef
        .set({
          transactionId: transactionId,
          dateStamp: Date.parse(this.state.date.toDateString()),
          timestamp: Date.parse(this.state.date),
          date: `${this.state.date.getFullYear()}-${this.state.date.getMonth()}-${this.state.date.getDate()}`,
          discription: `${this.state.modalOpened} by ${this.state.employeeName}`,
          cashIn: parseFloat(this.state.creditAmt),
          cashOut: parseFloat(this.state.debitAmt),
          entryBy: this.state.loginUser.name,
          timeStampId: `${this.state.date}`,
        })
        .then(() => {
          this.setState({ buttonLoading: false }, () => {
            this.setState({
              setVisible: false,
              SnackbarVisible: true,
              particular: "",
              creditAmt: 0,
              debitAmt: 0,
              date: new Date(),
              error: "Ledger Entry Successfully !",
              buttonLoading: false,
            });
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 2000);
          });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  startUpFunction = () => {
    this.setState(
      {
        monthArray: new Array(
          new Date(
            this.state.attendanceYearShow,
            this.state.attendanceMonthShow + 1,
            0
          ).getDate()
        ).fill({}),
      },
      () => {
        this.getAttendenceList();
      }
    );
  };
  getAttendenceList = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/attendancePerEmploy/${this.state.employeeId}/${this.state.attendanceYearShow}/${this.state.attendanceMonthShow}`
      );
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ attendenceList: data, commitsData: data }, () => {
          this.getfinalArray();
        });
      } else {
        this.setState(
          {
            isLoading: false,
            attendenceList: [],
            commitsData: [],
          },
          () => {
            this.getfinalArray();
          }
        );
      }
    });
  };
  getfinalArray = () => {
    let array = this.state.monthArray.map((item1, index1) => {
      item1 = {
        timestamp: Date.parse(
          new Date(
            `${this.state.attendanceMonthShow + 1}-${index1 + 1}-${
              this.state.attendanceYearShow
            }`
          )
        ),
      };
      this.state.attendenceList.map((item2, index2) => {
        if (index1 + 1 == new Date(item2.timestamp).getDate()) {
          item1 = item2;
        }
      });
      return item1;
    });
    this.setState({ finalCalenderArray: array }, () => {
      this.getUnpaidAmount();

      this.setState({ refreshing: false, isLoading: false });
    });
  };
  getUnpaidAmount = () => {
    let numberofDaysinSelectedMonth =
      this.state.attendanceMonthShow == new Date().getMonth()
        ? this.state.attendanceMonthShow ==
          new Date(this.state.joiningDateTimeStamp).getMonth()
          ? new Date(
              this.state.attendanceYearShow,
              this.state.attendanceMonthShow + 1,
              0
            ).getDate() -
            (new Date(this.state.joiningDateTimeStamp).getDate() + 1) -
            (new Date(
              this.state.attendanceYearShow,
              this.state.attendanceMonthShow + 1,
              0
            ).getDate() -
              new Date().getDate() -
              2)
          : new Date().getDate()
        : this.state.attendanceMonthShow ==
          new Date(this.state.joiningDateTimeStamp).getMonth()
        ? new Date(
            this.state.attendanceYearShow,
            this.state.attendanceMonthShow + 1,
            0
          ).getDate() -
          new Date(this.state.joiningDateTimeStamp).getDate() +
          1
        : new Date(
            this.state.attendanceYearShow,
            this.state.attendanceMonthShow + 1,
            0
          ).getDate();

    let paidDays = this.state.attendenceList.filter((item) => {
      if (
        item.paid &&
        item.p1 &&
        item.p2 &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      }
    });

    let absentDay = this.state.attendenceList.filter((item) => {
      if (
        !item.p1 &&
        !item.p2 &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      }
    });
    let halfDaysAndPaid = this.state.attendenceList.filter((item) => {
      if (
        !item.p1 &&
        item.p2 &&
        item.paid &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      } else if (
        item.p1 &&
        !item.p2 &&
        item.paid &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      }
    });
    let halfDays = this.state.attendenceList.filter((item) => {
      //
      if (
        !item.p1 &&
        item.p2 &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      } else if (
        item.p1 &&
        !item.p2 &&
        item.timestamp >= this.state.joiningDateTimeStamp
      ) {
        return item;
      }
    });
    const arrKeys = this.state.attendenceList.map((el) => el.paid);
    let lastPaidDayIndex = arrKeys.lastIndexOf(true);
    let holidays =
      lastPaidDayIndex != -1
        ? this.state.finalCalenderArray.filter((item, index) => {
            if (
              !item.p1 &&
              !item.p2 &&
              this.state.joiningDateTimeStamp <= item.timestamp &&
              this.state.attendenceList[lastPaidDayIndex].timestamp >=
                item.timestamp
            ) {
              return item;
            }
          })
        : [];

    let totalLengthOfPaidDays =
      paidDays.length +
      absentDay.length +
      halfDaysAndPaid.length / 2 +
      halfDays.length / 2;

    let unpaidDays =
      numberofDaysinSelectedMonth - totalLengthOfPaidDays - holidays.length;

    let unpaidAmount = (
      unpaidDays *
      (parseFloat(this.state.salary) /
        new Date(
          this.state.attendanceYearShow,
          this.state.attendanceMonthShow + 1,
          0
        ).getDate())
    ).toFixed(0);
    let paidOnly = this.state.attendenceList.filter((item) => {
      if ((item.p1 || item.p2) && !item.paid) {
        return item;
      }
    });
    this.setState({ unpaidAmount, unpaidDays, paidOnly: paidOnly.length });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  listFooter = () => (
    <View style={{ flexDirection: "row", width: "100%", marginTop: 12 }}>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            height: 12,
            width: 12,
            borderRadius: 50,
            backgroundColor: "#DE5246",
            alignSelf: "center",
            marginHorizontal: 5,
          }}
        />

        <Text style={{ color: "#000", fontSize: 14 }}>Absent</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            height: 12,
            width: 12,
            borderRadius: 50,
            marginHorizontal: 5,
            alignSelf: "center",
            backgroundColor: "#009C44",
          }}
        />
        <Text>Present</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            height: 12,
            width: 12,
            borderRadius: 50,
            marginHorizontal: 5,
            alignSelf: "center",
            backgroundColor: "#FFBF00",
          }}
        />
        <Text>Half Day</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            height: 6,
            width: 6,
            borderRadius: 50,
            marginHorizontal: 5,
            alignSelf: "center",
            backgroundColor: "#000",
          }}
        />
        <Text>Paid</Text>
      </View>
    </View>
  );
  savePaymentDataToDB = (date) => {
    let DBRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/attendance/${this.state.attendanceYearShow}/${this.state.attendanceMonthShow}/${date}/${this.state.employeeId}`
      );
    DBRef.update({
      paymentTimeStamp: Date.parse(new Date()),
      paid: true,
      paymentDate: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
    })
      .then(() => {
        let DBRef2 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/employeeData/attendancePerEmploy/${this.state.employeeId}/${this.state.attendanceYearShow}/${this.state.attendanceMonthShow}/${date}`
          );
        DBRef2.update({
          paymentTimeStamp: Date.parse(new Date()),
          paid: true,
          paymentDate: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()}`,
        });
      })
      .catch((e) => console.log(e.message));
  };
  generatePaymentInLedger = () => {
    this.setState({ buttonLoading: true });
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let ledgerTime = new Date();
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/ledger/${this.state.employeeId}/${ledgerTime}`
      );
    ledgerRef
      .set({
        ledgerId: ledgerId,
        dateNumber: Date.parse(new Date()),
        transDate: this.state.date.toDateString(),
        discription: `Payment for ${
          this.state.months[this.state.attendanceMonthShow]
        } Generated`,
        credit: this.state.unpaidAmount,
        debit: 0,
        timeStampId: `${ledgerTime}`,
        isCashEntry: false,
      })
      .then(() => {
        this.setState({ buttonLoading: false }, () => {
          this.setState({
            setVisible: false,
            SnackbarVisible: true,
            particular: "",
            creditAmt: 0,
            debitAmt: 0,
            date: new Date(),
            error: "Payment Generated Successfully !",
            buttonLoading: false,
          });
          setTimeout(() => {
            this.setState({ SnackbarVisible: false });
          }, 2000);
        });
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.employeeName} />
          <View style={styles.mainConsole}>
            <LeftBar employee="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Model */}
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState(
                      {
                        particular: "",
                        creditAmt: 0,
                        debitAmt: 0,
                        date: new Date(),
                      },
                      () => {
                        this.setState({ setVisible: false });
                      }
                    );
                  }}
                  contentContainerStyle={{
                    backgroundColor:
                      this.state.modalOpened == "Credit"
                        ? "#B3E4D0"
                        : "#FFDDDD",
                    width: 850,
                    borderRadius: 5,
                    borderColor:
                      this.state.modalOpened === "Credit"
                        ? "#009C44"
                        : "#de5246",
                    borderWidth: 3,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Amount {this.state.modalOpened} by{" "}
                      {this.state.employeeName}
                    </Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row", zIndex: 5 }}>
                      <View>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 22,
                              alignSelf: "center",
                              padding: 10,
                              width: 300,
                              textAlignVertical: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              viewCalender: !this.state.viewCalender,
                            });
                          }}
                        >
                          {this.state.date.getDate()}/
                          {this.state.date.getMonth() + 1}/
                          {this.state.date.getFullYear()}
                        </Text>

                        <View
                          style={{
                            paddingLeft: 20,
                            marginTop: 75,
                            zIndex: 5,
                            position: "absolute",
                          }}
                        >
                          {this.state.viewCalender ? (
                            <Calendar
                              value={this.state.date}
                              onChange={(value) => {
                                this.setState({ date: value }, () => {
                                  this.setState({ viewCalender: false });
                                });
                              }}
                            />
                          ) : null}
                        </View>
                      </View>

                      <TextInput
                        mode="outlined"
                        label="Particular"
                        value={this.state.particular}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ particular: text });
                        }}
                        placeholder="Particular"
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <View style={{ flexDirection: "row", zIndex: -1 }}>
                        <Text style={{ alignSelf: "center" }}>
                          Amount in Rs. to {this.state.modalOpened}
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Amount"
                          value={
                            this.state.modalOpened == "Credit"
                              ? this.state.creditAmt
                              : this.state.debitAmt
                          }
                          style={[styles.inputField, { width: 170 }]}
                          onChangeText={(text) => {
                            let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                            if (text.match(regex)) {
                              this.state.modalOpened == "Credit"
                                ? this.setState({ creditAmt: text })
                                : this.setState({ debitAmt: text });
                            }
                          }}
                          placeholder="Amount"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          disabled={this.state.buttonLoading}
                          style={[
                            styles.addNewButton,
                            {
                              width: 150,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.setState({ data: new Date() }, () => {
                              this.makeEntryInLedger();
                            });
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator
                              animating={true}
                              color="#fff"
                              size="small"
                            />
                          ) : (
                            <Text
                              style={{ color: "#fff", alignSelf: "center" }}
                            >
                              {" "}
                              Save {this.state.modalOpened}
                            </Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              width: 80,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.setState({ setVisible: false }, () => {
                              this.setState({
                                particular: "",
                                creditAmt: 0,
                                debitAmt: 0,
                                date: new Date(),
                              });
                            });
                          }}
                        >
                          <Text
                            style={{ color: "#0a60ff", alignSelf: "center" }}
                          >
                            Cancel
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>
              </Portal>

              <View
                style={{
                  flexDirection: "row",
                  marginHorizontal: 70,
                  paddingVertical: 20,
                  flexWrap: "wrap",
                }}
              >
                <View>
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.addNewButton, { width: 55, padding: 5 }]}
                      onPress={() => {
                        this.state.attendanceMonthShow == 0
                          ? this.setState(
                              {
                                attendanceYearShow:
                                  this.state.attendanceYearShow - 1,
                                attendanceMonthShow: 11,
                                isLoading: true,
                                finalCalenderArray: [],
                                attendenceList: [],
                              },
                              () => {
                                this.startUpFunction();
                              }
                            )
                          : this.setState(
                              {
                                attendanceMonthShow:
                                  this.state.attendanceMonthShow - 1,
                                attendanceYearShow:
                                  this.state.attendanceYearShow,
                                isLoading: true,
                                finalCalenderArray: [],
                                attendenceList: [],
                              },
                              () => {
                                this.startUpFunction();
                              }
                            );
                      }}
                    >
                      <Text
                        style={{
                          color: "#fff",

                          alignSelf: "center",
                        }}
                      >
                        Previous
                      </Text>
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={{ fontSize: 16, alignSelf: "center" }}>
                        {this.state.months[this.state.attendanceMonthShow]}-
                      </Text>

                      <Picker
                        selectedValue={this.state.attendanceYearShow}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState(
                            { attendanceYearShow: itemValue },
                            () => {
                              this.startUpFunction();
                            }
                          )
                        }
                      >
                        <Picker.Item
                          label={new Date().getUTCFullYear()}
                          value={new Date().getUTCFullYear()}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 1}
                          value={new Date().getUTCFullYear() - 1}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 2}
                          value={new Date().getUTCFullYear() - 2}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 3}
                          value={new Date().getUTCFullYear() - 3}
                        />
                      </Picker>
                    </View>

                    {/* {this.state.attendanceMonthShow != new Date().getMonth() ? ( */}
                    <TouchableOpacity
                      style={[styles.addNewButton, { width: 50, padding: 5 }]}
                      onPress={() => {
                        this.state.attendanceMonthShow == 11
                          ? this.setState(
                              {
                                attendanceYearShow:
                                  this.state.attendanceYearShow + 1,
                                attendanceMonthShow: 0,
                                isLoading: true,
                                finalCalenderArray: [],
                                attendenceList: [],
                              },
                              () => {
                                this.startUpFunction();
                              }
                            )
                          : this.setState(
                              {
                                attendanceMonthShow:
                                  this.state.attendanceMonthShow + 1,
                                attendanceYearShow:
                                  this.state.attendanceYearShow,
                                isLoading: true,
                                finalCalenderArray: [],
                                attendenceList: [],
                              },
                              () => {
                                this.startUpFunction();
                              }
                            );
                      }}
                    >
                      <Text
                        style={{
                          color: "#fff",

                          alignSelf: "center",
                        }}
                      >
                        Next
                      </Text>
                    </TouchableOpacity>
                    {/* ) : (
                      <Text style={{ color: "#fff" }}>Next</Text>
                    )} */}
                  </View>
                  <FlatList
                    data={this.state.finalCalenderArray}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={{
                            width: 40,
                            height: 40,
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            backgroundColor: item.holiday
                              ? "#7ac5ca"
                              : item.timestamp < Date.parse(new Date())
                              ? item.p1 && item.p2
                                ? "#009244"
                                : item.p1 == false && item.p2 == false
                                ? "#de5246"
                                : item.p1 == true || item.p2 == true
                                ? "#ffbf00"
                                : "#fff"
                              : "#fff",

                            borderWidth: 1,
                            borderRadius: 5,

                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {new Date(item.timestamp).toDateString() ==
                          new Date(
                            this.state.joiningDateTimeStamp
                          ).toDateString() ? (
                            <Text
                              style={{
                                color:
                                  item.p1 == true || item.p2 == true
                                    ? "#fff"
                                    : item.p1 == false && item.p2 == false
                                    ? "#fff"
                                    : "#000",
                                fontSize: 8,
                              }}
                            >
                              JOIN
                            </Text>
                          ) : null}

                          <Text
                            style={{
                              color:
                                item.p1 == true || item.p2 == true
                                  ? "#fff"
                                  : item.p1 == false && item.p2 == false
                                  ? "#fff"
                                  : "#000",
                            }}
                          >
                            {index + 1}
                          </Text>

                          {item.paid ? (
                            <View
                              style={{
                                height: 4,
                                width: 4,
                                borderRadius: 50,
                                backgroundColor:
                                  item.timestamp < Date.parse(new Date())
                                    ? "#fff"
                                    : "#000",
                                alignSelf: "center",
                                marginHorizontal: 5,
                              }}
                            />
                          ) : null}
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.timestamp)}
                    horizontal={false}
                    numColumns={7}
                    ListFooterComponent={this.listFooter}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        {this.state.isLoading ? (
                          <ActivityIndicator size="large" color="#0a60ff" />
                        ) : (
                          <Text>Data Not Avaliable</Text>
                        )}
                      </View>
                    }
                  />
                  {/* <ContributionGraph
                    values={this.state.commitsData}
                    endDate={new Date("2021-08-31")}
                    numDays={62}
                    width={500}
                    height={220}
                    chartConfig={chartConfig}
                  /> */}
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    marginLeft: 100,
                    marginTop: 0,
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                    borderRadius: 5,
                    width: 270,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      borderBottomWidth: 1,
                      borderBottomColor: "#c1c1c1",
                      padding: 12,
                    }}
                  >
                    <Text>Summary</Text>
                  </View>
                  <View
                    style={[
                      styles.attendanceSummary,
                      {
                        borderBottomColor: "#009C44",
                        borderBottomWidth: 4,
                        backgroundColor: "#e6fff1",
                      },
                    ]}
                  >
                    <Text>Total Full Day(s) Work:</Text>
                    <Text>
                      {
                        this.state.attendenceList.filter((item) => {
                          if (item.p1 && item.p2 == true) {
                            return item;
                          }
                        }).length
                      }
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.attendanceSummary,
                      {
                        borderBottomColor: "#FFBF00",
                        borderBottomWidth: 4,
                        backgroundColor: "#fff9e6",
                      },
                    ]}
                  >
                    <Text>Half Day(s):</Text>
                    <Text>
                      {
                        this.state.attendenceList.filter((item) => {
                          if (!item.p1 && item.p2) {
                            return item;
                          } else if (item.p1 && !item.p2) {
                            return item;
                          }
                        }).length
                      }
                      =
                      {this.state.attendenceList.filter((item) => {
                        if (!item.p1 && item.p2) {
                          return item;
                        } else if (item.p1 && !item.p2) {
                          return item;
                        }
                      }).length / 2}{" "}
                      Leave
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.attendanceSummary,
                      {
                        borderBottomColor: "#DE5246",
                        borderBottomWidth: 4,
                        backgroundColor: "#fbebe9",
                      },
                    ]}
                  >
                    <Text>Full Day(s) Leave</Text>
                    <Text>
                      {
                        this.state.attendenceList.filter((item) => {
                          if (!item.p1 && !item.p2) {
                            return item;
                          }
                        }).length
                      }{" "}
                      Leave
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.attendanceSummary,
                      {
                        borderBottomColor: "#000",
                        borderBottomWidth: 4,
                        backgroundColor: "#F5F5F5",
                      },
                    ]}
                  >
                    <Text>Total Absent/Leave</Text>
                    <Text>
                      {this.state.attendenceList.filter((item) => {
                        if (!item.p1 && item.p2) {
                          return item;
                        } else if (item.p1 && !item.p2) {
                          return item;
                        }
                      }).length /
                        2 +
                        this.state.attendenceList.filter((item) => {
                          if (!item.p1 && !item.p2) {
                            return item;
                          }
                        }).length}{" "}
                      Leave
                    </Text>
                  </View>
                </View>
                {this.state.salary ? (
                  <View
                    style={{
                      flexDirection: "column",
                      marginLeft: 100,
                      marginTop: 0,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      width: 270,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                        padding: 12,
                      }}
                    >
                      <Text>Payment Abstract</Text>
                    </View>
                    <View
                      style={[
                        styles.attendanceSummary,
                        {
                          borderBottomColor: "#009C44",
                          borderBottomWidth: 4,
                          backgroundColor: "#e6fff1",
                        },
                      ]}
                    >
                      <Text>Monthly Salary:</Text>
                      <Text>₹ {this.state.salary}</Text>
                    </View>
                    <View
                      style={[
                        styles.attendanceSummary,
                        {
                          borderBottomColor: "#DE5246",
                          borderBottomWidth: 4,
                          backgroundColor: "#fbebe9",
                        },
                      ]}
                    >
                      <Text>
                        Absent/Leave (-){" "}
                        {this.state.attendenceList.filter((item) => {
                          if (!item.p1 && item.p2) {
                            return item;
                          } else if (item.p1 && !item.p2) {
                            return item;
                          }
                        }).length /
                          2 +
                          this.state.attendenceList.filter((item) => {
                            if (!item.p1 && !item.p2) {
                              return item;
                            }
                          }).length}
                        x
                        {(
                          parseFloat(this.state.salary) /
                          new Date(
                            this.state.attendanceYearShow,
                            this.state.attendanceMonthShow + 1,
                            0
                          ).getDate()
                        ).toFixed(0)}
                      </Text>
                      <Text>
                        ₹{" "}
                        {(this.state.attendenceList.filter((item) => {
                          if (!item.p1 && item.p2) {
                            return item;
                          } else if (item.p1 && !item.p2) {
                            return item;
                          }
                        }).length /
                          2 +
                          this.state.attendenceList.filter((item) => {
                            if (!item.p1 && !item.p2) {
                              return item;
                            }
                          }).length) *
                          (
                            parseFloat(this.state.salary) /
                            new Date(
                              this.state.attendanceYearShow,
                              this.state.attendanceMonthShow + 1,
                              0
                            ).getDate()
                          ).toFixed(0)}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.attendanceSummary,
                        {
                          borderBottomColor: "#000",
                          borderBottomWidth: 4,
                          backgroundColor: "#F5F5F5",
                        },
                      ]}
                    >
                      <Text>
                        {this.state.months[this.state.attendanceMonthShow]}{" "}
                        Payment
                      </Text>
                      <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                        ₹{" "}
                        {this.state.paidOnly != 0 ? this.state.unpaidAmount : 0}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.attendanceSummary,
                        {
                          backgroundColor: "#fff",
                          justifyContent: "center",
                          padding: 5,
                          borderBottomWidth: 0,
                        },
                      ]}
                    >
                      <TouchableOpacity
                        disabled={this.state.paidOnly == 0 ? true : false}
                        style={[
                          styles.addNewButton,
                          {
                            width: 200,
                            padding: 5,
                            backgroundColor:
                              this.state.paidOnly == 0 ? "#e6fff1" : "#009C44",
                            borderColor:
                              this.state.paidOnly == 0 ? "#e6fff1" : "#009C44",
                          },
                        ]}
                        onPress={() => {
                          if (this.state.paidOnly !== 0) {
                            if (
                              this.state.finalCalenderArray !== [] &&
                              this.state.attendenceList !== []
                            ) {
                              this.state.attendenceList.map((item, index) => {
                                if (item.p1 || (item.p2 && !item.paid)) {
                                  this.savePaymentDataToDB(
                                    new Date(item.timestamp).getDate()
                                  );
                                }
                              });

                              this.generatePaymentInLedger();
                            }
                          }
                        }}
                      >
                        {this.state.paidOnly == 0 ? (
                          <Text style={{ color: "#000", textAlign: "center" }}>
                            No Unpaid Amount
                          </Text>
                        ) : (
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Generate Payment of ₹ {this.state.unpaidAmount}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: "column",
                      marginLeft: 100,
                      marginTop: 0,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      width: 270,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                        padding: 12,
                      }}
                    >
                      <Text>Payment Abstract</Text>
                    </View>
                    <View style={[styles.attendanceSummary]}>
                      <Text style={{ fontWeight: "500" }}>
                        Please Update Monthly Salary in{" "}
                        {this.state.employeeName}'s Profile
                      </Text>
                    </View>
                  </View>
                )}
              </View>
              <View>
                <View
                  style={[
                    styles.header,
                    {
                      marginHorizontal: 70,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Ledger
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          marginRight: 20,
                          backgroundColor: "#009C44",
                          borderColor: "#009C44",
                          paddingHorizontal: 20,
                        },
                      ]}
                      onPress={() => {
                        this.setState(
                          { modalOpened: "Credit", isModalOpening: true },
                          () => {
                            this.setState({ setVisible: true }, () => {
                              this.setState({ isModalOpening: false });
                            });
                            // this.state.loginUser.admin == true
                            //   ? null
                            //   : this.getCashBookLastEntry();
                          }
                        );
                      }}
                    >
                      {this.state.isModalOpening ? (
                        <ActivityIndicator color="#fff" size="small" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Credit</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#de5246",
                          borderColor: "#de5246",
                          paddingHorizontal: 20,
                        },
                      ]}
                      onPress={() => {
                        this.setState(
                          { modalOpened: "Debit", isModalOpening: true },
                          () => {
                            this.setState(
                              { setVisible: true, date: new Date() },
                              () => {
                                this.setState({ isModalOpening: false });
                              }
                            );
                            // this.state.loginUser.admin == true
                            //   ? null
                            //   : this.getCashBookLastEntry();
                          }
                        );
                      }}
                    >
                      {this.state.isModalOpening ? (
                        <ActivityIndicator color="#fff" size="small" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Debit</Text>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    marginHorizontal: 70,
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                    borderRadius: 5,
                    minWidth: 750,
                  }}
                >
                  <EmployeeLedger
                    id={this.state.employeeId}
                    employeeName={this.state.employeeName}
                    address=""
                    number=""
                    admin={this.state.loginUser.admin}
                    dataType="employeeData"
                    isDetailsAvaliable={false}
                    // dbLocation={`factoryData/employeeData/ledger/${this.state.employeeId}`}
                  />
                </View>
              </View>
            </View>
            <Snackbar
              visible={this.state.SnackbarVisible}
              onDismiss={() => {
                this.setState({ SnackbarVisible: false });
              }}
              action={{
                label: "OK",
                onPress: () => {
                  this.setState({ SnackbarVisible: false });
                },
              }}
            >
              {this.state.error}
            </Snackbar>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "14.28%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  attendanceSummary: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 15.2,
  },
});
