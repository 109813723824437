import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Alert,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
  Switch,
  Checkbox,
} from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class employeeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SnackbarVisible: false,
      id: "",
      post: "",
      name: "",
      address: "",
      contact: "",
      docSubmitted: "",
      note: "",
      joiningDate: "",
      salary: "",
      error: "",
      bankName: "",
      accountNo: "",
      ifsCode: "",
      branch: "",
      postcolor: "",
      namecolor: "",
      addresscolor: "",
      contactcolor: "",
      buttonLoading: false,
      //Model
      setVisible: false,

      modelloading: false,
      data: "OK",

      loginUser: {},
      uoid: "",
      isActive: false,
      isActiveModalOpen: false,
      setVisibleDetails: false,
      //
      isAdmin: false, //current employee admin status
      password: "",
      loginId: null,
      employeeId: "",
      admin: false, // current login user admin status not selected employee
      canAddOrdersToSalesAndroid: false,
      loginUserName: "",
      isERPMobileAndroidLoginAllowed: false,
      canGenerateDM: false,
      isERPSalesAndroidMobileLoginAllowed: false,
      canApproveOrders: false,
      canLoadLorry: false,
      canGenerateLeadsToSalesAndroid: false,
      hidePassword: true,
      saveDetailsLoading: false,
      isERPDesktopLoginAllowed: false, //
      canViewDashBoard: false,
      canViewCustomerManagement: false,
      canViewVendorManagement: false,
      canViewMRO: false,
      canViewOrderManagement: false,
      canViewProductManagement: false,
      canViewEmployeeManagement: false,
      canViewLeadsManagement: false,
      canViewFleetManagement: false,
      isSiteLogin: false,
    };
  }
  componentDidMount() {
    const { dataObject } = this.props.route.params;

    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState({
              id: dataObject.id,
              post: dataObject.post,
              name: dataObject.name,
              address: dataObject.address,
              contact: dataObject.contact,
              docSubmitted: dataObject.docSubmitted,
              note: dataObject.note,
              joiningDate: dataObject.joiningDateTimeStamp,
              employeeId: dataObject.loginId,
              salary: dataObject.salary,
              isActive: dataObject.active,
            });
            this.getLoginUser();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  saveChangesInRights = () => {
    this.setState({ saveDetailsLoading: true });
    let empId = this.state.employeeId.split(".")[0];
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/users/${empId}`);

    dbRef
      .update({
        canAddOrdersToSalesAndroid: this.state.canAddOrdersToSalesAndroid
          ? this.state.canAddOrdersToSalesAndroid
          : false,
        isERPMobileAndroidLoginAllowed: this.state
          .isERPMobileAndroidLoginAllowed
          ? this.state.isERPMobileAndroidLoginAllowed
          : false,
        canGenerateDM: this.state.canGenerateDM
          ? this.state.canGenerateDM
          : false,
        isERPSalesAndroidMobileLoginAllowed: this.state
          .isERPSalesAndroidMobileLoginAllowed
          ? this.state.isERPSalesAndroidMobileLoginAllowed
          : false,
        canApproveOrders: this.state.canApproveOrders
          ? this.state.canApproveOrders
          : false,
        canLoadLorry: this.state.canLoadLorry ? this.state.canLoadLorry : false,
        canGenerateLeadsToSalesAndroid: this.state
          .canGenerateLeadsToSalesAndroid
          ? this.state.canGenerateLeadsToSalesAndroid
          : false,
        admin: this.state.isAdmin ? this.state.isAdmin : false,
        isERPDesktopLoginAllowed: this.state.isERPDesktopLoginAllowed
          ? this.state.isERPDesktopLoginAllowed
          : false, //
        canViewDashBoard: this.state.canViewDashBoard
          ? this.state.canViewDashBoard
          : false,
        canViewCustomerManagement: this.state.canViewCustomerManagement
          ? this.state.canViewCustomerManagement
          : false,
        canViewVendorManagement: this.state.canViewVendorManagement
          ? this.state.canViewVendorManagement
          : false,
        canViewMRO: this.state.canViewMRO ? this.state.canViewMRO : false,
        canViewOrderManagement: this.state.canViewOrderManagement
          ? this.state.canViewOrderManagement
          : false,
        canViewProductManagement: this.state.canViewProductManagement
          ? this.state.canViewProductManagement
          : false,
        canViewEmployeeManagement: this.state.canViewEmployeeManagement
          ? this.state.canViewEmployeeManagement
          : false,
        canViewLeadsManagement: this.state.canViewLeadsManagement
          ? this.state.canViewLeadsManagement
          : false,
        canViewFleetManagement: this.state.canViewFleetManagement
          ? this.state.canViewFleetManagement
          : false,
        isSiteLogin: this.state.isSiteLogin ? this.state.isSiteLogin : false,
      })
      .then(() => {
        this.setState({ setVisibleDetails: false, saveDetailsLoading: false });
      });
  };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({
            admin: DataSnapShot.val().admin,
            loginId: DataSnapShot.val().loginId,
            loginUserName: DataSnapShot.val().name,
          });
        }
      });
    }
  };
  getEmployeeData = () => {
    let empId = this.state.employeeId.split(".")[0];
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/users/${empId}`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.exists()) {
        let data = DataSnapShot.val();
        this.setState({
          isAdmin: data.admin,
          canAddOrdersToSalesAndroid: data.canAddOrdersToSalesAndroid,
          password: data.passcode,
          isERPMobileAndroidLoginAllowed: data.isERPMobileAndroidLoginAllowed,
          canGenerateDM: data.canGenerateDM,
          isERPSalesAndroidMobileLoginAllowed:
            data.isERPSalesAndroidMobileLoginAllowed,
          canApproveOrders: data.canApproveOrders,
          canLoadLorry: data.canLoadLorry,
          canGenerateLeadsToSalesAndroid: data.canGenerateLeadsToSalesAndroid,
          isERPDesktopLoginAllowed: data.isERPDesktopLoginAllowed, //
          canViewDashBoard: data.canViewDashBoard,
          canViewCustomerManagement: data.canViewCustomerManagement,
          canViewVendorManagement: data.canViewVendorManagement,
          canViewMRO: data.canViewMRO,
          canViewOrderManagement: data.canViewOrderManagement,
          canViewProductManagement: data.canViewProductManagement,
          canViewEmployeeManagement: data.canViewEmployeeManagement,
          canViewLeadsManagement: data.canViewLeadsManagement,
          canViewFleetManagement: data.canViewFleetManagement,
          isSiteLogin: data.isSiteLogin,

          // TODO:
        });
      }
    });
  };
  updateDB = async () => {
    if (
      this.state.name !== "" &&
      this.state.contact !== "" &&
      this.state.post !== "" &&
      this.state.address !== ""
    ) {
      this.setState({ buttonLoading: true });

      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/employeeData/vitalInfo/${this.state.id}`
        );

      await dbRef.update(
        {
          post: this.state.post,
          name: this.state.name,
          address: this.state.address,
          contact: this.state.contact,
          docSubmitted: this.state.docSubmitted,
          note: this.state.note,
          bankName: this.state.bankName,
          accountNo: this.state.accountNo,
          ifsCode: this.state.ifsCode,
          branch: this.state.branch,
          salary: this.state.salary,
        },
        (error) => {
          if (!error) {
            this.setState(
              {
                SnackbarVisible: true,
                buttonLoading: false,
                error: "Changes Saved Successfully !",
              },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          }
        }
      );
    }
  };

  loginGenerator = () => {
    let fName = this.state.name.split(" ");
    let companyName = this.state.loginId.split("@");

    let companyInitial = companyName[1];
    let loginid =
      fName[0].toLocaleLowerCase() +
      Math.floor(Math.random() * 99) +
      `@${companyInitial}`;
    let password = Math.floor(Math.random() * -100000 + 999999);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(loginid)
      .then((value) => {
        if (value.length > 0) {
          this.loginGenerator();
        } else {
          this.setState({ employeeId: loginid, password }, () => {
            this.setState({ modelloading: false });
          });
        }
      });
  };

  createEmpUserInDb = async () => {
    let userid = this.state.employeeId.split(".")[0];
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/users/${userid}`);
    await dbRef.set({
      loginId: this.state.employeeId,
      passcode: this.state.password,
      name: this.state.name,
      id: this.state.id,
      createdAt: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      entryinAuth: false,
      createdBy: this.state.loginUserName,
      admin: this.state.isAdmin, //
      canAddOrdersToSalesAndroid: false,
      canApproveOrders: false,
      canLoadLorry: false,
      isERPSalesAndroidMobileLoginAllowed: false,
      isERPMobileAndroidLoginAllowed: false,
      isERPDesktopLoginAllowed: false,
      canGenerateLeadsToSalesAndroid: false,
      canGenerateDM: false,
      canViewDashBoard: false,
      canViewCustomerManagement: false,
      canViewVendorManagement: false,
      canViewMRO: false,
      canViewOrderManagement: false,
      canViewProductManagement: false,
      canViewEmployeeManagement: false,
      canViewLeadsManagement: false,
      canViewFleetManagement: false,
    });
    let dbref010 = firebase.database().ref(`allUsers/${userid}`);
    dbref010.set({
      mobileNumber: this.state.uoid,
      isUnique: false,
      timeStamp: new Date(),
    });

    let dbref1 = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/vitalInfo/${this.state.id}`
      );

    await dbref1
      .update({
        loginId: this.state.employeeId,
      })
      .then(() => {
        this.setState({ setVisible: false });
      });
  };
  activeStatus = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/vitalInfo/${this.state.id}`
      );

    dbRef
      .update(
        {
          active: !this.state.isActive,
          joiningDateTimeStamp: this.state.isActive
            ? this.state.joiningDate
            : Date.parse(new Date().toDateString()),
        },
        (error) => {
          if (!error) {
            this.setState(
              {
                SnackbarVisible: true,
                isActiveModalOpen: false,
                error: "Changes Saved Successfully !",
                isActive: !this.state.isActive,
              },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          }
        }
      )
      .then(() => {
        if (this.state.isActive == false) {
          let empId = this.state.employeeId.split(".")[0];
          let dbRef = firebase
            .database()
            .ref(`${this.state.uoid}/erpData/users/${empId}`);
          dbRef.update({
            canAddOrdersToSalesAndroid: false,
            isERPMobileAndroidLoginAllowed: false,
            canGenerateDM: false,
            isERPSalesAndroidMobileLoginAllowed: false,
            canApproveOrders: false,
            canLoadLorry: false,
            canGenerateLeadsToSalesAndroid: false,
            admin: false,
            isERPDesktopLoginAllowed: false, //
            canViewDashBoard: false,
            canViewCustomerManagement: false,
            canViewVendorManagement: false,
            canViewMRO: false,
            canViewOrderManagement: false,
            canViewProductManagement: false,
            canViewEmployeeManagement: false,
            canViewLeadsManagement: false,
            canViewFleetManagement: false,
          });
        }
      });
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Employee Profile Edit" />
          <View style={styles.mainConsole}>
            <LeftBar employee="#c1c1c1" />

            <View style={styles.palyArea}>
              {/* Model */}
              <Portal>
                <Modal
                  visible={this.state.isActiveModalOpen}
                  onDismiss={() => this.setState({ isActiveModalOpen: true })}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 350,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>Are you sure ?</Text>
                    {this.state.isActive ? (
                      <View>
                        <Text style={{ fontSize: 18, padding: 6 }}>
                          On Inactivation of {this.state.name}'s Account
                        </Text>
                        <Text style={{ padding: 5 }}>
                          • All the permissions granted to {this.state.name}{" "}
                          will be set to denied
                        </Text>
                        <Text style={{ padding: 5 }}>
                          • Data will NOT be deleted, but {this.state.name}'s
                          attendence will not be recorded and Payment Generation
                          will not function{" "}
                        </Text>
                        <Text style={{ padding: 5 }}>
                          • On reactivation of account, joining Date will be
                          updated to Date of Reactivation
                        </Text>
                      </View>
                    ) : (
                      <Text style={{ padding: 5 }}>
                        • On reactivation of account, joining Date will be
                        updated to Date of Reactivation
                      </Text>
                    )}

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginBottom: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 30,
                            marginTop: 28,
                            marginHorizontal: 20,
                            width: 100,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                          },
                        ]}
                        onPress={() => {
                          this.setState({ isActiveModalOpen: false });
                        }}
                      >
                        <Text
                          style={{
                            color: "#0a60ff",
                          }}
                        >
                          Cancel
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 30,
                            marginTop: 28,
                            marginHorizontal: 20,
                            width: 100,
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        ]}
                        onPress={() => {
                          this.activeStatus();
                        }}
                      >
                        <Text style={{ color: "#fff" }}>
                          Yes,{this.state.isActive ? "Inactivate" : "Active"}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => this.setState({ setVisible: false })}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 800,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Generate Login for {this.state.name}
                    </Text>
                  </View>
                  {this.state.modelloading ? (
                    <ActivityIndicator color="#0a60ff" size="large" />
                  ) : (
                    <View>
                      <View
                        style={{
                          flexDirection: "column",
                          padding: 20,
                          alignSelf: "center",
                          justifyContent: "space-evenly",
                          height: 210,
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          width: 250,
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <TextInput
                          disabled
                          mode="flat"
                          label="Login ID"
                          value={this.state.employeeId}
                          style={{ width: 200, height: 50 }}
                          onChangeText={(text) => {}}
                        />
                        <TextInput
                          mode="flat"
                          label="Password"
                          value={this.state.password}
                          style={{ width: 200, height: 50 }}
                          onChangeText={(text) => {}}
                        />
                        <View style={{ marginTop: 20 }}>
                          <Text>Admin</Text>
                          <Switch
                            value={this.state.isAdmin}
                            onValueChange={() => {
                              this.setState({ isAdmin: !this.state.isAdmin });
                            }}
                            color="#0a60ff"
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          padding: 5,
                          marginTop: 40,
                        }}
                      >
                        <Text style={{ fontSize: 12 }}>
                          By generating and sharing the userID and Password You
                          are permiting {this.state.name}({this.state.post}) to
                          modify/edit/enter/delete data from your ERP system.
                        </Text>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,

                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          // disabled={this.state.buttonLoading}
                          onPress={() => {
                            this.createEmpUserInDb();
                          }}
                        >
                          <Text style={{ color: "#fff" }}>
                            Save Login Details
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,

                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({ setVisible: false });
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </Modal>
              </Portal>
              <Portal>
                <Modal
                  visible={this.state.setVisibleDetails}
                  onDismiss={() => this.setState({ setVisibleDetails: false })}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 950,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Login Details and Permissions of {this.state.name}
                    </Text>
                  </View>
                  {this.state.modelloading ? (
                    <ActivityIndicator color="#0a60ff" size="large" />
                  ) : (
                    <View>
                      <View
                        style={{
                          flexDirection: "row",

                          height: 270,
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            padding: 20,
                            alignSelf: "flex-start",
                            justifyContent: "space-evenly",

                            width: 200,
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderBottomColor: "#c1c1c1",

                              width: 200,
                              alignItems: "center",
                              marginBottom: 5,
                            }}
                          >
                            <Text style={{ fontWeight: "700" }}>
                              Login Details
                            </Text>
                          </View>

                          <TextInput
                            disabled
                            mode="flat"
                            label="Login ID"
                            value={this.state.employeeId}
                            style={{ width: 180, height: 50 }}
                            onChangeText={(text) => {}}
                          />
                          <View>
                            <TextInput
                              disabled
                              secureTextEntry={this.state.hidePassword}
                              mode="flat"
                              label="Password"
                              value={this.state.password}
                              style={{ width: 180, height: 50 }}
                            />
                            <View style={{ flexDirection: "row" }}>
                              <Checkbox
                                status={
                                  this.state.hidePassword
                                    ? "unchecked"
                                    : "checked"
                                }
                                color="#0a60ff"
                                onPress={() => {
                                  this.setState({
                                    hidePassword: !this.state.hidePassword,
                                  });
                                }}
                              />
                              <Text style={{ marginTop: 2 }}>
                                Show Password
                              </Text>
                            </View>
                          </View>

                          <View style={{ marginTop: 1 }}>
                            <Text>Admin</Text>
                            <Switch
                              value={this.state.isAdmin}
                              onValueChange={() => {
                                this.setState({ isAdmin: !this.state.isAdmin });
                              }}
                              color="#0a60ff"
                            />
                          </View>
                          <View style={{ marginTop: 1 }}>
                            <Text>Is this login for Site(Plant)</Text>
                            <Switch
                              value={this.state.isSiteLogin}
                              onValueChange={() => {
                                this.setState({
                                  isSiteLogin: !this.state.isSiteLogin,
                                });
                              }}
                              color="#0a60ff"
                              style={{ alignSelf: "center" }}
                            />
                          </View>
                        </View>
                        <View style={styles.permissionHolders}>
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderBottomColor: "#c1c1c1",

                              width: 180,
                              alignItems: "center",
                            }}
                          >
                            <Text style={{ fontWeight: "700" }}>
                              Login Permissions
                            </Text>
                          </View>
                          <View style={{ height: 180 }}>
                            <View style={styles.switchHolders}>
                              <Text>Desktop</Text>
                              <Switch
                                value={this.state.isERPDesktopLoginAllowed}
                                color="#0a60ff"
                                onValueChange={() => {
                                  this.setState({
                                    isERPDesktopLoginAllowed:
                                      !this.state.isERPDesktopLoginAllowed,
                                  });
                                }}
                              />
                            </View>
                            <View style={styles.switchHolders}>
                              <Text>sERPMobile</Text>
                              <Switch
                                value={
                                  this.state.isERPMobileAndroidLoginAllowed
                                }
                                color="#0a60ff"
                                onValueChange={() => {
                                  this.setState({
                                    isERPMobileAndroidLoginAllowed:
                                      !this.state
                                        .isERPMobileAndroidLoginAllowed,
                                  });
                                }}
                              />
                            </View>
                            <View style={styles.switchHolders}>
                              <Text>sERPSales</Text>
                              <Switch
                                value={
                                  this.state.isERPSalesAndroidMobileLoginAllowed
                                }
                                color="#0a60ff"
                                onValueChange={() => {
                                  this.setState({
                                    isERPSalesAndroidMobileLoginAllowed:
                                      !this.state
                                        .isERPSalesAndroidMobileLoginAllowed,
                                  });
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        {/* <View style={styles.permissionHolders}>
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderBottomColor: "#c1c1c1",

                              width: 200,
                              alignItems: "center",
                            }}
                          >
                            <Text style={{ fontWeight: "900" }}>
                              sERPMobile App
                            </Text>
                          </View>
                          <View style={{ height: 180 }}>
                            <View style={styles.switchHolders}>
                              <Text>Can Generate DM</Text>
                              <Switch
                                value={this.state.canGenerateDM}
                                color="#0a60ff"
                                onValueChange={() => {
                                  this.setState({
                                    canGenerateDM: !this.state.canGenerateDM,
                                  });
                                }}
                              />
                            </View>

                         
                          </View>
                        </View> */}
                        <View
                          style={[
                            styles.permissionHolders,
                            {
                              width: 600,
                              paddingHorizontal: 5,
                              alignItems: "flex-start",
                            },
                          ]}
                        >
                          <View
                            style={{
                              borderBottomWidth: 1,
                              borderBottomColor: "#c1c1c1",

                              width: 550,
                              alignItems: "center",
                            }}
                          >
                            <Text style={{ fontWeight: "700" }}>
                              Other Permissions
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              width: 550,
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                height: 180,
                                borderRightWidth: 1,
                                borderRightColor: "#c1c1c1",
                                paddingRight: 5,
                              }}
                            >
                              {/*section 1 of oP */}
                              <View style={styles.switchHolders}>
                                <Text>Can Add Orders</Text>
                                <Switch
                                  value={this.state.canAddOrdersToSalesAndroid}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canAddOrdersToSalesAndroid:
                                        !this.state.canAddOrdersToSalesAndroid,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Can Approve orders</Text>
                                <Switch
                                  value={this.state.canApproveOrders}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canApproveOrders:
                                        !this.state.canApproveOrders,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Can load vehicles</Text>
                                <Switch
                                  value={this.state.canLoadLorry}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canLoadLorry: !this.state.canLoadLorry,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Can Generate DM</Text>
                                <Switch
                                  value={this.state.canGenerateDM}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canGenerateDM: !this.state.canGenerateDM,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Can generate leads</Text>
                                <Switch
                                  value={
                                    this.state.canGenerateLeadsToSalesAndroid
                                  }
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canGenerateLeadsToSalesAndroid:
                                        !this.state
                                          .canGenerateLeadsToSalesAndroid,
                                    });
                                  }}
                                />
                              </View>
                            </View>
                            <View
                              style={{
                                height: 180,
                                borderRightWidth: 1,
                                borderRightColor: "#c1c1c1",
                                paddingRight: 5,
                              }}
                            >
                              {/*section 2 of oP */}
                              <View style={styles.switchHolders}>
                                <Text>DashBoard access</Text>
                                <Switch
                                  value={this.state.canViewDashBoard}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewDashBoard:
                                        !this.state.canViewDashBoard,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Customer Mang. access</Text>
                                <Switch
                                  value={this.state.canViewCustomerManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewCustomerManagement:
                                        !this.state.canViewCustomerManagement,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Vendor Mang. access</Text>
                                <Switch
                                  value={this.state.canViewVendorManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewVendorManagement:
                                        !this.state.canViewVendorManagement,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>MRO Inventory access</Text>
                                <Switch
                                  value={this.state.canViewMRO}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewMRO: !this.state.canViewMRO,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Order Mang. access</Text>
                                <Switch
                                  value={this.state.canViewOrderManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewOrderManagement:
                                        !this.state.canViewOrderManagement,
                                    });
                                  }}
                                />
                              </View>
                            </View>
                            <View
                              style={{
                                height: 180,
                              }}
                            >
                              {/*section 3 of oP */}
                              <View style={styles.switchHolders}>
                                <Text>Product Mang. access</Text>
                                <Switch
                                  value={this.state.canViewProductManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewProductManagement:
                                        !this.state.canViewProductManagement,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Employee Mang. access</Text>
                                <Switch
                                  value={this.state.canViewEmployeeManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewEmployeeManagement:
                                        !this.state.canViewEmployeeManagement,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Leads Mang. access</Text>
                                <Switch
                                  value={this.state.canViewLeadsManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewLeadsManagement:
                                        !this.state.canViewLeadsManagement,
                                    });
                                  }}
                                />
                              </View>
                              <View style={styles.switchHolders}>
                                <Text>Fleet Mang. access</Text>
                                <Switch
                                  value={this.state.canViewFleetManagement}
                                  color="#0a60ff"
                                  onValueChange={() => {
                                    this.setState({
                                      canViewFleetManagement:
                                        !this.state.canViewFleetManagement,
                                    });
                                  }}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          padding: 5,
                          marginTop: 40,
                        }}
                      >
                        <Text style={{ fontSize: 12 }}>
                          By generating and sharing the userID and Password You
                          are permiting {this.state.name}({this.state.post}) to
                          modify/edit/enter/delete data from your ERP system.
                        </Text>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,

                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          disabled={this.state.saveDetailsLoading}
                          onPress={() => {
                            if (this.state.admin) {
                              this.saveChangesInRights();
                            }
                          }}
                        >
                          {this.state.saveDetailsLoading ? (
                            <ActivityIndicator size="small" color="#fff" />
                          ) : (
                            <Text style={{ color: "#fff" }}>Save Changes</Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,

                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({ setVisibleDetails: false });
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </Modal>
              </Portal>
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text>Basic Info</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Picker
                    selectedValue={this.state.post}
                    enabled={this.state.isActive}
                    style={{
                      height: 40,
                      width: 360,
                      marginHorizontal: 22,
                      borderColor: this.state.postcolor,
                    }}
                    onValueChange={(itemValue, itemIndex) =>
                      this.setState({ post: itemValue }, () => {})
                    }
                  >
                    <Picker.Item
                      label={this.state.post}
                      value={this.state.post}
                    />
                    <Picker.Item label="Manager" value="Manager" />
                    <Picker.Item
                      label="Sales Executive"
                      value="Sales Executive"
                    />
                    <Picker.Item
                      label="Head Office Staff"
                      value="Head Office Staff"
                    />
                    <Picker.Item label="Supervisor" value="Supervisor" />
                    <Picker.Item label="Driver" value="Driver" />
                    <Picker.Item
                      label="JCB/PC Operator"
                      value="JCB/PC Operator"
                    />
                    <Picker.Item
                      label="Factory Operator"
                      value="Factory Operator"
                    />
                    <Picker.Item label="Other" value="Other" />
                  </Picker>
                  <TextInput
                    disabled={!this.state.isActive}
                    mode="outlined"
                    label="Name of Employee"
                    value={this.state.name}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.namecolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ name: text }, () => {});
                    }}
                    placeholder="Name"
                  />
                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      disabled
                      mode="flat"
                      label="Login ID"
                      value={this.state.employeeId}
                      style={{ width: 200, height: 40 }}
                    />
                    {this.state.employeeId != null && this.state.admin ? (
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            height: 40,

                            marginHorizontal: 20,
                            width: 150,
                            alignItems: "center",
                          },
                        ]}
                        // disabled={this.state.buttonLoading}
                        onPress={() => {
                          if (this.state.isActive) {
                            this.getEmployeeData();
                            this.setState({ setVisibleDetails: true });
                          } else {
                            this.setState(
                              {
                                SnackbarVisible: true,
                                error: "Please activate the employee first",
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({ SnackbarVisible: false });
                                }, 8000);
                              }
                            );
                          }
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Login Details</Text>
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    disabled={!this.state.isActive}
                    mode="outlined"
                    label="Address"
                    value={this.state.address}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.addresscolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ address: text }, () => {});
                    }}
                    placeholder="Address"
                  />
                  <TextInput
                    disabled={!this.state.isActive}
                    mode="outlined"
                    label="Mobile Number"
                    value={this.state.contact}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.contactcolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ contact: text }, () => {});
                    }}
                    placeholder="Mobile Number"
                  />
                  <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
                    <Text>Active</Text>
                    <Switch
                      value={this.state.isActive}
                      color="#0a60ff"
                      onValueChange={() => {
                        if (this.state.admin) {
                          this.setState({
                            isActiveModalOpen: !this.state.isActiveModalOpen,
                          });
                        } else {
                          this.setState(
                            {
                              SnackbarVisible: true,
                              error: "Not Allowed, Only Admin",
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({ SnackbarVisible: false });
                              }, 5000);
                            }
                          );
                        }
                      }}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="Document Submitted"
                    value={this.state.docSubmitted}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ docSubmitted: text });
                    }}
                    placeholder="Document"
                  />
                  <TextInput
                    mode="outlined"
                    label="Note"
                    value={this.state.note}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ note: text });
                    }}
                    placeholder="Note"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <TextInput
                      mode="outlined"
                      label="Date of Joining*"
                      value={new Date(this.state.joiningDate).toDateString()}
                      style={styles.inputField}
                      editable={false}
                      disabled
                      placeholder="Date of Joning*"
                    />
                    <Text style={{ marginLeft: 20 }}>*If other than today</Text>
                  </View>
                  <TextInput
                    mode="outlined"
                    label="Salary(Payment)"
                    value={this.state.salary}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ salary: text });
                    }}
                    placeholder="Salary(Payment)"
                  />
                </View>
                {/*////////////////////  */}
                <Text>Bank Details</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TextInput
                    mode="outlined"
                    label="Name of Bank"
                    value={this.state.bankName}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.namecolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ bankName: text }, () => {});
                    }}
                    placeholder="Bank Name"
                  />
                  <TextInput
                    mode="outlined"
                    label="Account No."
                    value={this.state.accountNo}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.namecolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ accountNo: text }, () => {});
                    }}
                    placeholder="Account No."
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="IFS Code"
                    value={this.state.ifsCode}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.addresscolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ ifsCode: text }, () => {});
                    }}
                    placeholder="IFS Code"
                  />
                  <TextInput
                    mode="outlined"
                    label="Branch"
                    value={this.state.branch}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.contactcolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ branch: text }, () => {});
                    }}
                    placeholder="Branch"
                  />
                </View>

                {/* /////////// */}
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {this.state.admin == true ? (
                      this.state.employeeId == null ? (
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,
                              marginTop: 28,
                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          disabled={this.state.buttonLoading}
                          onPress={() => {
                            this.setState(
                              { setVisible: true, modelloading: true },
                              () => {
                                this.loginGenerator();
                              }
                            );
                          }}
                        >
                          <Text style={{ color: "#fff" }}>Generate Login</Text>
                        </TouchableOpacity>
                      ) : null
                    ) : null}

                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.updateDB();
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save Changes</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.navigate("employeeScreen");
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>

                    <Snackbar
                      visible={this.state.SnackbarVisible}
                      onDismiss={() => {
                        this.setState({ SnackbarVisible: false });
                      }}
                      action={{
                        label: "OK",
                        onPress: () => {
                          this.setState({ SnackbarVisible: false });
                        },
                      }}
                    >
                      {this.state.error}
                    </Snackbar>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  switchHolders: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 170,
    height: 40,
  },
  permissionHolders: {
    flexDirection: "column",
    paddingHorizontal: 20,
    paddingVertical: 5,
    alignSelf: "flex-start",
    justifyContent: "space-evenly",
    borderLeftWidth: 1,
    borderLeftColor: "#c1c1c1",
    width: 180,
    alignItems: "center",
    height: 270,
  },
});
