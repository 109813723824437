import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  Picker,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class adminCashBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      SnackbarVisible: false,
      error: "",
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: [
        "Sr. No.",
        "Date/Time",
        "Particular",
        "Cash In",
        "Cash Out",
        "Person",
      ],
      columnsFooter: ["", "", "Total Amount", ""],
      searchQuery: "",
      postSorterAce: true,

      dataFromDate: new Date(),
      dataToDate: new Date(),
      viewCalenderFrom: false,
      viewCalenderTo: false,
      isButtonLoading: false,
      uoid: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.callDataFromDB();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22`
      )
      .orderByChild("dateStamp")
      .startAt(Date.parse(this.state.dataFromDate.toDateString()))
      .endAt(Date.parse(this.state.dataToDate.toDateString()));

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.timestamp > b.timestamp) {
                return 1;
              }
              if (a.timestamp < b.timestamp) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.setState({
              searchData: this.state.mainData,
              isButtonLoading: false,
            });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          searchData: [],
          isButtonLoading: false,
        });
      }
    });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableFooter = () => {
    const reducerOut = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.cashOut);
    const reducerIn = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.cashIn);
    let footerRow = [
      "",
      "",
      "Total",
      this.state.searchData.reduce(reducerIn, 0),
      this.state.searchData.reduce(reducerOut, 0),
      "",
    ];

    return (
      <View style={[styles.tableHeader, { height: 100 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.columnHeader,
                {
                  borderTopWidth: 1,
                  borderTopColor: "#c1c1c1",
                  borderBottomWidth: 0,
                  paddingTop: 20,
                },
              ]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {item}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Cash Transactions" color2="#0a60ff" borderWidth2={3} />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Modal starts */}

              {/* Model */}
              <View>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: 70,
                        width: 250,
                      }}
                    >
                      <Text
                        style={{
                          marginVertical: 22,
                          color: "#404040",
                          padding: 10,
                        }}
                      >
                        From
                      </Text>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 22,
                            alignSelf: "flex-start",
                            padding: 10,
                            width: 150,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalenderFrom: !this.state.viewCalenderFrom,
                            viewCalenderTo: false,
                          });
                        }}
                      >
                        {this.state.dataFromDate.getDate()}/
                        {this.state.dataFromDate.getMonth() + 1}/
                        {this.state.dataFromDate.getFullYear()}
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: 60,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.viewCalenderFrom ? (
                        <Calendar
                          value={this.state.dataFromDate}
                          onChange={(value) => {
                            this.setState({ dataFromDate: value }, () => {
                              this.setState({ viewCalenderFrom: false });
                            });
                          }}
                        />
                      ) : null}
                    </View>
                  </View>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: 70,
                        width: 250,
                      }}
                    >
                      <Text
                        style={{
                          marginVertical: 22,
                          color: "#404040",
                          padding: 10,
                        }}
                      >
                        To
                      </Text>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 22,
                            alignSelf: "flex-start",
                            padding: 10,
                            width: 150,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalenderTo: !this.state.viewCalenderTo,
                            viewCalenderFrom: false,
                          });
                        }}
                      >
                        {this.state.dataToDate.getDate()}/
                        {this.state.dataToDate.getMonth() + 1}/
                        {this.state.dataToDate.getFullYear()}
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: 60,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.viewCalenderTo ? (
                        <Calendar
                          value={this.state.dataToDate}
                          onChange={(value) => {
                            this.setState({ dataToDate: value }, () => {
                              this.setState({ viewCalenderTo: false });
                            });
                          }}
                        />
                      ) : null}
                    </View>
                  </View>
                  <TouchableOpacity
                    disabled={this.state.isButtonLoading}
                    style={[
                      styles.addNewButton,
                      { marginVertical: 22, width: 120 },
                    ]}
                    onPress={() => {
                      this.setState({ isButtonLoading: true });
                      this.callDataFromDB();
                    }}
                  >
                    {this.state.isButtonLoading ? (
                      <ActivityIndicator size="small" color="#fff" />
                    ) : (
                      <Text style={{ color: "#fff", textAlign: "center" }}>
                        View
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
                <View
                  style={[
                    styles.header,
                    {
                      marginHorizontal: 70,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      zIndex: -1,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Cash Flow
                  </Text>
                </View>
                <View
                  style={{
                    marginHorizontal: 70,
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                    borderRadius: 5,
                    zIndex: -1,
                  }}
                >
                  <FlatList
                    data={this.state.searchData}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{index + 1}</Text>
                          <Text style={styles.columnRowTxt}>
                            {`${new Date(
                              item.timestamp
                            ).toDateString()},${new Date(
                              item.timestamp
                            ).getHours()}:${new Date(
                              item.timestamp
                            ).getMinutes()}`}
                          </Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.discription}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.cashIn ? item.cashIn : "-"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.cashOut ? item.cashOut : "-"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.entryBy}
                          </Text>
                          {/* <View
                  style={{
                    width: "33.33%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                  >
                    <Text style={{ color: "#0a60ff" }}>Sales Report</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("ledgerScreen", {
                        customerId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() =>
                      navigation.navigate("customerEdit", {
                        customerId: item.id,
                      })
                    }
                  >
                    <Text style={{ color: "#075e54" }}>Edit</Text>
                  </TouchableOpacity>
                </View> */}
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeader}
                    ListFooterComponent={this.tableFooter}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        {this.state.isLoading ? (
                          <ActivityIndicator size="large" color="#0a60ff" />
                        ) : (
                          <Text>No Entries Found in Selected Date Range</Text>
                        )}
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.timestamp)}
                  />
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "16.66%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.66%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
});
