import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class othersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uoid: "",
      buttonLoading: false,
      dmCount: 0,
      receiptCount: 0,
      invoiceCount: 0,
      localInvoiceCount: 0,
      quoteCount: 0,
      orderNo: 0,
      fiscalyear: "",
      disabled: false,
      expiryDate: new Date(),
      doj: "",
      updatebuttonLoading: false,
      admin: false,
      error: "",
      SnackbarVisible: false,
      nrdmCount: 0,
    };
  }
  componentDidMount() {
    const { admin } = this.props.route.params;
    this.setState({ admin });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.getData();
            this.getSubscription();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  getData = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }

    this.setState({ fiscalyear }, () => {
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);

      dbRef.on("value", (snapShot) => {
        if (snapShot.exists()) {
          let data = snapShot.val();
          this.setState({
            dmCount: data.dmCount,
            receiptCount: data.receiptNo,
            invoiceCount: data.gstBillCount,
            localInvoiceCount: data.localBillCount,
            quoteCount: data.quoteNo,
            orderNo: data.orderNo,
            nrdmCount: data.nrdmCount,
          });
        }
      });
    });
  };
  getSubscription = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);

    dbRef.on("value", (SnapShot) => {
      if (SnapShot.exists()) {
        let data = SnapShot.val();
        this.setState({
          expiryDate: data.expiryDate,
          doj: data.date,
        });
      }
    });
  };
  updateDB = () => {
    this.setState({ updatebuttonLoading: true });
    if (
      this.state.quoteCount >= 0 &&
      this.state.dmCount >= 0 &&
      this.state.receiptCount >= 0 &&
      this.state.invoiceCount >= 0
    ) {
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);

      dbRef
        .update({
          dmCount: this.state.dmCount,
          receiptNo: this.state.receiptCount,
          gstBillCount: this.state.invoiceCount,
          quoteNo: this.state.quoteCount,
          localBillCount: this.state.localInvoiceCount,
          orderNo: this.state.orderNo,
        })
        .then(() => {
          this.setState({ updatebuttonLoading: false }, () => {
            this.setState(
              {
                SnackbarVisible: true,
                error: "Updated !",
              },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          });
        });
    } else {
      this.setState({ updatebuttonLoading: false });
    }
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Subscriptions and Others" />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Modal starts */}

              {/* Model */}

              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text style={{ fontSize: 16, textDecorationLine: "underline" }}>
                  DM/Receipt/Invoice counts for FY {this.state.fiscalyear}
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="DM Counts"
                        value={this.state.dmCount}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ dmCount: parseInt(text) });
                          } else {
                            this.setState({ dmCount: "" });
                          }
                        }}
                        placeholder="DM Count"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Receipt Count"
                        value={this.state.receiptCount}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ receiptCount: parseInt(text) });
                          } else {
                            this.setState({ receiptCount: "" });
                          }
                        }}
                        placeholder="Receipt Count"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="GST Invoice Count"
                        value={this.state.invoiceCount}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ invoiceCount: parseInt(text) });
                          } else {
                            this.setState({ invoiceCount: "" });
                          }
                        }}
                        placeholder="GST Invoice Count"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Local Invoice Count"
                        value={this.state.localInvoiceCount}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({
                              localInvoiceCount: parseInt(text),
                            });
                          } else {
                            this.setState({ localInvoiceCount: "" });
                          }
                        }}
                        placeholder="Local Invoice Count"
                      />
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="non-Royalty DM"
                        value={this.state.nrdmCount}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ nrdmCount: parseInt(text) });
                          } else {
                            this.setState({ nrdmCount: "" });
                          }
                        }}
                        placeholder="non-Royalty DM"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Quotation Number"
                        value={this.state.quoteCount}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ quoteCount: parseInt(text) });
                          } else {
                            this.setState({ quoteCount: "" });
                          }
                        }}
                        placeholder="Quotation Number"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Order Number"
                        value={this.state.orderNo}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                          if (text.match(regex)) {
                            this.setState({ orderNo: parseInt(text) });
                          } else {
                            this.setState({ orderNo: "" });
                          }
                        }}
                        placeholder="Order Number"
                      />
                    </View>
                  </View>
                </View>

                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        if (this.state.admin) {
                          this.updateDB();
                        } else {
                          this.setState(
                            {
                              SnackbarVisible: true,
                              error: "Not Allowed, Only Admin",
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({ SnackbarVisible: false });
                              }, 5000);
                            }
                          );
                        }
                      }}
                    >
                      {this.state.updatebuttonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() =>
                        this.props.navigation.navigate("DashBoard")
                      }
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <Text
                  style={{
                    fontSize: 16,
                    textDecorationLine: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Update Only if needed. COUNTS ARE RESET TO 0(ZERO) EVERY NEW
                  FISCAL YEAR
                </Text>
              </View>
              <View style={{ paddingLeft: 20 }}>
                <Text
                  style={{
                    padding: 12,
                    fontSize: 22,
                    fontWeight: "500",
                    justifyContent: "center",
                  }}
                >
                  Subscription Details
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    zIndex: 5,
                  }}
                >
                  <View style={{ flexDirection: "column" }}>
                    <TextInput
                      mode="outlined"
                      label="Registered Mobile Number"
                      value={this.state.uoid}
                      style={[styles.inputField, { width: 300 }]}
                      placeholder="Mobile Number"
                      editable={false}
                    />
                    <TextInput
                      mode="outlined"
                      label="Joining Date"
                      value={this.state.doj}
                      style={[styles.inputField, { width: 300 }]}
                    />
                  </View>
                  <View style={{ flexDirection: "column" }}>
                    <TextInput
                      mode="outlined"
                      label="Expiry Date"
                      value={new Date(this.state.expiryDate).toDateString()}
                      style={[
                        styles.inputField,
                        {
                          width: 300,
                          backgroundColor:
                            this.state.expiryDate - Date.parse(new Date()) <
                              864000000 &&
                            this.state.expiryDate - Date.parse(new Date()) > 0 //10 days
                              ? "#ffff99"
                              : Date.parse(new Date()) > this.state.expiryDate
                              ? "#ffcccc"
                              : "#EAEAEA",
                        },
                      ]}
                      placeholder="Expiry Date"
                      editable={false}
                    />
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          width: 250,
                          margin: 21,
                          alignItems: "center",
                        },
                      ]}
                      //   onPress={() =>
                      //     this.props.navigation.navigate("DashBoard")
                      //   }
                    >
                      <Text style={{ color: "#0a60ff" }}>Plan Details</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 200,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
    alignSelf: "center",
  },
});
