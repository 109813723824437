import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { TextInput, Checkbox, Snackbar } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

class MROUpdate extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: Date.parse(new Date()),
      itemSearch: "",
      issuedQty: "",

      particular: "",
      acceptCheckBox: false,
      //Update on item select
      avaliableQty: "",
      uom: "Unit",
      mainDataItem: [],
      searchDataItem: [],
      mainDataEmply: [],
      searchDataEmply: [],
      empySearch: "",
      post: "",
      viewDropDown: false,
      SnackbarVisible: false,
      productId: "",
      currentdayAvaliableQty: 0,
      currentdayUsedQty: 0,
      uoid: "",
      buttonLoading: false,
      receivedUsedPart: null,
      isRepairRequired: null,
      isReusable: false,
      canItBeReused: null,
      employeeId: "",
      receivedUnit: "",
      currentDayReceivedUnit: 0,
      currentDataOfMROIssuedToEmply: { pendingQty: 0 },
      isEmplopyeeSelected: false,
      disableItem: false,
      currentScrapList: {},
      currentRepairList: {},
      error: "",
      borderColor: "#c1c1c1",
      issuedList: [],
      columns: ["Sr no.", "Item", "Issued Qty", "Received Unit"],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.getProductData();
              this.getEmplyData();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getProductData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/MROInventory/products`);

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };
  getEmplyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/employeeData/vitalInfo`);

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataEmply: data, mainDataEmply: data });
      }
    });
  };
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataItem: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  searchEmplyStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataEmply.filter((item) => {
        const itemData = item ? item.name.toUpperCase() : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataEmply: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataEmply: this.state.mainDataEmply });
    }
  };
  currentdayAvaliableQty = () => {
    let dbref1 = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryOverView/${
          this.state.productId
        }/${Date.parse(new Date().toDateString())}`
      );

    dbref1.on("value", (datasnapshot) => {
      if (datasnapshot.val()) {
        let data = datasnapshot.val();
        this._isMounted &&
          this.setState({
            currentdayAvaliableQty: data.qtyAdd,
            currentdayUsedQty: data.qtyUsed,
            currentDayReceivedUnit: data.receivedUnit ? data.receivedUnit : 0,
          });
      }
    });
  };
  saveDataToDbWithInventory = () => {
    this._isMounted && this.setState({ buttonLoading: true });
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
      );

    dbref
      .update({
        quantity:
          parseFloat(this.state.avaliableQty) -
          parseFloat(this.state.issuedQty),
        qtyUpdateDate: new Date().toDateString(),
      })
      .then(() => {
        let dbref1 = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/MROInventory/productHistoryOverView/${
              this.state.productId
            }/${Date.parse(new Date().toDateString())}`
          );
        dbref1
          .update({
            qtyUsed: (
              parseFloat(this.state.currentdayUsedQty) +
              parseFloat(this.state.issuedQty)
            ).toFixed(2),
            avaliableQty: (
              parseFloat(this.state.avaliableQty) -
              parseFloat(this.state.issuedQty)
            ).toFixed(2),
            dateNumber: Date.parse(new Date().toDateString()),
            date: new Date().toDateString(),
            productId: this.state.productId,
            qtyAdd: parseFloat(this.state.currentdayAvaliableQty),
            usedQtyAdded: 0,
            particular: `${this.state.particular}`,
          })
          .then(() => {
            let dbref2 = firebase
              .database()
              .ref(
                `${
                  this.state.uoid
                }/factoryData/MROInventory/productHistoryDetails/${
                  this.state.productId
                }/${Date.parse(new Date().toDateString())}/${Date.parse(
                  new Date()
                )}`
              );
            dbref2
              .set({
                timeStamp: Date.parse(new Date()),
                time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                particular: `${this.state.particular}`,
                date: new Date().toDateString(),
                qtySubtract: this.state.issuedQty,
                qtyAdd: 0,
                usedQtyAdded: 0,
                avaliableQty: (
                  parseFloat(this.state.avaliableQty) -
                  parseFloat(this.state.issuedQty)
                ).toFixed(2),
                issuedBy: this.state.empySearch,
              })
              .then(() => {
                this.setState(
                  {
                    error: "Saved Successfully",

                    buttonLoading: false,
                    issuedQty: "",
                    acceptCheckBox: false,
                    itemSearch: "",
                    empySearch: "",
                  },
                  () => {
                    this.props.closeMROModal();
                  }
                );
              })
              .catch((e) => {
                this.setState(
                  {
                    error: e.message,
                    SnackbarVisible: true,
                    buttonLoading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this._isMounted &&
                        this.setState({ SnackbarVisible: false });
                    }, 5000);
                  }
                );
              });
          })
          .catch((e) => {
            this.setState(
              { error: e.message, SnackbarVisible: true, buttonLoading: false },
              () => {
                setTimeout(() => {
                  this._isMounted && this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          });
      })
      .catch((e) => {
        this.setState(
          { error: e.message, SnackbarVisible: true, buttonLoading: false },
          () => {
            setTimeout(() => {
              this._isMounted && this.setState({ SnackbarVisible: false });
            }, 5000);
          }
        );
      });
  };
  getCurrentEmployeeMROIssued = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/mroInventoryIssued/${this.state.employeeId}/${this.state.productId}`
      );
    dbref.once("value", (snapShot) => {
      if (snapShot.exists()) {
        let data = snapShot.val();
        this.setState({
          currentDataOfMROIssuedToEmply: data,
        });
      } else {
        this.setState({
          currentDataOfMROIssuedToEmply: { pendingQty: "0" },
        });
      }
    });
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToEmployeeMROList = async () => {
    if (this.state.receivedUsedPart == false) {
      this._isMounted && this.setState({ buttonLoading: true });

      let dbref = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/employeeData/mroInventoryIssued/${this.state.employeeId}/${this.state.productId}`
        );
      await dbref
        .update({
          productId: this.state.productId,
          productName: this.state.itemSearch,
          pendingQty:
            parseFloat(this.state.issuedQty) -
            parseFloat(this.state.receivedUnit ? this.state.receivedUnit : 0) +
            parseFloat(this.state.currentDataOfMROIssuedToEmply.pendingQty),
          uom: this.state.uom,
          date: this.state.date,
          time: this.state.time,
          purpose: this.state.particular,
        })
        .then(() => {
          // this.setState({
          //   error: "Saved Successfully",

          //   buttonLoading: false,
          //   issuedQty: "",
          //   acceptCheckBox: false,
          //   itemSearch: "",
          //   empySearch: "",
          // });
          this.saveDataToDbWithInventory();
        });
    }
  };

  onlySaveToEmployeeMROIssue = async () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/mroInventoryIssued/${this.state.employeeId}/${this.state.productId}`
      );
    await dbref.update({
      productId: this.state.productId,
      productName: this.state.itemSearch,
      pendingQty:
        parseFloat(this.state.issuedQty) -
        parseFloat(this.state.receivedUnit) +
        parseFloat(this.state.currentDataOfMROIssuedToEmply.pendingQty),
      uom: this.state.uom,
      date: this.state.date,
      time: this.state.time,
      purpose: this.state.particular,
    });
  };
  saveToScrapList = () => {
    if (
      this.state.canItBeReused == false &&
      this.state.receivedUsedPart == true
    ) {
      this._isMounted && this.setState({ buttonLoading: true });
      let dbref = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/MROInventory/scrapList/${this.state.productId}`
        );

      dbref
        .update({
          productId: this.state.productId,
          productName: this.state.itemSearch,
          qty:
            parseFloat(this.state.receivedUnit) +
            parseFloat(this.state.currentScrapList.qty),
          uom: this.state.uom,
          date: this.state.date,
          time: this.state.time,
          submitedBy: this.state.empySearch,
        })
        .then(() => {
          this.saveDataToDbWithInventory();
        });
    }
  };
  getCurrentScrapList = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/scrapList/${this.state.productId}`
      );

    dbref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        this.setState({ currentScrapList: data });
      } else {
        this.setState({ currentScrapList: { qty: 0 } });
      }
    });
  };
  saveToRepairList = () => {
    if (
      this.state.receivedUsedPart == true &&
      this.state.canItBeReused == true &&
      this.state.isRepairRequired == true
    ) {
      this._isMounted && this.setState({ buttonLoading: true });
      let dbref = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/MROInventory/repairList/${this.state.productId}`
        );
      dbref
        .set({
          productId: this.state.productId,
          productName: this.state.itemSearch,
          qty:
            parseFloat(this.state.receivedUnit) +
            parseFloat(this.state.currentRepairList.qty),
          uom: this.state.uom,
          date: this.state.date,
          time: this.state.time,
          submitedBy: this.state.empySearch,
        })
        .then(() => {
          this.saveDataToDbWithInventory();
        });
    }
  };
  getCurrentRepairList = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/repairList/${this.state.productId}`
      );

    dbref.on("value", (snapShot) => {
      if (snapShot.exists()) {
        this.setState({ currentRepairList: snapShot.val() });
      } else {
        this.setState({ currentRepairList: { qty: 0 } });
      }
    });
  };
  updateMROCondition = () => {
    if (
      this.state.receivedUsedPart == true &&
      this.state.canItBeReused == true &&
      this.state.isRepairRequired == false
    ) {
      this._isMounted && this.setState({ buttonLoading: true });
      let dbref = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
        );

      dbref
        .update({
          condition: "used",
          quantity:
            parseFloat(this.state.avaliableQty) -
            parseFloat(this.state.issuedQty) +
            parseFloat(this.state.receivedUnit),
        })
        .then(() => {
          let dbref1 = firebase
            .database()
            .ref(
              `${
                this.state.uoid
              }/factoryData/MROInventory/productHistoryOverView/${
                this.state.productId
              }/${Date.parse(new Date().toDateString())}`
            );
          dbref1
            .update({
              qtyUsed: (
                parseFloat(this.state.currentdayUsedQty) +
                parseFloat(this.state.issuedQty)
              ).toFixed(2),
              avaliableQty: (
                parseFloat(this.state.avaliableQty) -
                parseFloat(this.state.issuedQty) +
                parseFloat(this.state.receivedUnit)
              ).toFixed(2),
              dateNumber: Date.parse(new Date().toDateString()),
              date: new Date().toDateString(),
              productId: this.state.productId,
              qtyAdd:
                parseFloat(this.state.currentdayAvaliableQty) +
                parseFloat(this.state.receivedUnit),
              usedQtyAdded:
                parseFloat(this.state.receivedUnit) +
                parseFloat(this.state.currentDayReceivedUnit),
              particular: `${this.state.particular}`,
            })
            .then(() => {
              let dbref2 = firebase
                .database()
                .ref(
                  `${
                    this.state.uoid
                  }/factoryData/MROInventory/productHistoryDetails/${
                    this.state.productId
                  }/${Date.parse(new Date().toDateString())}/${Date.parse(
                    new Date()
                  )}`
                );
              dbref2
                .set({
                  timeStamp: Date.parse(new Date()),
                  time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                  particular: `${this.state.particular}`,
                  date: new Date().toDateString(),
                  qtySubtract: this.state.issuedQty,
                  qtyAdd: 0,
                  usedQtyAdded: this.state.receivedUnit,
                  avaliableQty: (
                    parseFloat(this.state.avaliableQty) -
                    parseFloat(this.state.issuedQty) +
                    parseFloat(this.state.receivedUnit)
                  ).toFixed(2),
                  issuedBy: this.state.empySearch,
                })
                .then(() => {
                  this.setState(
                    {
                      error: "Saved Successfully",

                      buttonLoading: false,
                      issuedQty: "",
                      acceptCheckBox: false,
                      itemSearch: "",
                      empySearch: "",
                    },
                    () => {
                      this.props.closeMROModal();
                    }
                  );
                });
            });
        });
    }
  };
  saveToDailyMROIssue = () => {
    let dateNumber = Date.parse(new Date().toDateString());
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/dailyMROIssue/${dateNumber}/employeeList/${this.state.employeeId}`
      );

    dbref.set({
      employeeName: this.state.empySearch,
      employeeId: this.state.employeeId,
      verified: false,
    });

    let dateTimeNumber = Date.parse(new Date());
    let dbref1 = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/dailyMROIssue/${dateNumber}/mroIssued/${this.state.employeeId}/${dateTimeNumber}`
      );

    dbref1.set({
      productId: this.state.productId,
      itemName: this.state.itemSearch,
      issuedQty: this.state.issuedQty,
      receivedQty: this.state.receivedUnit,
      dateTimeNumber: dateTimeNumber,
      date: this.state.date,
      time: this.state.time,
      issuedBy: this.state.empySearch,
      uom: this.state.uom,
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={{ fontSize: 26, fontWeight: "bold", alignSelf: "center" }}
          >
            MRO Inventory Issue - {this.state.date},{this.state.time}
          </Text>
        </View>
        <View style={styles.playAreaHolder}>
          <View>
            <TouchableWithoutFeedback
              onPress={() => {
                this._isMounted &&
                  this.setState({
                    viewDropDown: false,
                    viewDropDownEmployee: false,
                  });
              }}
            >
              <View style={styles.mainCard}>
                <View style={{ flexDirection: "row", zIndex: 6 }}>
                  {/* <TextInput
                    disabled
                    mode="outlined"
                    label="Date"
                    value={this.state.date}
                    style={[styles.inputField, { width: 180 }]}
                  /> */}
                  {/* <TextInput
                    disabled
                    mode="outlined"
                    label="Time"
                    value={this.state.time}
                    style={[styles.inputField, { width: 180 }]}
                  /> */}

                  <TextInput
                    disabled={this.state.isEmplopyeeSelected}
                    mode="outlined"
                    label="Requested By"
                    value={this.state.empySearch}
                    style={[styles.inputField, { width: 320 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ empySearch: text }, () => {
                          this.searchEmplyStringInArray(this.state.empySearch);
                          this._isMounted &&
                            this.setState({ viewDropDownEmployee: true });
                        });
                    }}
                    placeholder="Requested By"
                  />
                  {this.state.viewDropDownEmployee ? (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 5,
                        position: "absolute",
                        marginTop: 70,
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 5,
                        zIndex: 5,
                        marginLeft: 20,
                        maxHeight: 180,
                      }}
                    >
                      <FlatList
                        data={this.state.searchDataEmply}
                        renderItem={({ item }) => {
                          return (
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#fff",
                                padding: 10,
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                width: 320,
                                alignItems: "center",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    empySearch: item.name,
                                    post: item.post,
                                    employeeId: item.id,
                                  },
                                  () => {
                                    this._isMounted &&
                                      this.setState({
                                        viewDropDownEmployee: false,
                                        isEmplopyeeSelected: true,
                                      });
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#000" }}>
                                {item.name}, {item.post}
                              </Text>
                            </TouchableOpacity>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  ) : null}
                  <TextInput
                    mode="outlined"
                    label="Issued for Work"
                    value={this.state.particular}
                    style={[styles.inputField, { width: 320, zIndex: -1 }]}
                    onChangeText={(text) => {
                      this._isMounted && this.setState({ particular: text });
                    }}
                    placeholder="Issued for Work"
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    zIndex: 5,
                    borderWidth: StyleSheet.hairlineWidth,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <TextInput
                    disabled={
                      this.state.disableItem ||
                      this.state.isEmplopyeeSelected == false
                    }
                    mode="outlined"
                    label="Item"
                    value={this.state.itemSearch}
                    style={[styles.inputField, { width: 260 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ itemSearch: text }, () => {
                          this.searchItemStringInArray(this.state.itemSearch);
                          this._isMounted &&
                            this.setState({ viewDropDown: true });
                        });
                    }}
                    placeholder="Select Item"
                  />
                  {this.state.viewDropDown ? (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 5,
                        position: "absolute",
                        marginTop: 70,
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 5,
                        zIndex: 5,
                        marginLeft: 20,
                        maxHeight: 150,
                      }}
                    >
                      <FlatList
                        data={this.state.searchDataItem}
                        renderItem={({ item }) => {
                          return (
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#fff",
                                padding: 10,
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                width: 260,
                                alignItems: "center",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    itemSearch: item.productName,
                                    avaliableQty: item.quantity,
                                    uom: item.UOM,
                                    productId: item.id,
                                    isReusable: item.isReusable,
                                    receivedUsedPart: null,
                                    canItBeReused: null,
                                    isRepairRequired: null,
                                    disableItem: true,
                                  },
                                  () => {
                                    this._isMounted &&
                                      this.setState({ viewDropDown: false });
                                    this.currentdayAvaliableQty();

                                    if (this.state.isReusable) {
                                      this.getCurrentScrapList();
                                      this.getCurrentRepairList();
                                      this.getCurrentEmployeeMROIssued();
                                    }
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#000" }}>
                                {item.productName}
                              </Text>
                            </TouchableOpacity>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  ) : null}

                  <TextInput
                    mode="outlined"
                    label="Avaliable Quantity"
                    value={this.state.avaliableQty}
                    style={[styles.inputField, { width: 160, zIndex: -1 }]}
                    placeholder="Avaliable Quantity"
                    disabled
                  />
                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      mode="outlined"
                      label="Issued Quantity"
                      value={this.state.issuedQty}
                      style={[styles.inputField, { width: 160, zIndex: -1 }]}
                      onChangeText={(text) => {
                        let regex = /^[0-9\.]*$/;
                        if (text.match(regex)) {
                          this._isMounted && this.setState({ issuedQty: text });
                        }
                      }}
                      placeholder="Issued Quantity"
                    />
                    <Text style={{ paddingTop: 40 }}>{this.state.uom}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    zIndex: -1,
                    alignSelf: "flex-start",
                  }}
                >
                  {/* TODO: */}
                  {this.state.error != "" ? (
                    <Text
                      style={{ fontSize: 16, color: "#DE5246", padding: 20 }}
                    >
                      {this.state.error}
                    </Text>
                  ) : null}
                </View>
                <View style={{ alignSelf: "flex-start", padding: 20 }}>
                  <View
                    style={{
                      borderWidth: this.state.isReusable
                        ? StyleSheet.hairlineWidth
                        : 0,
                      borderColor: this.state.borderColor,
                    }}
                  >
                    {this.state.isReusable ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "flex-start",
                        }}
                      >
                        <Text style={{ padding: 8, fontFamily: 14 }}>
                          Received Damaged/used Part ?
                        </Text>

                        <Checkbox
                          status={
                            this.state.receivedUsedPart == true
                              ? "checked"
                              : "unchecked"
                          }
                          onPress={() => {
                            this._isMounted &&
                              this.setState({
                                receivedUsedPart: true,
                              });
                          }}
                          color="#0a60ff"
                        />

                        <Checkbox
                          status={
                            this.state.receivedUsedPart == false
                              ? "checked"
                              : "unchecked"
                          }
                          onPress={() => {
                            this._isMounted &&
                              this.setState(
                                {
                                  receivedUsedPart: false,
                                },
                                () => {
                                  if (this.state.receivedUsedPart == false) {
                                    this.setState({
                                      isRepairRequired: null,
                                      canItBeReused: null,
                                    });
                                  }
                                }
                              );
                          }}
                          color="#0a60ff"
                        />

                        {this.state.receivedUsedPart == true ? (
                          <View style={{ flexDirection: "row" }}>
                            <TextInput
                              mode="outlined"
                              label="Received Quanity"
                              value={this.state.receivedUnit}
                              style={[
                                styles.inputField,
                                {
                                  width: 240,
                                  zIndex: -1,
                                  padding: 0,
                                  paddingHorizontal: 20,
                                },
                              ]}
                              onChangeText={(text) => {
                                let regex = /^[0-9\.]*$/;
                                if (text.match(regex)) {
                                  this._isMounted &&
                                    this.setState({ receivedUnit: text });
                                }
                              }}
                              placeholder="Received Qty"
                            />
                            <Text style={{ paddingTop: 10 }}>
                              {this.state.uom}
                            </Text>
                          </View>
                        ) : null}
                      </View>
                    ) : null}
                    {this.state.receivedUsedPart && this.state.isReusable ? (
                      <View style={styles.checkBoxHolder}>
                        <Text style={{ padding: 8, fontFamily: 14 }}>
                          Can it be reused ?
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Checkbox
                            status={
                              this.state.canItBeReused == true
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  canItBeReused: true,
                                });
                            }}
                            color="#0a60ff"
                          />
                          <Checkbox
                            status={
                              this.state.canItBeReused == false
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  canItBeReused: false,
                                  isRepairRequired: null,
                                });
                            }}
                            color="#0a60ff"
                          />
                        </View>
                      </View>
                    ) : null}
                    {this.state.canItBeReused &&
                    this.state.isReusable &&
                    this.state.receivedUsedPart ? (
                      <View style={styles.checkBoxHolder}>
                        <Text style={{ padding: 8, fontFamily: 14 }}>
                          Is repair required ?
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Checkbox
                            status={
                              this.state.isRepairRequired == true
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  isRepairRequired: true,
                                });
                            }}
                            color="#0a60ff"
                          />
                          <Checkbox
                            status={
                              this.state.isRepairRequired == false
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => {
                              this._isMounted &&
                                this.setState({
                                  isRepairRequired: false,
                                });
                            }}
                            color="#0a60ff"
                          />
                        </View>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
                  <Checkbox
                    status={this.state.acceptCheckBox ? "checked" : "unchecked"}
                    onPress={() => {
                      this._isMounted &&
                        this.setState({
                          acceptCheckBox: !this.state.acceptCheckBox,
                        });
                    }}
                    color="#0a60ff"
                  />
                  <Text style={{ padding: 8 }}>Above entry is true.</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    style={[styles.addNewButton]}
                    onPress={() => {
                      if (
                        this.state.acceptCheckBox &&
                        this.state.productId != "" &&
                        this.state.issuedQty != "" &&
                        this.state.particular != "" &&
                        this.state.employeeId != ""
                      ) {
                        if (this.state.isReusable == false) {
                          this.saveDataToDbWithInventory();
                          this.saveToDailyMROIssue();
                        } else if (
                          this.state.isReusable == true &&
                          this.state.receivedUsedPart == false
                        ) {
                          this.saveDataToEmployeeMROList(); //Followed by 'saveDataToDbWithInventory'
                          this.saveToDailyMROIssue();
                        } else if (
                          this.state.isReusable == true &&
                          this.state.receivedUsedPart == true && //
                          this.state.canItBeReused == false
                        ) {
                          if (this.state.receivedUnit != "") {
                            this.saveToScrapList(); //Followed by 'saveDataToDbWithInventory'
                            this.saveToDailyMROIssue();
                            if (
                              this.state.issuedQty > this.state.receivedUnit
                            ) {
                              this.onlySaveToEmployeeMROIssue();
                            }
                          } else {
                            this.setState({
                              error: "Please Enter Received Quantity",
                            });
                          }
                        } else if (
                          this.state.isReusable == true &&
                          this.state.receivedUsedPart == true && //
                          this.state.canItBeReused == true &&
                          this.state.isRepairRequired == true
                        ) {
                          if (this.state.receivedUnit != "") {
                            this.saveToRepairList(); //Followed by 'saveDataToDbWithInventory'
                            this.saveToDailyMROIssue();
                            if (
                              this.state.issuedQty > this.state.receivedUnit
                            ) {
                              this.onlySaveToEmployeeMROIssue();
                            }
                          } else {
                            this.setState({
                              error: "Please Enter Received Quantity",
                            });
                          }
                        } else if (
                          this.state.isReusable == true &&
                          this.state.receivedUsedPart == true && //
                          this.state.canItBeReused == true &&
                          this.state.isRepairRequired == false
                        ) {
                          if (this.state.receivedUnit != "") {
                            this.updateMROCondition();
                            this.saveToDailyMROIssue();
                            if (
                              this.state.issuedQty > this.state.receivedUnit
                            ) {
                              this.onlySaveToEmployeeMROIssue();
                            }
                          } else {
                            this.setState({
                              error: "Please Enter Received Quantity",
                            });
                          }
                        } else {
                          this.setState({
                            error: "Please Check the box",
                            borderColor: "#DE5246",
                          });
                        }
                      } else {
                        this.setState({ error: "All Fields are Required" });
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator size="small" color="#fff" />
                    ) : (
                      <Text style={{ color: "#fff" }}>Issue and Save</Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
            {this.state.issuedList.length != 0 ? (
              <FlatList
                data={this.state.issuedList}
                renderItem={({ item }) => {
                  return (
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white",
                        },
                      ]}
                    >
                      <Text style={styles.columnRowTxt}>{index + 1}</Text>
                      <Text
                        style={[styles.columnRowTxt, { fontWeight: "bold" }]}
                      >
                        {item.issuedItem}
                      </Text>
                      <Text style={styles.columnRowTxt}>{item.issuedQty}</Text>

                      <Text style={styles.columnRowTxt}>
                        {item.receivedUnit}
                      </Text>
                    </View>
                  );
                }}
                keyExtractor={(item) => JSON.stringify(item.id)}
                ListHeaderComponent={this.tableHeader}
              />
            ) : null}
          </View>

          <Snackbar
            visible={this.state.SnackbarVisible}
            onDismiss={() => {
              this._isMounted && this.setState({ SnackbarVisible: false });
            }}
            action={{
              label: "OK",
              onPress: () => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              },
            }}
          >
            {this.state.error}
          </Snackbar>
        </View>
      </View>
    );
  }
}
export default function (props) {
  const navigation = useNavigation();

  return <MROUpdate {...props} navigation={navigation} />;
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,

    backgroundColor: "#fff",
  },
  headerContainer: {},
  playAreaHolder: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#c1c1c1",
    borderRadius: 5,
  },
  leftSideHolder: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
  },
  rightSideHolder: {
    flex: 1,
  },
  subText: {
    fontWeight: "normal",
    fontSize: 18,
    alignSelf: "center",
  },
  mainCard: {
    maxWidth: 740,
    alignItems: "center",
    padding: 20,
  },
  inputField: {
    padding: 20,
    height: 40,
  },

  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
    marginTop: 28,
    marginHorizontal: 20,
    width: 150,
    alignItems: "center",
  },
  checkBoxHolder: {
    flexDirection: "row",
    alignSelf: "flex-start",
    width: "50%",
    justifyContent: "space-between",
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
});
