import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  Modal,
  Portal,
  Provider,
  Checkbox,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AntDesign } from "@expo/vector-icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
export default class customerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      productName: "",
      vendor: "",
      quantity: "",
      UOM: "",
      since: "",
      note: "",
      setVisibleAbstract: false,
      dataLoading: true,
      buttonLoading: false,

      historyOverView: [],
      historyDetails: [],
      columns: [
        "Sr. No.",
        "Date",

        "Qty Added (+)",
        "Qty Used (-)",
        "Avaliable Qty",
        "Action",
      ],
      abstractColumns: [
        "Time",
        "Particular",
        "Qty Added(+)",
        "Qty Used (-)",
        "Balance Qty",
        "Issued by",
      ],
      viewDate: "",
      viewDateString: "",
      uoid: "",
      star: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      viewCalenderFrom: false,
      viewCalenderTo: false,
      isButtonLoading: false,
      totalUsage: 0,
      averageUsages: 0,
      totalAdd: 0,
      averageAdd: 0,
      setVisibleUsage: false,
      isReusable: false,
    };
  }
  componentDidMount() {
    const { productId } = this.props.route.params;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState({ productId }, () => {
              this.getDataFromDb();
              var msInDay = 86400000;
              var daysToSub = 15;
              var now = new Date();
              var milliseconds = now.getTime();
              var newMillisecods = milliseconds - msInDay * daysToSub;
              var newDate = new Date(newMillisecods);
              this.setState({ dateFrom: newDate }, () => {
                this.historyOverView();
              });
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  getDataFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();

        this.setState(
          {
            productName: data.productName,
            quantity: data.quantity,
            UOM: data.UOM,
            since: data.joiningDate,
            note: data.note,
            star: data.star,
            // vendor: data.vendor,
            isReusable: data.isReusable ? data.isReusable : false,
          },
          () => {
            this.setState({ dataLoading: false });
          }
        );
      }
    });
  };
  updateDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
      );

    dbRef
      .update({
        productName: this.state.productName,
        quantity: this.state.quantity,
        UOM: this.state.UOM,
        joiningDate: this.state.since,
        note: this.state.note,
        // vendor: this.state.vendor,
        isReusable: this.state.isReusable,
      })
      .then(() => {
        this.setState({ buttonLoading: false });
      });
  };
  historyOverView = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryOverView/${this.state.productId}`
      )
      .orderByChild("dateNumber")
      // .limitToFirst(30);

      .startAt(Date.parse(this.state.dateFrom.toDateString()))
      .endAt(
        Date.parse(
          new Date(
            `${this.state.dateTo.getFullYear()}-${
              this.state.dateTo.getMonth() + 1
            }-${this.state.dateTo.getDate()} 23:59:59`
          )
        )
      );

    dbref.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ historyOverView: data }, () => {
          this.setState({ isButtonLoading: false });
        });
      }
    });
  };
  historyDetails = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryDetails/${this.state.productId}/${this.state.viewDate}`
      );
    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this.setState({
          historyDetails: data.sort(function (a, b) {
            if (a.timeStamp < b.timeStamp) {
              return -1;
            }
            if (a.timeStamp > b.timeStamp) {
              return 1;
            }
            return 0;
          }),
          isLoading: false,
        });
      }
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderAbstract = () => (
    <View style={styles.tableHeader}>
      {this.state.abstractColumns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  usageCalculation = () => {
    const reducerUsage = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.qtyUsed);

    const reducerAdd = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.qtyAdd);

    let totalUsage = this.state.historyOverView.reduce(reducerUsage, 0);
    let averageUsages = totalUsage / this.state.historyOverView.length;

    let totalAdd = this.state.historyOverView.reduce(reducerAdd, 0);
    let averageAdd = totalAdd / this.state.historyOverView.length;
    this.setState({ totalUsage, averageUsages, totalAdd, averageAdd });
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.productName} />
          <View style={styles.mainConsole}>
            <LeftBar mroInvetory="#c1c1c1" />
            {/* Modal starts */}

            <Portal>
              <Modal
                visible={this.state.setVisibleAbstract}
                onDismiss={() => {
                  this.setState(
                    {
                      //   States to reset on dismiss TODO:
                    },
                    () => {
                      this.setState({ setVisibleAbstract: false });
                    }
                  );
                }}
                contentContainerStyle={{
                  backgroundColor: "#ffffff",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                  borderWidth: 3,
                  alignSelf: "center",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    {this.state.productName} Abstract - Date:{" "}
                    {this.state.viewDateString}
                  </Text>
                </View>
                <View style={styles.modelBody}>
                  <FlatList
                    data={this.state.historyDetails}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{item.time}</Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.particular}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.qtyAdd} |{" "}
                            {item.usedQtyAdded ? item.usedQtyAdded : 0}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.qtySubtract}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.avaliableQty}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.issuedBy ? item.issuedBy : ""}
                          </Text>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                    ListHeaderComponent={this.tableHeaderAbstract}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        <ActivityIndicator size="large" color="#0a60ff" />
                      </View>
                    }
                  />
                </View>
              </Modal>
            </Portal>
            {/* ------------------- */}
            <Portal>
              <Modal
                visible={this.state.setVisibleUsage}
                onDismiss={() => {
                  this.setState(
                    {
                      //   States to reset on dismiss TODO:
                    },
                    () => {
                      this.setState({ setVisibleUsage: false });
                    }
                  );
                }}
                contentContainerStyle={{
                  backgroundColor: "#ffffff",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                  borderWidth: 3,
                  alignSelf: "center",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    {this.state.productName} Usage
                  </Text>
                </View>
                <View style={styles.modelBody}>
                  <Text>
                    Average Add Qty: {this.state.averageAdd}, with total of :{" "}
                    {this.state.totalAdd}
                  </Text>
                  <Text>
                    Average Used Qty: {this.state.averageUsages}, with total of
                    : {this.state.totalUsage}
                  </Text>
                  <Text>
                    Differance between avg add and used :
                    {this.state.averageAdd - this.state.averageUsages}
                  </Text>
                </View>
              </Modal>
            </Portal>
            {/* Modal ends */}
            <View style={styles.palyArea}>
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text>Basic Info</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View style={{ flexDirection: "row", padding: 20 }}>
                    <TextInput
                      mode="outlined"
                      label="Product Name"
                      value={this.state.productName}
                      style={[styles.inputField, { width: 320, padding: 0 }]}
                      onChangeText={(text) => {
                        this.setState({ productName: text });
                      }}
                      placeholder="Product Name"
                    />
                    <TouchableOpacity
                      onPress={() => {
                        let dbRef = firebase
                          .database()
                          .ref(
                            `${this.state.uoid}/factoryData/MROInventory/products/${this.state.productId}`
                          );

                        dbRef
                          .update({
                            star: !this.state.star,
                          })
                          .then(() => {
                            this.setState({ buttonLoading: false });
                          });
                      }}
                    >
                      <AntDesign
                        name="star"
                        size={24}
                        color={this.state.star ? "#FFD700" : "#c1c1c1"}
                        style={{ padding: 10 }}
                      />
                    </TouchableOpacity>
                  </View>
                  <TextInput
                    mode="outlined"
                    label="Vendor"
                    value={this.state.vendor}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ vendor: text });
                    }}
                    placeholder="Vendor"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    disabled
                    mode="outlined"
                    label="Avaliable Quantity"
                    value={this.state.quantity}
                    style={[styles.inputField, { width: 200 }]}
                    // onChangeText={(text) => {
                    //   this.setState({ quantity: text });
                    // }}
                    placeholder="Avaliable Quantity"
                  />
                  <TextInput
                    disabled
                    mode="outlined"
                    label="Unit of Measurement(UoM)"
                    value={this.state.UOM}
                    style={[styles.inputField, { width: 200 }]}
                    placeholder="Unit of Measurement(UoM)"
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      padding: 20,
                    }}
                  >
                    <Checkbox
                      status={this.state.isReusable ? "checked" : "unchecked"}
                      onPress={() => {
                        this.setState({
                          isReusable: !this.state.isReusable,
                        });
                      }}
                      color="#0a60ff"
                    />
                    <Text style={{ padding: 10, fontSize: 16 }}>
                      Is this item reusable ?
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="Since"
                    value={this.state.since}
                    style={styles.inputField}
                    placeholder="Since"
                  />
                  <TextInput
                    mode="outlined"
                    label="Note"
                    value={this.state.note}
                    onChangeText={(text) => {
                      this.setState({ note: text });
                    }}
                    style={styles.inputField}
                    placeholder="Note"
                  />
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.setState({ buttonLoading: true }, () => {
                          this.updateDb();
                        });
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator
                          size="small"
                          animating={true}
                          color="#fff"
                        />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save Changes</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.goBack();
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>

                    <Snackbar
                      visible={this.state.SnackbarVisible}
                      onDismiss={() => {
                        this.setState({ SnackbarVisible: false });
                      }}
                      action={{
                        label: "OK",
                        onPress: () => {
                          this.setState({ SnackbarVisible: false });
                        },
                      }}
                    >
                      Changes Saved Successfully !
                    </Snackbar>
                  </View>
                  <Text
                    style={{
                      padding: 20,

                      color: "red",
                    }}
                  >
                    {JSON.stringify(this.state.error)}
                  </Text>
                </View>
                <Text
                  style={{
                    padding: 20,
                    textDecorationLine: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Product History
                </Text>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <Text style={{ padding: 20 }}>From :</Text>
                  <View style={{ flexDirection: "row", zIndex: 5 }}>
                    <View>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 14,
                            alignSelf: "center",
                            padding: 10,
                            width: 200,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalenderFrom: !this.state.viewCalenderFrom,
                          });
                        }}
                      >
                        {/* {this.state.dateFrom.getDate()}/
                  {this.state.dateFrom.getMonth() + 1}/
                  {this.state.dateFrom.getFullYear()} */}
                        {this.state.dateFrom.toDateString()}
                      </Text>

                      <View
                        style={{
                          paddingLeft: 20,
                          marginTop: 75,
                          zIndex: 5,
                          position: "absolute",
                        }}
                      >
                        {this.state.viewCalenderFrom ? (
                          <Calendar
                            value={this.state.dateFrom}
                            onChange={(value) => {
                              this.setState({ dateFrom: value }, () => {
                                this.setState({ viewCalenderFrom: false });
                              });
                            }}
                          />
                        ) : null}
                      </View>
                    </View>
                  </View>
                  {/* to */}
                  <Text style={{ padding: 20 }}>To :</Text>
                  <View style={{ flexDirection: "row", zIndex: 5 }}>
                    <View>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 14,
                            alignSelf: "center",
                            padding: 10,
                            width: 200,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalenderTo: !this.state.viewCalenderTo,
                          });
                        }}
                      >
                        {/* {this.state.dateTo.getDate()}/
                  {this.state.dateTo.getMonth() + 1}/
                  {this.state.dateTo.getFullYear()} */}
                        {this.state.dateTo.toDateString()}
                      </Text>

                      <View
                        style={{
                          paddingLeft: 20,
                          marginTop: 75,
                          zIndex: 5,
                          position: "absolute",
                        }}
                      >
                        {this.state.viewCalenderTo ? (
                          <Calendar
                            value={this.state.dateTo}
                            onChange={(value) => {
                              this.setState({ dateTo: value }, () => {
                                this.setState({ viewCalenderTo: false });
                              });
                            }}
                          />
                        ) : null}
                      </View>
                    </View>
                  </View>

                  <TouchableOpacity
                    style={[
                      styles.actionbtn,
                      { borderColor: "#0a60ff", margin: 15, width: 100 },
                    ]}
                    onPress={() => {
                      this.setState({ isButtonLoading: true });
                      this.historyOverView();
                    }}
                  >
                    {this.state.isButtonLoading ? (
                      <ActivityIndicator size="small" color="#0a60ff" />
                    ) : (
                      <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                        View
                      </Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.actionbtn,
                      { borderColor: "#0a60ff", margin: 15, width: 100 },
                    ]}
                    onPress={() => {
                      this.usageCalculation();
                      this.setState({ setVisibleUsage: true });
                    }}
                  >
                    <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                      Usage
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={{ zIndex: -1 }}>
                  <FlatList
                    data={this.state.historyOverView}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{index + 1}</Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.date}
                          </Text>
                          <Text style={styles.columnRowTxt}>{item.qtyAdd}</Text>
                          <Text style={styles.columnRowTxt}>
                            {item.qtyUsed}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.avaliableQty}
                          </Text>
                          <View
                            style={{
                              width: "16.6%",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <TouchableOpacity
                              style={[
                                styles.actionbtn,
                                { borderColor: "#0a60ff" },
                              ]}
                              onPress={() => {
                                this.setState(
                                  {
                                    setVisibleAbstract: true,
                                    viewDate: item.dateNumber,
                                    viewDateString: item.date,
                                  },
                                  () => {
                                    this.historyDetails();
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#0a60ff" }}>View</Text>
                            </TouchableOpacity>
                            {/* <TouchableOpacity
                            style={[
                              styles.actionbtn,
                              { borderColor: "#B50706" },
                            ]}
                            onPress={() => {
                              this.setState(
                                {
                                
                                },
                                () => {
                                   }
                              );
                            }}
                          >
                            <Text style={{ color: "#B50706" }}>
                              PDF Download
                            </Text>
                          </TouchableOpacity> */}
                          </View>
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeader}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        <ActivityIndicator size="large" color="#0a60ff" />
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.dateNumber)}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    // maxHeight: Dimensions.get("window").height - 55,
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "16.6%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.6%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
});
