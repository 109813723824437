import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  FlatList,
  ImageBackground,
} from "react-native";
import { Modal, Portal, Button, Provider, Snackbar } from "react-native-paper";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import NewSales from "../src/components/NewSales";
import NewExpense from "../src/components/NewExpense";
import MROUpdate from "../src/components/MROUpdate";
import MROUpdateSubmit from "../src/components/MROUpdateSubmit";
import VST from "../src/components/VST";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import EmplyAttend from "../src/components/EmplyAttend";
import { AntDesign } from "@expo/vector-icons";
import { BarChart, LineChart } from "react-native-chart-kit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons, Feather } from "@expo/vector-icons";
const colorArray = ["#4285F4", "#EA4335", "#FBBC05", "#34A853"];
const chartConfig = {
  backgroundGradientFrom: "#fff",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#fff",
  backgroundGradientToOpacity: 0.5,

  strokeWidth: 3, // optional, default 3
  barPercentage: 0.7,
  useShadowColorFromDataset: false, // optional
  labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  color: () => "#c1c1c1",
  fillShadowGradient: "#9AD0F5",

  fillShadowGradientOpacity: 1,
};
export default class dashBoardScreen extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      setVisible: false,
      setVisibleExpense: false,
      setVisibleMRO: false,
      setVisibleemplyAttendance: false,
      setVisibleReportGen: false,
      showLeftBar: true,
      todaySales: 0,
      todaySalesAmount: 0,
      salesDataForChart: [0, 0, 0, 0, 0, 0, 0],
      dateFrom: new Date(),
      labels: [],
      chartLoading: true,
      loginUser: {},
      lastEntry: {},

      employeeCount: 0,
      dieselCount: 0,
      vendorLedgerdata: [],
      vendorList: [],
      mroStarId: "",
      historyDetails: [],
      setVisibleAbstract: false,
      productName: "",
      isLoading: false,
      uoid: "",
      expiryDocData: [],
      isAlertsLoading: true,
      abstractColumns: [
        "Time",
        "Particular",
        "Qty Added(+)",
        "Qty Used (-)",
        "Balance Qty",
        "Issued by",
      ],
      columnsSales: [
        "Sr. no./DM no.",
        "Time",
        "Name",
        "Item/Qty",
        "Amount",
        "Received DM Photo",
      ],
      salesArray: [],
      setVisibleTodaySales: false,
      dateNTime: "",
      notificationArray: [],
      setVisibleMROSubmit: false,
      setVisibleVST: false,
      SnackbarVisible: false,
      error: "",
      salesDate: new Date(),
      viewCalenderFrom: false,
      customerId: "",
      fiscalyearForDM: "",
      salesId: "",
      extensionType: "",
      DownloadImageURL: "empty",
      setVisibleViewImage: false,
      dmNo: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.getSalesHistory();
              this.chartLoad();
              this.getLoginUser();
              // this.getLastEntry();
              this.getPresentEmployee();
              this.getVendorData();
              this.getExpiryDocData();
              // this.calculateReceivable();
              this.getDateTime();
              this.getNotification();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  downloadURL = () => {
    const ref = firebase
      .storage()
      .ref()
      .child(
        `close/${this.state.uoid}/DmPhoto/${this.state.customerId}/${this.state.fiscalyearForDM}`
      )
      .child(this.state.salesId + "." + this.state.extensionType);

    // Get the download URL
    ref.getDownloadURL().then((url) => {
      this.setState({ DownloadImageURL: url, isLoading: false });
    });
  };
  getDateTime = () => {
    var now = new Date();
    var TwentyFourHour = now.getHours();
    var hour = now.getHours();
    var min = now.getMinutes();
    var date = now.getDate();
    var month = now.getMonth() + 1;
    var year = now.getFullYear();

    var mid = "pm";
    if (min < 10) {
      min = "0" + min;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour == 0) {
      hour = 12;
    }
    if (TwentyFourHour < 12) {
      mid = "am";
    }
    this._isMounted &&
      this.setState({
        dateNTime:
          date + "/" + month + "/" + year + "," + hour + ":" + min + " " + mid,
      });

    // setTimeout(this.getDateTime(), 60000);
    setTimeout(() => {
      this.getDateTime();
    }, 60000);
  };
  getNotification = () => {
    this._isMounted && this.setState({ isNotificationLoading: true });
    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/notifications`)
      .orderByChild("dismiss")
      .equalTo(false);

    dbref.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this._isMounted &&
          this.setState({ notificationArray: data }, () => {
            this._isMounted && this.setState({ isNotificationLoading: false });
          });
      } else {
        this._isMounted &&
          this.setState({
            isNotificationLoading: false,
            notificationArray: [],
          });
      }
    });
  };
  getExpiryDocData = () => {
    this._isMounted && this.setState({ isAlertsLoading: true });
    var msInDay = 86400000;
    var daysToAdd = 10;
    var now = new Date();
    var milliseconds = now.getTime();
    var newMillisecods = milliseconds + msInDay * daysToAdd;

    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/fleetData/allDocuments/`)
      .orderByChild("expiryDate")
      .endAt(newMillisecods);
    dbref.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this._isMounted &&
          this.setState({ expiryDocData: data }, () => {
            this._isMounted && this.setState({ isAlertsLoading: false });
          });
      } else {
        this._isMounted && this.setState({ isAlertsLoading: false });
      }
    });
  };
  getVendorData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/vendorData/vendorBasics/`);

    dbRef.on("value", (snapshot) => {
      if (snapshot) {
        let key = snapshot;
        // let newData = this.state.vendorList.concat(key);
        this._isMounted && this.setState({ vendorList: key });

        // this.calculatePayable();
      } else {
        this._isMounted && this.setState({ vendorList: [] });
      }
    });
  };
  // calculatePayable = async () => {
  //   console.log(
  //    this.state.vendorList.map( (item) => {
  //       let dbref1 = firebase
  //         .database()
  //         .ref(`factoryData/vendorData/ledger/${item}`)
  //         .orderByChild("dateNumber")
  //         .limitToLast(1);
  //        dbref1.on("value", (DataSnapShot) => {
  //         if (DataSnapShot.val()) {
  //           item = DataSnapShot.val();
  //         }
  //         return item;
  //       });
  //     })
  //   );
  // };
  // calculateReceivable = () => {
  //   var msInDay = 86400000;
  //   var daysToAdd = 30;
  //   var now = new Date();
  //   var milliseconds = now.getTime();
  //   var newMillisecods = milliseconds + msInDay * daysToAdd;
  //   let dbref = firebase
  //     .database()
  //     .ref(`factoryData/fleetData/vehicleDocument`)
  //     .orderByChild("expiryDate")
  //     .equalTo("1630089000000");

  //   dbref.on("value", (snapshot) => {
  //     console.log(snapshot.val());
  //     snapshot.forEach(function (data) {
  //       console.log(data.key);
  //     });
  //   });
  // };
  getPresentEmployee = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${
          this.state.uoid
        }/factoryData/employeeData/attendance/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}`
      );

    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        let filterData = data.filter((item) => item.p1 || item.p2 == true);
        this._isMounted && this.setState({ employeeCount: filterData.length });
      } else {
        this._isMounted && this.setState({ employeeCount: 0 });
      }
    });

    let dbRef1 = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/MROInventory/products`)
      .orderByChild("star")
      .equalTo(true)
      .limitToFirst(1);

    dbRef1.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());

        this._isMounted &&
          this.setState({
            dieselCount: data[0].quantity + data[0].UOM,
            mroStarId: data[0].id,
            productName: data[0].productName,
          });
      } else {
        this._isMounted &&
          this.setState({ dieselCount: "Add favorite Inventory" });
      }
    });
  };
  historyDetails = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryDetails/${
          this.state.mroStarId
        }/${Date.parse(new Date().toDateString())}`
      );
    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this._isMounted &&
          this.setState({
            historyDetails: data.sort(function (a, b) {
              if (a.timeStamp < b.timeStamp) {
                return -1;
              }
              if (a.timeStamp > b.timeStamp) {
                return 1;
              }
              return 0;
            }),
            isLoading: false,
          });
      } else {
        this._isMounted && this.setState({ isLoading: false });
      }
    });
  };
  // getLastEntry = () => {
  //   let ledgerRef1 = firebase
  //     .database()
  //     .ref(`${this.state.uoid}/erpData/organizationData/ledger/FY2021_22`)
  //     .orderByChild(`dateNumber`)
  //     .limitToLast(1);

  //   ledgerRef1.on("value", (snapshot) => {
  //     if (snapshot.val()) {
  //       let data = Object.values(snapshot.val());
  //       this._isMounted && this.setState({ lastEntry: data[0] });
  //     } else {
  //       this._isMounted && this.setState({ lastEntry: { balance: 0 } });
  //     }
  //   });
  // };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;

    if (loginId != null) {
      let userid = loginId.split(".")[0];

      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this._isMounted && this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  chartLoad = () => {
    var msInDay = 86400000;
    var daysToSub = 15;
    var now = new Date();
    var milliseconds = now.getTime();
    var newMillisecods = milliseconds - msInDay * daysToSub;
    var newDate = new Date(newMillisecods);
    this._isMounted &&
      this.setState({ dateFrom: newDate }, () => {
        this._isMounted && this.setState({ chartLoading: true });
        this.getSalesDataForChart();
      });
  };
  getSalesHistory = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/allSalesData/${Date.parse(
          this.state.salesDate.toDateString()
        )}`
      );

    dbref.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this._isMounted && this.setState({ salesArray: data });
        const reducer1 = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.totalQtySales);
        const reducer2 = (accumulator, currentValue) =>
          accumulator +
          parseFloat(
            currentValue.receivedAmount ? currentValue.receivedAmount : 0
          );
        this._isMounted &&
          this.setState({
            todaySales: data.reduce(reducer1, 0),
            todaySalesAmount: data.reduce(reducer2, 0)
              ? data.reduce(reducer2, 0)
              : 0,
          });
      } else {
        this._isMounted && this.setState({ salesArray: [] });
        this._isMounted &&
          this.setState({
            todaySales: 0,
            todaySalesAmount: 0,
          });
      }
    });
  };
  getSalesDataForChart = () => {
    this._isMounted &&
      this.setState({ salesDataForChart: new Array(15).fill([]) }, () => {
        let newarray = this.state.salesDataForChart.map((item, index) => {
          return (item = {
            dateStamp:
              Date.parse(this.state.dateFrom.toDateString()) +
              (index + 1) * 86400000,
          });
        });
        this._isMounted &&
          this.setState(
            {
              salesDataForChart: newarray,
              labels: newarray.map(
                (item) =>
                  `${new Date(item.dateStamp).getDate()}/${
                    new Date(item.dateStamp).getMonth() + 1
                  }`
              ),
            },
            () => {
              let dbref = firebase
                .database()
                .ref(`${this.state.uoid}/factoryData/productData/allSalesData`)
                .orderByKey()
                .startAt(`${Date.parse(this.state.dateFrom.toDateString())}`)
                .endAt(`${Date.parse(new Date().toDateString())}`);
              dbref.on("value", (snapshot) => {
                if (snapshot.val()) {
                  const reducer1 = (accumulator, currentValue) =>
                    accumulator + parseFloat(currentValue.totalQtySales);
                  let data1 = Object.values(snapshot.val());
                  let data2 = data1.map((item) => Object.values(item));
                  let data201 = this.state.salesDataForChart.map(
                    (item1, index1) => {
                      data2.map((item2, index) => {
                        if (item1.dateStamp == Date.parse(item2[0].date)) {
                          item1 = item2;
                        }
                      });
                      return item1;
                    }
                  );
                  let data3 = data201.map((item) =>
                    Array.isArray(item) ? item.reduce(reducer1, 0) : 0
                  );
                  this._isMounted &&
                    this.setState({ salesDataForChart: data3 }, () => {
                      this._isMounted && this.setState({ chartLoading: false });
                    });
                } else {
                  this._isMounted &&
                    this.setState({
                      chartLoading: false,
                      salesDataForChart: [0],
                    });
                }
              });
            }
          );
      });
  };

  tableHeaderAbstract = () => (
    <View style={styles.tableHeader}>
      {this.state.abstractColumns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this._isMounted && this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderSales = () => (
    <View style={styles.tableHeader}>
      {this.state.columnsSales.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  closeMROModalFun = () => {
    this._isMounted &&
      this.setState(
        {
          setVisibleMRO: false,
          setVisible: false,
          setVisibleExpense: false,
          setVisibleMROSubmit: false,
          setVisibleVST: false,
        },
        () => {
          setTimeout(() => {
            this.chartLoad();
          }, 1000);
        }
      );
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Dashboard" showBackArrow={false} />

          <View style={styles.mainConsole}>
            {this.state.showLeftBar ? <LeftBar dashboardBar="#c1c1c1" /> : null}
            <Portal>
              <Modal
                visible={this.state.setVisibleViewImage}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState({
                      setVisibleViewImage: false,
                      DownloadImageURL: "",
                    });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 800,
                  alignSelf: "center",
                }}
              >
                {this.state.DownloadImageURL != "empty" ? (
                  <ImageBackground
                    resizeMode="contain"
                    style={{ alignSelf: "center", height: 500, width: 375 }}
                    //   source={photo === "empty" ? require("../assets/email.png") : photo}
                    source={{ uri: this.state.DownloadImageURL }}
                  >
                    <Text
                      style={{ color: "#fff", fontSize: 18, fontWeight: "800" }}
                    >
                      DM {this.state.dmNo}
                    </Text>
                  </ImageBackground>
                ) : (
                  <ActivityIndicator size="large" color="#0a60ff" />
                )}
              </Modal>
            </Portal>
            <Portal>
              <Modal
                visible={this.state.setVisible}
                onDismiss={() => {
                  this._isMounted && this.setState({ setVisible: false });
                  this.chartLoad();
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 900,
                  alignSelf: "center",
                }}
              >
                <NewSales
                  closeMROModal={this.closeMROModalFun}
                  searchQuery=""
                  address=""
                  customerId=""
                  itemSearch=""
                  qty="" /////////////////////////
                  total=""
                  paymentMode=""
                  lorrySearch=""
                  isOurLorry=""
                  orderId=""
                  orderNo=""
                  itemId=""
                  itemUOM=""
                  editable={true}
                  lorryId=""
                />
              </Modal>
            </Portal>
            <Portal>
              <Modal
                visible={this.state.setVisibleExpense}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState({ setVisibleExpense: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 900,
                  alignSelf: "center",
                }}
              >
                <NewExpense closeMROModal={this.closeMROModalFun} />
              </Modal>
            </Portal>
            {/* Model Ends */}

            {/* Model starts  */}
            <Portal>
              <Modal
                visible={this.state.setVisibleMRO}
                onDismiss={() => {
                  this._isMounted && this.setState({ setVisibleMRO: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 740,
                  alignSelf: "center",
                }}
              >
                <MROUpdate closeMROModal={this.closeMROModalFun} />
              </Modal>
            </Portal>
            <Portal>
              <Modal
                visible={this.state.setVisibleVST}
                onDismiss={() => {
                  this._isMounted && this.setState({ setVisibleVST: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 680,
                  alignSelf: "center",
                }}
              >
                <VST closeMROModal={this.closeMROModalFun} />
              </Modal>
            </Portal>
            <Portal>
              <Modal
                visible={this.state.setVisibleMROSubmit}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState({ setVisibleMROSubmit: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 640,
                  alignSelf: "center",
                }}
              >
                <MROUpdateSubmit closeMROModal={this.closeMROModalFun} />
              </Modal>
            </Portal>
            {/* Model Ends */}
            {/* Model starts  */}
            <Portal>
              <Modal
                visible={this.state.setVisibleemplyAttendance}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState({ setVisibleemplyAttendance: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  width: 740,
                  alignSelf: "center",
                }}
              >
                <EmplyAttend />
              </Modal>
            </Portal>
            {/* Model Ends */}
            {/* Model starts  */}

            <Portal>
              <Modal
                visible={this.state.setVisibleTodaySales}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState(
                      {
                        //   States to reset on dismiss TODO:
                      },
                      () => {
                        this._isMounted &&
                          this.setState({ setVisibleTodaySales: false });
                      }
                    );
                }}
                contentContainerStyle={{
                  backgroundColor: "#ffffff",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                  borderWidth: 3,
                  alignSelf: "center",
                  maxHeight: 500,
                  minHeight: 300,
                }}
              >
                <View style={[styles.modelHeader, { flexDirection: "row" }]}>
                  <Text style={styles.modelHeaderText}>
                    Sales Abstract - Date:
                    {/* {new Date().toDateString()} */}
                  </Text>
                  <Text
                    style={[
                      styles.inputField,
                      {
                        backgroundColor: "#EAEAEA",
                        borderWidth: 1,
                        borderColor: "#c1c1c1",
                        borderRadius: 5,
                        margin: 14,
                        alignSelf: "center",
                        padding: 4,
                        width: 150,
                        textAlignVertical: "center",
                      },
                    ]}
                    onPress={() => {
                      this._isMounted &&
                        this.setState({
                          viewCalenderFrom: !this.state.viewCalenderFrom,
                        });
                    }}
                  >
                    {this.state.salesDate.toDateString()}
                  </Text>
                </View>
                <View
                  style={{
                    right: 20,
                    marginTop: -10,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderFrom ? (
                    <Calendar
                      value={this.state.salesDate}
                      onChange={(value) => {
                        this._isMounted &&
                          this.setState({ salesDate: value }, () => {
                            this.getSalesHistory();
                            this._isMounted &&
                              this.setState({ viewCalenderFrom: false });
                          });
                      }}
                    />
                  ) : null}
                </View>
                <View style={{ maxHeight: 400, overflow: "scroll" }}>
                  <FlatList
                    data={this.state.salesArray}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>
                            {index + 1}/{item.dmNo ? item.dmNo : "-"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.timeStamp
                              ? `${new Date(
                                  item.timeStamp
                                ).getHours()}:${new Date(
                                  item.timeStamp
                                ).getMinutes()}`
                              : "-"}
                          </Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.customer ? item.customer : "-"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.item ? item.item : "-"}/
                            {parseFloat(item.totalQtySales).toFixed(2)}
                          </Text>

                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.receivedAmount ? item.receivedAmount : "-"}
                          </Text>
                          {item.dmDownloadImageURL ? (
                            <TouchableOpacity
                              style={{
                                width: "16.66%",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    customerId: item.customerId,
                                    fiscalyearForDM: item.fiscalyearForDM,
                                    salesId: item.id,
                                    extensionType: item.extensionType
                                      ? item.extensionType
                                      : "jpg",
                                    dmNo: item.dmNo,
                                  },
                                  () => {
                                    this.setState({
                                      setVisibleViewImage: true,
                                      setVisibleTodaySales: false,
                                    });
                                    this.downloadURL();
                                  }
                                );
                              }}
                            >
                              <MaterialCommunityIcons
                                name="file-document-outline"
                                size={24}
                                color="black"
                                style={{ alignSelf: "center" }}
                              />
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity
                              style={{
                                width: "16.66%",
                              }}
                              // onPress={() => {
                              //   this.props.navigation.navigate("cameraScreen", {
                              //     customerId: item.customerId,
                              //     fiscalyearForDM: item.fiscalyearForDM,
                              //     salesId: item.id,
                              //     uoid: this.state.uoid,
                              //     dateStamp: item.timeStamp, //2
                              //     stringDate: item.date, //1
                              //   });
                              // }}
                            >
                              <Feather
                                name="camera-off"
                                size={24}
                                color="black"
                                style={{ alignSelf: "center" }}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                    ListHeaderComponent={this.tableHeaderSales}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        <Text>No Sales Today!</Text>
                      </View>
                    }
                  />
                </View>
              </Modal>
            </Portal>
            <Portal>
              <Modal
                visible={this.state.setVisibleAbstract}
                onDismiss={() => {
                  this._isMounted &&
                    this.setState(
                      {
                        //   States to reset on dismiss TODO:
                      },
                      () => {
                        this._isMounted &&
                          this.setState({ setVisibleAbstract: false });
                      }
                    );
                }}
                contentContainerStyle={{
                  backgroundColor: "#ffffff",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                  borderWidth: 3,
                  alignSelf: "center",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    {this.state.productName} Abstract - Date:
                    {new Date().toDateString()}
                  </Text>
                </View>
                <View style={styles.modelBody}>
                  <FlatList
                    data={this.state.historyDetails}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{item.time}</Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.particular}
                          </Text>
                          <Text style={styles.columnRowTxt}>{item.qtyAdd}</Text>
                          <Text style={styles.columnRowTxt}>
                            {item.qtySubtract}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.avaliableQty}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.issuedBy ? item.issuedBy : ""}
                          </Text>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                    ListHeaderComponent={this.tableHeaderAbstract}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        {this.state.isLoading ? (
                          <ActivityIndicator size="large" color="#0a60ff" />
                        ) : (
                          <Text>No Entries Found in Selected Date Range</Text>
                        )}
                      </View>
                    }
                  />
                </View>
              </Modal>
            </Portal>
            {this.state.loginUser.admin == false ? (
              <View style={styles.palyArea}>
                <View style={styles.overviewHolder}>
                  <TouchableOpacity
                    style={styles.overviewtile}
                    onPress={() => {
                      this._isMounted &&
                        this.setState({ setVisibleTodaySales: true });
                    }}
                  >
                    <Text style={styles.tileHeaderText}>
                      <Text style={styles.tileHeaderText}>
                        Total Quantity Sales today
                      </Text>
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={styles.tileDataText}>
                        {this.state.todaySales.toFixed(2)} cft
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                          textDecorationLine: "underline",
                          alignSelf: "flex-end",
                        }}
                      >
                        See More
                      </Text>
                    </View>
                  </TouchableOpacity>
                  {/*  */}
                  <TouchableOpacity
                    style={styles.overviewtile}
                    onPress={() => {
                      this._isMounted &&
                        this.setState({
                          setVisibleAbstract: true,
                          isLoading: true,
                        });
                      this.historyDetails();
                    }}
                  >
                    <Text style={styles.tileHeaderText}>
                      {this.state.productName} Abstract
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={styles.tileDataText}>
                        {this.state.dieselCount}
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                          textDecorationLine: "underline",
                          alignSelf: "flex-end",
                        }}
                      >
                        See More
                      </Text>
                    </View>
                  </TouchableOpacity>
                  {/* ---------------- */}
                  <View style={styles.overviewtile}>
                    <Text style={styles.tileHeaderText}>
                      Total Quantity Sales today
                    </Text>
                    <Text style={styles.tileDataText}>500</Text>
                  </View>
                  <View style={styles.overviewtile}>
                    <Text style={styles.tileHeaderText}>Date Time</Text>
                    <Text style={styles.tileDataText}>
                      {this.state.dateNTime}
                    </Text>
                  </View>
                </View>
                <View style={styles.quickActionHolder}>
                  <Text style={styles.subHeader}>Quick Actions</Text>

                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flex: 1,
                        borderRightWidth: 1,
                        borderRightColor: "#c1c1c1",
                        flexWrap: "wrap",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          { backgroundColor: "#009C44", width: 150 },
                        ]}
                        onPress={() => {
                          if (this.state.loginUser.canGenerateDM) {
                            this._isMounted &&
                              this.setState({ setVisible: true });
                          } else {
                            this.setState(
                              {
                                SnackbarVisible: true,
                                error: "Not allowed, Please contact admin",
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({ SnackbarVisible: false });
                                }, 5000);
                              }
                            );
                          }
                        }}
                      >
                        <Text style={styles.quickActionTile}>New Sales</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          { backgroundColor: "#de5246", width: 200 },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ setVisibleExpense: true });
                        }}
                      >
                        <Text style={styles.quickActionTile}>
                          Add Stock/Purchase
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderColor: "#0A60FF",
                            borderWidth: 1,
                            width: Dimensions.get("screen").width / 9,
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ setVisibleemplyAttendance: true });
                        }}
                      >
                        <Text
                          style={[styles.quickActionTile, { color: "#0a60ff" }]}
                        >
                          Emply Attendance
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderColor: "#0A60FF",
                            borderWidth: 1,
                            width: Dimensions.get("screen").width / 9,
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ setVisibleMRO: true });
                        }}
                      >
                        <Text
                          style={[
                            styles.quickActionTile,
                            { color: "#0a60ff", fontSize: 15 },
                          ]}
                        >
                          Invetory Issue
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderColor: "#0A60FF",
                            borderWidth: 1,
                            width: 150,
                            marginVertical: 10,
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ setVisibleMROSubmit: true });
                        }}
                      >
                        <Text
                          style={[styles.quickActionTile, { color: "#0a60ff" }]}
                        >
                          Submit
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderColor: "#0A60FF",
                            borderWidth: 1,
                            width: 150,
                            marginVertical: 10,
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ setVisibleVST: true });
                        }}
                      >
                        <Text
                          style={[styles.quickActionTile, { color: "#0a60ff" }]}
                        >
                          VST
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                {this.state.chartLoading ? (
                  <View
                    style={{
                      padding: 20,
                      width: Dimensions.get("screen").width / 3,
                      height: Dimensions.get("screen").width / 5,
                    }}
                  >
                    <ActivityIndicator color="#0a60ff" size="small" />
                  </View>
                ) : (
                  <View
                    style={{
                      padding: 20,
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginTop: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#c1c1c1",
                        borderRadius: 5,
                        width: Dimensions.get("window").width / 3 + 40,
                        paddingTop: 10,
                        paddingHorizontal: 10,
                      }}
                    >
                      <BarChart
                        data={{
                          labels: this.state.labels,

                          datasets: [
                            {
                              data: this.state.salesDataForChart,
                            },
                          ],
                        }}
                        yAxisSuffix="cft"
                        width={Dimensions.get("screen").width / 3}
                        height={Dimensions.get("screen").width / 5}
                        chartConfig={chartConfig}
                        verticalLabelRotation={0}
                        fromZero={true}
                        withHorizontalLabels={true}
                        withVerticalLabels={true}
                        withInnerLines={true}
                        style={{
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#c1c1c1",
                        borderRadius: 5,
                        width: Dimensions.get("window").width / 3 + 40,
                      }}
                    >
                      <Text
                        style={[
                          styles.subHeader,
                          { padding: 12, fontSize: 16 },
                        ]}
                      >
                        Notification
                      </Text>
                      <FlatList
                        data={this.state.expiryDocData}
                        renderItem={({ item }) => {
                          item.expiryDate > Date.parse(new Date());
                          return (
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                              }}
                            >
                              <View
                                style={{
                                  width: 10,
                                  height: 10,
                                  backgroundColor:
                                    item.expiryDate > Date.parse(new Date())
                                      ? "#eed202"
                                      : "#de5246",
                                  borderRadius: 5,
                                  margin: 6,
                                }}
                              />
                              <Text>
                                {item.name ? item.name : null}{" "}
                                {item.plateNumber
                                  ? item.plateNumber + ", "
                                  : null}{" "}
                                {item.documentTitle}{" "}
                                {item.expiryDate > Date.parse(new Date())
                                  ? "is about to expiry on"
                                  : "has expiry on"}{" "}
                                {`${new Date(item.expiryDate).getDate()}/${
                                  new Date(item.expiryDate).getMonth() + 1
                                }/${new Date(item.expiryDate).getFullYear()}`}
                              </Text>
                            </View>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                        ListEmptyComponent={
                          <View
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 50,
                            }}
                          >
                            {this.state.isAlertsLoading ? (
                              <ActivityIndicator size="small" color="#0a60ff" />
                            ) : (
                              <Text>All Good!, No Alerts</Text>
                            )}
                          </View>
                        }
                      />
                    </View>
                  </View>
                )}
              </View>
            ) : this.state.loginUser.admin == true ? (
              <View style={[styles.palyArea, { flexDirection: "row" }]}>
                <View style={{ flex: 4, flexDirection: "column", padding: 16 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                      padding: 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <BarChart
                      data={{
                        labels: this.state.labels,

                        datasets: [
                          {
                            data: this.state.salesDataForChart,
                          },
                        ],
                      }}
                      yAxisSuffix="cft"
                      width={Dimensions.get("screen").width / 3}
                      height={Dimensions.get("screen").width / 6}
                      chartConfig={chartConfig}
                      verticalLabelRotation={0}
                      fromZero={true}
                      withHorizontalLabels={true}
                      withVerticalLabels={true}
                      withInnerLines={true}
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                      }}
                    />
                    <View
                      style={{
                        // flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "100%", //HERE
                        justifyContent: "space-around",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderLeftWidth: 4,
                            borderLeftColor:
                              colorArray[Math.floor(Math.random() * 4)],
                          },
                        ]}
                        onPress={() => {
                          this._isMounted &&
                            this.setState(
                              {
                                setVisibleTodaySales: true,
                                salesDate: new Date(),
                              },
                              () => {
                                this.getSalesHistory();
                              }
                            );
                        }}
                      >
                        <Text style={styles.tileHeaderText}>
                          Quantity Sales today
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={styles.tileDataText}>
                            {this.state.todaySales.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                            cft
                          </Text>
                          <Text
                            style={{
                              fontSize: 11,
                              textDecorationLine: "underline",
                              alignSelf: "flex-end",
                            }}
                          >
                            See More
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <View
                        style={[
                          styles.overviewtile,
                          {
                            borderLeftWidth: 4,
                            borderLeftColor:
                              colorArray[Math.floor(Math.random() * 4)],
                          },
                        ]}
                      >
                        <Text style={styles.tileHeaderText}>
                          Cash Sales today
                        </Text>
                        <Text style={styles.tileDataText}>
                          ₹ {this.state.todaySalesAmount}
                        </Text>
                      </View>
                      <TouchableOpacity
                        style={[
                          styles.overviewtile,
                          {
                            borderLeftWidth: 4,
                            borderLeftColor:
                              colorArray[Math.floor(Math.random() * 4)],
                          },
                        ]}
                        onPress={() => {
                          this.props.navigation.navigate("cashBookScreen");
                        }}
                      >
                        <Text style={styles.tileHeaderText}>Cash at Site</Text>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* <Text style={styles.tileDataText}>
                            ₹ {this.state.lastEntry.balance}
                          </Text> */}
                          <Text
                            style={{
                              fontSize: 11,
                              textDecorationLine: "underline",
                              alignSelf: "flex-end",
                            }}
                          >
                            See More
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <View style={{ flex: 3 }}>
                      <Text style={[styles.subHeader, { padding: 0 }]}>
                        Quick View
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 10,
                          flexWrap: "wrap",

                          justifyContent: "space-evenly",
                        }}
                      >
                        <TouchableOpacity
                          style={[
                            styles.overviewtile,
                            {
                              marginVertical: 10,
                              borderLeftWidth: 4,
                              borderLeftColor: "#009C44",
                              width: Dimensions.get("screen").width / 8,
                            },
                          ]}
                          onPress={() => {
                            this._isMounted &&
                              this.setState({
                                setVisibleemplyAttendance: true,
                              });
                          }}
                        >
                          <Text style={styles.tileHeaderText}>
                            Employee Overview
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.tileDataText}>
                              {this.state.employeeCount}
                            </Text>
                            <Text
                              style={{
                                fontSize: 11,
                                textDecorationLine: "underline",
                                alignSelf: "flex-end",
                              }}
                            >
                              See More
                            </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.overviewtile,
                            {
                              marginVertical: 10,
                              borderLeftWidth: 4,
                              borderLeftColor: "#009C44",
                              width: Dimensions.get("screen").width / 8,
                            },
                          ]}
                          onPress={() => {
                            this._isMounted &&
                              this.setState({
                                setVisibleAbstract: true,
                                isLoading: true,
                              });
                            this.historyDetails();
                          }}
                        >
                          <Text style={styles.tileHeaderText}>
                            {this.state.productName} Abstract
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.tileDataText}>
                              {this.state.dieselCount}
                            </Text>
                            <Text
                              style={{
                                fontSize: 11,
                                textDecorationLine: "underline",
                                alignSelf: "flex-end",
                              }}
                            >
                              See More
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <Text style={[styles.subHeader, { padding: 0 }]}>
                        Accounts Overview
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 10,
                          flexWrap: "wrap",

                          justifyContent: "space-evenly",
                        }}
                      >
                        <TouchableOpacity
                          style={[
                            styles.overviewtile,
                            {
                              marginVertical: 10,
                              borderLeftWidth: 4,
                              borderLeftColor: "#de5246",
                              width: Dimensions.get("screen").width / 8,
                            },
                          ]}
                        >
                          <Text style={styles.tileHeaderText}>
                            Accounts Payable
                          </Text>
                          <Text style={styles.tileDataText}>₹ N/a</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                          style={[
                            styles.overviewtile,
                            {
                              marginVertical: 10,
                              borderLeftWidth: 4,
                              borderLeftColor: "#009C44",
                              width: Dimensions.get("screen").width / 8,
                            },
                          ]}
                          onPress={() => {
                            //nav to Site Cash Book
                          }}
                        >
                          <Text style={styles.tileHeaderText}>
                            Accounts Receivable
                          </Text>
                          <Text style={styles.tileDataText}>₹ N/a</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1.5,
                        borderLeftWidth: 1,
                        borderLeftColor: "#c1c1c1",
                      }}
                    >
                      <Text style={[styles.subHeader, { paddingVertical: 5 }]}>
                        Quick Actions
                      </Text>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          // height: "90%",
                          flexWrap: "wrap",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                backgroundColor: "#009C44",
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            onPress={() => {
                              if (this.state.loginUser.canGenerateDM) {
                                this._isMounted &&
                                  this.setState({ setVisible: true });
                              } else {
                                this.setState(
                                  {
                                    SnackbarVisible: true,
                                    error: "Not allowed, Please contact admin",
                                  },
                                  () => {
                                    setTimeout(() => {
                                      this.setState({ SnackbarVisible: false });
                                    }, 5000);
                                  }
                                );
                              }
                            }}
                          >
                            <Text style={styles.quickActionTile}>
                              New Sales
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                backgroundColor: "#de5246",
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            onPress={() => {
                              this._isMounted &&
                                this.setState({ setVisibleExpense: true });
                            }}
                          >
                            <Text style={styles.quickActionTile}>Expenses</Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                borderColor: "#0A60FF",
                                borderWidth: 1,
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            onPress={() => {
                              this._isMounted &&
                                this.setState({ setVisibleMRO: true });
                            }}
                          >
                            <Text
                              style={[
                                styles.quickActionTile,
                                { color: "#0a60ff" },
                              ]}
                            >
                              Inventory Issue
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                borderColor: "#0A60FF",
                                borderWidth: 1,
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            onPress={() => {
                              this._isMounted &&
                                this.setState({ setVisibleMROSubmit: true });
                            }}
                          >
                            <Text
                              style={[
                                styles.quickActionTile,
                                { color: "#0a60ff" },
                              ]}
                            >
                              Submit
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                borderColor: "#0A60FF",
                                borderWidth: 1,
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            onPress={() => {
                              this._isMounted &&
                                this.setState({ setVisibleVST: true });
                            }}
                          >
                            <Text
                              style={[
                                styles.quickActionTile,
                                { color: "#0a60ff" },
                              ]}
                            >
                              VST
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              styles.overviewtile,
                              {
                                borderColor: "#0A60FF",
                                borderWidth: 1,
                                width: 150,
                                marginVertical: 10,
                              },
                            ]}
                            // onPress={() => {
                            //   this._isMounted &&
                            //     this.setState({ setVisibleMROSubmit: true });
                            // }}
                          >
                            <Text
                              style={[
                                styles.quickActionTile,
                                { color: "#0a60ff" },
                              ]}
                            >
                              CheckList{" "}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    flex: 1,
                    borderLeftWidth: 1,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      borderBottomWidth: 1,
                      borderColor: "#c1c1c1",
                      flexDirection: "column",
                    }}
                  >
                    <View
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                      }}
                    >
                      <Text
                        style={[
                          styles.subHeader,
                          { padding: 12, fontSize: 16 },
                        ]}
                      >
                        Alerts
                      </Text>
                    </View>
                    <FlatList
                      data={this.state.expiryDocData}
                      renderItem={({ item }) => {
                        item.expiryDate > Date.parse(new Date());
                        return (
                          <TouchableOpacity
                            style={{
                              padding: 10,
                              flexDirection: "row",
                            }}
                            onPress={() => {
                              item.plateNumber
                                ? this.props.navigation.navigate(
                                    "fleetViewScreen",
                                    {
                                      id: item.vehicelId,
                                    }
                                  )
                                : this.props.navigation.navigate(
                                    "companyDetails"
                                  );
                            }}
                          >
                            <View
                              style={{
                                width: 10,
                                height: 10,
                                backgroundColor:
                                  item.expiryDate > Date.parse(new Date())
                                    ? "#eed202"
                                    : "#de5246",
                                borderRadius: 5,
                                margin: 6,
                              }}
                            />
                            <Text>
                              {item.name ? item.name : null}{" "}
                              {item.plateNumber
                                ? item.plateNumber + ", "
                                : null}{" "}
                              {item.documentTitle}{" "}
                              {item.expiryDate > Date.parse(new Date())
                                ? "is about to expiry on"
                                : "has expiry on"}{" "}
                              {`${new Date(item.expiryDate).getDate()}/${
                                new Date(item.expiryDate).getMonth() + 1
                              }/${new Date(item.expiryDate).getFullYear()}`}
                            </Text>
                          </TouchableOpacity>
                        );
                      }}
                      keyExtractor={(item) => JSON.stringify(item.id)}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isAlertsLoading ? (
                            <ActivityIndicator size="small" color="#0a60ff" />
                          ) : (
                            <Text>All Good!, No Alerts</Text>
                          )}
                        </View>
                      }
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <View
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                      }}
                    >
                      <Text
                        style={[
                          styles.subHeader,
                          { padding: 12, fontSize: 16 },
                        ]}
                      >
                        Notification
                      </Text>
                    </View>
                    <FlatList
                      data={this.state.notificationArray}
                      renderItem={({ item }) => {
                        item.expiryDate > Date.parse(new Date());
                        return (
                          <View
                            style={{
                              padding: 10,
                              flexDirection: "row",
                            }}
                          >
                            <View
                              style={{
                                width: 10,
                                height: 10,
                                backgroundColor: "#0a60ff",
                                borderRadius: 5,
                                margin: 6,
                              }}
                            />
                            <View style={{ width: "80%" }}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Text style={{ fontWeight: "500" }}>
                                  {item.title}
                                </Text>
                                <TouchableOpacity
                                  onPress={() => {
                                    let dbref = firebase
                                      .database()
                                      .ref(
                                        `${this.state.uoid}/erpData/organizationData/notifications/${item.id}`
                                      );

                                    dbref.update({
                                      dismiss: true,
                                    });
                                  }}
                                >
                                  <AntDesign
                                    name="close"
                                    size={20}
                                    color="black"
                                  />
                                </TouchableOpacity>
                              </View>

                              <Text>{item.notice}</Text>
                            </View>
                          </View>
                        );
                      }}
                      keyExtractor={(item) => JSON.stringify(item.id)}
                      ListEmptyComponent={
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 50,
                          }}
                        >
                          {this.state.isNotificationLoading ? (
                            <ActivityIndicator size="small" color="#0a60ff" />
                          ) : (
                            <Text>No Notifications</Text>
                          )}
                        </View>
                      }
                    />
                  </View>
                </View>
              </View>
            ) : (
              <View
                style={[
                  styles.palyArea,
                  { justifyContent: "center", alignItems: "center" },
                ]}
              >
                <ActivityIndicator color="#0a60ff" size="large" />
              </View>
            )}
            <Snackbar
              visible={this.state.SnackbarVisible}
              onDismiss={() => {
                this.setState({ SnackbarVisible: false });
              }}
              action={{
                label: "OK",
                onPress: () => {
                  this.setState({ SnackbarVisible: false });
                },
              }}
            >
              {this.state.error}
            </Snackbar>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    width: Dimensions.get("screen").width * 0.85,
  },
  overviewHolder: {
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: 10,
  },
  overviewtile: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    alignSelf: "center",
    padding: 16,
    width: Dimensions.get("screen").width / 6,
    borderRadius: 5,
  },
  tileHeaderText: {
    fontSize: 13,
    fontWeight: "normal",
  },
  tileDataText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  quickActionHolder: {
    marginTop: 20,
    flexWrap: "wrap",
  },
  quickActionTile: {
    fontSize: 17,
    fontWeight: "normal",
    alignSelf: "center",
    color: "#fff",
  },
  subHeader: {
    padding: 20,
    fontSize: 20,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.6%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "16.6%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
});
