import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
  Picker,
} from "react-native";
import {
  ActivityIndicator,
  TextInput,
  Chip,
  Checkbox,
  Snackbar,
} from "react-native-paper";
import { jsPDF } from "jspdf";
import { useNavigation } from "@react-navigation/native";
// import { Picker } from "@react-native-picker/picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
class NewSales extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      date: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      dateString: new Date(),
      searchData: [],
      searchDataLoading: true,
      mainData: [],
      searchQuery: "",
      address: "",
      deliveryAddress: "",
      customerId: "",
      //
      itemSearch: "",
      itemId: "",
      viewDropDownItem: false,
      searchDataItem: [],
      mainDataItem: [],
      royaltyNo: "",
      isOurLorry: "",
      //
      viewDropDown: false,
      //
      viewDropDownLorry: false,
      searchDataLorry: [],
      lorrySearch: "",
      mainDataLorry: [],
      sellQtyCft: "",
      //
      viewDropDownDriver: false,
      searchDataDriver: [],
      mainDataDriver: [],
      driverSearch: "",
      //
      paymentMode: "",
      total: "",
      received: "",
      checked: false,
      //
      dmCount: "",
      nrdmCount: "",
      loginUser: {},
      orderId: "",
      buttonLoading: false,
      SnackbarVisible: false,

      //
      currentdayTotalSales: 0,

      makeCashbookEntry: true,
      error: "",
      itemUOM: "",
      uoid: "",
      fiscalyear: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
      islastBalanceAvaliable: false,
      note: "",
      transportIncluded: false,
      fiscalyearForDMSave: "",
      transactionId: "",
      orderNo: "",
      lorryId: "",
      editable: false,
      kmReading: "",
      rst: "",
      netWeight: "",
      errorColor: "#DE5246",
      isPOdispatch: false,
      selectedPOName: "",
      selectedPOData: {},
      poList: [],
      selectedPOItemList: [],
      selectedItemFromPO: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState(
              {
                uoid,
                searchQuery: this.props.searchQuery,
                customerId: this.props.customerId,
                itemSearch: this.props.itemSearch,
                sellQtyCft: this.props.qty,
                total: this.props.total,
                address: this.props.address,
                deliveryAddress: this.props.address,
                orderId: this.props.orderId,
                itemId: this.props.itemId,
                searchQuery: this.props.searchQuery,
                customerId: this.props.customerId,
                itemSearch: this.props.itemSearch,
                sellQtyCft: this.props.qty,
                total: this.props.total,
                address: this.props.address,
                deliveryAddress: this.props.address,
                orderId: this.props.orderId,
                itemId: this.props.itemId,
                paymentMode: this.props.paymentMode,
                lorrySearch: this.props.lorrySearch,
                isOurLorry: this.props.isOurLorry,
                itemUOM: this.props.itemUOM,
                orderNo: this.props.orderNo,
                editable: this.props.editable,
                lorryId: this.props.lorryId,
              },
              () => {
                if (this.state.isOurLorry) {
                  this.getDriverData();
                }
                if (this.state.editable) {
                  this._isMounted && this.getCustomerData(); //opt
                  this._isMounted && this.getLorryData(); //opt
                  this._isMounted && this.getItemData(); //opt
                }
                this._isMounted && this.getLoginUser(); //1
                this._isMounted && this.getDmCount(); //1
                this._isMounted && this.getCompanyData(); //1
                this._isMounted && this.getFYforDM(); //1
              }
            );
        }
      });
    } catch (e) {
      // error reading value
    }

    this._isMounted && this.setState({});
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getFYforDM = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear =
        "FY" +
        (today.getFullYear() - 1) +
        "_" +
        today.getFullYear().toString().substring(2);
    } else {
      fiscalyear =
        "FY" +
        today.getFullYear() +
        "_" +
        (today.getFullYear() + 1).toString().substring(2);
    }

    this.setState({ fiscalyearForDMSave: fiscalyear });
  };
  makeEntryInCustomerLEdger = () => {
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    // let ledgerTime = new Date();
    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/ledger/${this.state.customerId}/${this.state.dateString}`
      );
    ledgerRef.set({
      ledgerId: ledgerId,
      transDate: this.state.dateString.toDateString(),
      dateNumber: Date.parse(this.state.dateString),
      discription: `Received for DM-${
        this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
      }`,
      credit: this.state.received,
      isEditable: false,
      timeStampId: `${this.state.dateString}`,
      isCashEntry: true,
      // balance: parseFloat(
      //   parseFloat(this.state.lastEntry.balance) +
      //     parseFloat(this.state.debitAmt) -
      //     parseFloat(this.state.creditAmt)
      // ).toFixed(2),
    });
  };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this._isMounted && this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  getUOM = () => {
    let newarray = this.state.searchData.filter((item) => {
      if (item.id == this.state.customerId) {
        return item;
      }
    });
    let productRate = newarray[0] ? newarray[0].productRate : [];

    let selectedItemUOM = productRate
      ? Object.values(productRate).filter((item) => {
          if (item.productName == this.state.itemSearch) {
            return item;
          }
        })
      : [];

    this._isMounted &&
      this.setState({
        itemUOM: selectedItemUOM.length > 0 ? selectedItemUOM[0].uom : "cft",
      });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    var transactionId = this.randomString(
      22,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );

    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear =
        "FY" +
        (today.getFullYear() - 1) +
        "_" +
        today.getFullYear().toString().substring(2);
    } else {
      fiscalyear =
        "FY" +
        today.getFullYear() +
        "_" +
        (today.getFullYear() + 1).toString().substring(2);
    }

    this.setState({ fiscalyearForDMSave: fiscalyear, transactionId }, () => {
      var rString = this.randomString(
        30,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMSave}/${rString}`
        );
      if (
        this.state.searchQuery != "" &&
        this.state.address != "" &&
        this.state.deliveryAddress != "" &&
        this.state.itemSearch != "" &&
        this.state.sellQtyCft != "" &&
        this.state.lorrySearch != "" &&
        this.state.paymentMode != "" &&
        this.state.checked != false &&
        this.state.itemId != "" &&
        this.state.customerId != ""
      ) {
        this._isMounted && this.setState({ buttonLoading: true });
        dbRef
          .set({
            id: rString,

            dmNo: this.state.royaltyNo
              ? this.state.dmCount
              : this.state.nrdmCount,
            date: this.state.date,
            time: this.state.time,
            deliveryAddress: this.state.deliveryAddress,
            item: this.state.itemSearch,
            sellQtyCft: this.state.sellQtyCft,
            royaltyNo: this.state.royaltyNo,
            lorryNo: this.state.lorrySearch,
            driverName: this.state.driverSearch,
            paymentMode: this.state.paymentMode, //
            totalAmount: this.state.total,
            receivedAmount: this.state.received,
            addedby: this.state.loginUser.name,
            checkedByAuthPerson: false,
            isMarkforLedgerGen: false,
            isLedgerEntryDone: false,
            balanceAmount:
              this.state.paymentMode == "Credit"
                ? "-"
                : this.state.total - this.state.received,
            isMarkforBillGen: false,
            isBillGenertd: false,
            dateString: this.state.dateString.toDateString(),
            dateStamp: Date.parse(this.state.dateString),
            itemUOM: this.state.itemUOM,
            note: this.state.note,
            transportIncluded: this.state.transportIncluded,
            orderNo: this.state.orderNo,

            rst: this.state.rst,
            netWeight: this.state.netWeight,
            kmReading: this.state.kmReading,
          })
          .then(() => {
            let DMRef = firebase
              .database()
              .ref(
                `${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`
              );
            {
              this.state.royaltyNo
                ? DMRef.update({
                    dmCount: this.state.dmCount,
                  })
                : DMRef.update({
                    nrdmCount: this.state.nrdmCount,
                  });
            }
            this.dmPdfDownload();
            this.getunCheckedDm();
            this.updateProductInDB();
            this.props.closeMROModal();
            if (
              this.state.paymentMode == "Cash" &&
              this.state.makeCashbookEntry &&
              parseFloat(this.state.received) != 0
            ) {
              this.makeEntryInCustomerLEdger();
              if (this.state.loginUser.isSiteLogin == true) {
                this.updateOrgCashBook();
                this.updateAdminCashBook();
              } else if (this.state.loginUser.isSiteLogin == false) {
                this.updateAdminCashBook();
              }
            }

            if (this.state.orderId !== "") {
              let orderRef = firebase
                .database()
                .ref(
                  `${this.state.uoid}/factoryData/ordersData/ordersbasics/${this.state.orderId}`
                );
              orderRef.update({
                status: `Dispatched D:${
                  this.state.royaltyNo
                    ? this.state.dmCount
                    : this.state.nrdmCount
                }`,
                dispatched: true,
                dispatchedBy: this.state.loginUser.name,
                dispatchAt: `${new Date()}`,
              });
            }
          });
      }
    });
  };

  updateOrgCashBook = () => {
    if (this.state.received != 0) {
      this._isMounted && this.setState({ buttonLoading: true });

      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${this.state.dateString}`
        );
      ledgerRef
        .set({
          ledgerId: this.state.transactionId,
          dateNumber: Date.parse(this.state.dateString),
          dateStamp: Date.parse(this.state.dateString.toDateString()),
          transDate: this.state.dateString.toDateString(),
          discription: `${this.state.searchQuery}-${
            this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
          }`,
          credit: this.state.received,
          debit: 0,
        })
        .then(() => {
          this._isMounted &&
            this.setState({
              error: "Successfully !",
            });
          setTimeout(() => {
            this._isMounted && this.setState({ SnackbarVisible: false });
          }, 2000);
        })
        .catch((error) => {
          this._isMounted && this.setState({ error: error.message });
        });
    }
  };
  updateAdminCashBook = () => {
    if (this.state.otherAmount != 0) {
      this._isMounted && this.setState({ buttonLoading: true });

      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${this.state.dateString}`
        );
      ledgerRef
        .set({
          transactionId: this.state.transactionId,
          dateStamp: Date.parse(this.state.dateString.toDateString()),
          timestamp: Date.parse(this.state.dateString),
          date: `${this.state.dateString.getFullYear()}-${this.state.dateString.getMonth()}-${this.state.dateString.getDate()}`,
          discription: `${this.state.searchQuery}-${
            this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
          }`,
          cashIn: parseFloat(this.state.received),
          cashOut: 0,
          entryBy: this.state.loginUser.name,
        })
        .then(() => {
          this._isMounted &&
            this.setState({
              SnackbarVisible: true,

              error: "Successfully !",
            });
          setTimeout(() => {
            this._isMounted && this.setState({ SnackbarVisible: false });
          }, 2000);
        })
        .catch((error) => {
          this._isMounted && this.setState({ error: error.message });
        });
    }
  };
  currentdayAvaliableQty = () => {
    let dbref1 = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productHistoryOverView/${
          this.state.itemId
        }/${Date.parse(new Date().toDateString())}`
      );

    dbref1.on("value", (datasnapshot) => {
      if (datasnapshot.val()) {
        let data = datasnapshot.val();
        this._isMounted &&
          this.setState({
            currentdayTotalSales: data.totalQtySales,
          });
      }
    });
  };
  updateProductInDB = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/productData/productHistoryOverView/${
          this.state.itemId
        }/${Date.parse(new Date().toDateString())}`
      );
    dbref
      .update({
        dateNumber: Date.parse(this.state.dateString.toDateString()),
        date: this.state.dateString.toDateString(),
        totalQtySales:
          (this.state.itemUOM == "m³"
            ? parseFloat(this.state.sellQtyCft) * 35.315
            : this.state.itemUOM == "MT"
            ? parseFloat(this.state.sellQtyCft) * 23.24
            : this.state.itemUOM == "Brass"
            ? parseFloat(this.state.sellQtyCft) * 100
            : parseFloat(this.state.sellQtyCft)) +
          parseFloat(this.state.currentdayTotalSales),
      })
      .then(() => {
        let dbref = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/productData/productHistoryDetails/${
              this.state.itemId
            }/${Date.parse(new Date().toDateString())}/${Date.parse(
              new Date()
            )}`
          );

        dbref.set({
          timeStamp: Date.parse(this.state.dateString),
          time: `${this.state.dateString.getHours()}:${this.state.dateString.getMinutes()}:${this.state.dateString.getSeconds()}`,
          particular: this.state.itemSearch,
          qtySales:
            this.state.itemUOM == "m³"
              ? parseFloat(this.state.sellQtyCft) * 35.315
              : this.state.itemUOM == "MT"
              ? parseFloat(this.state.sellQtyCft) * 23.24
              : this.state.itemUOM == "Brass"
              ? parseFloat(this.state.sellQtyCft) * 100
              : parseFloat(this.state.sellQtyCft),
          issuedBy: this.state.loginUser.name,
          dmNo: this.state.royaltyNo
            ? this.state.dmCount
            : this.state.nrdmCount,
        });
      })
      .then(() => {
        let dbref = firebase
          .database()
          .ref(
            `${
              this.state.uoid
            }/factoryData/productData/allSalesData/${Date.parse(
              new Date().toDateString()
            )}/${Date.parse(this.state.dateString)}`
          );

        dbref.set({
          customer: this.state.searchQuery,
          paymentMode: this.state.paymentMode,
          receivedAmount: this.state.received,
          timeStamp: Date.parse(this.state.dateString),
          date: this.state.dateString.toDateString(),
          item: this.state.itemSearch,
          totalQtySales:
            this.state.itemUOM == "m³"
              ? parseFloat(this.state.sellQtyCft) * 35.315
              : this.state.itemUOM == "MT"
              ? parseFloat(this.state.sellQtyCft) * 23.24
              : this.state.itemUOM == "Brass"
              ? parseFloat(this.state.sellQtyCft) * 100
              : parseFloat(this.state.sellQtyCft),
          dmNo: this.state.royaltyNo
            ? this.state.dmCount
            : this.state.nrdmCount,
        });
      });
  };
  getunCheckedDm = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMSave}`
      )
      .orderByChild("checkedByAuthPerson")
      .equalTo(false);
    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        let finalData = data.filter((item) => item.isactive != false).length;
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
          );
        dbref1
          .update({
            unCheckedDM: finalData,
          })
          .catch(() => {
            // this._isMounted && this.setState({ buttonLoading: false });
          });
      } else {
        let dbref1 = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
          );
        dbref1
          .update({
            unCheckedDM: 0,
          })
          .catch(() => {
            // this._isMounted && this.setState({ buttonLoading: false });
          });
      }
    });
  };
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this._isMounted && this.setState({ companyData: data });
      }
    });
  };
  getPoDetails = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/purchaseOrder/${this.state.customerId}/${this.state.fiscalyearForDMSave}`
      );
    dbref.on("value", (SnapShot) => {
      if (SnapShot.exists()) {
        let data = Object.values(SnapShot.val());
        this._isMounted && this.setState({ poList: data });
      } else {
        this._isMounted && this.setState({ poList: [] });
      }
    });
  };

  renderPOList = () => {
    return this.state.poList.map((item) => {
      return (
        <Picker.Item
          label={item.poNumber}
          value={JSON.stringify(item)}
          key={item.poNumber}
        />
      );
    });
  };

  dmPdfDownload = () => {
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.rect(5, 42, 135, 135);

    doc.setFont("times", "italic");
    doc.setFontSize(20);
    doc.text(
      74.25,
      12,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text(
      74.25,
      18,
      `Plant Address:- ${this.state.companyData.addressOne}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(
      74.25,
      24,
      `Office:- ${this.state.companyData.addressTwo}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(74.25, 30, "(Registered Under Small Scale Industries)", "center");
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(
      5,
      38,
      `Mobile No.: ${
        this.state.companyData.contactNo != ""
          ? this.state.companyData.contactNo
          : this.state.uoid
      }`
    );
    doc.text(
      138,
      38,
      `D.M. no.: ${
        this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
      }`,
      "right"
    );
    doc.setFont("times");
    doc.setFontSize(14);

    doc.rect(5, 50, 135, 0, "F");
    doc.rect(5, 82, 135, 0, "F");
    doc.rect(5, 89, 135, 0, "F");
    doc.rect(20, 82, 0, 95, "F");
    doc.rect(103, 82, 0, 95, "F");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(50, 48, `DELIVERY CHALLAN`);

    doc.setFontSize(12);
    doc.text(7, 58, `Date`);
    doc.setFontSize(12);
    doc.text(28, 58, `: ${this.state.date}`);
    doc.text(78, 58, `Time`);
    doc.text(100, 58, `: ${this.state.time}`);

    doc.setFontSize(12);
    doc.text(7, 65, `Vehicle No.`);
    doc.text(28, 65, `: ${this.state.lorrySearch}`);
    doc.text(78, 65, `Royalty No.`);
    doc.text(100, 65, doc.splitTextToSize(`${this.state.royaltyNo}`, 40));

    doc.setFontSize(13);
    doc.setFont("times", "bold");
    doc.text(7, 72, `Name`);
    doc.text(28, 72, `: ${this.state.searchQuery}`);
    doc.text(78, 79, `P.O.No.`);
    doc.text(100, 79, `: ${this.state.note}`);

    doc.text(7, 79, `Address`);
    doc.text(28, 79, `: ${this.state.address}`);
    doc.setFont("times", "normal");

    doc.text(7, 87, "Sr.No.");
    doc.text(45, 87, "Particulars");
    doc.text(115, 87, "Quantity");

    doc.text(11, 98, "1");
    doc.text(24, 98, `${this.state.itemSearch} Metal`);
    doc.text(
      131,
      98,
      `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
      "right"
    );

    doc.text(7, 195, `Receiver's Signature`);
    doc.text(
      140,
      195,
      `For, ${this.state.companyData.organizationName}`,
      "right"
    );

    doc.rect(153, 42, 135, 135);

    doc.setFont("times", "italic");
    doc.setFontSize(20);
    doc.text(
      222.75,
      12,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text(
      222.75,
      18,
      `Plant Address:- ${this.state.companyData.addressOne}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(
      222.75,
      24,
      `Office:- ${this.state.companyData.addressTwo}`,
      "center"
    );
    doc.setFontSize(12);
    doc.text(222.75, 30, "(Registered Under Small Scale Industries)", "center");
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(
      153,
      38,
      `Mobile No.: ${
        this.state.companyData.contactNo != ""
          ? this.state.companyData.contactNo
          : this.state.uoid
      }`
    );
    doc.text(
      287,
      38,
      `D.M. no.: ${
        this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
      }`,
      "right"
    );
    doc.setFont("times");
    doc.setFontSize(14);

    doc.rect(153, 50, 135, 0, "F");
    doc.rect(153, 82, 135, 0, "F");
    doc.rect(153, 89, 135, 0, "F");
    doc.rect(169, 82, 0, 95, "F");
    doc.rect(250, 82, 0, 95, "F");

    doc.setFontSize(12);
    doc.text(155, 58, `Date`);
    doc.setFontSize(12);
    doc.text(176, 58, `:  ${this.state.date}`);
    doc.text(226, 58, `Time`);
    doc.text(248, 58, `:  ${this.state.time}`);

    doc.setFontSize(12);
    doc.text(155, 65, `Vehicle No.`);
    doc.text(176, 65, `: ${this.state.lorrySearch}`);
    doc.text(226, 65, `Royalty No.`);
    doc.text(248, 65, doc.splitTextToSize(`${this.state.royaltyNo}`, 40));

    doc.setFontSize(13);
    doc.setFont("times", "bold");
    doc.text(155, 72, `Name`);
    doc.text(176, 72, `: ${this.state.searchQuery}`);
    doc.text(226, 79, `P.O.No.`);
    doc.text(248, 79, `: ${this.state.note}`);

    doc.text(155, 79, `Address`);
    doc.text(176, 79, `: ${this.state.address}`);
    doc.setFont("times", "normal");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(198, 48, `DELIVERY CHALLAN`);

    doc.text(155, 87, "Sr.No.");
    doc.text(193, 87, "Particulars");
    doc.text(263, 87, "Quantity");

    doc.text(159, 98, "1");
    doc.text(174, 98, `${this.state.itemSearch} Metal`);
    doc.text(
      279,
      98,
      `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
      "right"
    );

    doc.text(155, 195, `Receiver's Signature`);
    doc.text(
      287,
      195,
      `For, ${this.state.companyData.organizationName}`,
      "right"
    );

    doc.rect(147, 0, 0, 3, "F");
    doc.rect(147, 6, 0, 3, "F");
    doc.rect(147, 12, 0, 3, "F");
    doc.rect(147, 18, 0, 3, "F");
    doc.rect(147, 24, 0, 3, "F");
    doc.rect(147, 30, 0, 3, "F");
    doc.rect(147, 36, 0, 3, "F");
    doc.rect(147, 42, 0, 3, "F");
    doc.rect(147, 48, 0, 3, "F");
    doc.rect(147, 54, 0, 3, "F");
    doc.rect(147, 60, 0, 3, "F");
    doc.rect(147, 66, 0, 3, "F");
    doc.rect(147, 72, 0, 3, "F");
    doc.rect(147, 78, 0, 3, "F");
    doc.rect(147, 84, 0, 3, "F");
    doc.rect(147, 90, 0, 3, "F");
    doc.rect(147, 96, 0, 3, "F");
    doc.rect(147, 102, 0, 3, "F");
    doc.rect(147, 108, 0, 3, "F");
    doc.rect(147, 114, 0, 3, "F");
    doc.rect(147, 120, 0, 3, "F");
    doc.rect(147, 126, 0, 3, "F");
    doc.rect(147, 132, 0, 3, "F");
    doc.rect(147, 138, 0, 3, "F");
    doc.rect(147, 144, 0, 3, "F");
    doc.rect(147, 150, 0, 3, "F");
    doc.rect(147, 156, 0, 3, "F");
    doc.rect(147, 162, 0, 3, "F");
    doc.rect(147, 168, 0, 3, "F");
    doc.rect(147, 174, 0, 3, "F");
    doc.rect(147, 180, 0, 3, "F");
    doc.rect(147, 186, 0, 3, "F");
    doc.rect(147, 192, 0, 3, "F");
    doc.rect(147, 198, 0, 3, "F");
    doc.rect(147, 204, 0, 3, "F");
    doc.rect(147, 210, 0, 3, "F");
    doc.rect(147, 216, 0, 3, "F");

    if (this.state.paymentMode == "Cash") {
      doc.text(174, 134, "Total");
      doc.text(
        279,
        134,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.text(174, 143, "Cash Payment Details");
      doc.text(263, 143, "Amount");
      doc.text(174, 152, "Total Amount");
      doc.text(279, 152, `${parseFloat(this.state.total).toFixed(2)}`, "right");
      doc.text(174, 158, "Received Amount");
      doc.text(
        279,
        158,
        `${parseFloat(this.state.received).toFixed(2)}`,
        "right"
      );
      doc.text(174, 164, "Balance Amount");
      doc.text(
        279,
        164,
        `${parseFloat(this.state.total - this.state.received).toFixed(2)}`,
        "right"
      );
      doc.rect(153, 128, 135, 0, "F");
      doc.rect(153, 137, 135, 0, "F");
      doc.rect(153, 145, 135, 0, "F");
      doc.rect(153, 168, 135, 0, "F");

      //----------
      doc.rect(5, 128, 135, 0, "F");
      doc.rect(5, 137, 135, 0, "F");
      doc.rect(5, 145, 135, 0, "F");
      doc.rect(5, 168, 135, 0, "F");

      doc.text(24, 134, "Total");
      doc.text(
        131,
        134,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.text(24, 143, "Cash Payment Details");
      doc.text(114, 143, "Amount");
      doc.text(24, 152, "Total Amount");
      doc.text(131, 152, `${parseFloat(this.state.total).toFixed(2)}`, "right");
      doc.text(24, 158, "Received Amount");
      doc.text(
        131,
        158,
        `${parseFloat(this.state.received).toFixed(2)}`,
        "right"
      );
      doc.text(24, 164, "Balance Amount");
      doc.text(
        131,
        164,
        `${parseFloat(this.state.total - this.state.received).toFixed(2)}`,
        "right"
      );
    } else if (this.state.paymentMode == "Credit") {
      doc.rect(153, 168, 135, 0, "F");
      doc.text(174, 174, "Total");
      doc.text(
        279,
        174,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
      doc.rect(5, 168, 135, 0, "F");
      doc.text(24, 174, "Total");
      doc.text(
        131,
        174,
        `${parseFloat(this.state.sellQtyCft).toFixed(2)} ${this.state.itemUOM}`,
        "right"
      );
    }

    doc.save(
      `${
        this.state.royaltyNo ? this.state.dmCount : this.state.nrdmCount
      }_DM.pdf`
    );
  };
  getCustomerData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/customerData/customerBasics`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchData: data, mainData: data }, () => {
            this._isMounted && this.setState({ searchDataLoading: false });
          });
      }
    });
  };
  getDmCount = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }
    this._isMounted &&
      this.setState({ fiscalyear }, () => {
        let DMRef = firebase
          .database()
          .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);
        DMRef.once("value", (dataSnap) => {
          if (dataSnap.exists()) {
            this._isMounted &&
              this.setState({
                dmCount: dataSnap.val().dmCount + 1,
                nrdmCount: dataSnap.val().nrdmCount + 1,
              });
          } else {
            DMRef.update({
              dmCount: 0,
              gstBillCount: 0,
              localBillCount: 0,
              nrdmCount: 0,
              quoteNo: 0,
              receiptNo: 0,
            }).then(() => {
              this.getDmCount();
            });
          }
        });
      });
  };

  getItemData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };

  getLorryData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/fleetData/vehicleVital`)
      .orderByChild("type")
      .equalTo("Truck");
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataLorry: data, mainDataLorry: data });
      }
    });
  };
  getDriverData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/employeeData/vitalInfo`)
      .orderByChild("post")
      .equalTo("Driver");
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState(
            { searchDataDriver: data, mainDataDriver: data },
            () => {}
          );
      }
    });
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.customerName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchData: newdata });
    } else {
      this._isMounted && this.setState({ searchData: this.state.mainData });
    }
  };
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataItem: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  searchLorryStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataLorry.filter((item) => {
        const itemData = item
          ? item.plateNumber.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataLorry: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataLorry: this.state.mainDataLorry });
    }
  };
  searchDriverStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataDriver.filter((item) => {
        const itemData = item ? item.name.toUpperCase() : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataDriver: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataDriver: this.state.mainDataDriver });
    }
  };
  lorrycheck = () => {
    let found = this.state.mainDataLorry.find(
      (element) => element.plateNumber == this.state.lorrySearch
    );

    if (found) {
      this._isMounted &&
        this.setState({ isOurLorry: true, transportIncluded: true }, () => {
          this.getDriverData();
        });
    } else {
      this._isMounted &&
        this.setState({ isOurLorry: false, transportIncluded: false });
    }
  };
  getDriverList = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/employeeData/vitalInfo`)
      .orderByChild("post")
      .equalTo("Driver");

    dbRef.on("value", (dataSnap) => {
      if (dataSnap.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataDriver: data, mainDataDriver: data });
      }
    });
  };

  filterMainItemListToMatchPOList = () => {
    let objectToArray = Object.values(this.state.selectedPOItemList);
    const newData = this.state.mainDataItem.map((item1) => {
      objectToArray.map((item2) => {
        if (item1.id == item2.id) {
          item2 = item1;
        } else {
          item1 = null;
        }
      });
      return item1;
    });
    let finalNewData = newData.filter((item) => item != null);
    this.setState({ searchDataItem: finalNewData, mainDataItem: finalNewData });
  };

  renderPOItemPendingData = () => {
    let objectToArray = Object.values(this.state.selectedPOItemList);
    let selectedItem = objectToArray.filter(
      (item) => item.id == this.state.itemId
    );
    this.setState({ selectedItemFromPO: selectedItem[0] });
  };

  handeNextClickOfSecondPage = () => {
    if (this.state.itemSearch != "" && this.state.itemId != "") {
      if (this.state.sellQtyCft != "") {
        if (this.state.rst != "" && this.state.netWeight != "") {
          if (this.state.lorrySearch != "") {
            if (this.state.isOurLorry) {
              if (this.state.driverSearch != "" && this.state.kmReading != "") {
                this.setState({
                  error: "",
                });
                this.scroller.scrollTo({
                  x: 860,
                  y: 0,
                  animated: true,
                });
              } else {
                this.setState({
                  error: "Driver Name and Km Reading is Required",
                });
              }
            } else {
              this.setState({
                error: "",
              });
              this.scroller.scrollTo({
                x: 860,
                y: 0,
                animated: true,
              });
            }
          } else {
            this.setState({
              error: "Vehicel Number is Required",
            });
          }
        } else {
          this.setState({
            error: "Please enter RST No. and Net-weight",
          });
        }
      } else {
        this.setState({
          error: "Please enter Quantity",
        });
      }
    } else {
      this.setState({
        error: "Please enter Sales item/particular",
      });
    }
  };
  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={{ fontSize: "20", fontWeight: "bold", alignSelf: "center" }}
          >
            D.M. Generator
          </Text>
        </View>
        <View style={styles.playAreaHolder}>
          <View style={styles.leftSideHolder}>
            <Text style={styles.subText}>D.M. Review</Text>
            <Text
              style={{ fontSize: 24, fontStyle: "italic", textAlign: "center" }}
            >
              {`${this.state.companyData.organizationName}`}
            </Text>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ paddingVertical: 10 }}>
                Date: {this.state.date}
              </Text>
              <Text style={{ paddingVertical: 10 }}>
                {" "}
                Time: {this.state.time}
              </Text>
            </View>
            <Text style={{ paddingVertical: 10, fontWeight: "bold" }}>
              Name: {this.state.searchQuery}
            </Text>
            <Text style={{ paddingVertical: 10 }}>
              Address: {this.state.address}
            </Text>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ paddingVertical: 10 }}>
                Lorry No: {this.state.lorrySearch}
              </Text>
              <Text style={{ paddingVertical: 10 }}>
                Royalty(TP) No: {this.state.royaltyNo}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 18,
                fontStyle: "italic",
                textAlign: "center",
                textDecorationLine: "underline",
              }}
            >
              Particular
            </Text>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={{ paddingVertical: 10 }}>
                Material: {this.state.itemSearch}
              </Text>
              <Text style={{ paddingVertical: 10 }}>
                Quantity: {this.state.sellQtyCft} {this.state.itemUOM}
              </Text>
            </View>
            <Text style={{ paddingVertical: 10 }}>
              Payment Mode: {this.state.paymentMode}
            </Text>
            {this.state.paymentMode == "Cash" ? (
              <View>
                <Text style={{ paddingVertical: 10 }}>
                  Total Amount: {this.state.total}
                </Text>
                <Text style={{ paddingVertical: 10 }}>
                  Received Amount: {this.state.received}
                </Text>
              </View>
            ) : null}
            <Text style={{ fontSize: 10, padding: 10 }}>
              *This is Just a Review of Delivery Memo, Actual D.M. contains all
              Details
            </Text>
            <View>
              <View style={styles.modelHeader}>
                <Text style={styles.modelHeaderText}>Information Block</Text>
              </View>
              {this.state.error != "" ? (
                <Text
                  style={{
                    padding: 10,
                    backgroundColor: this.state.errorColor,
                  }}
                >
                  Errors: {this.state.error}
                </Text>
              ) : null}
            </View>
          </View>
          <View style={styles.rightSideHolder}>
            <ScrollView
              horizontal
              pagingEnabled
              showsHorizontalScrollIndicator={false}
              ref={(scroller) => {
                this.scroller = scroller;
              }}
              scrollEnabled={false}
            >
              <View style={styles.mainCard}>
                <View
                  style={{
                    flexDirection: "row",
                    width: 310,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.subText,
                      { textDecorationLine: "underline" },
                    ]}
                  >
                    Customer Info
                  </Text>
                  <Text>
                    D.M. No.{" "}
                    {this.state.royaltyNo
                      ? this.state.dmCount
                      : this.state.nrdmCount}
                  </Text>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    disabled
                    mode="outlined"
                    label="Date"
                    value={this.state.date}
                    style={[styles.inputField, { width: 180 }]}
                  />
                  <TextInput
                    disabled
                    mode="outlined"
                    label="Time"
                    value={this.state.time}
                    style={[styles.inputField, { width: 180 }]}
                  />
                </View>
                <View>
                  <TextInput
                    editable={this.state.editable}
                    mode="outlined"
                    label="Name of Customer"
                    value={this.state.searchQuery}
                    style={[styles.inputField, { width: 360 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ searchQuery: text }, () => {
                          this.searchStringInArray(this.state.searchQuery);
                          this._isMounted &&
                            this.setState({ viewDropDown: true });
                        });
                    }}
                    placeholder="Name of Customer"
                  />
                  {this.state.viewDropDown ? (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 5,
                        position: "absolute",
                        marginTop: 70,
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 5,
                        zIndex: 5,
                        marginLeft: 20,
                        maxHeight: 150,
                      }}
                    >
                      <FlatList
                        data={this.state.searchData}
                        renderItem={({ item }) => {
                          return (
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#fff",
                                padding: 10,
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                width: 320,
                                alignItems: "center",
                              }}
                              onPress={() => {
                                this._isMounted &&
                                  this.setState(
                                    {
                                      searchQuery: item.customerName,
                                      address: item.address,
                                      deliveryAddress: item.address,
                                      customerId: item.id,
                                      isPOdispatch: item.isPOdispatch,
                                    },
                                    () => {
                                      this._isMounted &&
                                        this.setState({ viewDropDown: false });
                                    }
                                  );
                              }}
                            >
                              <Text style={{ color: "#000" }}>
                                {item.customerName},{item.address}
                              </Text>
                            </TouchableOpacity>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  ) : null}
                </View>
                <TextInput
                  editable={this.state.editable}
                  mode="outlined"
                  label="Customer Address"
                  value={this.state.address}
                  style={[styles.inputField, { width: 360, zIndex: -1 }]}
                  placeholder="Customer Address"
                />
                <TextInput
                  mode="outlined"
                  editable={this.state.editable}
                  label="Delivery Address"
                  value={this.state.deliveryAddress}
                  style={[styles.inputField, { width: 360, zIndex: -1 }]}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ deliveryAddress: text });
                  }}
                  placeholder="Delivery Address"
                />
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      navigation.navigate("customerScreen");
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Add New Customer</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      if (
                        this.state.searchQuery != "" &&
                        this.state.customerId != ""
                      ) {
                        if (this.state.address != "") {
                          //GET PO DETAILs
                          if (this.state.isPOdispatch == true) {
                            this.getPoDetails();
                          }
                          this.setState({ error: "" });
                          this.scroller.scrollTo({
                            x: 430,
                            y: 0,
                            animated: true,
                          });
                        } else {
                          this.setState({ error: "Please Enter Address" });
                        }
                      } else {
                        this.setState({ error: "Please Select Customer Name" });
                      }
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Next</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <TouchableWithoutFeedback
                onPress={() => {
                  this._isMounted &&
                    this.setState({ viewDropDownLorry: false });
                }}
              >
                <View style={styles.mainCard}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: 310,
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={[
                        styles.subText,
                        { textDecorationLine: "underline" },
                      ]}
                    >
                      Particular
                    </Text>
                    <Text>
                      D.M. No.{" "}
                      {this.state.royaltyNo
                        ? this.state.dmCount
                        : this.state.nrdmCount}
                    </Text>
                  </View>
                  <View>
                    {this.state.isPOdispatch ? (
                      <Picker
                        enabled={this.state.editable}
                        selectedValue={this.state.selectedPOName}
                        style={{
                          height: 40,
                          width: 340,
                          marginHorizontal: 22,
                          marginVertical: 10,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this._isMounted &&
                          this.setState(
                            {
                              selectedPOName: JSON.parse(itemValue).poNumber,
                              selectedPOItemList:
                                JSON.parse(itemValue).itemData,
                            },
                            () => {
                              this.filterMainItemListToMatchPOList();
                              console.log(
                                this.state.selectedPOName,
                                "<<<<<<<<<<<<-------"
                              );
                            }
                          )
                        }
                      >
                        <Picker.Item
                          label="Select PO Number"
                          value=""
                          key={"SelectKey"}
                        />
                        {this.renderPOList()}
                      </Picker>
                    ) : null}
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        editable={this.state.editable}
                        mode="outlined"
                        label="Select Item"
                        value={this.state.itemSearch}
                        style={[styles.inputField, { width: 150 }]}
                        onChangeText={(text) => {
                          this._isMounted &&
                            this.setState({ itemSearch: text }, () => {
                              this.searchItemStringInArray(
                                this.state.itemSearch
                              );
                              this._isMounted &&
                                this.setState({ viewDropDownItem: true });
                            });
                        }}
                        placeholder="Select Item"
                      />
                      {this.state.viewDropDownItem ? (
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#000",
                            borderRadius: 5,
                            position: "absolute",
                            marginTop: 70,
                            shadowOffset: {
                              width: 0,
                              height: 0,
                            },
                            shadowOpacity: 0.2,
                            shadowRadius: 5,
                            zIndex: 5,
                            marginLeft: 20,
                            maxHeight: 150,
                          }}
                        >
                          <FlatList
                            data={this.state.searchDataItem}
                            renderItem={({ item }) => {
                              return (
                                <TouchableOpacity
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: 10,
                                    borderWidth: 1,
                                    borderColor: "#c1c1c1",
                                    borderRadius: 5,
                                    width: 200,
                                    alignItems: "center",
                                  }}
                                  onPress={() => {
                                    this._isMounted &&
                                      this.setState(
                                        {
                                          itemSearch: item.productName,
                                          itemId: item.id,
                                        },
                                        () => {
                                          this.currentdayAvaliableQty();
                                          this.renderPOItemPendingData();
                                          this.getUOM();
                                          this._isMounted &&
                                            this.setState({
                                              viewDropDownItem: false,
                                            });
                                        }
                                      );
                                  }}
                                >
                                  <Text style={{ color: "#000" }}>
                                    {item.productName}
                                  </Text>
                                </TouchableOpacity>
                              );
                            }}
                            keyExtractor={(item) => JSON.stringify(item.id)}
                          />
                        </View>
                      ) : null}
                      {this.state.isPOdispatch ? (
                        <View>
                          <Text style={{ marginTop: 40, fontSize: 18 }}>
                            {this.state.selectedItemFromPO.completedQty
                              ? this.state.selectedItemFromPO.completedQty
                              : "0"}
                            /{this.state.selectedItemFromPO.itemQty}
                            {this.state.selectedItemFromPO.uom}
                          </Text>
                        </View>
                      ) : null}

                      <View style={{ flexDirection: "row", width: 190 }}>
                        <TextInput
                          editable={this.state.editable}
                          mode="outlined"
                          label="Quantity"
                          value={this.state.sellQtyCft}
                          style={[styles.inputField, { width: 150 }]}
                          onChangeText={(text) => {
                            let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ sellQtyCft: text });
                            } else {
                              this._isMounted &&
                                this.setState({ sellQtyCft: "" });
                            }
                          }}
                          placeholder="Quantity"
                        />
                        <Text style={{ marginTop: 40, fontSize: 18 }}>
                          {this.state.itemUOM}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        zIndex: -1,
                      }}
                    >
                      <TextInput
                        mode="outlined"
                        label="RST"
                        value={this.state.rst}
                        style={[styles.inputField, { width: 150 }]}
                        onChangeText={(text) => {
                          let regex = /^[0-9\.]*$/;
                          if (text.match(regex)) {
                            this._isMounted && this.setState({ rst: text });
                          }
                        }}
                        placeholder="RST"
                        keyboardType="decimal-pad"
                      />
                      <View
                        style={{
                          flexDirection: "row",
                          width: 190,
                          zIndex: -1,
                        }}
                      >
                        <TextInput
                          mode="outlined"
                          label="Net Weight"
                          value={this.state.netWeight}
                          style={[styles.inputField, { width: 150 }]}
                          onChangeText={(text) => {
                            let regex = /^[0-9\.]*$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ netWeight: text });
                            }
                          }}
                          placeholder="Net Weight"
                          keyboardType="decimal-pad"
                        />
                        <Text
                          style={{
                            marginTop: 20,
                            fontSize: 24,
                            marginLeft: 3,
                            fontWeight: "bold",
                          }}
                        >
                          MT
                        </Text>
                      </View>
                    </View>
                    <TextInput
                      mode="outlined"
                      label="Royalty No(TP)"
                      value={this.state.royaltyNo}
                      style={[styles.inputField, { width: 360 }]}
                      onChangeText={(text) => {
                        this._isMounted && this.setState({ royaltyNo: text });
                      }}
                      placeholder="Royalty No(TP)"
                    />
                    {this.state.isPOdispatch ? null : (
                      <TextInput
                        mode="outlined"
                        label="Note"
                        value={this.state.note}
                        style={[styles.inputField, { width: 360 }]}
                        onChangeText={(text) => {
                          this._isMounted && this.setState({ note: text });
                        }}
                        placeholder="Note"
                      />
                    )}

                    <View style={{ flexDirection: "row", padding: 20 }}>
                      <TextInput
                        editable={this.state.editable}
                        mode="outlined"
                        label="Lorry Number"
                        value={this.state.lorrySearch.toUpperCase()}
                        style={[styles.inputField, { width: 180, padding: 0 }]}
                        onChangeText={(text) => {
                          this._isMounted &&
                            this.setState({ lorrySearch: text }, () => {
                              this.searchLorryStringInArray(
                                this.state.lorrySearch
                              );
                              this._isMounted &&
                                this.setState({ viewDropDownLorry: true });
                              this.lorrycheck();
                            });
                        }}
                        placeholder="Lorry Number"
                      />
                      {this.state.isOurLorry ? (
                        <View>
                          <TextInput
                            mode="outlined"
                            label="Driver Name"
                            value={this.state.driverSearch}
                            style={[
                              styles.inputField,
                              { width: 180, padding: 0 },
                            ]}
                            onChangeText={(text) => {
                              this._isMounted &&
                                this.setState({ driverSearch: text }, () => {
                                  this.searchDriverStringInArray(
                                    this.state.driverSearch
                                  );
                                  this._isMounted &&
                                    this.setState({ viewDropDownDriver: true });
                                });
                            }}
                            placeholder="Driver Name"
                          />
                          {this.state.viewDropDownDriver ? (
                            <View
                              style={{
                                borderWidth: 1,
                                borderColor: "#000",
                                borderRadius: 5,
                                position: "absolute",
                                marginTop: 50,
                                shadowOffset: {
                                  width: 0,
                                  height: 0,
                                },
                                shadowOpacity: 0.2,
                                shadowRadius: 5,
                                zIndex: 5,
                                marginLeft: 20,
                                maxHeight: 150,
                              }}
                            >
                              <FlatList
                                data={this.state.searchDataDriver}
                                renderItem={({ item }) => {
                                  return (
                                    <TouchableOpacity
                                      style={{
                                        backgroundColor: "#fff",
                                        padding: 5,
                                        borderWidth: 1,
                                        borderColor: "#c1c1c1",
                                        borderRadius: 5,
                                        width: 180,
                                        alignItems: "center",
                                      }}
                                      onPress={() => {
                                        this._isMounted &&
                                          this.setState(
                                            {
                                              driverSearch: item.name,
                                            },
                                            () => {
                                              this._isMounted &&
                                                this.setState({
                                                  viewDropDownDriver: false,
                                                });
                                            }
                                          );
                                      }}
                                    >
                                      <Text style={{ color: "#000" }}>
                                        {item.name}
                                      </Text>
                                    </TouchableOpacity>
                                  );
                                }}
                                keyExtractor={(item) => JSON.stringify(item.id)}
                              />
                            </View>
                          ) : null}
                        </View>
                      ) : null}
                    </View>
                    {this.state.isOurLorry ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          zIndex: -2,
                        }}
                      >
                        <TextInput
                          mode="outlined"
                          label="Reading in KM"
                          value={this.state.kmReading}
                          style={[styles.inputField, { width: 360 }]}
                          onChangeText={(text) => {
                            let regex = /^[0-9\.]*$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ kmReading: text });
                            }
                          }}
                          placeholder="Reading in KM"
                          keyboardType="numeric"
                          autoCorrect={false}
                        />
                      </View>
                    ) : null}
                    {this.state.viewDropDownLorry ? (
                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: "#000",
                          borderRadius: 5,
                          position: "absolute",
                          marginTop: 240,
                          shadowOffset: {
                            width: 0,
                            height: 0,
                          },
                          shadowOpacity: 0.2,
                          shadowRadius: 5,
                          zIndex: 5,
                          marginLeft: 20,
                          maxHeight: 150,
                        }}
                      >
                        <FlatList
                          data={this.state.searchDataLorry}
                          renderItem={({ item }) => {
                            return (
                              <TouchableOpacity
                                style={{
                                  backgroundColor: "#fff",
                                  padding: 10,
                                  borderWidth: 1,
                                  borderColor: "#c1c1c1",
                                  borderRadius: 5,
                                  width: 320,
                                  alignItems: "center",
                                }}
                                onPress={() => {
                                  this._isMounted &&
                                    this.setState(
                                      {
                                        lorrySearch: item.plateNumber,
                                      },
                                      () => {
                                        this._isMounted &&
                                          this.setState({
                                            viewDropDownLorry: false,
                                          });
                                        this.lorrycheck();
                                      }
                                    );
                                }}
                              >
                                <Text style={{ color: "#000" }}>
                                  {item.plateNumber}
                                </Text>
                              </TouchableOpacity>
                            );
                          }}
                          keyExtractor={(item) => JSON.stringify(item.id)}
                        />
                      </View>
                    ) : null}

                    <View style={{ flexDirection: "row", zIndex: -1 }}>
                      <TouchableOpacity
                        style={[styles.addNewButton, { zIndex: -1 }]}
                        onPress={() => {
                          this.scroller.scrollTo({
                            x: 0,
                            y: 0,
                            animated: true,
                          });
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Back</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[styles.addNewButton, { zIndex: -1 }]}
                        onPress={() => {
                          if (this.state.isPOdispatch) {
                            if (this.state.selectedPOName) {
                              if (
                                this.state.sellQtyCft <=
                                parseFloat(
                                  this.state.selectedItemFromPO.itemQty
                                ) -
                                  parseFloat(
                                    this.state.selectedItemFromPO.completedQty
                                      ? this.state.selectedItemFromPO
                                          .completedQty
                                      : "0"
                                  )
                              ) {
                                this.handeNextClickOfSecondPage();
                              } else {
                                this.setState({
                                  error:
                                    "Item quantity cannot be more than remaning quantity",
                                });
                              }
                            } else {
                              this.setState({
                                error: "Please select PO number",
                              });
                            }
                          } else {
                            this.handeNextClickOfSecondPage();
                          }
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Next</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
              <View style={styles.mainCard}>
                <View
                  style={{
                    flexDirection: "row",
                    width: 310,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.subText,
                      { textDecorationLine: "underline" },
                    ]}
                  >
                    Payment
                  </Text>
                  <Text>
                    D.M. No.{" "}
                    {this.state.royaltyNo
                      ? this.state.dmCount
                      : this.state.nrdmCount}
                  </Text>
                </View>

                <View>
                  <Picker
                    enabled={this.state.editable}
                    selectedValue={this.state.paymentMode}
                    style={{
                      height: 40,
                      width: 340,
                      marginHorizontal: 22,
                      marginVertical: 25,
                    }}
                    onValueChange={(itemValue, itemIndex) =>
                      this._isMounted &&
                      this.setState({ paymentMode: itemValue })
                    }
                  >
                    <Picker.Item label="Payment Mode" value="" />
                    <Picker.Item label="Credit" value="Credit" />
                    <Picker.Item label="Cash" value="Cash" />
                  </Picker>
                  {this.state.paymentMode == "Cash" ? (
                    <View
                      style={{ alignItems: "flex-end", marginHorizontal: 20 }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </Text>
                        <TextInput
                          editable={this.state.editable}
                          mode="outlined"
                          label="Total"
                          value={`${this.state.total}`}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          onChangeText={(text) => {
                            let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                            if (text.match(regex)) {
                              this._isMounted && this.setState({ total: text });
                            } else {
                              this._isMounted && this.setState({ total: "" });
                            }
                          }}
                          placeholder="Total"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Received
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Received Amount"
                          value={this.state.received}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          onChangeText={(text) => {
                            let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                            if (text.match(regex)) {
                              this._isMounted &&
                                this.setState({ received: text });
                            } else {
                              this._isMounted &&
                                this.setState({ received: "" });
                            }
                          }}
                          placeholder="Received Amount"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            padding: 20,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Balance
                        </Text>
                        <TextInput
                          disabled
                          mode="outlined"
                          label="Balance Amount"
                          value={`${this.state.total - this.state.received}`}
                          style={[
                            styles.inputField,
                            { width: 190, padding: 0 },
                          ]}
                          placeholder="Balance Amount"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Checkbox
                          status={
                            this.state.makeCashbookEntry
                              ? "checked"
                              : "unchecked"
                          }
                          onPress={() => {
                            this._isMounted &&
                              this.setState({
                                makeCashbookEntry:
                                  !this.state.makeCashbookEntry,
                              });
                          }}
                          color="#0a60ff"
                        />
                        <Text style={{ padding: 8 }}>
                          Make a Cash Book Entry
                        </Text>
                      </View>
                    </View>
                  ) : null}

                  <View style={{ flexDirection: "row" }}>
                    <Checkbox
                      status={this.state.checked ? "checked" : "unchecked"}
                      onPress={() => {
                        this._isMounted &&
                          this.setState({ checked: !this.state.checked });
                      }}
                      color="#0a60ff"
                    />
                    <Text style={{ padding: 8 }}>
                      I Accept Terms and Conditions.
                    </Text>
                  </View>
                  <TouchableOpacity
                    disabled={this.state.buttonLoading}
                    style={[
                      styles.addNewButton,
                      {
                        backgroundColor: this.state.buttonLoading
                          ? "#E6FFF1"
                          : "#009C44",
                        width: 340,
                      },
                    ]}
                    onPress={() => {
                      if (
                        this.state.searchQuery != "" &&
                        this.state.customerId != ""
                      ) {
                        if (this.state.address != "") {
                          if (
                            this.state.itemSearch != "" &&
                            this.state.itemId != ""
                          ) {
                            if (this.state.sellQtyCft != "") {
                              if (
                                this.state.rst != "" &&
                                this.state.netWeight != ""
                              ) {
                                if (this.state.lorrySearch != "") {
                                  if (this.state.isOurLorry) {
                                    //////
                                    if (
                                      this.state.driverSearch != "" &&
                                      this.state.kmReading != ""
                                    ) {
                                      if (this.state.paymentMode == "Cash") {
                                        if (
                                          this.state.received != "" &&
                                          this.state.total != ""
                                        ) {
                                          if (
                                            this.state.checked &&
                                            this.state.buttonLoading == false
                                          ) {
                                            this.saveDataToDb();
                                          } else {
                                            this.setState({
                                              error:
                                                "Please Accept Terms and Condition",
                                            });
                                          }
                                        } else {
                                          this.setState({
                                            error:
                                              "Enter Total and Received Amount",
                                          });
                                        }
                                      } else {
                                        if (
                                          this.state.checked &&
                                          this.state.buttonLoading == false
                                        ) {
                                          this.saveDataToDb();
                                        } else {
                                          this.setState({
                                            error:
                                              "Please Accept Terms and Condition",
                                          });
                                        }
                                      }
                                    } else {
                                      this.setState({
                                        error:
                                          "Driver Name and Km Reading is Required",
                                      });
                                    }
                                  } else {
                                    if (this.state.paymentMode == "Cash") {
                                      if (
                                        this.state.received != "" &&
                                        this.state.total != ""
                                      ) {
                                        if (
                                          this.state.checked &&
                                          this.state.buttonLoading == false
                                        ) {
                                          this.saveDataToDb();
                                        } else {
                                          this.setState({
                                            error:
                                              "Please Accept Terms and Condition",
                                          });
                                        }
                                      } else {
                                        this.setState({
                                          error:
                                            "Enter Total and Received Amount",
                                        });
                                      }
                                    } else {
                                      if (
                                        this.state.checked &&
                                        this.state.buttonLoading == false
                                      ) {
                                        this.saveDataToDb();
                                      } else {
                                        this.setState({
                                          error:
                                            "Please Accept Terms and Condition",
                                        });
                                      }
                                    }
                                  }
                                } else {
                                  this.setState({
                                    error: "Vehicel Number is Required",
                                  });
                                }
                              } else {
                                this.setState({
                                  error: "RST and Net Weight is Required",
                                });
                              }
                            } else {
                              this.setState({
                                error: "Sales Quantity is Required",
                              });
                            }
                          } else {
                            this.setState({
                              error: "Select Item/Product",
                            });
                          }
                        } else {
                          this.setState({
                            error: "Address is Required",
                          });
                        }
                      } else {
                        this.setState({
                          error: "Select Customer Name",
                        });
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator size="small" color="#0a60ff" />
                    ) : (
                      <Text style={{ color: "#fff" }}>Save and Print</Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      { backgroundColor: "#fff", width: 340 },
                    ]}
                    onPress={() => {
                      this.scroller.scrollTo({
                        x: 430,
                        y: 0,
                        animated: true,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Back</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </ScrollView>
          </View>
          <Snackbar
            visible={this.state.SnackbarVisible}
            onDismiss={() => {
              this._isMounted && this.setState({ SnackbarVisible: false });
            }}
            action={{
              label: "OK",
              onPress: () => {
                this._isMounted && this.setState({ SnackbarVisible: false });
              },
            }}
          >
            {this.state.error}
          </Snackbar>
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <NewSales {...props} navigation={navigation} />;
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,

    backgroundColor: "#fff",
  },
  headerContainer: {},
  playAreaHolder: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#c1c1c1",
    borderRadius: 5,
  },
  leftSideHolder: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
    padding: 25,
  },
  rightSideHolder: {
    flex: 1,
  },
  subText: {
    fontWeight: "normal",
    fontSize: "18",
    alignSelf: "center",
  },
  mainCard: {
    width: 430,
    borderWidth: 1,
    borderColor: "#c1c1c1",
    alignItems: "center",
    padding: 20,
  },
  inputField: {
    padding: 20,
    height: 40,
  },

  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
    marginTop: 28,
    marginHorizontal: 20,
    width: 150,
    alignItems: "center",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
});

// doc.setTextColor(65);
// doc.setFontSize(10);
// doc.text(71, 208, `Management Solution by SquareTech(7796133460)`)
// doc.text(220, 208, `Management Solution by SquareTech(7796133460)`)
