import { Text, StyleSheet, View } from "react-native";
import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
export default class CalenderWField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewCalender: false,
    };
  }
  render() {
    return (
      <View>
        <Text style={{ fontSize: 14, color: "#000" }}>{this.props.title}</Text>
        <View>
          <Text
            style={[
              styles.inputField,
              {
                backgroundColor: "#EAEAEA",
                borderWidth: 1,
                borderColor: "#c1c1c1",
                borderRadius: 5,
                margin: 10,
                alignSelf: "center",
                padding: 10,
                width: 200,
                textAlignVertical: "center",
              },
              { width: this.props.width },
            ]}
            
            onPress={() => {
              if (!this.props.disable){
                this.setState({
                  viewCalender: !this.state.viewCalender,
                });
              }
              
            }}
          >
           {typeof this.props.dateToShow!=="string"?`${this.props.dateToShow.getDate()}/${this.props.dateToShow.getMonth() + 1}/${this.props.dateToShow.getFullYear()}`:this.props.dateToShow}
          </Text>

          <View
            style={{
              paddingLeft: 20,
              marginTop: 60,
              zIndex: 5,
              position: "absolute",
            }}
          >
            {this.state.viewCalender ? (
              <Calendar
                value={this.props.dateToShow}
                onChange={(value) => {
                  this.props.onChange(value);
                  this.setState({ viewCalender: false });
                }}
              />
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputField: {
   
  
    height: 40,
  },
});
