import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  Modal,
  Portal,
  Provider,
  List,
  TextInput,
  Snackbar,
  RadioButton,
} from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import LeadsTable from "../src/components/LeadsTable";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class vendorScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setVisible: false,
      companyName: "",
      discription: "",
      contactPerson: "",
      contactPersonPost: "",
      type: "",
      address: "",
      contact: "",
      email: "",
      status: "",
      note: "",
      //
      buttonLoading: false,
      error: "",
      uoid: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDatatoDb = async () => {
    if (
      this.state.companyName != "" &&
      this.state.discription != "" &&
      this.state.contactPerson != "" &&
      this.state.type != "" &&
      this.state.address != "" &&
      this.state.contact != "" &&
      this.state.note != ""
    ) {
      this.setState({ buttonLoading: true });
      var rString = this.randomString(
        10,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/factoryData/leads/${rString}/`);
      await dbRef
        .set({
          id: rString,
          companyName: this.state.companyName,
          contactPerson: this.state.contactPerson,
          discription: this.state.discription,
          type: this.state.type,
          address: this.state.address,
          contact: this.state.contact,
          email: this.state.email,
          status: this.state.status,
          note: this.state.note,
          entrytime: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        })
        .then(() => {
          this.setState({ buttonLoading: false, setVisible: false });
          this.setState({
            companyName: "",
            discription: "",
            contactPerson: "",
            contactPersonPost: "",
            type: "",
            address: "",
            contact: "",
            email: "",
            status: "",
            note: "",
          });
        })
        .catch((error) => {
          this.setState({ buttonLoading: false, error: error.message });
        });
    } else {
      this.setState({ error: "Fields are Required" });
    }
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Leads Management" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar leads="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Model Start */}

              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    maxWidth: 850,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>Generate Lead</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      padding: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <TextInput
                        mode="outlined"
                        label="Name of the Client"
                        value={this.state.companyName}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.addresscolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ companyName: text });
                        }}
                        placeholder="Name"
                      />
                      <TextInput
                        mode="outlined"
                        label="Discription"
                        value={this.state.discription}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.contactcolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ discription: text });
                        }}
                        placeholder="Ex:Contractor/Hotmix/Factory.."
                      />
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <TextInput
                        mode="outlined"
                        label="Contact Person Name"
                        value={this.state.contactPerson}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.addresscolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ contactPerson: text });
                        }}
                        placeholder="Name"
                      />
                      <TextInput
                        mode="outlined"
                        label="Post of Person"
                        value={this.state.contactPersonPost}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.contactcolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ contactPersonPost: text });
                        }}
                        placeholder="Owner/Manager/supervisor.."
                      />
                    </View>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Picker
                        selectedValue={this.state.type}
                        style={{
                          height: 40,
                          width: 360,
                          marginHorizontal: 22,
                          borderColor: this.state.postcolor,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ type: itemValue })
                        }
                      >
                        <Picker.Item label="Type" value="" />
                        <Picker.Item label="Private" value="Private" />
                        <Picker.Item label="Government" value="Government" />
                      </Picker>
                      <TextInput
                        mode="outlined"
                        label="Address"
                        value={this.state.address}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.namecolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ address: text });
                        }}
                        placeholder="Address"
                      />
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Contact No."
                        value={this.state.contact}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ contact: text });
                        }}
                        placeholder="Mobile no."
                      />
                      <TextInput
                        mode="outlined"
                        label="Email"
                        value={this.state.email}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ email: text });
                        }}
                        placeholder="xyzopp@company.com"
                      />
                    </View>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Picker
                        selectedValue={this.state.status}
                        style={{
                          height: 40,
                          width: 360,
                          marginHorizontal: 22,
                          borderColor: this.state.postcolor,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ status: itemValue })
                        }
                      >
                        <Picker.Item label="Status" value="" />
                        <Picker.Item label="In Queue" value="In Queue" />
                        <Picker.Item label="Contacted" value="Contacted" />
                        <Picker.Item label="Working" value="Working" />
                        <Picker.Item label="Converted" value="converted" />
                        <Picker.Item
                          label="Attention Required"
                          value="Attention Required"
                        />
                      </Picker>
                      <TextInput
                        mode="outlined"
                        label="Note"
                        multiline
                        value={this.state.note}
                        style={[
                          styles.inputField,
                          { backgroundColor: this.state.namecolor },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ note: text });
                        }}
                        placeholder="Note"
                      />
                    </View>
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,
                              marginTop: 28,
                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          disabled={this.state.buttonLoading}
                          onPress={() => {
                            this.saveDatatoDb();
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator size="small" color="#fff" />
                          ) : (
                            <Text style={{ color: "#fff" }}>Save</Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,
                              marginTop: 28,
                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              setVisible: false,
                            });
                            this.setState({
                              companyName: "",
                              discription: "",
                              contactPerson: "",
                              contactPersonPost: "",
                              type: "",
                              address: "",
                              contact: "",
                              email: "",
                              status: "",
                              note: "",
                            });
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={{
                          padding: 20,

                          color: "red",
                        }}
                      >
                        {this.state.error}
                      </Text>
                    </View>
                  </View>
                </Modal>
              </Portal>
              {/* Model Ends */}

              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Leads
                  </Text>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.setState({ setVisible: true });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Generate New Lead</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.tableBody}>
                  <LeadsTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
});
