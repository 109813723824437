import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { jsPDF } from "jspdf";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TextInput, Modal, Portal } from "react-native-paper";

class EmployeeLedger extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      mainData: [],
      searchData: [],
      unSelectedArray: [],
      isLoading: true,
      columns: ["Date", "Particular", "Debit", "Credit", "Action"],
      columnsDetails: [
        "Date",
        "Particular",
        "Quantity",
        "Receipt/DM no.",
        "Amount",
      ],
      searchQuery: "",
      postSorterAce: true,
      id: "",

      dateFrom: new Date(),
      viewCalenderFrom: false,
      dateTo: new Date(),
      viewCalenderTo: false,
      isButtonLoading: false,
      creditTotal: 0,
      uoid: "",
      debitTotal: 0,
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
      openingBalance: 0,
      creditTotalSearch: 0,
      debitTotalSearch: 0,
      editEntryOpen: false,
      particular: "",
      selectedDebit: "",
      selectedCredit: "",
      timeStampId: "",
      isCashEntry: false,
      buttonLoading: false,
      viewDetails: false,
      ledgerDetails: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          var newDate = new Date(`${new Date().getFullYear()}-1-1`);
          this._isMounted &&
            this.setState(
              { uoid, id: this.props.id, dateFrom: newDate },
              () => {
                this.callDataFromDB();
                this.getCompanyData();
              }
            );
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this._isMounted && this.setState({ companyData: data });
      }
    });
  };
  makeUpdateInLedger = () => {
    this._isMounted && this.setState({ buttonLoading: true });
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/${this.props.dataType}/ledger/${this.props.id}/${this.state.timeStampId}`
      );

    dbRef
      .update({
        discription: this.state.particular,
        debit: this.state.selectedDebit,
        credit: this.state.selectedCredit,
      })
      .then(() => {
        if (this.state.isCashEntry) {
          this.updateOrgCashBook();
          this.updateAdminCashBook();
        } else {
          this._isMounted &&
            this.setState({ buttonLoading: false }, () => {
              this._isMounted &&
                this.setState({
                  buttonLoading: false,
                  editEntryOpen: false,
                  particular: "",
                  selectedCredit: "",
                  selectedDebit: "",
                  timeStampId: "",
                });
            });
        }
      });
  };
  updateOrgCashBook = () => {
    this._isMounted && this.setState({ buttonLoading: true });

    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${this.state.timeStampId}`
      );
    ledgerRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        ledgerRef
          .update({
            discription: `${this.props.employeeName} ${this.state.particular}`,
            credit: this.state.selectedCredit,
            debit: this.state.selectedDebit,
          })
          .then(() => {
            this._isMounted &&
              this.setState({ buttonLoading: false }, () => {
                this._isMounted &&
                  this.setState({
                    buttonLoading: false,
                    editEntryOpen: false,
                    particular: "",
                    selectedCredit: "",
                    selectedDebit: "",
                    timeStampId: "",
                  });
              });
          })
          .catch((error) => {
            this._isMounted && this.setState({ error: error.message });
          });
      }
    });
  };
  updateAdminCashBook = () => {
    this._isMounted && this.setState({ buttonLoading: true });

    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${this.state.timeStampId}`
      );
    ledgerRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        ledgerRef
          .update({
            discription: `${this.props.employeeName} ${this.state.particular}`,
            cashIn: parseFloat(this.state.selectedCredit),
            cashOut: parseFloat(this.state.selectedDebit),
          })
          .then(() => {
            this._isMounted &&
              this.setState({ buttonLoading: false }, () => {
                this._isMounted &&
                  this.setState({
                    buttonLoading: false,
                    editEntryOpen: false,
                    particular: "",
                    selectedCredit: "",
                    selectedDebit: "",
                    timeStampId: "",
                  });
              });
          })
          .catch((error) => {
            this._isMounted && this.setState({ error: error.message });
          });
      } else {
        this._isMounted &&
          this.setState({ buttonLoading: false }, () => {
            this._isMounted &&
              this.setState({
                buttonLoading: false,
                editEntryOpen: false,
                particular: "",
                selectedCredit: "",
                selectedDebit: "",
                timeStampId: "",
              });
          });
      }
    });
  };
  deleteEntryInLedger = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/${this.props.dataType}/ledger/${this.props.id}/${this.state.timeStampId}`
      );

    dbRef.remove().then(() => {
      if (this.state.isCashEntry) {
        this.deleteOrgCashBook();
        this.deleteAdminCashBook();
      } else {
        this._isMounted &&
          this.setState({ buttonLoading: false }, () => {
            this._isMounted &&
              this.setState({
                buttonLoading: false,
                editEntryOpen: false,
                particular: "",
                selectedCredit: "",
                selectedDebit: "",
                timeStampId: "",
              });
          });
      }
    });
  };
  deleteOrgCashBook = () => {
    this._isMounted && this.setState({ buttonLoading: true });

    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${this.state.timeStampId}`
      );
    ledgerRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        ledgerRef
          .remove()
          .then(() => {
            this._isMounted &&
              this.setState({ buttonLoading: false }, () => {
                this._isMounted &&
                  this.setState({
                    buttonLoading: false,
                    editEntryOpen: false,
                    particular: "",
                    selectedCredit: "",
                    selectedDebit: "",
                    timeStampId: "",
                  });
              });
          })
          .catch((error) => {
            this._isMounted && this.setState({ error: error.message });
          });
      }
    });
  };

  deleteAdminCashBook = () => {
    this._isMounted && this.setState({ buttonLoading: true });

    let ledgerRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${this.state.timeStampId}`
      );
    ledgerRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        ledgerRef
          .remove()
          .then(() => {
            this._isMounted &&
              this.setState({ buttonLoading: false }, () => {
                this._isMounted &&
                  this.setState({
                    buttonLoading: false,
                    editEntryOpen: false,
                    particular: "",
                    selectedCredit: "",
                    selectedDebit: "",
                    timeStampId: "",
                  });
              });
          })
          .catch((error) => {
            this._isMounted && this.setState({ error: error.message });
          });
      }
    });
  };
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/${this.props.dataType}/ledger/${this.props.id}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this._isMounted &&
          this.setState(
            {
              mainData: data.sort(function (a, b) {
                if (a.dateNumber < b.dateNumber) {
                  return 1;
                }
                if (a.dateNumber > b.dateNumber) {
                  return -1;
                }
                return 0;
              }),
              isLoading: false,
              isButtonLoading: false,
            },
            () => {
              this.filterLedger();
              const reducerDebit = (accumulator, currentValue) =>
                accumulator +
                parseFloat(currentValue.debit ? currentValue.debit : 0);
              const reducerCredit = (accumulator, currentValue) =>
                accumulator +
                parseFloat(currentValue.credit ? currentValue.credit : 0);

              let creditTotal = this.state.mainData.reduce(reducerCredit, 0);
              let debitTotal = this.state.mainData.reduce(reducerDebit, 0);

              this._isMounted && this.setState({ creditTotal, debitTotal });
            }
          );
      } else {
        this._isMounted &&
          this.setState({
            mainData: [],
            searchData: [],
            isButtonLoading: false,
            isLoading: false,
          });
      }
    });
  };
  filterLedger = () => {
    let filterdArray = this.state.mainData.filter(
      (item) =>
        item.dateNumber >= Date.parse(this.state.dateFrom.toDateString()) &&
        item.dateNumber <=
          Date.parse(
            new Date(
              this.state.dateTo.getFullYear(),
              this.state.dateTo.getMonth(),
              this.state.dateTo.getDate() + 1
            )
          )
    );

    this._isMounted &&
      this.setState({ searchData: filterdArray }, () => {
        const reducerDebit = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
        const reducerCredit = (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.credit ? currentValue.credit : 0);

        let creditTotalSearch = this.state.searchData.reduce(reducerCredit, 0);
        let debitTotalSearch = this.state.searchData.reduce(reducerDebit, 0);

        this._isMounted &&
          this.setState({ debitTotalSearch, creditTotalSearch });
      });

    let unSelectedDateArray = this.state.mainData.filter(
      (item) => item.dateNumber < Date.parse(this.state.dateFrom.toDateString())
    );

    this._isMounted &&
      this.setState({ unSelectedArray: unSelectedDateArray }, () => {
        const reducerDebit = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
        const reducerCredit = (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.credit ? currentValue.credit : 0);

        let creditTotal = this.state.unSelectedArray.reduce(reducerCredit, 0);
        let debitTotal = this.state.unSelectedArray.reduce(reducerDebit, 0);
        let openingBalance = debitTotal - creditTotal;

        this._isMounted && this.setState({ openingBalance: openingBalance });
      });
  };
  getTotals = () => {
    const reducerDebit = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
    const reducerCredit = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.credit ? currentValue.credit : 0);

    let creditTotal = this.state.mainData.reduce(reducerCredit, 0);
    let debitTotal = this.state.mainData.reduce(reducerDebit, 0);

    this._isMounted &&
      this.setState({ creditTotal, debitTotal }, () => {
        this.pdfDownload();
      });
  };
  pdfDownload = () => {
    var doc = new jsPDF("portrait", "mm", "a4");
    doc.setFont("times", "normal");
    doc.setFontSize(20);
    doc.text(
      12,
      16,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`
    );
    doc.setFillColor(242, 242, 242);
    doc.roundedRect(12, 20, 186, 40, 3, 3, "F");
    doc.setFontSize(12);
    doc.text(
      14,
      37,
      `Statement of Account from ${this.state.dateFrom.getDate()}/${
        this.state.dateFrom.getMonth() + 1
      }/${this.state.dateFrom.getFullYear()} to ${this.state.dateTo.getDate()}/${
        this.state.dateTo.getMonth() + 1
      }/${this.state.dateTo.getFullYear()}`
    );

    doc.setFontSize(12);
    doc.text(
      14,
      45,
      `Generated on: ${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`
    );
    doc.setDrawColor(193, 193, 193);
    doc.rect(104, 25, 0, 30);
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(14);
    doc.text(112, 29, "STATEMENT OF ACCOUNT");
    doc.rect(112, 30, 62, 0);
    doc.setFontSize(12);
    doc.text(112, 37, `${this.props.employeeName}`);
    doc.text(112, 45, `${this.props.address}`);
    doc.text(112, 53, `${this.props.number}`);
    doc.rect(12, 65, 186, 6);
    doc.rect(35, 65, 0, 6);
    doc.rect(100, 65, 0, 6);
    doc.rect(132, 65, 0, 6);
    doc.rect(165, 65, 0, 6);
    doc.text(24, 70, "Date", "center");
    doc.text(65, 70, "Account Discription", "center");
    doc.text(115, 70, "Debit", "center");
    doc.text(145, 70, "Credit", "center");
    doc.text(180, 70, "Note", "center");

    let pageCount = 1;
    doc.setTextColor(150);
    doc.text(196, 293, `Page ${pageCount}`, "right");
    doc.setTextColor(0);
    let starty = 0;
    for (let index = 0; index < this.state.searchData.length; index++) {
      if (pageCount == 1 && starty < 35) {
        doc.rect(12, 71 + starty * 6, 186, 6);
        doc.rect(35, 71 + starty * 6, 0, 6);
        doc.rect(100, 71 + starty * 6, 0, 6);
        doc.rect(132, 71 + starty * 6, 0, 6);
        doc.rect(165, 71 + starty * 6, 0, 6);

        doc.text(
          24,
          76 + starty * 6,
          `${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getDate()}/${
            new Date(
              this.state.searchData[
                this.state.searchData.length - (index + 1)
              ].transDate
            ).getMonth() + 1
          }/${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getFullYear()}`,
          "center"
        );
        doc.text(
          37,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .discription
          }`
        );
        doc.text(
          130,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .debit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].debit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          163,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .credit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].credit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        // doc.text(
        //   196,
        //   76 + starty * 6,
        //   `${parseFloat(
        //     this.state.searchData[this.state.searchData.length - (index + 1)]
        //       .balance
        //   ).toFixed(2)} ${
        //     parseFloat(
        //       this.state.searchData[this.state.searchData.length - (index + 1)]
        //         .balance
        //     ) > 0
        //       ? "Cr"
        //       : "Dr"
        //   }`,
        //   "right"
        // );
        if (index == this.state.searchData.length - 1) {
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 71 + (starty + 1) * 6, 186, 6, "FD");
          doc.rect(35, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(100, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(132, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(165, 71 + (starty + 1) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 76 + (starty + 1) * 6, ``, "center");
          doc.text(37, 76 + (starty + 1) * 6, `Opening Balance`);
          doc.text(
            130,
            76 + (starty + 1) * 6,
            `${
              this.state.openingBalance > 0
                ? this.state.openingBalance.toFixed(2)
                : ""
            }`,
            "right"
          );
          doc.text(
            163,
            76 + (starty + 1) * 6,
            `${
              this.state.openingBalance < 0
                ? this.state.openingBalance.toFixed(2)
                : ""
            }`,
            "right"
          );

          // -----------------
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 71 + (starty + 2) * 6, 186, 6, "FD");
          doc.rect(35, 71 + (starty + 2) * 6, 0, 6);
          doc.rect(100, 71 + (starty + 2) * 6, 0, 6);
          doc.rect(132, 71 + (starty + 2) * 6, 0, 6);
          doc.rect(165, 71 + (starty + 2) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 76 + (starty + 2) * 6, ``, "center");
          doc.text(37, 76 + (starty + 2) * 6, `Current Total`);
          doc.text(
            130,
            76 + (starty + 2) * 6,
            `${parseFloat(this.state.debitTotalSearch).toFixed(2)}`,
            "right"
          );
          doc.text(
            163,
            76 + (starty + 2) * 6,
            `${parseFloat(this.state.creditTotalSearch).toFixed(2)}`,
            "right"
          );

          //-------
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 71 + (starty + 3) * 6, 186, 6, "FD");
          doc.rect(35, 71 + (starty + 3) * 6, 0, 6);
          doc.rect(100, 71 + (starty + 3) * 6, 0, 6);
          doc.rect(132, 71 + (starty + 3) * 6, 0, 6);
          doc.rect(165, 71 + (starty + 3) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 76 + (starty + 3) * 6, ``, "center");
          doc.text(37, 76 + (starty + 3) * 6, `Closing Balance`);
          doc.text(
            130,
            76 + (starty + 3) * 6,
            `${
              this.state.openingBalance +
                parseFloat(this.state.debitTotalSearch) -
                parseFloat(this.state.creditTotalSearch) >
              0
                ? (
                    this.state.openingBalance +
                    parseFloat(this.state.debitTotalSearch) -
                    parseFloat(this.state.creditTotalSearch)
                  ).toFixed(2)
                : ""
            }`,
            "right"
          );
          doc.text(
            163,
            76 + (starty + 3) * 6,
            `${
              this.state.openingBalance +
                parseFloat(this.state.debitTotal) -
                parseFloat(this.state.creditTotal) <
              0
                ? (
                    this.state.openingBalance +
                    parseFloat(this.state.debitTotal) -
                    parseFloat(this.state.creditTotal)
                  ).toFixed(2)
                : ""
            }`,
            "right"
          );

          //-------
          doc.setFont("times", "normal");
          doc.text(105, 76 + (starty + 5) * 6, `End of Statement`, "center");
        }
        if (index == 34 && this.state.searchData.length > 35) {
          pageCount = pageCount + 1;

          starty = 0;
          doc.addPage();

          doc.rect(12, 16, 186, 6);
          doc.rect(35, 16, 0, 6);
          doc.rect(100, 16, 0, 6);
          doc.rect(132, 16, 0, 6);
          doc.rect(165, 16, 0, 6);
          doc.text(24, 21, "Date", "center");
          doc.text(65, 21, "Account Discription", "center");
          doc.text(115, 21, "Debit", "center");
          doc.text(145, 21, "Credit", "center");
          doc.text(180, 21, "Note", "center");
          doc.setTextColor(150);
          doc.text(196, 293, `Page ${pageCount}`, "right");
          doc.setTextColor(0);
        } else {
          starty = starty + 1;
        }
      } else if (pageCount > 1 && index > 34) {
        if (starty % 42 == 0 && pageCount > 2) {
          if (index == 0) {
          } else {
            doc.addPage();

            starty = 0;
            doc.rect(12, 16, 186, 6);
            doc.rect(35, 16, 0, 6);
            doc.rect(100, 16, 0, 6);
            doc.rect(132, 16, 0, 6);
            doc.rect(165, 16, 0, 6);
            doc.text(24, 21, "Date", "center");
            doc.text(65, 21, "Account Discription", "center");
            doc.text(115, 21, "Debit", "center");
            doc.text(145, 21, "Credit", "center");
            doc.text(180, 21, "Note", "center");
            pageCount = pageCount + 1;
            doc.setTextColor(150);
            doc.text(196, 293, `Page ${pageCount}`, "right");
            doc.setTextColor(0);
          }
        }
        if (index == 78) {
          doc.addPage();

          starty = 0;
          doc.rect(12, 16, 186, 6);
          doc.rect(35, 16, 0, 6);
          doc.rect(100, 16, 0, 6);
          doc.rect(132, 16, 0, 6);
          doc.rect(165, 16, 0, 6);
          doc.text(24, 21, "Date", "center");
          doc.text(65, 21, "Account Discription", "center");
          doc.text(115, 21, "Debit", "center");
          doc.text(145, 21, "Credit", "center");
          doc.text(180, 21, "Note", "center");
          pageCount = pageCount + 1;
          doc.setTextColor(150);
          doc.text(196, 293, `Page ${pageCount}`, "right");
          doc.setTextColor(0);
        }
        if (index == this.state.searchData.length - 1) {
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 22 + (starty + 1) * 6, 186, 6, "FD");
          doc.rect(35, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(100, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(132, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(165, 22 + (starty + 1) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 27 + (starty + 1) * 6, ``, "center");
          doc.text(37, 27 + (starty + 1) * 6, `Opening Balance`);
          doc.text(
            130,
            27 + (starty + 1) * 6,
            `${this.state.openingBalance > 0 ? this.state.openingBalance : ""}`,
            "right"
          );
          doc.text(
            163,
            27 + (starty + 1) * 6,
            `${this.state.openingBalance < 0 ? this.state.openingBalance : ""}`,
            "right"
          );

          //----
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 22 + (starty + 2) * 6, 186, 6, "FD");
          doc.rect(35, 22 + (starty + 2) * 6, 0, 6);
          doc.rect(100, 22 + (starty + 2) * 6, 0, 6);
          doc.rect(132, 22 + (starty + 2) * 6, 0, 6);
          doc.rect(165, 22 + (starty + 2) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 27 + (starty + 2) * 6, ``, "center");
          doc.text(37, 27 + (starty + 2) * 6, `Current Total`);
          doc.text(
            130,
            27 + (starty + 2) * 6,
            `${parseFloat(this.state.debitTotal).toFixed(2)}`,
            "right"
          );
          doc.text(
            163,
            27 + (starty + 2) * 6,
            `${parseFloat(this.state.creditTotal).toFixed(2)}`,
            "right"
          );
          //---
          //----
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 22 + (starty + 3) * 6, 186, 6, "FD");
          doc.rect(35, 22 + (starty + 3) * 6, 0, 6);
          doc.rect(100, 22 + (starty + 3) * 6, 0, 6);
          doc.rect(132, 22 + (starty + 3) * 6, 0, 6);
          doc.rect(165, 22 + (starty + 3) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 27 + (starty + 3) * 6, ``, "center");
          doc.text(37, 27 + (starty + 3) * 6, `Closing Balance`);
          doc.text(
            130,
            27 + (starty + 3) * 6,
            `${
              this.state.openingBalance +
                parseFloat(this.state.debitTotalSearch) -
                parseFloat(this.state.creditTotalSearch) >
              0
                ? this.state.openingBalance +
                  parseFloat(this.state.debitTotalSearch) -
                  parseFloat(this.state.creditTotalSearch)
                : ""
            }`,
            "right"
          );
          doc.text(
            163,
            27 + (starty + 3) * 6,
            `${
              this.state.openingBalance +
                parseFloat(this.state.debitTotal) -
                parseFloat(this.state.creditTotal) <
              0
                ? this.state.openingBalance +
                  parseFloat(this.state.debitTotal) -
                  parseFloat(this.state.creditTotal)
                : ""
            }`,
            "right"
          );
          //---

          doc.setFont("times", "normal");
          doc.text(105, 27 + (starty + 5) * 6, `End of Statement`, "center");
        }
        doc.rect(12, 22 + starty * 6, 186, 6);
        doc.rect(35, 22 + starty * 6, 0, 6);
        doc.rect(100, 22 + starty * 6, 0, 6);
        doc.rect(132, 22 + starty * 6, 0, 6);
        doc.rect(165, 22 + starty * 6, 0, 6);
        doc.text(
          24,
          27 + starty * 6,
          `${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getDate()}/${
            new Date(
              this.state.searchData[
                this.state.searchData.length - (index + 1)
              ].transDate
            ).getMonth() + 1
          }/${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getFullYear()}`,
          "center"
        );
        doc.text(
          37,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .discription
          }`
        );
        doc.text(
          130,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .debit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].debit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          163,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .credit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].credit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          196,
          27 + starty * 6,
          `${parseFloat(
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .balance
          ).toFixed(2)} ${
            parseFloat(
              this.state.searchData[this.state.searchData.length - (index + 1)]
                .balance
            ) > 0
              ? "Cr"
              : "Dr"
          }`,
          "right"
        );
        starty = starty + 1;
      }
    }

    doc.save(`${this.props.employeeName}_Account.pdf`);
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={[styles.columnHeader]}>
              <Text style={[styles.columnHeaderTxt]}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderDetails = () => (
    <View style={styles.tableHeader}>
      {this.state.columnsDetails.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={[styles.columnHeader]}>
              <Text style={[styles.columnHeaderTxt]}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <Portal>
          <Modal
            visible={this.state.editEntryOpen}
            onDismiss={() =>
              this._isMounted && this.setState({ editEntryOpen: false })
            }
            contentContainerStyle={{
              backgroundColor: "white",
              width: 400,
              borderRadius: 5,
              alignSelf: "center",
            }}
          >
            <View>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Edit Entry
              </Text>
              <TextInput
                mode="outlined"
                label="Particular"
                value={this.state.particular}
                style={styles.inputField}
                onChangeText={(text) => {
                  this._isMounted && this.setState({ particular: text });
                }}
                placeholder="Particular"
              />
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  mode="outlined"
                  label="Credit"
                  value={this.state.selectedCredit}
                  style={[styles.inputField, { width: 200 }]}
                  onChangeText={(text) => {
                    let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                    if (text.match(regex)) {
                      this._isMounted &&
                        this.setState({ selectedCredit: text });
                    } else {
                      this._isMounted && this.setState({ selectedCredit: "" });
                    }
                  }}
                  placeholder="Credit"
                />
                <TextInput
                  mode="outlined"
                  label="Debit"
                  value={this.state.selectedDebit}
                  style={[styles.inputField, { width: 200 }]}
                  onChangeText={(text) => {
                    let regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                    if (text.match(regex)) {
                      this._isMounted && this.setState({ selectedDebit: text });
                    } else {
                      this._isMounted && this.setState({ selectedDebit: "" });
                    }
                  }}
                  placeholder="Credit"
                />
              </View>
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <TouchableOpacity
                  disabled={this.state.buttonLoading}
                  style={[
                    styles.addNewButton,
                    {
                      width: 100,
                      marginHorizontal: 20,
                      height: 42,
                      marginTop: 26,
                      backgroundColor: "#fff",
                      borderColor: "#B50706",
                    },
                  ]}
                  onPress={() => {
                    this.deleteEntryInLedger();
                  }}
                >
                  <Text style={{ color: "#B50706", alignSelf: "center" }}>
                    Delete
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={this.state.buttonLoading}
                  style={[
                    styles.addNewButton,
                    {
                      width: 100,
                      marginHorizontal: 20,
                      height: 42,
                      marginTop: 26,
                    },
                  ]}
                  onPress={() => {
                    if (this.props.admin) {
                      if (this.state.particular != "") {
                        if (
                          this.state.selectedCredit != "" ||
                          this.state.selectedDebit != ""
                        ) {
                          this.makeUpdateInLedger();
                        }
                      }
                    }
                  }}
                >
                  {this.state.buttonLoading ? (
                    <ActivityIndicator
                      animating={true}
                      color="#fff"
                      size="small"
                    />
                  ) : (
                    <Text style={{ color: "#fff", alignSelf: "center" }}>
                      Update
                    </Text>
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.addNewButton,
                    {
                      backgroundColor: "#fff",
                      width: 80,
                      marginHorizontal: 20,
                      height: 42,
                      marginTop: 26,
                    },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        editEntryOpen: false,
                        particular: "",
                        selectedCredit: "",
                        selectedDebit: "",
                        timeStampId: "",
                      });
                  }}
                >
                  <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </Portal>
        <Portal>
          <Modal
            visible={this.state.viewDetails}
            onDismiss={() => {
              this.setState({ viewDetails: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 850,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Details for selected entry of {this.props.employeeName}
              </Text>
            </View>
            <View>
              <View
                style={{
                  padding: 8,
                  borderBottomWidth: 1,
                  borderBottomColor: "#c1c1c1",
                  justifyContent: "center",
                }}
              >
                <View style={{ height: 300 }}>
                  <FlatList
                    data={this.state.ledgerDetails}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{item.date}</Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.item}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {this.props.dataType == "vendorData"
                              ? `${item.addQty} ${item.uom}`
                              : `${item.sellQtyCft} ${item.itemUOM}`}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {this.props.dataType == "vendorData"
                              ? item.receiptNo
                              : item.dmNo}
                          </Text>

                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {this.props.dataType == "vendorData"
                              ? item.otherAmount
                              : item.totalAmount}
                          </Text>
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeaderDetails}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        <Text>No Details Avaliable for selected Entry</Text>
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.id)}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                {/* <View style={{ flexDirection: "column" }}>
                      <Checkbox
                        status={
                          this.state.acceptBillGen ? "checked" : "unchecked"
                        }
                        onPress={() => {
                          this.setState({
                            acceptBillGen: !this.state.acceptBillGen,
                          });
                        }}
                        color="#0a60ff"
                      />
                      <Text style={{ padding: 8, fontWeight: "bold" }}>
                        Make a debit entry of{" "}
                        {this.state.ledgerCalculations.ledGrandTotal}
                      </Text>
                      <TouchableOpacity
                        disabled={!this.state.acceptBillGen}
                        style={styles.addNewButton}
                        onPress={() => {
                          if (this.state.acceptBillGen) {
                            this.setState({ setVisibleBill: false });
                            this.saveToBills();
                          }
                        }}
                      >
                        <Text style={{ color: "#fff", alignSelf: "center" }}>
                          Generate and save Invoice
                        </Text>
                      </TouchableOpacity>
                    </View> */}
                <TouchableOpacity
                  style={[
                    styles.addNewButton,
                    { width: 150, backgroundColor: "#fff" },
                  ]}
                  onPress={() => {
                    this.setState({ viewDetails: false });
                  }}
                >
                  <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </Portal>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            backgroundColor: "#f2f2f2",
            borderRadius: 2,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={{ padding: 3, paddingTop: 25 }}>From</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 150,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        viewCalenderFrom: !this.state.viewCalenderFrom,
                      });
                  }}
                >
                  {this.state.dateFrom.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderFrom ? (
                    <Calendar
                      value={this.state.dateFrom}
                      onChange={(value) => {
                        this._isMounted &&
                          this.setState({ dateFrom: value }, () => {
                            this._isMounted &&
                              this.setState({ viewCalenderFrom: false });
                          });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>
            {/* to */}
            <Text style={{ padding: 3, paddingTop: 25 }}>To</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 150,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        viewCalenderTo: !this.state.viewCalenderTo,
                      });
                  }}
                >
                  {this.state.dateTo.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderTo ? (
                    <Calendar
                      value={this.state.dateTo}
                      onChange={(value) => {
                        this._isMounted &&
                          this.setState({ dateTo: value }, () => {
                            this._isMounted &&
                              this.setState({ viewCalenderTo: false });
                          });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>

            <TouchableOpacity
              style={[
                styles.actionbtn,
                {
                  borderColor: "#0a60ff",
                  margin: 15,
                  width: 100,
                  paddingVertical: 10,
                  backgroundColor: "#0a60ff",
                },
              ]}
              onPress={() => {
                this._isMounted && this.setState({ isButtonLoading: true });
                this.callDataFromDB();
              }}
            >
              {this.state.isButtonLoading ? (
                <ActivityIndicator size="small" color="#fff" />
              ) : (
                <Text style={{ color: "#fff", textAlign: "center" }}>View</Text>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.actionbtn,
                {
                  borderColor: "#B50706",
                  margin: 15,
                  width: 120,
                  paddingVertical: 10,
                  backgroundColor: "#fff",
                },
              ]}
              onPress={() => {
                this.getTotals(); //Followed by pdfDownload()
              }}
            >
              {this.state.isButtonLoadingPdf ? (
                <ActivityIndicator size="small" color="#B50706" />
              ) : (
                <Text style={{ color: "#B50706", textAlign: "left" }}>
                  PDF Download
                </Text>
              )}
            </TouchableOpacity>
          </View>
          <View style={{ padding: 20 }}>
            <Text
              style={{
                fontWeight: "bold",
                color: "#000",
                fontSize: 18,
              }}
            >
              Closing Balance ₹
              {(
                parseFloat(this.state.debitTotal) -
                parseFloat(this.state.creditTotal)
              ).toFixed(2)}{" "}
              {(
                parseFloat(this.state.debitTotal) -
                parseFloat(this.state.creditTotal)
              ).toFixed(2) > 0
                ? "Dr"
                : "Cr"}
            </Text>
          </View>
        </View>
        <View style={{ maxHeight: 450, zIndex: -1 }}>
          <FlatList
            data={this.state.searchData}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                  ]}
                >
                  <Text style={styles.columnRowTxt}>{item.transDate}</Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    {item.discription}
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {item.debit ? parseFloat(item.debit).toFixed(2) : "-"}
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {item.credit ? parseFloat(item.credit).toFixed(2) : "-"}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    {this.props.isDetailsAvaliable ? (
                      <TouchableOpacity
                        style={[
                          styles.actionbtn,
                          {
                            borderColor: "#0a60ff",
                            margin: 8,
                            width: 70,
                            paddingVertical: 4,
                            backgroundColor: "#fff",
                          },
                        ]}
                        onPress={() => {
                          item.isCashEntry
                            ? null
                            : this.setState({
                                viewDetails: true,
                                ledgerDetails:
                                  this.props.dataType == "vendorData"
                                    ? item.details
                                      ? item.details.receiptArray
                                      : []
                                    : item.details
                                    ? item.details.dmArray
                                    : [],
                              });
                        }}
                      >
                        <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                          Details
                        </Text>
                      </TouchableOpacity>
                    ) : null}

                    <TouchableOpacity
                      style={[
                        styles.actionbtn,
                        {
                          borderColor: "#0a60ff",
                          margin: 8,
                          width: 70,
                          paddingVertical: 4,
                          backgroundColor: "#fff",
                        },
                      ]}
                      onPress={() => {
                        if (this.props.admin && item.isEditable != false) {
                          this._isMounted &&
                            this.setState({
                              editEntryOpen: true,
                              particular: item.discription,
                              selectedCredit: item.credit,
                              selectedDebit: item.debit,
                              timeStampId: item.timeStampId,
                              isCashEntry: item.isCashEntry,
                            });
                        }
                      }}
                    >
                      <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                        Edit
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              );
            }}
            ListHeaderComponent={this.tableHeader}
            stickyHeaderIndices={[0]}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator size="large" color="#0a60ff" />
                ) : (
                  <Text>No Records Found</Text>
                )}
              </View>
            }
            keyExtractor={(item) => JSON.stringify(item.ledgerId)}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            backgroundColor: "#f2f2f2",
            borderRadius: 2,
            paddingVertical: 15,
            zIndex: -1,
          }}
        >
          <Text style={{ width: "20%" }}></Text>
          <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
            Opening Balance
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {this.state.openingBalance > 0 ? this.state.openingBalance : ""}
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {this.state.openingBalance < 0 ? this.state.openingBalance : ""}
          </Text>
          <Text style={{ width: "20%" }}></Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            backgroundColor: "#f2f2f2",
            borderRadius: 2,
            paddingVertical: 15,
            zIndex: -1,
          }}
        >
          <Text style={{ width: "20%" }}></Text>
          <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
            Current Total
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {parseFloat(this.state.debitTotalSearch).toFixed(2)}
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {parseFloat(this.state.creditTotalSearch).toFixed(2)}
          </Text>
          <Text style={{ width: "20%" }}></Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            backgroundColor: "#f2f2f2",
            borderRadius: 2,
            paddingVertical: 15,
            borderTopWidth: 1,
            borderTopColor: "#000",
            zIndex: -1,
          }}
        >
          <Text style={{ width: "20%" }}></Text>
          <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
            Closing Balance
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {(
              this.state.openingBalance +
              parseFloat(this.state.debitTotalSearch) -
              parseFloat(this.state.creditTotalSearch)
            ).toFixed(2) > 0
              ? (
                  this.state.openingBalance +
                  parseFloat(this.state.debitTotalSearch) -
                  parseFloat(this.state.creditTotalSearch)
                ).toFixed(2)
              : ""}
          </Text>
          <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
            {this.state.openingBalance +
              parseFloat(this.state.debitTotalSearch) -
              parseFloat(this.state.creditTotalSearch) <
            0
              ? this.state.openingBalance +
                parseFloat(this.state.debitTotalSearch) -
                parseFloat(this.state.creditTotalSearch)
              : ""}
          </Text>
          <Text style={{ width: "20%" }}></Text>
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <EmployeeLedger {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  inputField: {
    width: 300,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
});
