import { StatusBar } from "expo-status-bar";
import React from "react";
import { render } from "react-dom";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Alert,
  ActivityIndicator,
} from "react-native";
import squaretech from "../src/images/squaretech.png";

export default class loadupScreen extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.logoHolder}>
          <Image source={squaretech} style={{ width: 350, height: 350 }} />
          <ActivityIndicator animating={true} size="large" color="#275472" />
        </View>
        <View style={styles.versionHolder}>
          <Text>Version 1.0.5</Text>
          <Text>Developed by SquareTech Apps</Text>
        </View>
        <StatusBar style="auto" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EFEFEF",
    alignItems: "center",
    justifyContent: "center",
  },
  logoHolder: {
    flex: 3,
  },
  versionHolder: {
    flex: 1,
    alignItems: "center",
  },
});
