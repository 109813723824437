import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  Modal,
  Portal,
  Provider,
  TextInput,
  Snackbar,
  Checkbox,
} from "react-native-paper";
import firebase from "firebase";
import { Picker } from "@react-native-picker/picker";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import MROtable from "../src/components/MROtable";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FlatList } from "react-native-gesture-handler";

export default class mroScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SnackbarVisible: false,
      setVisible: false,
      quantity: "",
      note: "",
      productName: "",
      error: "",
      UOM: "",
      buttonLoading: false,
      productId: "",
      uoid: "",
      isReusable: false,
      maintenancesetVisible: false,
      maintenanceData: [],
      coloumTitle: [
        "Sr. No.",
        "Date",
        "Particular",
        "Count/Unit",
        "Entry By",
        "Action",
      ],
      modalTitles: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveToDb = async () => {
    if (
      this.state.productName !== "" &&
      this.state.quantity !== "" &&
      this.state.note !== "" &&
      this.state.UOM !== ""
    ) {
      this.setState({ buttonLoading: true });
      var rString = this.randomString(
        10,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let dbRef = firebase
        .database()
        .ref()
        .child(
          `${this.state.uoid}/factoryData/MROInventory/products/${rString}`
        );
      await dbRef.set(
        {
          productName: this.state.productName,
          note: this.state.note,
          quantity: this.state.quantity,
          UOM: this.state.UOM,
          id: rString,
          joiningDate: new Date().toDateString(),
          isReusable: this.state.isReusable,
        },
        (error) => {
          if (!error) {
            this.setState({ setVisible: false, productId: rString }, () => {
              this.addtoProductOverView();
              this.addToProductHistoryDetails();
            });

            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 5000);
          }
        }
      );
    }
  };
  addtoProductOverView = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryOverView/${
          this.state.productId
        }/${Date.parse(new Date().toDateString())}`
      );

    dbref.set(
      {
        productId: this.state.productId,
        qtyAdd: this.state.quantity,
        qtyUsed: 0,
        avaliableQty: this.state.quantity,
        dateNumber: Date.parse(new Date().toDateString()),
        date: new Date().toDateString(),
      },
      (error) => {
        if (!error) {
          this.setState({
            setVisible: false,
            quantity: "",
            note: "",
            productName: "",
            error: "",
            UOM: "",
            buttonLoading: false,
            productId: "",
          });
        }
      }
    );
  };

  addToProductHistoryDetails = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/MROInventory/productHistoryDetails/${
          this.state.productId
        }/${Date.parse(new Date().toDateString())}/${Date.parse(new Date())}`
      );

    dbref.set({
      timeStamp: Date.parse(new Date()),
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      date: new Date().toDateString(),
      qtySubtract: 0,
      qtyAdd: this.state.quantity,
      avaliableQty: this.state.quantity,
      particulr: "Opening Balance",
    });
  };
  getMaintenanceList = (location) => {
    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/MROInventory/${location}`);

    dbref.on("value", (snapShot) => {
      if (snapShot.exists()) {
        let data = Object.values(snapShot.val());
        this.setState({ maintenanceData: data }, () => {
          this.setState({ buttonLoading: false });
        });
      } else {
        this.setState({ buttonLoading: false });
      }
    });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.coloumTitle.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="MRO Inventory" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar mroInvetory="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Model Start */}
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false, buttonLoading: false });
                    this.setState({
                      SnackbarVisible: false,
                      setVisible: false,
                      quantity: "",
                      note: "",
                      productName: "",
                      error: "",
                      UOM: "",
                      buttonLoading: false,
                    });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 850,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>New Product</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      padding: 20,
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <TextInput
                        mode="outlined"
                        label="Product Name"
                        value={this.state.productName}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ productName: text });
                        }}
                        placeholder="Product Name"
                      />
                      <TextInput
                        mode="outlined"
                        label="Note"
                        value={this.state.note}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ note: text });
                        }}
                        placeholder="Note"
                        multiline
                      />
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Quantity"
                        value={this.state.quantity}
                        style={[styles.inputField, { width: 250 }]}
                        onChangeText={(text) => {
                          this.setState({ quantity: text });
                        }}
                        placeholder="Quantity"
                      />

                      <Picker
                        selectedValue={this.state.UOM}
                        style={{
                          height: 40,
                          width: 250,
                          marginHorizontal: 22,
                          marginTop: 28,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ UOM: itemValue }, () => {})
                        }
                      >
                        <Picker.Item label="Unit of Measurement" value="" />
                        <Picker.Item label="Liter" value="Liter" />
                        <Picker.Item label="Kg" value="Kg" />
                        <Picker.Item label="Foot" value="Foot" />
                        <Picker.Item label="Pieces" value="Pieces" />
                        <Picker.Item label="Unit" value="Unit" />
                      </Picker>
                      <View
                        style={{
                          flexDirection: "row",
                          padding: 20,
                        }}
                      >
                        <Checkbox
                          status={
                            this.state.isReusable ? "checked" : "unchecked"
                          }
                          onPress={() => {
                            this.setState({
                              isReusable: !this.state.isReusable,
                            });
                          }}
                          color="#0a60ff"
                        />
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          Is this item reusable ?
                        </Text>
                      </View>
                    </View>

                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,
                              marginTop: 28,
                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.saveToDb();
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator size="small" color="#fff" />
                          ) : (
                            <Text style={{ color: "#fff" }}>Add Product</Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,
                              marginTop: 28,
                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              setVisible: false,
                              buttonLoading: false,
                            });
                            this.setState({
                              SnackbarVisible: false,
                              setVisible: false,
                              quantity: "",
                              note: "",
                              productName: "",
                              error: "",
                              UOM: "",
                              buttonLoading: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={{
                          padding: 20,

                          color: "red",
                        }}
                      >
                        {this.state.error}
                      </Text>
                    </View>
                  </View>
                </Modal>
              </Portal>

              {/* ------------- */}
              <Portal>
                <Modal
                  visible={this.state.maintenancesetVisible}
                  onDismiss={() => {
                    this.setState({ maintenancesetVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 650,
                    borderRadius: 5,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      {this.state.modalTitles}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      padding: 20,
                    }}
                  >
                    <FlatList
                      data={this.state.maintenanceData}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text style={styles.columnRowTxt}>{index + 1}</Text>
                            <Text style={styles.columnRowTxt}>{item.date}</Text>
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.productName}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.qty} {item.uom}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.submitedBy}
                            </Text>
                          </View>
                        );
                      }}
                      keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                      ListHeaderComponent={this.tableHeader}
                      ListEmptyComponent={
                        <View style={{ padding: 50 }}>
                          {this.state.buttonLoading ? (
                            <ActivityIndicator size="large" color="#0a60ff" />
                          ) : (
                            <Text>No Data Found !</Text>
                          )}
                        </View>
                      }
                    />
                  </View>
                </Modal>
              </Portal>
              {/* Model Ends */}

              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Avaliable Inventory
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "40%",
                      justifyContent: "space-around",
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.addNewButton, { backgroundColor: "#fff" }]}
                      onPress={() => {
                        this.setState({
                          maintenancesetVisible: true,
                          buttonLoading: true,
                          modalTitles: "MRO Maintenance List",
                        });
                        this.getMaintenanceList("repairList");
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>MRO Maintenance</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.addNewButton, { backgroundColor: "#fff" }]}
                      onPress={() => {
                        this.setState({
                          maintenancesetVisible: true,
                          buttonLoading: true,
                          modalTitles: "Scrapped Items",
                        });
                        this.getMaintenanceList("scrapList");
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Scrapped MRO</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.addNewButton}
                      onPress={() => {
                        this.setState({ setVisible: true });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Add New Product</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.tableBody}>{<MROtable />}</View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                Product Added Successfully !
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableBody: {
    maxHeight: 500,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.66%",
    textAlign: "center",
  },
  columnHeader: {
    width: "16.66%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
});
