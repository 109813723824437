import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import { Picker } from "@react-native-picker/picker";

import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { jsPDF } from "jspdf";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class viewLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      companyName: "",
      discription: "",
      contactPerson: "",
      contactPersonPost: "",
      type: "",
      address: "",
      contact: "",
      email: "",
      status: "",
      note: "",
      //
      buttonLoading: false,
      error: "",
      disabled: true,
      ///
      todayConvo: "",
      thread: [],
      setVisible: false,
      modalViewOnly: false,
      date: new Date().toDateString(),
      quotNumber: "",
      itemSearch: "",
      ratePerQty: "",
      uom: "",
      itemData: [],
      columns: ["Sr.no.", "Item", "Rate/unit", "Unit", "Action"],
      columnsQuote: ["Sr.no.", "Date", "Quotation No.", "Action"],

      searchDataItem: [],
      mainDataItem: [],
      viewDropDownItem: false,
      quotedata: [],
      quoteOnlyNumber: 0,
      hsnCode: "",
      uoid: "",
      fiscalyear: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
      loginUser: {},
    };
  }
  componentDidMount() {
    const { dataObject } = this.props.route.params;

    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState(
              {
                id: dataObject.id,
                companyName: dataObject.companyName,
                discription: dataObject.discription,
                contactPerson: dataObject.contactPerson,
                contactPersonPost: dataObject.contactPersonPost,
                type: dataObject.type,
                address: dataObject.address,
                contact: dataObject.contact,
                email: dataObject.email,
                status: dataObject.status,
                note: dataObject.note,
              },
              () => {
                this.callDataForThread();
                this.getQuoteList();
                this.getCompanyData();
                this.getLoginUser();
              }
            );
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this.setState({ companyData: data });
      }
    });
  };
  getQuoteList = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/quotations/${this.state.id}`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({
          quotedata: data.sort(function (a, b) {
            if (a.timestamp < b.timestamp) {
              return 1;
            }
            if (a.timestamp > b.timestamp) {
              return -1;
            }
            return 0;
          }),
        });
      }
    });
  };
  updateDB = async () => {
    if (
      this.state.companyName != "" &&
      this.state.discription != "" &&
      this.state.contactPerson != "" &&
      this.state.type != "" &&
      this.state.address != "" &&
      this.state.contact != "" &&
      this.state.note != ""
    ) {
      this.setState({ buttonLoading: true });

      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/factoryData/leads/${this.state.id}/`);

      await dbRef.update(
        {
          companyName: this.state.companyName,
          contactPerson: this.state.contactPerson,
          discription: this.state.discription,
          type: this.state.type,
          address: this.state.address,
          contact: this.state.contact,
          email: this.state.email,
          status: this.state.status,
          note: this.state.note,
          lastUpdate: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        },
        (error) => {
          if (!error) {
            this.setState(
              { SnackbarVisible: true, buttonLoading: false, disabled: true },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          }
        }
      );
    } else {
      this.setState({ error: "Fields are Required" });
    }
  };
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchDataItem: newdata });
    } else {
      this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  getItemData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };

  getQuoteNo = () => {
    var fiscalyear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
    }
    this.setState({ fiscalyear }, () => {
      let DMRef = firebase
        .database()
        .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);
      DMRef.once("value", (dataSnap) => {
        if (dataSnap.exists()) {
          this.setState({
            quotNumber: "Q" + (dataSnap.val().quoteNo + 1),
            quoteOnlyNumber: dataSnap.val().quoteNo + 1,
          });
        } else {
          DMRef.set({
            dmCount: 0,
            gstBillCount: 0,
            localBillCount: 0,
            nrdmCount: 0,
            quoteNo: 0,
            receiptNo: 0,
          }).then(() => {
            this.getQuoteNo();
          });
        }
      });
    });
  };
  emptyThread = () => {
    return <Text>No Conversation </Text>;
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  addThread = async () => {
    var rString = this.randomString(
      10,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/leadsConvo/${this.state.id}/${rString}`
      );
    await dbRef.set({
      convoTime: `${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      todayConvo: this.state.todayConvo,
      id: rString,
    });
  };

  callDataForThread = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/leadsConvo/${this.state.id}/`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());

        this.setState({
          thread: data,
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };
  tableHeader = () => (
    <View style={[styles.tableHeader, { zIndex: -1 }]}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column} </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderQuotation = () => (
    <View style={[styles.tableHeader, { zIndex: -1 }]}>
      {this.state.columnsQuote.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column} </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  addItemToArray = () => {
    let objectToAdd = {
      item: this.state.itemSearch,
      uom: this.state.uom,
      ratePerQty: this.state.ratePerQty,
      id: Date.parse(new Date()),
      hsnCode: this.state.hsnCode,
    };
    let currentArray = this.state.itemData.concat(objectToAdd);

    this.setState({ itemData: currentArray }, () => {
      this.setState({
        itemSearch: "",
        ratePerQty: "",
        uom: "",
        hsnCode: "",
      });
    });
  };
  saveQuotationToDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/quotations/${
          this.state.id
        }/${Date.parse(new Date())}`
      );

    dbRef
      .set({
        itemData: this.state.itemData,
        quotNumber: this.state.quotNumber,
        timestamp: Date.parse(new Date()),
        dateNumber: Date.parse(new Date().toDateString()),
        date: this.state.date,
      })
      .then(() => {
        let dbref = firebase
          .database()
          .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);

        dbref.update({
          quoteNo: this.state.quoteOnlyNumber,
        });
        this.setState({ setVisible: false });
      });
  };
  pdfDownload = () => {
    var doc = new jsPDF("portrait", "mm", "a4");
    doc.setFont("times", "bold");
    doc.setFontSize(24);
    doc.text(15, 21, `${this.state.companyData.organizationName}`);
    doc.setFont("times", "normal");
    doc.setFontSize(14);
    doc.text(15, 28, `${this.state.companyData.addressTwo}`);

    doc.text(
      15,
      34,
      `Mob. No. ${
        this.state.companyData.contactNo
          ? this.state.companyData.contactNo
          : this.state.uoid
      }`
    );
    doc.text(15, 40, `Email: ${this.state.companyData.email}`);
    doc.setFontSize(13);
    doc.text(
      15,
      47,
      `${
        this.state.companyData.gstNo
          ? "GSTIN" + ": " + this.state.companyData.gstNo
          : null
      }`
    );
    doc.rect(0, 54, 210, 0, "F");
    doc.rect(0, 55, 210, 0, "F");

    doc.setFontSize(16);
    doc.text(105, 65, "QUOTATION", "center");

    doc.rect(87, 67, 38, 0.2, "F");
    doc.setFontSize(12);
    doc.setFont("times", "bold");
    doc.text(195, 75, `Date: ${this.state.date}`, "right");
    doc.text(195, 82, `Quotation No.: ${this.state.quotNumber}`, "right");
    doc.setFont("times", "normal");
    doc.text(15, 90, "To,");
    doc.setFont("times", "bold");
    doc.text(25, 98, `${this.state.companyName},`);
    doc.text(25, 105, `${this.state.address}`);
    doc.setFont("times", "normal");
    doc.text(
      15,
      120,
      "Subject: Quotation for Stone Aggregate including loading, unloading and transportation."
    );
    doc.text(15, 130, "Dear Sir/Madam,");
    doc.text(
      195,
      138,
      "As per your request the rate for crushed Stone Aggregate including loading, unloading and",
      "right"
    );
    doc.text(15, 144, "transportation to your site are mention below.");
    doc.rect(15, 150, 180, 5);
    doc.rect(35, 150, 0, 5);
    doc.rect(120, 150, 0, 5);
    doc.rect(150, 150, 0, 5);

    doc.setFont("times", "bold");
    doc.text(18, 154, "Sr. No.");
    doc.text(40, 154, "Particular");
    doc.text(122, 154, "HSN Code");
    doc.text(152, 154, "Rate Per Unit");
    doc.setFont("times", "normal");
    let starty = 0;
    for (let index = 0; index < this.state.itemData.length; index++) {
      doc.rect(15, 155 + starty * 5, 180, 5); //+5
      doc.rect(35, 155 + starty * 5, 0, 5);
      doc.rect(120, 155 + starty * 5, 0, 5);
      doc.rect(150, 155 + starty * 5, 0, 5);
      doc.text(18, 159 + starty * 5, `${index + 1}`);
      doc.text(40, 159 + starty * 5, `${this.state.itemData[index].item}`);
      doc.text(122, 159 + starty * 5, `${this.state.itemData[index].hsnCode}`);
      doc.text(
        152,
        159 + starty * 5,
        `Rs. ${parseFloat(this.state.itemData[index].ratePerQty).toFixed(
          2
        )} per ${this.state.itemData[index].uom}`
      );
      if (index == this.state.itemData.length - 1) {
        doc.setFont("times", "normal");
        doc.text(15, 170 + (starty + 1) * 5, "Note:"); //+15 of last
        doc.text(
          20,
          177 + (starty + 1) * 5,
          "1. 2.5% CGST and 2.5% SGST will be applied extra on final amount."
        );
        doc.text(
          20,
          184 + (starty + 1) * 5,
          "2. Payment Terms: 100% Payment on delivery"
        );
        doc.text(15, 195 + (starty + 1) * 5, "Contact Person :");
        doc.text(15, 200 + (starty + 1) * 5, `1. ${this.state.loginUser.name}`);
        doc.text(
          25,
          205 + (starty + 1) * 5,
          `Mobile No: ${
            this.state.companyData.contactNo
              ? this.state.companyData.contactNo
              : this.state.uoid
          }`
        );
        doc.text(
          25,
          210 + (starty + 1) * 5,
          `Email: ${this.state.companyData.email}`
        );

        doc.text(
          195,
          225 + (starty + 1) * 5,
          `For ${this.state.companyData.organizationName}`,
          "right"
        );
        doc.setFont("times", "italic");
        doc.text(195, 240 + (starty + 1) * 5, "Authorise Signatory", "right");
      }
      starty = starty + 1;
    }

    doc.save(
      `${this.state.quotNumber}${this.state.companyName.replace(" ", "_")}.pdf`
    );
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.companyName} />
          <View style={styles.mainConsole}>
            <LeftBar leads="#c1c1c1" />
            <View style={styles.palyArea}>
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    width: 750,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Generate Quotation
                    </Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Quotation Date"
                        value={this.state.date}
                        style={[styles.inputField, { width: 200 }]}
                        editable={false}
                        placeholder="Quotation Date"
                      />
                      <TextInput
                        mode="outlined"
                        label="Quotation Number"
                        value={this.state.quotNumber}
                        style={[styles.inputField, { width: 200 }]}
                        editable={false}
                        placeholder="Quotation Number"
                      />
                    </View>
                    {this.state.modalViewOnly == false ? (
                      <View style={{ flexDirection: "row" }}>
                        <TextInput
                          mode="outlined"
                          label=" Select Item"
                          value={this.state.itemSearch}
                          style={[styles.inputField, { width: 200 }]}
                          onChangeText={(text) => {
                            if (this.state.modalViewOnly == false) {
                              this.setState({ itemSearch: text }, () => {
                                this.searchItemStringInArray(
                                  this.state.itemSearch
                                );
                                this.setState({ viewDropDownItem: true });
                              });
                            }
                          }}
                          editable={!this.state.modalViewOnly}
                          placeholder="Item"
                        />
                        {this.state.viewDropDownItem ? (
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#000",
                              borderRadius: 5,
                              position: "absolute",
                              marginTop: 70,
                              shadowOffset: {
                                width: 0,
                                height: 0,
                              },
                              shadowOpacity: 0.2,
                              shadowRadius: 5,
                              zIndex: 5,
                              marginLeft: 20,
                              maxHeight: 150,
                            }}
                          >
                            <FlatList
                              data={this.state.searchDataItem}
                              renderItem={({ item }) => {
                                return (
                                  <TouchableOpacity
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: 10,
                                      borderWidth: 1,
                                      borderColor: "#c1c1c1",
                                      borderRadius: 5,
                                      width: 200,
                                      alignItems: "center",
                                    }}
                                    onPress={() => {
                                      this.setState(
                                        {
                                          itemSearch: item.productName,
                                          hsnCode: item.hsn,
                                        },
                                        () => {
                                          this.setState({
                                            viewDropDownItem: false,
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    <Text style={{ color: "#000" }}>
                                      {item.productName}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }}
                              keyExtractor={(item) => JSON.stringify(item.id)}
                            />
                          </View>
                        ) : null}
                        <TextInput
                          mode="outlined"
                          label="Rate"
                          value={this.state.ratePerQty}
                          style={[styles.inputField, { width: 200 }]}
                          onChangeText={(text) => {
                            if (this.state.modalViewOnly == false) {
                              this.setState({ ratePerQty: text });
                            }
                          }}
                          placeholder="Rate"
                          editable={!this.state.modalViewOnly}
                        />
                        <Picker
                          selectedValue={this.state.uom}
                          style={{
                            height: 40,
                            width: 170,
                            marginHorizontal: 10,
                            marginTop: 28,
                          }}
                          onValueChange={(itemValue, itemIndex) =>
                            this.setState({ uom: itemValue })
                          }
                          editable={!this.state.modalViewOnly}
                        >
                          <Picker.Item label="Unit of Measurement" value="" />
                          <Picker.Item label="cft" value="cft" />
                          <Picker.Item label="m³" value="m³" />
                          <Picker.Item label="MT" value="MT" />
                        </Picker>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              height: 40,
                              marginTop: 28,
                              width: 100,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            if (
                              this.state.itemSearch != "" &&
                              this.state.uom != "" &&
                              this.state.ratePerQty != "" &&
                              this.state.modalViewOnly == false
                            ) {
                              this.addItemToArray();
                            }
                          }}
                        >
                          <Text style={{ color: "#0a60ff" }}>Add Item</Text>
                        </TouchableOpacity>
                      </View>
                    ) : null}

                    <View style={{ zIndex: -1 }}>
                      <FlatList
                        data={this.state.itemData}
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={[
                                styles.tableRow,
                                {
                                  backgroundColor:
                                    index % 2 == 1 ? "#F0FBFC" : "white",
                                  zIndex: -1,
                                },
                              ]}
                            >
                              <Text style={styles.columnRowTxt}>
                                {index + 1}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.item}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.ratePerQty}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.uom}
                              </Text>
                              <View
                                style={{
                                  width: "20%",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <TouchableOpacity
                                  style={[
                                    styles.actionbtn,
                                    { borderColor: "#DE5246" },
                                  ]}
                                  onPress={() => {
                                    if (this.state.modalViewOnly == false) {
                                      let array = this.state.itemData;
                                      array.splice(index, 1);
                                      this.setState({ itemData: array });
                                    }
                                  }}
                                >
                                  <Text style={{ color: "#DE5246" }}>
                                    Delete
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.id)}
                        ListHeaderComponent={this.tableHeader}
                        ListFooterComponent={
                          <View
                            style={{
                              justifyContent: "space-around",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <TouchableOpacity
                              style={[
                                styles.addNewButton,
                                {
                                  backgroundColor: "#fff",
                                  height: 40,
                                  marginTop: 28,
                                  width: 100,
                                  alignItems: "center",
                                },
                              ]}
                              onPress={() => {
                                this.setState({ setVisible: false }, () => {
                                  this.setState({
                                    itemData: [],
                                    itemSearch: "",
                                    ratePerQty: "",
                                    uom: "",
                                  });
                                });
                              }}
                            >
                              <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                            </TouchableOpacity>
                            {this.state.modalViewOnly == false ? (
                              <TouchableOpacity
                                style={[
                                  styles.addNewButton,
                                  {
                                    backgroundColor: "#009C44",
                                    height: 40,
                                    marginTop: 28,
                                    width: 100,
                                    alignItems: "center",
                                    borderColor: "#009C44",
                                  },
                                ]}
                                onPress={() => {
                                  if (
                                    this.state.itemData &&
                                    this.state.modalViewOnly == false
                                  ) {
                                    this.saveQuotationToDb();
                                  }
                                }}
                              >
                                <Text style={{ color: "#fff" }}>Generate</Text>
                              </TouchableOpacity>
                            ) : null}

                            <TouchableOpacity
                              style={[
                                styles.addNewButton,
                                {
                                  backgroundColor: "#009C44",
                                  height: 40,
                                  marginTop: 28,
                                  width: 100,
                                  alignItems: "center",
                                  borderColor: "#009C44",
                                },
                              ]}
                              onPress={() => {
                                if (this.state.itemData) {
                                  this.pdfDownload();
                                }
                              }}
                            >
                              <Text style={{ color: "#fff" }}>Print</Text>
                            </TouchableOpacity>
                          </View>
                        }
                        ListEmptyComponent={
                          <Text style={{ textAlign: "center" }}>
                            No Item Added
                          </Text>
                        }
                      />
                    </View>
                  </View>
                </Modal>
              </Portal>
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Name of the Client"
                    value={this.state.companyName}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.addresscolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ companyName: text });
                    }}
                    placeholder="Name"
                  />
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Discription"
                    value={this.state.discription}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.contactcolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ discription: text });
                    }}
                    placeholder="Ex:Contractor/Hotmix/Factory.."
                  />
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Contact Person Name"
                    value={this.state.contactPerson}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.addresscolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ contactPerson: text });
                    }}
                    placeholder="Name"
                  />
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Post of Person"
                    value={this.state.contactPersonPost}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.contactcolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ contactPersonPost: text });
                    }}
                    placeholder="Owner/Manager/supervisor.."
                  />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Picker
                    disabled={this.state.disabled}
                    selectedValue={this.state.type}
                    style={{
                      height: 40,
                      width: 360,
                      marginHorizontal: 22,
                      borderColor: this.state.postcolor,
                    }}
                    onValueChange={(itemValue, itemIndex) =>
                      this.setState({ type: itemValue })
                    }
                  >
                    <Picker.Item label="Type" value="" />
                    <Picker.Item label="Private" value="Private" />
                    <Picker.Item label="Government" value="Government" />
                  </Picker>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Address"
                    value={this.state.address}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.namecolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ address: text });
                    }}
                    placeholder="Address"
                  />
                </View>

                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Contact No."
                    value={this.state.contact}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ contact: text });
                    }}
                    placeholder="Mobile no."
                  />
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Email"
                    value={this.state.email}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ email: text });
                    }}
                    placeholder="xyzopp@company.com"
                  />
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Picker
                    disabled={this.state.disabled}
                    selectedValue={this.state.status}
                    style={{
                      height: 40,
                      width: 360,
                      marginHorizontal: 22,
                      borderColor: this.state.postcolor,
                    }}
                    onValueChange={(itemValue, itemIndex) =>
                      this.setState({ status: itemValue })
                    }
                  >
                    <Picker.Item label="Status" value="" />
                    <Picker.Item label="In Queue" value="In Queue" />
                    <Picker.Item label="Contacted" value="Contacted" />
                    <Picker.Item label="Working" value="Working" />
                    <Picker.Item label="Converted" value="converted" />
                    <Picker.Item
                      label="Attention Required"
                      value="Attention Required"
                    />
                  </Picker>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Note"
                    multiline
                    value={this.state.note}
                    style={[
                      styles.inputField,
                      { backgroundColor: this.state.namecolor },
                    ]}
                    onChangeText={(text) => {
                      this.setState({ note: text });
                    }}
                    placeholder="Note"
                  />
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.state.disabled
                          ? this.setState({ disabled: false })
                          : this.updateDB();
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : this.state.disabled ? (
                        <Text style={{ color: "#fff" }}>Edit</Text>
                      ) : (
                        <Text style={{ color: "#fff" }}>Save</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.goBack();
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={{
                      padding: 20,

                      color: "red",
                    }}
                  >
                    {this.state.error}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={[
                        styles.threadContainer,
                        { flex: 1, width: "100%" },
                      ]}
                    >
                      <View
                        style={{
                          borderBottomWidth: 1,
                          borderTopWidth: 1,
                          borderColor: "#c1c1c1",
                        }}
                      >
                        <Text
                          style={{
                            padding: 12,
                            fontSize: 22,
                            fontWeight: "500",
                            justifyContent: "center",
                          }}
                        >
                          Conversation Thread
                        </Text>
                      </View>

                      <View
                        style={{
                          flexDirection: "column",
                          alignSelf: "center",
                          width: "80%",
                        }}
                      >
                        <TextInput
                          multiline
                          mode="outlined"
                          label="Note"
                      
                          value={this.state.todayConvo}
                          style={[
                            styles.inputField,
                            { height: 120, width: "100%" },
                          ]}
                          onChangeText={(text) => {
                            this.setState({ todayConvo: text });
                          }}
                          placeholder="Todays Details"
                        />
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#0a60ff",
                              height: 40,
                              marginTop: 28,
                              width: 100,
                              alignItems: "center",
                              alignSelf: "flex-end",
                            },
                          ]}
                          onPress={() => {
                            if (this.state.todayConvo) {
                              this.addThread();
                            }
                          }}
                        >
                          <Text style={{ color: "#fff" }}>Add</Text>
                        </TouchableOpacity>
                      </View>
                      <View>
                        <FlatList
                          data={this.state.thread}
                          renderItem={({ item }) => {
                            return (
                              <View style={{ alignSelf: "center" }}>
                                <TextInput
                                  disabled
                                  multiline
                                  mode="outlined"
                                  label="Note"
                                  value={item.todayConvo}
                                  style={[styles.inputField, { height: 80 }]}
                                />
                                <Text
                                  style={{
                                    alignSelf: "flex-end",
                                    marginTop: -16,
                                  }}
                                >
                                  {item.convoTime}
                                </Text>
                              </View>
                            );
                          }}
                          keyExtractor={(item) =>
                            JSON.stringify(item.convoTime)
                          }
                          ListEmptyComponent={() => this.emptyThread()}
                          inverted
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        borderLeftWidth: 1,
                        borderColor: "#c1c1c1",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottomWidth: 1,
                          borderTopWidth: 1,
                          borderColor: "#c1c1c1",
                        }}
                      >
                        <Text
                          style={{
                            padding: 12,
                            fontSize: 22,
                            fontWeight: "500",
                            justifyContent: "center",
                          }}
                        >
                          Quotations
                        </Text>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              height: 40,
                              marginTop: 7,
                              marginHorizontal: 20,
                              width: 150,
                              alignItems: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              setVisible: true,
                              modalViewOnly: false,
                              itemData: [],
                            });
                            this.getItemData();
                            this.getQuoteNo();
                          }}
                        >
                          <Text style={{ color: "#fff" }}>Generate Quote</Text>
                        </TouchableOpacity>
                      </View>
                      <FlatList
                        data={this.state.quotedata}
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={[
                                styles.tableRow,
                                {
                                  backgroundColor:
                                    index % 2 == 1 ? "#F0FBFC" : "white",
                                  zIndex: -1,
                                },
                              ]}
                            >
                              <Text
                                style={[styles.columnRowTxt, { width: "25%" }]}
                              >
                                {index + 1}
                              </Text>
                              <Text
                                style={[styles.columnRowTxt, { width: "25%" }]}
                              >
                                {item.date}
                              </Text>
                              <Text
                                style={[styles.columnRowTxt, { width: "25%" }]}
                              >
                                {item.quotNumber}
                              </Text>

                              <View
                                style={{
                                  width: "25%",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <TouchableOpacity
                                  style={[
                                    styles.actionbtn,
                                    { borderColor: "#0a60ff" },
                                  ]}
                                  onPress={() => {
                                    this.setState(
                                      { modalViewOnly: true },
                                      () => {
                                        this.setState({
                                          setVisible: true,
                                          date: item.date,
                                          quotNumber: item.quotNumber,
                                          itemData: item.itemData,
                                        });
                                      }
                                    );
                                  }}
                                >
                                  <Text style={{ color: "#0a60ff" }}>View</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={[
                                    styles.actionbtn,
                                    { borderColor: "#DE5246" },
                                  ]}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        date: item.date,
                                        quotNumber: item.quotNumber,
                                        itemData: item.itemData,
                                      },
                                      () => {
                                        this.pdfDownload();
                                      }
                                    );
                                  }}
                                >
                                  <Text style={{ color: "#DE5246" }}>PDF</Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.timestamp)}
                        ListHeaderComponent={this.tableHeaderQuotation}
                        ListEmptyComponent={
                          <TouchableOpacity
                            style={[
                              styles.addNewButton,
                              {
                                height: 40,
                                marginTop: 7,
                                marginHorizontal: 20,
                                width: 150,
                                alignItems: "center",
                              },
                            ]}
                            onPress={() => {
                              this.setState({ setVisible: true });
                              this.getItemData();
                              this.getQuoteNo();
                            }}
                          >
                            <Text style={{ color: "#fff" }}>
                              Generate Quote
                            </Text>
                          </TouchableOpacity>
                        }
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
});
