import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  Modal,
  Portal,
  Provider,
  RadioButton,
  TextInput,
  Snackbar,
  Checkbox,
} from "react-native-paper";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import PurchaseReportTable from "../src/components/PurchaseReportTable";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class purchaseReportScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorId: "",
      vendorName: "",
      address: "",
      gst: "",
      mainArray: [],

      //For Ledger Entry
      setVisible: false, //modal visible
      date: new Date(),
      dateforEntry: new Date(),
      DataforLedgerEntry: [], //Selected For Ledger Generation
      viewCalender: false,
      // lastEntry: {}, //last entry to get balance amount of customer
      columns: [
        "Sr no.",
        "Date",
        "Particular",
        "Receipt No.",
        "Pay. Mode",
        "Amount",
      ],
      ledgerCalculations: {},
      lodingLedgerCal: false,
      addGsttoLedger: true,
      acceptEntryLedger: false,
      //
      //For Bill Generation
      uniqueItem: [],
      setVisibleBill: false,
      viewCalenderForBill: false,
      dateForBill: new Date(),
      billData: [],
      billno: 0,
      itemSplit: [],
      billCalculations: {},
      acceptBillGen: false,
      //
      loadingBasic: true,
      modalOpened: null,
      buttonloading: false,

      debitAmt: 0,
      SnackbarVisible: false,
      error: "",
      productRate: [], //common
      subtotal: [], // not in use
      itemSplit: [], //commmon
      uoid: "",
      loginUser: "",
    };
  }
  componentDidMount() {
    const { vendorId } = this.props.route.params;
    this.setState({ vendorId });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid, vendorId }, () => {
            this.getBasicsFromDb();
            this.getLoginUser();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  getLoginUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let loginId = firebase.auth().currentUser.email;
        if (loginId) {
          this.setState({ loginUser: loginId });
        }
      }
    });
  };
  getBasicsFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();
        this.setState(
          {
            vendorName: data.vendorName,
            address: data.address,
            gst: data.gst,
          },
          () => {
            this.setState({ loadingBasic: false });
            this.filterForLedgerEntry();
            // let dbref1 = firebase.database().ref("factoryData/extra/2021_22");
            // dbref1.on("value", (DataSnapShot) => {
            //   if (DataSnapShot.val()) {
            //     this.state.gst
            //       ? this.setState({
            //           billno: DataSnapShot.val().gstBillCount + 1,
            //         })
            //       : this.setState({
            //           billno: DataSnapShot.val().localBillCount + 1,
            //         });
            //   }
            // });
          }
        );
      }
    });
  };
  ledgerCalculations = () => {
    this.setState({ lodingLedgerCal: true });
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.otherAmount);

    let ledTotal = this.state.DataforLedgerEntry.reduce(reducer1, 0);
    this.setState(
      {
        ledgerCalculations: {
          ledTotal: ledTotal,
        },
      },
      () => {
        this.setState({ lodingLedgerCal: false });
      }
    );
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveToLedger = () => {
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let ledgerTime = new Date();
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/ledger/${this.state.vendorId}/${ledgerTime}`
      );

    dbref
      .set({
        ledgerId: ledgerId,
        dateNumber: Date.parse(this.state.date),
        transDate: this.state.date.toDateString(),
        discription: this.state.vendorName + "Bill received",
        credit: this.state.ledgerCalculations.ledTotal,
        debit: this.state.debitAmt,
        // balance:
        //   parseFloat(this.state.lastEntry.balance) +
        //   parseFloat(this.state.ledgerCalculations.ledTotal) -
        //   parseFloat(this.state.debitAmt),
        details: {
          receiptArray: this.state.DataforLedgerEntry,
          ledgerCalculations: this.state.ledgerCalculations,
        },
        entryBy: this.state.loginUser,
        timeStampId: `${ledgerTime}`,
      })
      .then(() => {
        this.ledgerGenerated();
      });
  };
  ledgerGenerated = () => {
    this.state.DataforLedgerEntry.map(
      (item) => {
        let dbRef = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22/${item.id}`
          );
        dbRef.update({
          isLedgerEntryDone: true,
        });
      },
      () => {
        this.setState(
          { SnackbarVisible: true, error: "Entry Successfully !" },
          () => {
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 2500);
          }
        );
      }
    );
  };

  filterForLedgerEntry = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.state.vendorId}/FY2021_22`
      )
      .orderByChild("receiptNo");

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ mainArray: data });
        let newData = data.filter(
          (item) =>
            item.checkedByAuthPerson == true &&
            item.isLedgerEntryDone == false &&
            item.isMarkforLedgerGen == true
        );
        this.setState({
          DataforLedgerEntry: newData.sort(function (a, b) {
            if (a.receiptNo < b.receiptNo) {
              return 1;
            }
            if (a.receiptNo > b.receiptNo) {
              return -1;
            }
            return 0;
          }),
          isLoading: false,
        });
      }
    });
  };
  // getLastEntry = () => {
  //   let ledgerRef1 = firebase
  //     .database()
  //     .ref(
  //       `${this.state.uoid}/factoryData/vendorData/ledger/${this.state.vendorId}`
  //     )
  //     .orderByChild("dateNumber")
  //     .limitToLast(1);

  //   ledgerRef1.once("value", (snapshot) => {
  //     if (snapshot.val()) {
  //       let data = Object.values(snapshot.val());
  //       this.setState({ lastEntry: data[0] });
  //     }
  //   });
  // };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableFooter = () => {
    // const reducer = (accumulator, currentValue) =>
    //   accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      `${this.state.DataforLedgerEntry[0].date} to ${
        this.state.DataforLedgerEntry[this.state.DataforLedgerEntry.length - 1]
          .date
      }`,
      <Text>Total Receipt{this.state.DataforLedgerEntry.length}</Text>,
      <View>
        {/* <Text>Anything to add</Text> */}
        {/* {itemSplit.map((item1) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.newData.reduce(reducer, 0)}</Text>
        </View> */}
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>{this.state.ledgerCalculations.ledTotal}</Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 100 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { width: "25%" }]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {item}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  tableFooterForBill = () => {
    // let itemSplit = []; //eg:[[],[],....]

    // for (let index = 0; index < this.state.uniqueItem.length; index++) {
    //   itemSplit.push([]);
    //   for (let index2 = 0; index2 < this.state.billData.length; index2++) {
    //     if (
    //       this.state.uniqueItem[index].item == this.state.billData[index2].item
    //     ) {
    //       itemSplit[index].push(this.state.billData[index2]);
    //     }
    //   }
    // }

    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      "Total",
      `Total DM : ${this.state.billData.length}`,
      <View>
        {this.state.itemSplit.map((item1) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.billData.reduce(reducer, 0)}</Text>
        </View>
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>{this.state.billCalculations.billTotal}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% SGST</Text>

          <Text>{this.state.billCalculations.sgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% CGST</Text>

          <Text>{this.state.billCalculations.cgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total GST</Text>

          <Text>
            {this.state.billCalculations.cgst +
              this.state.billCalculations.sgst}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Grand Total</Text>

          <Text>{this.state.billCalculations.billGrandTotal}</Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 120 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { paddingTop: 10, width: "25%" }]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {item}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  updateSubTotal = () => {
    this.state.newData.map((item1, index1) => {
      this.state.productRate.map((initem, index2) => {
        if (Object.keys(initem) == item1.item) {
          let data = this.state.subtotal.concat(
            initem[`${item1.item}`] * item1.sellQtyCft
          );
          this.setState({ subtotal: data });
        }
      });
    });
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Purchase Report" />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" vendor="underline" />
            {/* Modal Section Start------------------------ */}
            <Portal>
              <Modal
                visible={this.state.setVisible}
                onDismiss={() => {
                  this.setState({ setVisible: false }, () => {
                    this.setState({
                      ledgerCalculations: {},
                      lodingLedgerCal: false,
                      addGsttoLedger: true,
                      acceptEntryLedger: false,
                      date: new Date(),
                      dateforEntry: new Date(),
                      //   DataforLedgerEntry: [], //Selected For Ledger Generation
                      viewCalender: false,
                      // lastEntry: {}, //last entry to get balance amount of customer
                    });
                  });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  borderWidth: 1,
                  alignSelf: "center",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    Debit Ledger of {this.state.customerName}
                  </Text>
                </View>
                <View>
                  <View
                    style={{
                      padding: 8,
                      borderBottomWidth: 1,
                      borderBottomColor: "#c1c1c1",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          padding: 10,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Customer Name : {this.state.vendorName}
                      </Text>
                      <Text
                        style={{
                          padding: 10,
                          fontSize: 16,
                        }}
                      >
                        Current Entry Type : Credit
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        Customer Address: {this.state.address}
                      </Text>
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        Total Receipt in Entry:{" "}
                        {this.state.DataforLedgerEntry.length}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        {/* Current Balance ₹: {this.state.lastEntry.balance} */}
                      </Text>
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        New Credit Amount:{" "}
                        {this.state.ledgerCalculations.ledTotal}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        {/* Last Entry Date: {this.state.lastEntry.transDate} */}
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          New Entry Date
                        </Text>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 10,
                              alignSelf: "center",
                              padding: 10,
                              width: 120,
                              textAlignVertical: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              viewCalender: !this.state.viewCalender,
                            });
                          }}
                        >
                          {this.state.date.getDate()}/
                          {this.state.date.getMonth() + 1}/
                          {this.state.date.getFullYear()}
                        </Text>

                        <View
                          style={{
                            paddingLeft: 20,
                            marginTop: -270,
                            zIndex: 5,
                            position: "absolute",
                          }}
                        >
                          {this.state.viewCalender ? (
                            <Calendar
                              value={this.state.date}
                              onChange={(value) => {
                                this.setState({ date: value }, () => {
                                  this.setState({ viewCalender: false });
                                });
                              }}
                            />
                          ) : null}
                        </View>
                      </View>
                    </View>
                    <View style={{ height: 300 }}>
                      <FlatList
                        data={this.state.DataforLedgerEntry}
                        renderItem={({ item, index }) => {
                          return (
                            <View style={{ zIndex: -5 }}>
                              <View
                                style={[
                                  styles.tableRow,
                                  {
                                    backgroundColor:
                                      index % 2 == 1 ? "#F0FBFC" : "white",
                                  },
                                ]}
                              >
                                <Text style={styles.columnRowTxt}>
                                  {index + 1}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.date}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold" },
                                  ]}
                                >
                                  {item.item}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.receiptNo}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.paymentMode}
                                </Text>

                                {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold" },
                                  ]}
                                >
                                  {item.otherAmount}
                                </Text>
                              </View>
                            </View>
                          );
                        }}
                        ListHeaderComponent={this.tableHeader}
                        ListFooterComponent={this.tableFooter}
                        stickyHeaderIndices={[0]}
                        ListEmptyComponent={
                          <View style={{ padding: 50 }}>
                            <ActivityIndicator size="large" color="#0a60ff" />
                          </View>
                        }
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <Checkbox
                        status={
                          this.state.acceptEntryLedger ? "checked" : "unchecked"
                        }
                        onPress={() => {
                          this.setState({
                            acceptEntryLedger: !this.state.acceptEntryLedger,
                          });
                        }}
                        color="#0a60ff"
                      />
                      <Text style={{ padding: 8, fontWeight: "bold" }}>
                        Make a Credit entry of{" "}
                        {this.state.ledgerCalculations.ledGrandTotal}
                      </Text>
                      <TouchableOpacity
                        disabled={!this.state.acceptEntryLedger}
                        style={styles.addNewButton}
                        onPress={() => {
                          if (this.state.acceptEntryLedger) {
                            this.setState({ setVisible: false }, () => {
                              this.saveToLedger();
                            });
                          }
                        }}
                      >
                        <Text style={{ color: "#fff", alignSelf: "center" }}>
                          Save Ledger
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        { width: 150, backgroundColor: "#fff" },
                      ]}
                      onPress={() => {
                        this.setState({ setVisible: false }, () => {
                          this.setState({
                            ledgerCalculations: {},
                            lodingLedgerCal: false,
                            addGsttoLedger: true,
                            acceptEntryLedger: false,
                            date: new Date(),
                            dateforEntry: new Date(),
                            // DataforLedgerEntry: [], //Selected For Ledger Generation
                            viewCalender: false,
                            // lastEntry: {}, //last entry to get balance amount of customer
                          });
                        });
                      }}
                    >
                      <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </Portal>
            {/* Modal Section Ends------------------------- */}
            {/* {Modal Section 2 for bill start} */}
            <Portal>
              <Modal
                visible={this.state.setVisibleBill}
                onDismiss={() => {
                  this.setState({ setVisibleBill: false });
                }}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  borderWidth: 1,
                  alignSelf: "center",
                  width: 850,
                  borderRadius: 5,
                  borderColor: "#c1c1c1",
                }}
              >
                <View style={styles.modelHeader}>
                  <Text style={styles.modelHeaderText}>
                    Generate bill for selected DM of {this.state.customerName}
                  </Text>
                </View>
                <View>
                  <View
                    style={{
                      padding: 8,
                      borderBottomWidth: 1,
                      borderBottomColor: "#c1c1c1",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          padding: 10,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Customer Name : {this.state.customerName}
                      </Text>
                      <Text
                        style={{
                          padding: 10,
                          fontSize: 16,
                        }}
                      >
                        Bill no:{this.state.billno}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        Customer Address: {this.state.address}
                      </Text>
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        Total DM in Bill: {this.state.billData.length}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ padding: 10, fontSize: 16 }}>
                        GSTIN: {this.state.gst}
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          Bill Date
                        </Text>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 10,
                              alignSelf: "center",
                              padding: 10,
                              width: 120,
                              textAlignVertical: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              viewCalenderForBill:
                                !this.state.viewCalenderForBill,
                            });
                          }}
                        >
                          {this.state.dateForBill.getDate()}/
                          {this.state.dateForBill.getMonth() + 1}/
                          {this.state.dateForBill.getFullYear()}
                        </Text>

                        <View
                          style={{
                            paddingLeft: 20,
                            marginTop: -270,
                            zIndex: 5,
                            position: "absolute",
                          }}
                        >
                          {this.state.viewCalenderForBill ? (
                            <Calendar
                              value={this.state.dateForBill}
                              onChange={(value) => {
                                this.setState({ dateForBill: value }, () => {
                                  this.setState({ viewCalenderForBill: false });
                                });
                              }}
                            />
                          ) : null}
                        </View>
                      </View>
                    </View>
                    <View style={{ height: 300 }}>
                      <FlatList
                        data={this.state.billData}
                        renderItem={({ item, index }) => {
                          return (
                            <View style={{ zIndex: -5 }}>
                              <View
                                style={[
                                  styles.tableRow,
                                  {
                                    backgroundColor:
                                      index % 2 == 1 ? "#F0FBFC" : "white",
                                  },
                                ]}
                              >
                                <Text style={styles.columnRowTxt}>
                                  {index + 1}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.date}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold" },
                                  ]}
                                >
                                  {item.lorryNo}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.royaltyNo}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.dmNo}
                                </Text>

                                {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold" },
                                  ]}
                                >
                                  {item.item}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.sellQtyCft}
                                </Text>
                                <Text style={styles.columnRowTxt}>
                                  {item.paymentMode}
                                </Text>

                                <Text style={styles.columnRowTxt}>
                                  {item.totalAmount}
                                </Text>
                              </View>
                            </View>
                          );
                        }}
                        ListHeaderComponent={this.tableHeader}
                        ListFooterComponent={this.tableFooterForBill}
                        stickyHeaderIndices={[0]}
                        ListEmptyComponent={
                          <View style={{ padding: 50 }}>
                            <ActivityIndicator size="large" color="#0a60ff" />
                          </View>
                        }
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <Checkbox
                        status={
                          this.state.acceptBillGen ? "checked" : "unchecked"
                        }
                        onPress={() => {
                          this.setState({
                            acceptBillGen: !this.state.acceptBillGen,
                          });
                        }}
                        color="#0a60ff"
                      />
                      <Text style={{ padding: 8, fontWeight: "bold" }}>
                        Make a Credit entry of{" "}
                        {this.state.ledgerCalculations.ledGrandTotal}
                      </Text>
                      <TouchableOpacity
                        disabled={!this.state.acceptBillGen}
                        style={styles.addNewButton}
                        onPress={() => {
                          if (this.state.acceptBillGen) {
                            this.setState({ setVisibleBill: false });
                            this.saveToBills();
                          }
                        }}
                      >
                        <Text style={{ color: "#fff", alignSelf: "center" }}>
                          Generate and save Bill
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        { width: 150, backgroundColor: "#fff" },
                      ]}
                      onPress={() => {
                        this.setState({ setVisibleBill: false });
                      }}
                    >
                      <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </Portal>
            {/* Ends modl */}
            <View style={styles.palyArea}>
              <View
                style={{
                  flexDirection: "row",
                  marginHorizontal: 55,
                  marginTop: 10,
                }}
              >
                <Ionicons
                  name="arrow-back"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                  size={24}
                  color="black"
                  style={{ marginRight: 30 }}
                />
                <View style={{ flexDirection: "column", flex: 3 }}>
                  <Text style={{ fontSize: 18 }}>
                    {this.state.vendorName}, {this.state.address}
                  </Text>
                  <Text style={{ marginTop: 5, fontSize: 12 }}>
                    {this.state.gst}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("ledgerVendorScreen", {
                        vendorId: this.state.vendorId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("vendorEdit", {
                        vendorId: this.state.vendorId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Profile</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    {this.state.vendorName}-Purchase Report
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: 280,
                    }}
                  >
                    {/* <TouchableOpacity
                      style={styles.addNewButton}
                      onPress={() => {
                        this.setState({ setVisibleBill: true });
                        this.billDataArray();
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Generate Bill</Text>
                    </TouchableOpacity> */}
                    <TouchableOpacity
                      style={styles.addNewButton}
                      onPress={() => {
                        if (this.state.DataforLedgerEntry.length != 0) {
                          this.setState({ setVisible: true });
                          // this.getLastEntry();
                          this.filterForLedgerEntry();
                          this.ledgerCalculations();
                        } else {
                          this.setState(
                            {
                              error: "No Ledger Entry Selected",
                              SnackbarVisible: true,
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({ SnackbarVisible: false });
                              }, 2500);
                            }
                          );
                        }
                      }}
                    >
                      <Text style={{ color: "#fff" }}>New Ledger Entry</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.tableBody}>
                  <PurchaseReportTable
                    vendorId={this.state.vendorId}
                    vendorName={this.state.vendorName}
                    vendorAddress={this.state.address}
                  />
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },

  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  columnHeader: {
    width: "16.6%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnFooter: {
    width: "16.66%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "#c1c1c1",
    paddingTop: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.66%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
});
