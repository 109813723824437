import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";

import {
  Feather,
  SimpleLineIcons,
  FontAwesome,
  Ionicons,
  AntDesign,
  MaterialCommunityIcons,
  EvilIcons,
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import firebase from "firebase";
import erpLogo from "../images/erp.jpg";
import { Modal, Portal, Provider, Divider } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Header({
  title,
  borderWidth1,
  color1,
  borderWidth2,
  color2,
  order,
  showHideLeftBar,
  showBackArrow,
}) {
  const navigation = useNavigation();
  const [userData, setuserData] = useState({
    name: "",
    loginId: "",
    admin: false,
  });
  const [setVisible, stateSetVisible] = useState(false);
  useEffect(() => {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              let loginId = firebase.auth().currentUser.email
                ? firebase.auth().currentUser.email
                : user.phoneNumber;
              if (loginId != null) {
                let userid = loginId.split(".")[0];
                let dbRef = firebase
                  .database()
                  .ref(`${uoid}/erpData/users/${userid}`);

                dbRef.once("value", (DataSnapShot) => {
                  if (DataSnapShot.val()) {
                    setuserData(DataSnapShot.val());
                  }
                });
              }
            }
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <View style={styles.mainHeader}>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "flex-start",
          padding: 12,
        }}
      >
        <TouchableOpacity
          onPress={() => {}}
          style={{ justifyContent: "center", paddingHorizontal: 10 }}
        >
          <Feather name="menu" size={24} color="black" />
        </TouchableOpacity>
        <Image
          source={erpLogo}
          style={{ width: 90, height: 30, paddingLeft: 10 }}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 5,
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            padding: 12,
            justifyContent: "center",
          }}
        >
          {showBackArrow == false ? null : (
            <Ionicons
              name="arrow-back"
              onPress={() => {
                navigation.goBack();
              }}
              size={24}
              color="black"
              style={{ marginRight: 15, paddingTop: 5 }}
            />
          )}

          <Text style={styles.headertitle}>{title}</Text>
        </View>
        <Portal>
          <Modal
            visible={setVisible}
            onDismiss={() => {
              stateSetVisible(false);
            }}
            contentContainerStyle={{
              backgroundColor: "#ffffff",
              width: 400,
              borderRadius: 5,
              borderColor: "#c1c1c1",
              borderWidth: 3,
              alignSelf: "center",
            }}
          >
            {userData.admin ? (
              <View>
                <TouchableOpacity
                  style={styles.navOptionsModal}
                  onPress={() => {
                    navigation.navigate("reportScreen", {
                      admin: userData.admin,
                    });
                    stateSetVisible(false);
                  }}
                >
                  <MaterialCommunityIcons
                    name="account-box-multiple-outline"
                    size={24}
                    color="black"
                  />
                  <Text style={{ paddingLeft: 14, fontSize: 16 }}>Reports</Text>
                </TouchableOpacity>
                <Divider />
              </View>
            ) : null}
            <TouchableOpacity
              style={styles.navOptionsModal}
              onPress={() => {
                navigation.navigate("companyDetails", {
                  admin: userData.admin,
                });
                stateSetVisible(false);
              }}
            >
              <Ionicons name="business-outline" size={24} color="black" />
              <Text style={{ paddingLeft: 14, fontSize: 16 }}>
                Company Details
              </Text>
            </TouchableOpacity>
            <Divider />

            <TouchableOpacity
              style={styles.navOptionsModal}
              onPress={() => {
                navigation.navigate("othersScreen", {
                  admin: userData.admin,
                });
                stateSetVisible(false);
              }}
            >
              <MaterialCommunityIcons
                name="check-network-outline"
                size={24}
                color="black"
              />
              <Text style={{ paddingLeft: 14, fontSize: 16 }}>
                Subscriptions and Others
              </Text>
            </TouchableOpacity>

            <Divider />
            <TouchableOpacity
              style={styles.navOptionsModal}
              onPress={() => {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    AsyncStorage.removeItem("uoid");
                  });
              }}
            >
              <SimpleLineIcons name="logout" size={20} color="black" />
              <Text style={{ paddingLeft: 14, fontSize: 16 }}>Logout</Text>
            </TouchableOpacity>
          </Modal>
        </Portal>
        <View style={styles.controllButton}>
          <TouchableOpacity
            onPress={() => navigation.navigate("cashBookScreen")}
            style={[
              styles.navOptions,
              {
                borderBottomWidth: borderWidth1 ? borderWidth1 : 0,
                borderColor: color1 ? color1 : "#000",
              },
            ]}
          >
            <MaterialCommunityIcons
              name="account-details-outline"
              size={24}
              color={color1 ? color1 : "#000"}
            />
            <Text
              style={{
                paddingLeft: 7,
                color: color1 ? color1 : "#000",
              }}
            >
              Site Cash Book
            </Text>
          </TouchableOpacity>
          {userData.admin ? (
            <TouchableOpacity
              onPress={() => navigation.navigate("adminCashBook")}
              style={[
                styles.navOptions,
                {
                  borderBottomWidth: borderWidth2 ? borderWidth2 : 0,
                  borderColor: color2 ? color2 : "#000",
                },
              ]}
            >
              <MaterialCommunityIcons
                name="account-box-outline"
                size={24}
                color={color2 ? color2 : "#000"}
              />
              <Text
                style={{
                  paddingLeft: 7,
                  color: color2 ? color2 : "#000",
                }}
              >
                Transaction Book
              </Text>
            </TouchableOpacity>
          ) : null}
          <TouchableOpacity
            style={[styles.navOptions]}
            onPress={() => {
              stateSetVisible(true);
            }}
          >
            <Feather
              name="menu"
              size={24}
              color="black"
              style={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    height: 59,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headertitle: {
    fontSize: 22,
    fontWeight: "500",
  },
  controllButton: {
    flexDirection: "row",
    marginRight: 80,
    marginTop: 5,
    justifyContent: "space-between",
  },
  navOptions: {
    height: 53,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 15,
  },
  navOptionsModal: {
    height: 50,
    paddingLeft: 16,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30%",
  },
});
