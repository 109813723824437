//Line 102
import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  Modal,
  Portal,
  Button,
  Provider,
  TextInput,
  RadioButton,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import CustomerTable from "../src/components/CustomerTable";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class customerScreen extends React.Component {
  render() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Customer Management" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" customer="underline" />
            <View style={styles.palyArea}>
              {/* Model Start */}

              {/* Model Ends */}
              <View style={styles.table}>
                <View style={styles.tableBody}>
                  <CustomerTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    width: 1316,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },

  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
