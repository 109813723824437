import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import { jsPDF } from "jspdf";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

class LedgerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: [],
      searchData: [],

      isLoading: true,
      columns: [
        "Sr. no.",
        "Date",
        "Particular",
        "Debit",
        "Credit",
        "Balance",
        "Dr/Cr",
      ],

      customerId: "",
      dateFrom: new Date(),
      viewCalenderFrom: false,
      dateTo: new Date(),
      viewCalenderTo: false,
      isButtonLoading: false,
      creditTotal: 0,
      debitTotal: 0,
      uoid: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          var newDate = new Date(`${new Date().getFullYear()}-1-1`);
          this.setState(
            { uoid, customerId: this.props.customerId, dateFrom: newDate },
            () => {
              this.callDataFromDB();
              this.getCompanyData();
            }
          );
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this.setState({ companyData: data });
      }
    });
  };
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/ledger/${this.props.customerId}/FY2021_22`
      )
      .orderByChild("dateNumber")
      .startAt(Date.parse(this.state.dateFrom.toDateString()))
      .endAt(
        Date.parse(
          new Date(
            `${this.state.dateTo.getFullYear()}-${
              this.state.dateTo.getMonth() + 1
            }-${this.state.dateTo.getDate() + 1}`
          )
        )
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.dateNumber < b.dateNumber) {
                return 1;
              }
              if (a.dateNumber > b.dateNumber) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.openingClosingBalance();
          }
        );
      } else {
        this.setState({
          mainData: [],
          searchData: [],
          isButtonLoading: false,
          isLoading: false,
        });
      }
    });
  };

  openingClosingBalance = () => {
    let currentArray = this.state.mainData;
    let lastElement = currentArray[currentArray.length - 1];

    currentArray.push(lastElement);

    let newArray = currentArray.map((item, index) => {
      if (currentArray.length === index + 1) {
        item = {
          balance:
            item.debit == "0" || 0
              ? parseFloat(item.balance) + parseFloat(item.credit)
              : parseFloat(item.balance) - parseFloat(item.debit),
          credit:
            item.credit == "0" || 0
              ? parseFloat(item.balance) - parseFloat(item.debit)
              : "",
          debit:
            item.debit == "0" || 0
              ? parseFloat(item.balance) + parseFloat(item.credit)
              : "",
          discription: "Opening Balance",
          ledgerId: item.ledgerId + "DGpKumyM",
          transDate: item.transDate,
          dateNumber: item.dateNumber,
        };
      } else if (index === 0) {
      }
      return item;
    });
    this.setState(
      {
        mainData: newArray,
        searchData: newArray,
      },
      () => {
        this.setState({
          isButtonLoading: false,
        });
      }
    );
  };
  getTotals = () => {
    const reducerDebit = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
    const reducerCredit = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.credit ? currentValue.credit : 0);

    let creditTotal = this.state.mainData.reduce(reducerCredit, 0);
    let debitTotal = this.state.mainData.reduce(reducerDebit, 0);

    this.setState({ creditTotal, debitTotal }, () => {
      this.pdfDownload();
    });
  };
  pdfDownload = () => {
    var doc = new jsPDF("portrait", "mm", "a4");
    doc.setFont("times", "normal");
    doc.setFontSize(20);
    doc.text(
      12,
      16,
      `${this.state.companyData.organizationName}, ${this.state.companyData.atPost}`
    );
    doc.setFillColor(242, 242, 242);
    doc.roundedRect(12, 20, 186, 40, 3, 3, "F");
    doc.setFontSize(12);
    doc.text(
      14,
      37,
      `Statement of Account from ${this.state.dateFrom.getDate()}/${
        this.state.dateFrom.getMonth() + 1
      }/${this.state.dateFrom.getFullYear()} to ${this.state.dateTo.getDate()}/${
        this.state.dateTo.getMonth() + 1
      }/${this.state.dateTo.getFullYear()}`
    );

    doc.setFontSize(12);
    doc.text(
      14,
      45,
      `Generated on: ${new Date().getDate()}/${
        new Date().getMonth() + 1
      }/${new Date().getFullYear()}`
    );
    doc.setDrawColor(193, 193, 193);
    doc.rect(104, 25, 0, 30);
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(14);
    doc.text(112, 29, "STATEMENT OF ACCOUNT");
    doc.rect(112, 30, 62, 0);
    doc.setFontSize(12);
    doc.setFont("times", "bold");
    doc.text(112, 37, `${this.props.customerData.customerName}`);
    doc.setFont("times", "normal");
    doc.text(112, 45, `${this.props.customerData.address}`);
    doc.text(112, 53, `${this.props.customerData.number}`);
    doc.rect(12, 65, 186, 6);
    doc.rect(35, 65, 0, 6);
    doc.rect(100, 65, 0, 6);
    doc.rect(132, 65, 0, 6);
    doc.rect(165, 65, 0, 6);
    doc.text(24, 70, "Date", "center");
    doc.text(65, 70, "Account Discription", "center");
    doc.text(115, 70, "Debit", "center");
    doc.text(145, 70, "Credit", "center");
    doc.text(180, 70, "Balance", "center");

    let pageCount = 1;
    doc.setTextColor(150);
    doc.text(196, 293, `Page ${pageCount}`, "right");
    doc.setTextColor(0);
    let starty = 0;
    for (let index = 0; index < this.state.searchData.length; index++) {
      if (pageCount == 1 && starty < 35) {
        doc.rect(12, 71 + starty * 6, 186, 6);
        doc.rect(35, 71 + starty * 6, 0, 6);
        doc.rect(100, 71 + starty * 6, 0, 6);
        doc.rect(132, 71 + starty * 6, 0, 6);
        doc.rect(165, 71 + starty * 6, 0, 6);

        doc.text(
          24,
          76 + starty * 6,
          `${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getDate()}/${
            new Date(
              this.state.searchData[
                this.state.searchData.length - (index + 1)
              ].transDate
            ).getMonth() + 1
          }/${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getFullYear()}`,
          "center"
        );
        doc.text(
          37,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .discription
          }`
        );
        doc.text(
          130,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .debit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].debit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          163,
          76 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .credit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].credit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          196,
          76 + starty * 6,
          `${parseFloat(
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .balance
          ).toFixed(2)} ${
            parseFloat(
              this.state.searchData[this.state.searchData.length - (index + 1)]
                .balance
            ) > 0
              ? "Dr"
              : "Cr"
          }`,
          "right"
        );
        if (index == this.state.searchData.length - 1) {
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 71 + (starty + 1) * 6, 186, 6, "FD");
          doc.rect(35, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(100, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(132, 71 + (starty + 1) * 6, 0, 6);
          doc.rect(165, 71 + (starty + 1) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 76 + (starty + 1) * 6, ``, "center");
          doc.text(37, 76 + (starty + 1) * 6, `TOTAL`);
          doc.text(
            130,
            76 + (starty + 1) * 6,
            `${this.state.debitTotal.toFixed(2)}`,
            "right"
          );
          doc.text(
            163,
            76 + (starty + 1) * 6,
            `${this.state.creditTotal.toFixed(2)}`,
            "right"
          );
          doc.text(
            196,
            76 + (starty + 1) * 6,
            `${(this.state.debitTotal - this.state.creditTotal).toFixed(2)} ${
              parseFloat(this.state.debitTotal - this.state.creditTotal) > 0
                ? "Dr"
                : "Cr"
            }`,
            "right"
          );
          doc.setFont("times", "normal");
          doc.text(105, 76 + (starty + 3) * 6, `End of Statement`, "center");
        }
        if (index == 34 && this.state.searchData.length > 35) {
          pageCount = pageCount + 1;

          starty = 0;
          doc.addPage();

          doc.rect(12, 16, 186, 6);
          doc.rect(35, 16, 0, 6);
          doc.rect(100, 16, 0, 6);
          doc.rect(132, 16, 0, 6);
          doc.rect(165, 16, 0, 6);
          doc.text(24, 21, "Date", "center");
          doc.text(65, 21, "Account Discription", "center");
          doc.text(115, 21, "Debit", "center");
          doc.text(145, 21, "Credit", "center");
          doc.text(180, 21, "Balance", "center");
          doc.setTextColor(150);
          doc.text(196, 293, `Page ${pageCount}`, "right");
          doc.setTextColor(0);
        } else {
          starty = starty + 1;
        }
      } else if (pageCount > 1 && index > 34) {
        if (starty % 42 == 0 && pageCount > 2) {
          if (index == 0) {
          } else {
            doc.addPage();

            starty = 0;
            doc.rect(12, 16, 186, 6);
            doc.rect(35, 16, 0, 6);
            doc.rect(100, 16, 0, 6);
            doc.rect(132, 16, 0, 6);
            doc.rect(165, 16, 0, 6);
            doc.text(24, 21, "Date", "center");
            doc.text(65, 21, "Account Discription", "center");
            doc.text(115, 21, "Debit", "center");
            doc.text(145, 21, "Credit", "center");
            doc.text(180, 21, "Balance", "center");
            pageCount = pageCount + 1;
            doc.setTextColor(150);
            doc.text(196, 293, `Page ${pageCount}`, "right");
            doc.setTextColor(0);
          }
        }
        if (index == 78) {
          doc.addPage();

          starty = 0;
          doc.rect(12, 16, 186, 6);
          doc.rect(35, 16, 0, 6);
          doc.rect(100, 16, 0, 6);
          doc.rect(132, 16, 0, 6);
          doc.rect(165, 16, 0, 6);
          doc.text(24, 21, "Date", "center");
          doc.text(65, 21, "Account Discription", "center");
          doc.text(115, 21, "Debit", "center");
          doc.text(145, 21, "Credit", "center");
          doc.text(180, 21, "Balance", "center");
          pageCount = pageCount + 1;
          doc.setTextColor(150);
          doc.text(196, 293, `Page ${pageCount}`, "right");
          doc.setTextColor(0);
        }
        if (index == this.state.searchData.length - 1) {
          doc.setFillColor(242, 242, 242);
          doc.rect(12, 22 + (starty + 1) * 6, 186, 6, "FD");
          doc.rect(35, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(100, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(132, 22 + (starty + 1) * 6, 0, 6);
          doc.rect(165, 22 + (starty + 1) * 6, 0, 6);
          doc.setFont("times", "bold");
          doc.text(24, 27 + (starty + 1) * 6, ``, "center");
          doc.text(37, 27 + (starty + 1) * 6, `TOTAL`);
          doc.text(
            130,
            27 + (starty + 1) * 6,
            `${this.state.debitTotal.toFixed(2)}`,
            "right"
          );
          doc.text(
            163,
            27 + (starty + 1) * 6,
            `${this.state.creditTotal.toFixed(2)}`,
            "right"
          );
          doc.text(
            196,
            27 + (starty + 1) * 6,
            `${(this.state.debitTotal - this.state.creditTotal).toFixed(2)} ${
              parseFloat(this.state.debitTotal - this.state.creditTotal) > 0
                ? "Dr"
                : "Cr"
            }`,
            "right"
          );
          doc.setFont("times", "normal");
          doc.text(105, 27 + (starty + 3) * 6, `End of Statement`, "center");
        }
        doc.rect(12, 22 + starty * 6, 186, 6);
        doc.rect(35, 22 + starty * 6, 0, 6);
        doc.rect(100, 22 + starty * 6, 0, 6);
        doc.rect(132, 22 + starty * 6, 0, 6);
        doc.rect(165, 22 + starty * 6, 0, 6);
        doc.text(
          24,
          27 + starty * 6,
          `${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getDate()}/${
            new Date(
              this.state.searchData[
                this.state.searchData.length - (index + 1)
              ].transDate
            ).getMonth() + 1
          }/${new Date(
            this.state.searchData[
              this.state.searchData.length - (index + 1)
            ].transDate
          ).getFullYear()}`,
          "center"
        );
        doc.text(
          37,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .discription
          }`
        );
        doc.text(
          130,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .debit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].debit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          163,
          27 + starty * 6,
          `${
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .credit
              ? parseFloat(
                  this.state.searchData[
                    this.state.searchData.length - (index + 1)
                  ].credit
                ).toFixed(2)
              : ""
          }`,
          "right"
        );
        doc.text(
          196,
          27 + starty * 6,
          `${parseFloat(
            this.state.searchData[this.state.searchData.length - (index + 1)]
              .balance
          ).toFixed(2)} ${
            parseFloat(
              this.state.searchData[this.state.searchData.length - (index + 1)]
                .balance
            ) > 0
              ? "Dr"
              : "Cr"
          }`,
          "right"
        );
        starty = starty + 1;
      }
    }

    doc.save(`${this.props.customerData.customerName}_AC.pdf`);
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item;
        const textData = str;
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchData: newdata });
    } else {
      this.setState({ searchData: this.state.mainData });
    }
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              backgroundColor: "#f2f2f2",
              borderRadius: 2,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text style={{ padding: 3, paddingTop: 25 }}>From</Text>
              <View style={{ flexDirection: "row", zIndex: 5 }}>
                <View>
                  <Text
                    style={[
                      styles.inputField,
                      {
                        backgroundColor: "#fff",
                        borderWidth: 1,

                        borderRadius: 5,
                        margin: 14,
                        alignSelf: "center",
                        padding: 10,
                        width: 150,
                        textAlignVertical: "center",
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        viewCalenderFrom: !this.state.viewCalenderFrom,
                      });
                    }}
                  >
                    {this.state.dateFrom.toDateString()}
                  </Text>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 75,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderFrom ? (
                      <Calendar
                        value={this.state.dateFrom}
                        onChange={(value) => {
                          this.setState({ dateFrom: value }, () => {
                            this.setState({ viewCalenderFrom: false });
                          });
                        }}
                      />
                    ) : null}
                  </View>
                </View>
              </View>
              {/* to */}
              <Text style={{ padding: 3, paddingTop: 25 }}>To</Text>
              <View style={{ flexDirection: "row", zIndex: 5 }}>
                <View>
                  <Text
                    style={[
                      styles.inputField,
                      {
                        backgroundColor: "#fff",
                        borderWidth: 1,

                        borderRadius: 5,
                        margin: 14,
                        alignSelf: "center",
                        padding: 10,
                        width: 150,
                        textAlignVertical: "center",
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        viewCalenderTo: !this.state.viewCalenderTo,
                      });
                    }}
                  >
                    {this.state.dateTo.toDateString()}
                  </Text>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 75,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderTo ? (
                      <Calendar
                        value={this.state.dateTo}
                        onChange={(value) => {
                          this.setState({ dateTo: value }, () => {
                            this.setState({ viewCalenderTo: false });
                          });
                        }}
                      />
                    ) : null}
                  </View>
                </View>
              </View>

              <TouchableOpacity
                style={[
                  styles.actionbtn,
                  {
                    borderColor: "#0a60ff",
                    margin: 15,
                    width: 100,
                    paddingVertical: 10,
                    backgroundColor: "#0a60ff",
                  },
                ]}
                onPress={() => {
                  this.setState({ isButtonLoading: true });
                  this.callDataFromDB();
                }}
              >
                {this.state.isButtonLoading ? (
                  <ActivityIndicator size="small" color="#fff" />
                ) : (
                  <Text style={{ color: "#fff", textAlign: "center" }}>
                    View
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.actionbtn,
                  {
                    borderColor: "#B50706",
                    margin: 15,
                    width: 120,
                    paddingVertical: 10,
                    backgroundColor: "#fff",
                  },
                ]}
                onPress={() => {
                  this.getTotals(); //Followed by pdfDownload()
                }}
              >
                {this.state.isButtonLoadingPdf ? (
                  <ActivityIndicator size="small" color="#B50706" />
                ) : (
                  <Text style={{ color: "#B50706", textAlign: "left" }}>
                    PDF Download
                  </Text>
                )}
              </TouchableOpacity>
            </View>
            <View style={{ padding: 20 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  color:
                    parseFloat(this.props.closingBalance) > 0
                      ? "#de5246"
                      : "#000",
                  fontSize: 18,
                }}
              >
                Closing Balance ₹
                {isNaN(parseFloat(this.props.closingBalance))
                  ? 0
                  : parseFloat(this.props.closingBalance).toFixed(2)}{" "}
                {parseFloat(this.props.closingBalance) > 0
                  ? "Dr"
                  : parseFloat(this.props.closingBalance) < 0
                  ? "Cr"
                  : ""}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ maxHeight: 450, zIndex: -1 }}>
          <FlatList
            data={this.state.searchData}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                  ]}
                >
                  <Text style={styles.columnRowTxt}>{index + 1}</Text>
                  <Text style={styles.columnRowTxt}>{item.transDate}</Text>
                  <Text style={[styles.columnRowTxt]}>{item.discription}</Text>
                  <Text style={styles.columnRowTxt}>{item.debit}</Text>
                  <Text style={styles.columnRowTxt}>{item.credit}</Text>

                  <Text
                    style={[
                      styles.columnRowTxt,
                      {
                        color:
                          item.balance > 0
                            ? "#de5246"
                            : item.balance < 0
                            ? "#009C44"
                            : "#000",

                        fontSize: 14,
                      },
                    ]}
                  >
                    {item.balance}
                  </Text>

                  <Text style={styles.columnRowTxt}>
                    {item.balance > 0 ? "Dr" : "Cr"}
                  </Text>
                </View>
              );
            }}
            ListHeaderComponent={this.tableHeader}
            stickyHeaderIndices={[0]}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator size="large" color="#0a60ff" />
                ) : (
                  <Text>No Entries Found in Selected Date Range</Text>
                )}
              </View>
            }
            keyExtractor={(item) => JSON.stringify(item.ledgerId)}
          />
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <LedgerTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "14.28%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "14.28%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
});
