import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { jsPDF } from "jspdf";
import { Picker } from "@react-native-picker/picker";
export default class bulkInvoice extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      viewCalender: false,
      date: new Date(),

      setVisible: false,

      OpeningsetVisible: false,

      viewCalenderTo: false,
      viewCalenderFrom: false,
      dataFromDate: new Date(),
      dataToDate: new Date(),
      mainData: [],

      columns: [
        "Sr. no.",
        "Invoice Date",
        "Invoice Number",
        "Amount",
        "Action",
      ],

      isButtonLoading: false,
      uoid: "",

      isloading: true,

      fiscalyearForBulk: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
      },
      billno: "",
      billDate: "",
      billArray: [],
      billCalculations: {},
      billdata: [],
      itemSplit: [],
      customerName: "",
      address: "",
      gst: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            var fiscalyear = "";
            var today = new Date();
            if (today.getMonth() + 1 <= 3) {
              fiscalyear =
                "FY" +
                (today.getFullYear() - 1) +
                "_" +
                today.getFullYear().toString().substring(2);
            } else {
              fiscalyear =
                "FY" +
                today.getFullYear() +
                "_" +
                (today.getFullYear() + 1).toString().substring(2);
            }
            this.setState({ fiscalyearForBulk: fiscalyear }, () => {
              this.callDataFromDB();
              this.getCompanyData();
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  callDataFromDB = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/bulkInvoices/${this.state.fiscalyearForBulk}`
      );

    dbref.on("value", (DataSnapShot) => {
      if (DataSnapShot.exists()) {
        let data = Object.values(DataSnapShot.val());
        this.setState({ mainData: data }, () => {
          this.setState({ isloading: false, isButtonLoading: false });
        });
      } else {
        this.setState({ mainData: [] });
        this.setState({ isloading: false, isButtonLoading: false });
      }
    });
  };
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this.setState({ companyData: data });
      }
    });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column} </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  printPDF = (copyType) => {
    var doc = new jsPDF();
    doc.rect(10, 10, 190, 277);

    var doc = new jsPDF();
    doc.rect(10, 45, 190, 235);

    doc.setFont("times", "normal");
    doc.setFontSize(11);
    doc.text(198, 9, `${copyType}`, "right");
    doc.setFont("times", "italic");
    doc.setFontSize(25);
    doc.text(
      105,
      20,
      `${this.state.companyData.organizationName},${this.state.companyData.atPost}`,
      "center"
    );

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(105, 26, `${this.state.companyData.addressTwo}`, "center");

    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.text(105, 31, `GSTIN- ${this.state.companyData.gstNo}`, "center");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(198, 40, `Invoice No.: ${this.state.billno}`, "right");
    doc.rect(10, 55, 190, 0, "F");
    doc.rect(10, 80, 190, 0, "F");
    doc.rect(10, 87, 190, 0, "F");
    doc.rect(105, 87, 0, 35, "F");
    doc.rect(10, 122, 190, 0, "F");
    doc.rect(10, 132, 190, 0, "F");
    doc.rect(20, 122, 0, 88, "F");
    doc.rect(80, 122, 0, 88, "F");
    doc.rect(95, 122, 0, 88, "F");
    doc.rect(110, 122, 0, 88, "F");
    doc.rect(135, 122, 0, 88, "F");
    doc.rect(155, 122, 0, 133, "F");
    doc.rect(105, 210, 0, 45, "F");
    doc.rect(10, 210, 190, 0, "F");
    doc.rect(105, 219, 95, 0, "F");
    doc.rect(105, 228, 95, 0, "F");
    doc.rect(105, 237, 95, 0, "F");
    doc.rect(105, 246, 95, 0, "F");
    doc.rect(10, 255, 190, 0, "F");

    doc.setFont("times");
    doc.setFontSize(14);
    doc.text(91, 52, `TAX-INVOICE`);

    doc.setFontSize(12);
    doc.text(12, 62, `Date of Supply`);
    doc.setFontSize(12);
    doc.text(
      48,
      62,
      `: ${this.state.billArray[0].date} to ${
        this.state.billArray[this.state.billArray.length - 1].date
      }`
    );
    doc.text(115, 62, `Transport Mode`);
    doc.text(145, 62, `: Road`);

    doc.setFontSize(12);
    doc.text(12, 68, `Invoice Date`);
    doc.text(48, 68, `: ${this.state.billDate}`);
    doc.text(115, 68, `Vehicle Number`);
    doc.text(
      145,
      68,
      `: ${this.state.billArray[0].lorryNo},${
        this.state.billArray[this.state.billArray.length - 1].lorryNo
      }`
    );

    doc.setFontSize(12);
    doc.text(12, 74, `State`);
    doc.text(48, 74, `: Maharashtra`);
    doc.text(78, 74, `State Code 27`);
    doc.text(115, 74, `Place of Supply`);
    doc.text(145, 74, `: ${this.state.billArray[0].deliveryAddress}`);

    doc.text(38, 85, `Details of Receiver Bill to`);
    doc.text(129, 85, `Drafts of Consginee Shipped to`);

    doc.text(12, 95, `Name`);
    doc.text(38, 95, `: ${this.state.customerName}`);
    doc.text(12, 103, `Address`);
    doc.text(38, 103, `: ${this.state.address}`);
    doc.text(12, 111, `GST IN`);
    doc.text(38, 111, `: ${this.state.gst}`);
    doc.text(12, 119, `State`);
    doc.text(38, 119, `: Maharashtra`);
    doc.text(68, 119, `State Code 27`);

    doc.text(107, 95, `Name`);
    doc.text(133, 95, `: `);
    doc.text(107, 103, `Address`);
    doc.text(133, 103, `: `);
    doc.text(107, 111, `GST IN`);
    doc.text(133, 111, `: `);
    doc.text(107, 119, `State`);
    doc.text(133, 119, `: Maharashtra`);
    doc.text(163, 119, `State Code 27`);

    doc.text(13, 126, `Sr\nNo.`);
    doc.text(29, 128, `Name of Product/Service`);
    doc.text(83, 128, `HSN`);
    doc.text(96, 128, `GST %`);
    doc.text(116, 128, `Quantity`);
    doc.text(140, 128, `Rate`);
    doc.text(190, 128, `Amount`, "right");
    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.totalAmount);
    for (let index = 0; index < this.state.itemSplit.length; index++) {
      doc.text(14, 142 + index * 10, `${index + 1}`);
      doc.text(
        25,
        142 + index * 10,
        `${this.state.itemSplit[index][0].item} Metal`
      );
      doc.text(83, 142 + index * 10, "2517");
      doc.text(99, 142 + index * 10, "5%");
      doc.text(
        134,
        142 + index * 10,
        `${this.state.itemSplit[index].reduce(reducer, 0).toFixed(2)} ${
          this.state.itemSplit[index][0].itemUOM
        }`,
        "right"
      );
      doc.text(
        140,
        142 + index * 10,
        `${(
          this.state.itemSplit[index].reduce(reducer1, 0) /
          this.state.itemSplit[index].reduce(reducer, 0)
        ).toFixed(2)}`
      );
      doc.text(
        190,
        142 + index * 10,
        `${this.state.itemSplit[index].reduce(reducer1, 0).toFixed(2)}`,
        "right"
      );
    }
    doc.text(14, 220, "Company Bank Details :");
    doc.text(14, 227, `Bank Name  : ${this.state.companyData.bank}`);
    doc.text(14, 233, `Account No. : ${this.state.companyData.account}`);
    doc.text(14, 239, `IFS Code      : ${this.state.companyData.ifsc}`);
    doc.text(14, 245, `Branch          : ${this.state.companyData.branch}`);

    doc.text(107, 217, "Total Amount Before Tax");
    doc.text(118, 226, "Add @2.5% CGST");
    doc.text(118, 235, "Add @2.5% SGST");
    doc.text(113, 244, "Total Amount of GST");

    doc.text(109, 253, "Total Amount After Tax");

    doc.setFontSize(14);
    doc.text(190, 217, `${this.state.billCalculations.billTotal}`, "right");
    doc.text(190, 226, `${this.state.billCalculations.cgst}`, "right");
    doc.text(190, 235, `${this.state.billCalculations.sgst}`, "right");
    doc.text(
      190,
      244,
      `${(
        parseFloat(this.state.billCalculations.cgst) +
        parseFloat(this.state.billCalculations.sgst)
      ).toFixed(2)}`,
      "right"
    );
    doc.setFont("times", "bold");
    doc.text(
      190,
      253,
      `${parseFloat(this.state.billCalculations.billGrandTotal).toFixed(2)}`,
      "right"
    );
    doc.setFont("times", "italic");
    doc.text(
      190,
      262,
      `For- ${this.state.companyData.organizationName}`,
      "right"
    );
    doc.text(190, 276, "Authorised Signatory", "right");
    doc.save(`invoice_${this.state.billno}_${this.state.customerName}.pdf`);
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Bulk Invoice View" />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Modal starts */}
              <Portal>
                <Modal
                  visible={this.state.OpeningsetVisible}
                  onDismiss={() => {
                    this.setState({ OpeningsetVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "#ffffff",
                    width: 650,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                    borderWidth: 3,
                    alignSelf: "center",
                    OpeningsetVisible: false,
                  }}
                >
                  {/* Modal View */}
                </Modal>
              </Portal>
              {/* Model */}

              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Picker
                    selectedValue={this.state.fiscalyearForBulk}
                    style={{
                      padding: 12,
                      width: 180,
                      borderWidth: 1,
                      borderRadius: 5,
                    }}
                    onValueChange={(itemValue) =>
                      this.setState({ fiscalyearForBulk: itemValue })
                    }
                  >
                    <Picker.Item label="FY2022_23" value="FY2022_23" />
                    <Picker.Item label="FY2021_22" value="FY2021_22" />
                    <Picker.Item label="FY2020_21" value="FY2020_21" />
                    {/* <Picker.Item
                          label={new Date().getUTCFullYear() - 2}
                          value={new Date().getUTCFullYear() - 2}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 3}
                          value={new Date().getUTCFullYear() - 3}
                        /> */}
                  </Picker>
                  <TouchableOpacity
                    style={[
                      styles.actionbtn,
                      {
                        borderColor: "#0a60ff",
                        marginHorizontal: 10,
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        isButtonLoading: true,
                      });
                      this.callDataFromDB();
                    }}
                  >
                    {this.state.isButtonLoading ? (
                      <ActivityIndicator size="small" color="#0a60ff" />
                    ) : (
                      <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                        View
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{ zIndex: -1 }}>
                <View
                  style={[
                    styles.header,
                    {
                      marginHorizontal: 70,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      zIndex: -1,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Invoices
                  </Text>
                </View>
                <View
                  style={{
                    marginHorizontal: 70,
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                    borderRadius: 5,
                    zIndex: -1,
                    maxHeight: 450,
                  }}
                >
                  <FlatList
                    data={this.state.mainData}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{index + 1}</Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.billDate}
                          </Text>
                          <Text style={styles.columnRowTxt}>{item.billno}</Text>
                          <Text style={styles.columnRowTxt}>
                            {parseFloat(
                              item.billCalculations.billGrandTotal
                            ).toFixed(2)}
                          </Text>
                          <View
                            style={{
                              width: "20%",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {/* <TouchableOpacity
                              style={[
                                styles.actionbtn,
                                { borderColor: "#0a60ff" },
                              ]}
                              onPress={() => {
                                this.setState(
                                  {
                                    billno: item.billno,
                                    billDate: item.billDate,
                                    billArray: item.billArray,
                                    billCalculations: item.billCalculations,
                                    itemSplit: item.items,
                                    customerName: item.customerName,
                                    address: item.address,
                                  },
                                  () => {
                                    this.setState({ setVisibleBill: true });
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#0a60ff" }}>View</Text>
                            </TouchableOpacity> */}
                            <TouchableOpacity
                              style={[
                                styles.actionbtn,
                                { borderColor: "#B50706" },
                              ]}
                              onPress={() => {
                                this.setState(
                                  {
                                    billno: item.billno,
                                    billDate: item.billDate,
                                    billArray: item.billArray,
                                    billCalculations: item.billCalculations,
                                    itemSplit: item.items,
                                    customerName: item.customerName,
                                    address: item.address,
                                    gst: item.gst,
                                  },
                                  () => {
                                    this.printPDF("(Original for Recipient)");
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#B50706" }}>PDF 1°</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.actionbtn,
                                { borderColor: "#B50706" },
                              ]}
                              onPress={() => {
                                this.setState(
                                  {
                                    billno: item.billno,
                                    billDate: item.billDate,
                                    billArray: item.billArray,
                                    billCalculations: item.billCalculations,
                                    itemSplit: item.items,
                                    customerName: item.customerName,
                                    address: item.address,
                                    gst: item.gst,
                                  },
                                  () => {
                                    this.printPDF("(Duplicate)");
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#B50706" }}>PDF 2°</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.actionbtn,
                                { borderColor: "#B50706" },
                              ]}
                              onPress={() => {
                                this.setState(
                                  {
                                    billno: item.billno,
                                    billDate: item.billDate,
                                    billArray: item.billArray,
                                    billCalculations: item.billCalculations,
                                    itemSplit: item.items,
                                    gst: item.gst,
                                  },
                                  () => {
                                    this.printPDF("(Triplicate)");
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#B50706" }}>PDF 3°</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeader}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        {this.state.isloading ? (
                          <ActivityIndicator size="large" color="#0a60ff" />
                        ) : (
                          <Text>No Invoices Found</Text>
                        )}
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.billId)}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
});
