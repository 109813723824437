import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { Modal, Portal, Provider, TextInput } from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import OrderTable from "../src/components/OrderTable";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Picker } from "@react-native-picker/picker";
export default class fleetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setVisible: false,
      customerName: "",
      customerId: "",
      address: "",

      type: "",
      itemSearch: "",
      qty: "",

      buttonLoading: false,
      loginUser: {},
      mainData: [],
      searchData: [],
      searchDataItem: [],
      mainDataItem: [],
      viewDropDown: false,
      viewDropDownItem: false,
      itemId: "",
      uoid: "",
      itemUOM: "Unit",
      paymentMode: "",
      ratePerUnit: "",
      totalAmount: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid });
          this.getLoginUser();
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  getUOM = () => {
    let newarray = this.state.searchData.filter((item) => {
      if (item.id == this.state.customerId) {
        return item;
      }
    });
    let productRate = newarray[0] ? newarray[0].productRate : [];

    let selectedItemUOM = productRate
      ? Object.values(productRate).filter((item) => {
          if (item.productName == this.state.itemSearch) {
            return item;
          }
        })
      : [];

    this.setState({
      itemUOM: selectedItemUOM.length > 0 ? selectedItemUOM[0].uom : "Brass",
    });
  };
  getLoginUser = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let userid = loginId.split(".")[0];
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/${userid}`);

      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          this.setState({ loginUser: DataSnapShot.val() });
        }
      });
    }
  };
  getCustomerData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/customerData/customerBasics`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ searchData: data, mainData: data }, () => {
          this.setState({ searchDataLoading: false });
        });
      }
    });
  };
  getItemData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ searchDataItem: data, mainDataItem: data });
      }
    });
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.customerName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchData: newdata });
    } else {
      this.setState({ searchData: this.state.mainData });
    }
  };
  searchItemStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataItem.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchDataItem: newdata });
    } else {
      this.setState({ searchDataItem: this.state.mainDataItem });
    }
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    var rString = this.randomString(
      20,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
    );
    if (
      this.state.customerName != "" &&
      this.state.customerId != "" &&
      this.state.itemSearch != "" &&
      this.state.itemId != "" &&
      this.state.qty != "" &&
      this.state.address != "" &&
      this.state.paymentMode != ""
    ) {
      this.setState({ buttonLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/ordersData/ordersbasics/${rString}`
        );
      dbRef
        .set({
          customerName: this.state.customerName,
          customerId: this.state.customerId,

          qty: this.state.qty,
          item: this.state.itemSearch,
          itemId: this.state.itemId,

          orderGenratedAt: `${new Date()}`,
          addedBy: this.state.loginUser.name,
          addedById: this.state.loginUser.loginId,
          id: rString,
          dateNumber: Date.parse(new Date()),
          status: "Approval Pending",
          approved: null,
          address: this.state.address,
          itemUOM: this.state.itemUOM,

          paymentMode: this.state.paymentMode,
          ratePerUnit:
            this.state.paymentMode == "Cash" ? this.state.ratePerUnit : "-",
          total:
            this.state.paymentMode == "Cash"
              ? parseFloat(this.state.qty) * parseFloat(this.state.ratePerUnit)
              : "-",
        })
        .then(() => {
          this.setState({
            buttonLoading: false,
            setVisible: false,
            customerName: "",
            customerId: "",
            itemId: "",
            itemSearch: "",
            itemUOM: "",
            paymentMode: "",
            qty: "",
            ratePerUnit: "",
            address: "",
          });
        });
    }
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Order Management" showBackArrow={false} />
          <View style={styles.mainConsole}>
            <LeftBar order="#c1c1c1" number="5" />
            <View style={styles.palyArea}>
              {/* Modal Start ///////////////// */}

              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({
                      buttonLoading: false,
                      setVisible: false,
                      customerName: "",
                      customerId: "",
                      itemId: "",
                      itemSearch: "",
                      itemUOM: "",
                      paymentMode: "",
                      qty: "",
                      ratePerUnit: "",
                      address: "",
                    });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    width: 750,
                    borderRadius: 5,

                    position: "absolute",
                    top: 80,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>Add new Order</Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        mode="outlined"
                        label="Select Customer Name"
                        value={this.state.customerName}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ customerName: text }, () => {
                            this.searchStringInArray(this.state.customerName);
                            this.setState({ viewDropDown: true });
                          });
                        }}
                        placeholder="Select Customer Name"
                      />
                      {this.state.viewDropDown ? (
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#000",
                            borderRadius: 5,
                            position: "absolute",
                            marginTop: 70,
                            shadowOffset: {
                              width: 0,
                              height: 0,
                            },
                            shadowOpacity: 0.2,
                            shadowRadius: 5,
                            zIndex: 5,
                            marginLeft: 20,
                            maxHeight: 150,
                          }}
                        >
                          <FlatList
                            data={this.state.searchData}
                            renderItem={({ item }) => {
                              return (
                                <TouchableOpacity
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: 10,
                                    borderWidth: 1,
                                    borderColor: "#c1c1c1",
                                    borderRadius: 5,
                                    width: 320,
                                    alignItems: "center",
                                  }}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        customerName: item.customerName,
                                        address: item.address,
                                        customerId: item.id,
                                      },
                                      () => {
                                        this.setState({ viewDropDown: false });
                                      }
                                    );
                                  }}
                                >
                                  <Text style={{ color: "#000" }}>
                                    {item.customerName},{item.address}
                                  </Text>
                                </TouchableOpacity>
                              );
                            }}
                            keyExtractor={(item) => JSON.stringify(item.id)}
                          />
                        </View>
                      ) : null}
                      <TextInput
                        mode="outlined"
                        label="Delivery Address"
                        value={this.state.address}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ address: text });
                        }}
                        placeholder="Delivery Address"
                      />
                    </View>
                    <View style={{ flexDirection: "row", zIndex: -1 }}>
                      <TextInput
                        mode="outlined"
                        label="Select Item"
                        value={this.state.itemSearch}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ itemSearch: text }, () => {
                            this.searchItemStringInArray(this.state.itemSearch);
                            this.setState({ viewDropDownItem: true });
                          });
                        }}
                        placeholder="Select Item"
                      />

                      {this.state.viewDropDownItem ? (
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#000",
                            borderRadius: 5,
                            position: "absolute",
                            marginTop: 70,
                            shadowOffset: {
                              width: 0,
                              height: 0,
                            },
                            shadowOpacity: 0.2,
                            shadowRadius: 5,
                            zIndex: 5,
                            marginLeft: 20,
                            maxHeight: 150,
                          }}
                        >
                          <FlatList
                            data={this.state.searchDataItem}
                            renderItem={({ item }) => {
                              return (
                                <TouchableOpacity
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: 10,
                                    borderWidth: 1,
                                    borderColor: "#c1c1c1",
                                    borderRadius: 5,
                                    width: 210,
                                    alignItems: "center",
                                  }}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        itemSearch: item.productName,
                                        itemId: item.id,
                                      },
                                      () => {
                                        this.getUOM();
                                        this.setState({
                                          viewDropDownItem: false,
                                        });
                                      }
                                    );
                                  }}
                                >
                                  <Text style={{ color: "#000" }}>
                                    {item.productName}
                                  </Text>
                                </TouchableOpacity>
                              );
                            }}
                            keyExtractor={(item) => JSON.stringify(item.id)}
                          />
                        </View>
                      ) : null}
                      <View style={{ flexDirection: "row" }}>
                        <TextInput
                          mode="outlined"
                          label="Quantity"
                          value={this.state.qty}
                          style={[styles.inputField, { width: 175 }]}
                          onChangeText={(text) => {
                            let regex = /^[0-9\.]*$/;
                            if (text.match(regex)) {
                              this.setState({ qty: text });
                            }
                          }}
                          placeholder="Quantity"
                        />
                        <Text
                          style={{ padding: 20, marginTop: 16, fontSize: 22 }}
                        >
                          {this.state.itemUOM}
                        </Text>
                      </View>
                      {/* Add here more Fields */}
                    </View>
                    <View style={{ flexDirection: "row", zIndex: -2 }}>
                      <Picker
                        selectedValue={this.state.paymentMode}
                        style={{
                          height: 40,
                          width: 310,
                          marginHorizontal: 22,
                          marginVertical: 25,
                        }}
                        onValueChange={(itemValue, itemIndex) =>
                          this.setState({ paymentMode: itemValue })
                        }
                      >
                        <Picker.Item label="Payment Mode" value="" />
                        <Picker.Item label="Credit" value="Credit" />
                        <Picker.Item label="Cash" value="Cash" />
                      </Picker>
                      {this.state.paymentMode == "Cash" ? (
                        <TextInput
                          mode="outlined"
                          label="Rate per Unit"
                          value={this.state.ratePerUnit}
                          style={styles.inputField}
                          onChangeText={(text) => {
                            this.setState({ ratePerUnit: text });
                          }}
                          placeholder="Rate per Unit"
                        />
                      ) : null}
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: 30,
                      }}
                    >
                      {this.state.paymentMode == "Cash" ? (
                        <Text
                          style={{
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                            fontSize: 22,
                          }}
                        >
                          Total Rs.{" "}
                          {parseFloat(this.state.qty) *
                          parseFloat(this.state.ratePerUnit)
                            ? parseFloat(this.state.qty) *
                              parseFloat(this.state.ratePerUnit)
                            : "0.0"}
                        </Text>
                      ) : null}

                      <TouchableOpacity
                        disabled={this.state.buttonLoading}
                        style={[
                          styles.addNewButton,
                          {
                            width: 150,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          if (this.state.paymentMode == "Cash") {
                            if (this.state.ratePerUnit != "") {
                              this.saveDataToDb();
                            }
                          } else {
                            this.saveDataToDb();
                          }
                        }}
                      >
                        {this.state.buttonLoading ? (
                          <ActivityIndicator
                            animating={true}
                            color="#fff"
                            size="small"
                          />
                        ) : (
                          <Text style={{ color: "#fff", alignSelf: "center" }}>
                            {" "}
                            Save Details
                          </Text>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            backgroundColor: "#fff",
                            width: 80,
                            marginHorizontal: 20,
                            height: 42,
                            marginTop: 26,
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            buttonLoading: false,
                            setVisible: false,
                            customerName: "",
                            customerId: "",
                            itemId: "",
                            itemSearch: "",
                            itemUOM: "",
                            paymentMode: "",
                            qty: "",
                            ratePerUnit: "",
                            address: "",
                          });
                        }}
                      >
                        <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                          Cancel
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </Portal>

              {/* {Model Ends} //////////////*/}
              <View style={styles.table}>
                <View style={styles.header}>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Orders
                  </Text>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      if (this.state.loginUser.canAddOrdersToSalesAndroid) {
                        this.setState({ setVisible: true });

                        this.getCustomerData();
                        this.getItemData();
                      }
                    }}
                  >
                    <Text style={{ color: "#fff" }}>
                      {this.state.loginUser.canAddOrdersToSalesAndroid
                        ? "Add Order"
                        : "Not Allowed"}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.tableBody}>
                  <OrderTable />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 350,
    padding: 20,
    height: 40,
  },
});
