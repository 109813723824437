import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { AntDesign } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import firebase from "firebase";
import {
  Modal,
  Portal,
  Provider,
  RadioButton,
  TextInput,
  Snackbar,
  Checkbox,
} from "react-native-paper";
import { jsPDF } from "jspdf";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

class SalesReportTable extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      mainData: [],

      isLoading: true,
      isButtonLoading: false,
      columns: [
        "Date",
        "Lorry No.",
        "Royalty No.",
        "D.M. No.",
        "Size in mm",
        "Total Qty",
        "Pay. Mode",
        "Amount",
        "Status",
      ],
      columnsModalBill: [
        "Sr no.",
        "Date",
        "Lorry No.",
        "Royalty No.",
        "D.M. No.",
        "Size in mm",
        "Total cft",
        "Pay. Mode",
        "Amount",
      ],

      postSorterAce: true,
      customerId: "", //
      show_nrdm: true,

      customerName: "",
      address: "",
      statementArray: [],
      dateFrom: new Date(),
      viewCalenderFrom: false,
      dateTo: new Date(),
      viewCalenderTo: false,
      paymentType: "",
      productRate: [],
      total: 0,
      errorColor: "",
      uoid: "",
      setVisibleRateConfirm: false,
      fiscalyearForDMCall: "",
      companyData: {
        addressOne: "",
        addressTwo: "",
        atPost: "",
        contactNo: "",
        date: "",
        email: "",
        gstNo: "",
        organizationName: "",
        supplyState: "",
        stateCode: null,
      },
      rateConfirm: false,
      //For Bill

      uniqueItem: [],
      setVisibleBill: false,
      viewCalenderForBill: false,
      dateForBill: new Date(),
      billData: [],
      billno: 0,

      itemSplit: [],
      billCalculations: {},
      acceptBillGen: false,
      fiscalyear: "",
      loginUser: "",
      //For Ledger Entry
      setVisible: false, //modal visible
      date: new Date(),
      dateforEntry: new Date(),
      newData: [], //Selected For Ledger Generation
      viewCalender: false,
      // lastEntry: {}, //last entry to get balance amount of customer

      ledgerCalculations: {},
      lodingLedgerCal: false,
      addGsttoLedger: true,
      acceptEntryLedger: false,
      particular: "Ganesh Metal Bill",
      creditAmt: 0,
      newDataBill: [], //selected for Invoice Generation,
      paymentTypeFilter: false,
      salesStatementFilterModal: false,
      transportationIncluded: "",
      transportationFilter: false,
      showError: false,
      setVisibleActivityIndicatory: false,
      stateCode: null,
      supplyState: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({ customerId: this.props.customerId });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this._isMounted &&
                this.setState({ customerId: this.props.customerId }, () => {
                  this.getProductRate();
                });
              this.getCompanyData();
              var msInDay = 86400000;
              var daysToSub = new Date().getDate() - 1;
              var now = new Date();
              var milliseconds = now.getTime();
              var newMillisecods = milliseconds - msInDay * daysToSub;
              var newDate = new Date(newMillisecods);
              this._isMounted &&
                this.setState({ dateFrom: newDate }, () => {
                  this.callDataFromDB(true);
                  this.getbillno(true);
                  this.getLoginUser();
                });
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getLoginUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let loginId = firebase.auth().currentUser.email
          ? firebase.auth().currentUser.email
          : firebase.auth().currentUser.phoneNumber;
        if (loginId) {
          this._isMounted && this.setState({ loginUser: loginId });
        }
      }
    });
  };
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this._isMounted && this.setState({ companyData: data });
      }
    });
  };
  getbillno = (loadCurrent) => {
    var fiscalyear = this.state.fiscalyear;
    if (loadCurrent) {
      var today = new Date();
      if (today.getMonth() + 1 <= 3) {
        fiscalyear = today.getFullYear() - 1 + "_" + today.getFullYear();
      } else {
        fiscalyear = today.getFullYear() + "_" + (today.getFullYear() + 1);
      }
    }
    this._isMounted &&
      this.setState({ fiscalyear }, () => {
        let DMRef = firebase
          .database()
          .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);
        DMRef.once("value", (dataSnap) => {
          if (dataSnap.exists()) {
            this._isMounted &&
              this.setState({
                billno:
                  this.props.gst == ""
                    ? dataSnap.val().localBillCount + 1
                    : dataSnap.val().gstBillCount + 1,
              });
          }
          // else {
          //   DMRef.set({
          //     dmCount: 0,
          //     gstBillCount: 0,
          //     localBillCount: 0,
          //     nrdmCount: 0,
          //     quoteNo: 0,
          //     receiptNo: 0,
          //   }).then(() => {
          //     this.getbillno(true);//??
          //   });
          // }
        });
      });
  };
  billDataArray = () => {
    let newDataBill = this.state.mainData.filter(
      (item) => item.isMarkforBillGen == true && item.isBillGenertd == false
    );

    if (newDataBill.length != 0) {
      this._isMounted &&
        this.setState({
          setVisibleBill: true,
          dateforEntry: new Date(),
          setVisibleRateConfirm: false,
        });

      let finalArray = newDataBill.map((item1, index1) => {
        if (item1.paymentMode == "Credit") {
          this.state.productRate.map((item2, index2) => {
            if (item1.item == item2.productName) {
              item1.totalAmount = parseFloat(item2.itemRate) * item1.sellQtyCft;
            }
          });
          return { ...item1 };
        } else if (item1.paymentMode == "Cash") {
          // item1.balanceAmount = item1.totalAmount - item1.receivedAmount;

          return {
            ...item1,
            totalAmount:
              item1.totalAmount -
              (item1.totalAmount - item1.totalAmount * (100 / (100 + 5))), //Formula for reverse gst Calculation
          };
        }
      });
      this._isMounted &&
        this.setState({ billData: finalArray }, () => {
          var resArr = [];
          this.state.billData.forEach(function (item) {
            var i = resArr.findIndex((x) => x.item == item.item);
            if (i <= -1) {
              resArr.push({ item: item.item });
            }
          });
          this._isMounted &&
            this.setState({ uniqueItem: resArr }, () => {
              this.billCalculations();
            });
        });
    } else {
      this.props.snackbarVisibleFun("Please Mark for Invoice Entry");
    }
  };

  billCalculations = () => {
    //TODO: IGST
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.totalAmount);

    let billTotal = this.state.billData.reduce(reducer1, 0);
    if (this.state.stateCode == this.state.companyData.stateCode) {
      let sgst = this.state.billData.reduce(reducer1, 0) * 0.025;
      let cgst = this.state.billData.reduce(reducer1, 0) * 0.025;
      let billGrandTotal = billTotal + sgst + cgst;

      this._isMounted &&
        this.setState({
          billCalculations: {
            billTotal: billTotal.toFixed(2),
            sgst: sgst.toFixed(2),
            cgst: cgst.toFixed(2),
            billGrandTotal: billGrandTotal.toFixed(2),
          },
        });
    } else if (this.state.stateCode != this.state.companyData.stateCode) {
      let igst = this.state.billData.reduce(reducer1, 0) * 0.05;
      let billGrandTotal = billTotal + igst;

      this._isMounted &&
        this.setState({
          billCalculations: {
            billTotal: billTotal.toFixed(2),
            igst: igst.toFixed(2),
            billGrandTotal: billGrandTotal.toFixed(2),
          },
        });
    }

    let itemSplit = []; //eg:[[],[],....]

    for (let index = 0; index < this.state.uniqueItem.length; index++) {
      itemSplit.push([]);
      for (let index2 = 0; index2 < this.state.billData.length; index2++) {
        if (
          this.state.uniqueItem[index].item == this.state.billData[index2].item
        ) {
          itemSplit[index].push(this.state.billData[index2]);
        }
      }
      if (index == this.state.uniqueItem.length - 1) {
        this._isMounted && this.setState({ itemSplit });
      }
    }
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveToBills = () => {
    this.setState({ setVisibleActivityIndicatory: true });

    var billId = this.randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/bills/${
          this.state.customerId
        }/${this.state.fiscalyearForDMCall}/${new Date()}`
      );

    dbref
      .set({
        billId: billId,
        billDate: this.state.dateForBill.toDateString(),
        entrydate: this.state.date.toDateString(),
        items: this.state.itemSplit,
        billno:
          this.props.gst == "" ? `A-${this.state.billno}` : this.state.billno,
        billCalculations: this.state.billCalculations,
        entryBy: this.state.loginUser,
        billArray: this.state.billData,
        // ledgerCalculations: this.state.ledgerCalculations,
      })
      .then(() => {
        this.billGenerated();

        let dbref1 = firebase
          .database()
          .ref(`${this.state.uoid}/factoryData/extra/${this.state.fiscalyear}`);
        this.props.gst == ""
          ? dbref1.update({
              localBillCount: this.state.billno,
            })
          : dbref1.update({
              gstBillCount: this.state.billno,
            });
      });
  };
  billGenerated = () => {
    this.state.billData.map((item) => {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${item.id}`
        );
      dbRef.update({
        isBillGenertd: true,
      });
    });

    this.setState({ setVisibleActivityIndicatory: false });
    this.props.snackbarVisibleFun("Entry Successfully !");
  };

  saveBillToBulk = () => {
    var billId = this.randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/bulkInvoices/${
          this.state.fiscalyearForDMCall
        }/${new Date()}`
      );

    dbref.set({
      billId: billId + `${this.state.billno}`,
      billDate: this.state.dateForBill.toDateString(),
      entrydate: this.state.date.toDateString(),
      items: this.state.itemSplit,
      billno:
        this.props.gst == "" ? `A-${this.state.billno}` : this.state.billno,
      billCalculations: this.state.billCalculations,
      entryBy: this.state.loginUser,
      billArray: this.state.billData,
      customerName: this.props.customerName,
      address: this.props.address,
      dateStamp: Date.parse(this.state.date.toDateString()),
      gst: this.props.gst,
    });
  };
  tableFooter = () => {
    let itemSplit = []; //eg:[[],[],....]

    for (let index = 0; index < this.state.uniqueItem.length; index++) {
      itemSplit.push([]);
      for (let index2 = 0; index2 < this.state.newData.length; index2++) {
        if (
          this.state.uniqueItem[index].item == this.state.newData[index2].item
        ) {
          itemSplit[index].push(this.state.newData[index2]);
        }
      }
    }
    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      `${this.state.newData[0].date} to ${
        this.state.newData[this.state.newData.length - 1].date
      }`,
      <Text>Total DM{this.state.newData.length}</Text>,
      <View>
        {itemSplit.map((item1, key) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
              key={key}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.newData.reduce(reducer, 0)}</Text>
        </View>
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>{this.state.ledgerCalculations.ledTotal}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% SGST</Text>
          <Text>{this.state.ledgerCalculations.sgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% CGST</Text>

          <Text>{this.state.ledgerCalculations.cgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total GST</Text>

          <Text>
            {`${
              parseFloat(this.state.ledgerCalculations.cgst) +
              parseFloat(this.state.ledgerCalculations.sgst)
            }`}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Grand Total</Text>

          <Text>{this.state.ledgerCalculations.ledGrandTotal}</Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 100 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { width: "25%" }]}
            >
              <Text style={styles.columnHeaderTxt}>{item} </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  tableFooterForBill = () => {
    // let itemSplit = []; //eg:[[],[],....]

    // for (let index = 0; index < this.state.uniqueItem.length; index++) {
    //   itemSplit.push([]);
    //   for (let index2 = 0; index2 < this.state.billData.length; index2++) {
    //     if (
    //       this.state.uniqueItem[index].item == this.state.billData[index2].item
    //     ) {
    //       itemSplit[index].push(this.state.billData[index2]);
    //     }
    //   }
    // }

    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      "Total",
      `Total DM : ${this.state.billData.length}`,
      <View>
        {this.state.itemSplit.map((item1, key) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
              key={key}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.billData.reduce(reducer, 0)}</Text>
        </View>
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>{this.state.billCalculations.billTotal}</Text>
        </View>
        {this.state.stateCode == this.state.companyData.stateCode &&
        this.state.stateCode != null ? (
          <View>
            <View
              style={{
                flexDirection: "row",

                justifyContent: "space-between",
              }}
            >
              <Text>2.5% SGST</Text>

              <Text>{this.state.billCalculations.sgst}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",

                justifyContent: "space-between",
              }}
            >
              <Text>2.5% CGST</Text>

              <Text>{this.state.billCalculations.cgst}</Text>
            </View>
          </View>
        ) : this.state.stateCode !== this.state.companyData.stateCode &&
          this.state.stateCode != null ? (
          <View
            style={{
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Text>5% IGST</Text>

            <Text>{this.state.billCalculations.igst}</Text>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                padding: 8,
                fontWeight: "bold",
                backgroundColor: "#DE5246",
              }}
            >
              Please Select Supply State
            </Text>
          </View>
        )}

        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total GST</Text>

          <Text>
            {this.state.stateCode == this.state.companyData.stateCode
              ? `${(
                  parseFloat(this.state.billCalculations.cgst) +
                  parseFloat(this.state.billCalculations.sgst)
                ).toFixed(2)}`
              : `${parseFloat(this.state.billCalculations.igst).toFixed(2)}`}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Grand Total</Text>

          <Text>{this.state.billCalculations.billGrandTotal}</Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 120 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { paddingTop: 10, width: "25%" }]}
            >
              <Text style={styles.columnHeaderTxt}>{item} </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  getProductRate = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.props.customerId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();
        this._isMounted &&
          this.setState({
            stateCode: data.stateCode,
            supplyState: data.supplyState,
            productRate: data.productRate
              ? Object.values(data.productRate)
              : [],
          });
      }
    });
  };
  filterArrayForStatement = () => {
    let statementArrayRaw = this.state.mainData.filter(
      (item) =>
        (this.state.paymentTypeFilter == true
          ? item.paymentMode == this.state.paymentType && item.isactive != false
          : item.isactive != false) &&
        (this.state.transportationFilter == true
          ? item.transportIncluded ==
              (this.state.transportationIncluded == "Included"
                ? true
                : false) && item.isactive != false
          : item.isactive != false)
    );
    let finalArray = statementArrayRaw.map((item1, index1) => {
      if (item1.paymentMode == "Credit") {
        this.state.productRate.map((item2, index2) => {
          if (item1.item == item2.productName) {
            item1.totalAmount = item2.itemRate * item1.sellQtyCft;
          }
        });
        return item1;
      } else if (item1.paymentMode == "Cash") {
        return item1;
      }
    });
    this.setState(
      {
        statementArray: finalArray.sort(function (a, b) {
          if (a.dmNo > b.dmNo) {
            return 1;
          }
          if (a.dmNo < b.dmNo) {
            return -1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        this.generateStatementPDF();
      }
    );
  };
  generateStatementPDF = () => {
    var doc = new jsPDF("landscape", "mm", "a4");
    doc.setFont("times", "normal");
    doc.setFontSize(20);
    doc.text(148.5, 12, "SALES STATEMENT", "center");
    doc.setFontSize(14);
    doc.text(
      25,
      20,
      `From: ${this.state.companyData.organizationName},${this.state.companyData.atPost}`
    );
    doc.text(25, 27, `To: ${this.props.customerName}, ${this.state.address}`);
    doc.text(
      25,
      34,
      `For Period of ${this.state.dateFrom.toDateString()} to ${this.state.dateTo.toDateString()}`
    );
    doc.setFontSize(12);
    doc.setFont("times", "bold");
    doc.text(26, 46.2, "Sr.No.");
    doc.text(42, 46.2, "Date");
    doc.text(67, 46.2, "Lorry No.");
    doc.text(99, 46.2, "Royalty No.");
    doc.text(125, 46.2, "D.M. No.");
    doc.text(147, 46.2, "Size in mm");
    doc.text(173, 46.2, "Total Qty");
    doc.text(200, 46.2, "Rate/Unit");
    doc.text(224, 46.2, "Amount");
    doc.setFontSize(12);
    doc.setFont("times", "normal");
    doc.rect(25, 42, 229, 5);
    doc.rect(41, 42, 0, 5);
    doc.rect(66, 42, 0, 5);
    doc.rect(97, 42, 0, 5);
    doc.rect(124, 42, 0, 5);
    doc.rect(146, 42, 0, 5);
    doc.rect(172, 42, 0, 5);
    doc.rect(199, 42, 0, 5);
    doc.rect(223, 42, 0, 5);
    let starty = 0;
    let totalpage = 1;
    for (let index = 0; index < this.state.statementArray.length; index++) {
      doc.rect(25, 47 + starty * 5, 229, 5);
      doc.rect(41, 47 + starty * 5, 0, 5);
      doc.rect(66, 47 + starty * 5, 0, 5);
      doc.rect(97, 47 + starty * 5, 0, 5);
      doc.rect(124, 47 + starty * 5, 0, 5);
      doc.rect(146, 47 + starty * 5, 0, 5);
      doc.rect(172, 47 + starty * 5, 0, 5);
      doc.rect(199, 47 + starty * 5, 0, 5);
      doc.rect(223, 47 + starty * 5, 0, 5);

      doc.text(28, 51 + starty * 5, `${index + 1}`);
      doc.text(42, 51 + starty * 5, `${this.state.statementArray[index].date}`);
      doc.text(
        67,
        51 + starty * 5,
        `${this.state.statementArray[index].lorryNo}`
      );

      let royaltyLimit = `${this.state.statementArray[index].royaltyNo}`
        .split("")
        .slice(0, 12)
        .join("");
      doc.text(99, 51 + starty * 5, `${royaltyLimit}`);
      doc.text(
        125,
        51 + starty * 5,
        `${this.state.statementArray[index].dmNo}`
      );
      doc.text(
        147,
        51 + starty * 5,
        `${this.state.statementArray[index].item}`
      );
      doc.text(
        173,
        51 + starty * 5,
        `${parseFloat(this.state.statementArray[index].sellQtyCft).toFixed(
          2
        )} ${this.state.statementArray[index].itemUOM}`
      );
      doc.text(
        200,
        51 + starty * 5,
        `${(
          parseFloat(this.state.statementArray[index].totalAmount) /
          parseFloat(this.state.statementArray[index].sellQtyCft)
        ).toFixed(2)}`
      );
      doc.text(
        224,
        51 + starty * 5,
        `${parseFloat(this.state.statementArray[index].totalAmount).toFixed(2)}`
      );
      const reducer1 = (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.totalAmount);
      const reducer2 = (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.sellQtyCft);
      let total = this.state.statementArray.reduce(reducer1, 0);
      this._isMounted && this.setState({ total });
      if (index == this.state.statementArray.length - 1) {
        doc.rect(25, 47 + (starty + 1) * 5, 229, 5);
        doc.rect(41, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(66, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(97, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(124, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(146, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(172, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(199, 47 + (starty + 1) * 5, 0, 5);
        doc.rect(223, 47 + (starty + 1) * 5, 0, 5);
        doc.setFont("times", "bold");
        doc.text(26, 46.2 + (starty + 2) * 5, "TOTAL");
        // doc.text(
        //   173,
        //   46.2 + (starty + 2) * 5,
        //   `${parseFloat(this.state.statementArray.reduce(reducer2, 0)).toFixed(
        //     2
        //   )}`
        // );
        doc.text(
          224,
          46.2 + (starty + 2) * 5,
          `${parseFloat(total).toFixed(2)}`
        );
        doc.setFontSize(9);
        doc.setFont("times", "normal");
        doc.text(
          26,
          52.2 + (starty + 2) * 5,
          "*Above amount is exclusive of GST, 2.5% CGST and 2.5% SGST will be applied.\n#This is Computer Generated Document."
        );
        doc.setFontSize(12);
      }
      if (index % 28 == 0) {
        if (index == 0) {
          starty = starty + 1;

          doc.text(270, 200, `1 of ${totalpage}`);
          totalpage = totalpage + 1;
        } else {
          doc.addPage();
          let current = doc.internal.getNumberOfPages();
          doc.text(270, 200, `${current} of ${totalpage}`);
          starty = 0;
          doc.setFont("times", "normal");
          doc.setFontSize(20);
          doc.text(148.5, 12, "SALES STATEMENT", "center");
          doc.setFontSize(14);
          doc.text(
            25,
            20,
            `From: ${this.state.companyData.organizationName},${this.state.companyData.atPost}`
          );
          doc.text(
            25,
            27,
            `To: ${this.props.customerName}, ${this.props.address}`
          );
          doc.text(
            25,
            34,
            `For Period of ${this.state.dateFrom.toDateString()} to ${this.state.dateTo.toDateString()}`
          );
          doc.setFontSize(12, "bold");
          doc.text(26, 46.2, "Sr.No.");
          doc.text(42, 46.2, "Date");
          doc.text(67, 46.2, "Lorry No.");
          doc.text(99, 46.2, "Royalty No.");
          doc.text(125, 46.2, "D.M. No.");
          doc.text(147, 46.2, "Size in mm");
          doc.text(173, 46.2, "Total Qty");
          doc.text(200, 46.2, "Rate/Unit");
          doc.text(224, 46.2, "Amount");
          doc.setFontSize(12, "normal");
          doc.rect(25, 42, 229, 5);
          doc.rect(41, 42, 0, 5);
          doc.rect(66, 42, 0, 5);
          doc.rect(97, 42, 0, 5);
          doc.rect(124, 42, 0, 5);
          doc.rect(146, 42, 0, 5);
          doc.rect(172, 42, 0, 5);
          doc.rect(199, 42, 0, 5);
          doc.rect(223, 42, 0, 5);
          totalpage = totalpage + 1;
        }
      } else {
        starty = starty + 1;
      }
    }

    doc.save(`Statement.pdf`);
  };
  callDataFromDB = (changeToCurrentFY) => {
    var fiscalyear = this.state.fiscalyearForDMCall;

    if (changeToCurrentFY) {
      var today = new Date();
      if (today.getMonth() + 1 <= 3) {
        fiscalyear =
          "FY" +
          (today.getFullYear() - 1) +
          "_" +
          today.getFullYear().toString().substring(2);
      } else {
        this._isMounted &&
          this.setState({
            minYear: today.getFullYear(),
            minYear: today.getFullYear() + 1,
          });
        fiscalyear =
          "FY" +
          today.getFullYear() +
          "_" +
          (today.getFullYear() + 1).toString().substring(2);
      }
    }

    this._isMounted &&
      this.setState({ fiscalyearForDMCall: fiscalyear }, () => {
        let dbRef = firebase
          .database()
          .ref(
            `${this.state.uoid}/factoryData/customerData/salesReport/${this.props.customerId}/${this.state.fiscalyearForDMCall}`
          )
          .orderByChild("dateStamp")
          .startAt(Date.parse(this.state.dateFrom.toDateString()))
          .endAt(
            Date.parse(
              new Date(
                `${this.state.dateTo.getFullYear()}-${
                  this.state.dateTo.getMonth() + 1
                }-${this.state.dateTo.getDate()} 23:59:59`
              )
            )
          );

        dbRef.on("value", (DataSnapShot) => {
          if (DataSnapShot.val()) {
            let data = Object.values(DataSnapShot.val());
            this.setState(
              {
                mainData: data.sort(function (a, b) {
                  if (a.dmNo < b.dmNo) {
                    return 1;
                  }
                  if (a.dmNo > b.dmNo) {
                    return -1;
                  }
                  return 0;
                }),
                isLoading: false,
              },
              () => {
                this._isMounted &&
                  this.setState({
                    customerId: this.props.customerId,
                    customerName: this.props.customerName,
                    address: this.props.address,
                    isButtonLoading: false,
                  });
              }
            );
          } else {
            this._isMounted &&
              this.setState({
                isLoading: false,
                mainData: [],
                isButtonLoading: false,
              });
          }
        });
      });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                if (column == "Date") {
                  this.setState(
                    { postSorterAce: !this.state.postSorterAce },
                    () => {
                      this.tableRowSorter();
                    }
                  );
                } else if (column == "Status") {
                  // this._isMounted && this.setState({  show_nrdm: !this.state.show_nrdm });
                }
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Date" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderBill = () => (
    <View style={styles.tableHeader}>
      {this.state.columnsModalBill.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                if (column == "Date") {
                  this.setState(
                    { postSorterAce: !this.state.postSorterAce },
                    () => {
                      this.tableRowSorter();
                    }
                  );
                } else if (column == "Status") {
                  // this._isMounted && this.setState({  show_nrdm: !this.state.show_nrdm });
                }
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Date" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this._isMounted &&
      this.setState({
        mainData: this.state.mainData.sort(function (a, b) {
          if (a.date > b.date) {
            return direction ? 1 : -1;
          }
          if (a.date < b.date) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      });
  };

  filterForLedgerEntry = () => {
    let newData = this.state.mainData.filter(
      (item) =>
        item.checkedByAuthPerson == true &&
        item.isLedgerEntryDone == false &&
        item.isMarkforLedgerGen == true
    );
    if (newData.length != 0) {
      this._isMounted && this.setState({ setVisible: true });
      if (this.state.productRate) {
        let finalArray = newData.map((item1, index1) => {
          if (item1.paymentMode == "Credit") {
            this.state.productRate.map((item2, index2) => {
              if (item1.item == item2.productName) {
                item1.balanceAmount =
                  parseFloat(item2.itemRate) * item1.sellQtyCft;
              }
            });
            return item1;
          } else if (item1.paymentMode == "Cash") {
            item1.balanceAmount = item1.totalAmount; /*- item1.receivedAmount;*/
            return item1;
          }
        });

        this.setState(
          {
            newData: finalArray.sort(function (a, b) {
              if (a.dmNo < b.dmNo) {
                return 1;
              }
              if (a.dmNo > b.dmNo) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            // this.updateSubTotal();
            var resArr = [];
            this.state.newData.forEach(function (item) {
              var i = resArr.findIndex((x) => x.item == item.item);
              if (i <= -1) {
                resArr.push({ item: item.item });
              }
            });
            this._isMounted &&
              this.setState({ uniqueItem: resArr }, () => {
                this.ledgerCalculations();
              });
          }
        );
      } else {
        this.props.snackbarVisibleFun("Please Enter Product Rates at profile");
      }
    } else {
      this.props.snackbarVisibleFun("Please Select Ledger Entry");
    }
  };

  ledgerCalculations = () => {
    this._isMounted && this.setState({ lodingLedgerCal: true });
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.balanceAmount);

    let ledTotal = this.state.newData.reduce(reducer1, 0);
    let sgst = this.state.addGsttoLedger
      ? this.state.newData.reduce(reducer1, 0) * 0.025
      : 0;
    let cgst = this.state.addGsttoLedger
      ? this.state.newData.reduce(reducer1, 0) * 0.025
      : 0;
    let ledGrandTotal = (ledTotal + sgst + cgst).toFixed(2);
    this.setState(
      {
        ledgerCalculations: {
          ledTotal: ledTotal,
          sgst: sgst,
          cgst: cgst,
          ledGrandTotal: ledGrandTotal,
        },
      },
      () => {
        this._isMounted && this.setState({ lodingLedgerCal: true });
      }
    );
  };

  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveToLedger = () => {
    this.setState({ setVisibleActivityIndicatory: true });
    var ledgerId = this.randomString(
      15,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    let ledgerTime = new Date();
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/ledger/${this.state.customerId}/${ledgerTime}`
      );

    dbref
      .set({
        ledgerId: ledgerId,
        dateNumber: Date.parse(this.state.date),
        transDate: this.state.date.toDateString(),
        discription: this.state.particular,
        debit: this.state.ledgerCalculations.ledGrandTotal,
        credit: this.state.creditAmt,
        // balance:
        //   parseFloat(this.state.lastEntry.balance) +
        //   parseFloat(this.state.ledgerCalculations.ledGrandTotal) -
        //   parseFloat(this.state.creditAmt),
        details: {
          dmArray: this.state.newData,
          ledgerCalculations: this.state.ledgerCalculations,
        },
        entryBy: this.state.loginUser,
        timeStampId: `${ledgerTime}`,
        isCashEntry: false,
      })
      .then(() => {
        this.ledgerGenerated();
      });
  };
  ledgerGenerated = () => {
    this.state.newData.map((item) => {
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/salesReport/${this.state.customerId}/${this.state.fiscalyearForDMCall}/${item.id}`
        );
      dbRef.update({
        isLedgerEntryDone: true,
      });
    });
    this.setState({ setVisibleActivityIndicatory: false });
    this.props.snackbarVisibleFun("Entry Successfully !");
  };
  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <Portal>
          <Modal
            visible={this.state.setVisibleActivityIndicatory}
            contentContainerStyle={{
              backgroundColor: "#c1c1c1",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 550,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View
              style={{
                alignContent: "center",
                justifyContent: "center",
                padding: 40,
              }}
            >
              <ActivityIndicator size="large" color="#0a60ff" />
            </View>
          </Modal>
        </Portal>
        <Portal>
          <Modal
            visible={this.state.salesStatementFilterModal}
            onDismiss={() => {
              this._isMounted &&
                this.setState({ salesStatementFilterModal: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 550,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Sales Statement Filter and Download
              </Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Picker
                selectedValue={this.state.paymentType}
                style={{
                  height: 40,
                  width: 180,
                  marginHorizontal: 22,
                  marginVertical: 15,
                  borderWidth: 1,
                }}
                onValueChange={(itemValue, itemIndex) =>
                  itemValue == "All"
                    ? this._isMounted &&
                      this.setState({
                        paymentTypeFilter: false,
                        paymentType: itemValue,
                      })
                    : this._isMounted &&
                      this.setState({
                        paymentType: itemValue,
                        paymentTypeFilter: true,
                      })
                }
              >
                <Picker.Item label="Payment Mode" value="" />
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Cash" value="Cash" />
                <Picker.Item label="Credit" value="Credit" />
              </Picker>
              <Picker
                selectedValue={this.state.transportationIncluded}
                style={{
                  height: 40,
                  width: 180,
                  marginHorizontal: 22,
                  marginVertical: 15,
                  borderWidth: 1,
                }}
                onValueChange={(itemValue, itemIndex) =>
                  itemValue == "Both"
                    ? this._isMounted &&
                      this.setState({
                        transportationFilter: false,
                        transportationIncluded: itemValue,
                      })
                    : this._isMounted &&
                      this.setState({
                        transportationIncluded: itemValue,
                        transportationFilter: true,
                      })
                }
              >
                <Picker.Item label="Transportation Included" value="" />
                <Picker.Item label="Both" value="Both" />
                <Picker.Item label="Included" value="Included" />
                <Picker.Item label="Not Included" value="Not Included" />
              </Picker>
            </View>
            <TouchableOpacity
              style={[styles.addNewButton, { width: 180, alignSelf: "center" }]}
              onPress={() => {
                this._isMounted &&
                  this.setState({ salesStatementFilterModal: false });
              }}
            >
              <Text style={{ color: "#fff" }}>OK</Text>
            </TouchableOpacity>
          </Modal>
        </Portal>
        <Portal>
          <Modal
            visible={this.state.setVisibleRateConfirm}
            onDismiss={() => {
              this._isMounted &&
                this.setState({ setVisibleRateConfirm: false }, () => {});
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 550,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Please confirm the rates of Product.
              </Text>
            </View>
            <View style={{ height: 300 }}>
              <FlatList
                data={this.state.productRate}
                renderItem={({ item, index }) => {
                  return (
                    <View style={{ zIndex: -5 }}>
                      <View
                        style={[
                          styles.tableRow,
                          {
                            backgroundColor:
                              index % 2 == 1 ? "#F0FBFC" : "white",
                          },
                        ]}
                      >
                        <Text style={{ width: "30%", textAlign: "center" }}>
                          {index + 1}
                        </Text>
                        <Text style={{ width: "30%", textAlign: "center" }}>
                          {item.productName}
                        </Text>
                        <Text style={{ width: "30%", textAlign: "center" }}>
                          ₹ {item.itemRate} per {item.uom}
                        </Text>
                        <Text style={{ width: "30%", textAlign: "center" }}>
                          ₹ {item.itemRate2} per {item.uom2}
                        </Text>
                      </View>
                    </View>
                  );
                }}
                ListEmptyComponent={
                  <View style={{ padding: 50 }}>
                    <Text>
                      No Rates has been updated in customer's profile! Please
                      update it now
                    </Text>
                  </View>
                }
                keyExtractor={(item) => JSON.stringify(item.productName)}
              />
              <View style={{ flexDirection: "row" }}>
                <Checkbox
                  status={this.state.rateConfirm ? "checked" : "unchecked"}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        rateConfirm: !this.state.rateConfirm,
                      });
                  }}
                  color="#0a60ff"
                />
                <Text style={{ padding: 8 }}>
                  I/We has checked the product rate and confirms the same.I/we
                  knows that once invoice is generated it cannot be reverted for
                  the same entry in sales report.
                </Text>
                <View>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      if (this.state.rateConfirm == true) {
                        this.billDataArray();
                      } else {
                        this.props.snackbarVisibleFun(
                          "Please Confirm the Rates"
                        );
                      }
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Confirm</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.addNewButton, { marginTop: 15 }]}
                    onPress={() => {
                      this.props.navigation.navigate("customerEdit", {
                        customerId: this.state.customerId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Update Rates</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </Portal>
        {/* Invoice Modal Start */}
        <Portal>
          <Modal
            visible={this.state.setVisibleBill}
            onDismiss={() => {
              this._isMounted &&
                this.setState({ setVisibleBill: false, billData: [] }, () => {
                  this.getbillno(false);
                });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 850,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Generate Invoice for selected DM of {this.props.customerName}
              </Text>
            </View>
            <View>
              <View
                style={{
                  padding: 8,
                  borderBottomWidth: 1,
                  borderBottomColor: "#c1c1c1",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      padding: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Customer Name : {this.props.customerName}
                  </Text>
                  <Text
                    style={{
                      padding: 10,
                      fontSize: 16,
                    }}
                  >
                    Invoice no:
                    {this.props.gst == ""
                      ? `A-${this.state.billno}`
                      : this.state.billno}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    Customer Address: {this.state.address}
                  </Text>
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    Total DM in Invoice: {this.state.billData.length}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    GSTIN: {this.props.gst}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ alignSelf: "center", fontSize: 16 }}>
                      Invoice Date
                    </Text>
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor: "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 10,
                          alignSelf: "center",
                          padding: 10,
                          width: 120,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this._isMounted &&
                          this.setState({
                            viewCalenderForBill:
                              !this.state.viewCalenderForBill,
                          });
                      }}
                    >
                      {this.state.dateForBill.getDate()}/
                      {this.state.dateForBill.getMonth() + 1}/
                      {this.state.dateForBill.getFullYear()}
                    </Text>

                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: -270,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.viewCalenderForBill ? (
                        <Calendar
                          value={this.state.dateForBill}
                          onChange={(value) => {
                            this._isMounted &&
                              this.setState({ dateForBill: value }, () => {
                                this._isMounted &&
                                  this.setState({ viewCalenderForBill: false });
                              });
                          }}
                        />
                      ) : null}
                    </View>
                  </View>
                </View>
                <View style={{ height: 300 }}>
                  <FlatList
                    data={this.state.billData}
                    renderItem={({ item, index }) => {
                      return (
                        <View style={{ zIndex: -5 }}>
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text style={styles.columnRowTxt}>{index + 1}</Text>
                            <Text style={styles.columnRowTxt}>{item.date}</Text>
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.lorryNo}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.royaltyNo}
                            </Text>
                            <Text style={styles.columnRowTxt}>{item.dmNo}</Text>

                            {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.item}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.sellQtyCft}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.paymentMode}
                            </Text>

                            <Text style={styles.columnRowTxt}>
                              {item.totalAmount}
                            </Text>
                          </View>
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeaderBill}
                    ListFooterComponent={this.tableFooterForBill}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        <ActivityIndicator size="large" color="#0a60ff" />
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.id)}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <View style={{ flexDirection: "column" }}>
                  <View style={{ flexDirection: "row" }}>
                    <Checkbox
                      status={
                        this.state.acceptBillGen ? "checked" : "unchecked"
                      }
                      onPress={() => {
                        if (
                          this.state.stateCode != null &&
                          this.state.companyData.stateCode != null
                        ) {
                          if (this.state.billCalculations.billTotal != "NaN") {
                            this._isMounted &&
                              this.setState({
                                acceptBillGen: !this.state.acceptBillGen,
                                showError: false,
                              });
                          } else {
                            this._isMounted &&
                              this.setState({
                                showError: true,
                              });
                          }
                        }
                      }}
                      color="#0a60ff"
                    />
                    {this.state.showError ? (
                      <Text
                        style={{
                          padding: 8,
                          fontWeight: "bold",
                          backgroundColor: "#DE5246",
                        }}
                      >
                        Please update item rate in profile
                      </Text>
                    ) : null}
                  </View>
                  <Text style={{ padding: 8, fontWeight: "bold" }}>
                    I Confirm to Generate this Invoice no. {this.state.billno}
                  </Text>
                  <TouchableOpacity
                    disabled={!this.state.acceptBillGen}
                    style={styles.addNewButton}
                    onPress={() => {
                      if (this.state.acceptBillGen) {
                        this._isMounted &&
                          this.setState({ setVisibleBill: false });
                        this.saveToBills();
                        this.saveBillToBulk();
                      }
                    }}
                  >
                    <Text style={{ color: "#fff", alignSelf: "center" }}>
                      Generate and save Invoice
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  style={[
                    styles.addNewButton,
                    { width: 150, backgroundColor: "#fff" },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({ setVisibleBill: false, billData: [] });
                  }}
                >
                  <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </Portal>
        {/* Invoice Modal Ends */}

        {/* Ledger modal Start */}

        <Portal>
          <Modal
            visible={this.state.setVisible}
            onDismiss={() => {
              this._isMounted &&
                this.setState({ setVisible: false }, () => {
                  this._isMounted &&
                    this.setState({
                      ledgerCalculations: {},
                      lodingLedgerCal: false,
                      addGsttoLedger: true,
                      acceptEntryLedger: false,
                      date: new Date(),
                      dateforEntry: new Date(),
                      // newData: [], //Selected For Ledger Generation
                      viewCalender: false,
                      // lastEntry: {}, //last entry to get balance amount of customer
                    });
                });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 850,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>
                Debit Ledger of {this.props.customerName}
              </Text>
            </View>
            <View>
              <View
                style={{
                  padding: 8,
                  borderBottomWidth: 1,
                  borderBottomColor: "#c1c1c1",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      padding: 10,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Customer Name : {this.props.customerName}
                  </Text>
                  <Text
                    style={{
                      padding: 10,
                      fontSize: 16,
                    }}
                  >
                    Current Entry Type : Debit
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    Customer Address: {this.props.address}
                  </Text>
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    Total DM in Entry: {this.state.newData.length}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    {/* Current Balance ₹: {this.state.lastEntry.balance} */}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Checkbox
                      status={
                        this.state.addGsttoLedger ? "checked" : "unchecked"
                      }
                      onPress={() => {
                        this.setState(
                          {
                            addGsttoLedger: !this.state.addGsttoLedger,
                          },
                          () => {
                            this.ledgerCalculations();
                          }
                        );
                      }}
                      color="#0a60ff"
                    />
                    <Text style={{ padding: 8 }}>Add 5% GST</Text>
                    <Text style={{ padding: 10, fontSize: 16 }}>
                      New Debit Amount:{" "}
                      {this.state.ledgerCalculations.ledGrandTotal}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ padding: 10, fontSize: 16 }}>
                    {/* Last Entry Date: {this.state.lastEntry.transDate} */}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ alignSelf: "center", fontSize: 16 }}>
                      New Entry Date
                    </Text>
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor: "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 10,
                          alignSelf: "center",
                          padding: 10,
                          width: 120,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this._isMounted &&
                          this.setState({
                            viewCalender: !this.state.viewCalender,
                          });
                      }}
                    >
                      {this.state.date.getDate()}/
                      {this.state.date.getMonth() + 1}/
                      {this.state.date.getFullYear()}
                    </Text>

                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: -270,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.viewCalender ? (
                        <Calendar
                          value={this.state.date}
                          onChange={(value) => {
                            this._isMounted &&
                              this.setState({ date: value }, () => {
                                this._isMounted &&
                                  this.setState({ viewCalender: false });
                              });
                          }}
                        />
                      ) : null}
                    </View>
                  </View>
                </View>
                <View style={{ height: 300 }}>
                  <FlatList
                    data={this.state.newData}
                    renderItem={({ item, index }) => {
                      return (
                        <View style={{ zIndex: -5 }}>
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor:
                                  index % 2 == 1 ? "#F0FBFC" : "white",
                              },
                            ]}
                          >
                            <Text style={styles.columnRowTxt}>{index + 1}</Text>
                            <Text style={styles.columnRowTxt}>{item.date}</Text>
                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.lorryNo}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.royaltyNo}
                            </Text>
                            <Text style={styles.columnRowTxt}>{item.dmNo}</Text>

                            {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                            <Text
                              style={[
                                styles.columnRowTxt,
                                { fontWeight: "bold" },
                              ]}
                            >
                              {item.item}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.sellQtyCft}
                            </Text>
                            <Text style={styles.columnRowTxt}>
                              {item.paymentMode}
                            </Text>

                            <Text style={styles.columnRowTxt}>
                              {item.balanceAmount}
                            </Text>
                          </View>
                        </View>
                      );
                    }}
                    ListHeaderComponent={this.tableHeaderBill}
                    ListFooterComponent={this.tableFooter}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        <ActivityIndicator size="large" color="#0a60ff" />
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.id)}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <View style={{ flexDirection: "column" }}>
                  <View style={{ flexDirection: "row" }}>
                    <Checkbox
                      status={
                        this.state.acceptEntryLedger ? "checked" : "unchecked"
                      }
                      onPress={() => {
                        if (
                          this.state.ledgerCalculations.ledGrandTotal != "NaN"
                        ) {
                          this._isMounted &&
                            this.setState({
                              acceptEntryLedger: !this.state.acceptEntryLedger,
                              showError: false,
                            });
                        } else {
                          this._isMounted &&
                            this.setState({
                              showError: true,
                            });
                        }
                      }}
                      color="#0a60ff"
                    />
                    {this.state.showError ? (
                      <Text
                        style={{
                          padding: 8,
                          fontWeight: "bold",
                          backgroundColor: "#DE5246",
                        }}
                      >
                        Please update item rate in profile
                      </Text>
                    ) : null}
                  </View>
                  <Text style={{ padding: 8, fontWeight: "bold" }}>
                    Make a debit entry of{" "}
                    {this.state.ledgerCalculations.ledGrandTotal}
                  </Text>
                  <TouchableOpacity
                    disabled={!this.state.acceptEntryLedger}
                    style={styles.addNewButton}
                    onPress={() => {
                      if (this.state.acceptEntryLedger) {
                        this._isMounted &&
                          this.setState({ setVisible: false }, () => {
                            this.saveToLedger();
                          });
                      }
                    }}
                  >
                    <Text style={{ color: "#fff", alignSelf: "center" }}>
                      Save Ledger
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  style={[
                    styles.addNewButton,
                    { width: 150, backgroundColor: "#fff" },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({ setVisible: false }, () => {
                        this._isMounted &&
                          this.setState({
                            ledgerCalculations: {},
                            lodingLedgerCal: false,
                            addGsttoLedger: true,
                            acceptEntryLedger: false,
                            date: new Date(),
                            dateforEntry: new Date(),
                            // newData: [], //Selected For Ledger Generation
                            viewCalender: false,
                          });
                      });
                  }}
                >
                  <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </Portal>
        {/* Ledger Model Ends */}
        <View style={{ flexDirection: "column" }}>
          <View style={styles.header}>
            <Text
              style={{
                fontSize: 20,
                fontWeight: "500",
                alignSelf: "center",
              }}
            >
              {this.props.customerName}-Sales Report
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 280,
              }}
            >
              <TouchableOpacity
                style={styles.addNewButton}
                onPress={() => {
                  this._isMounted &&
                    this.setState({
                      setVisibleRateConfirm: true,
                      showError: false,
                    });
                  this.getbillno(false);
                }}
                // onPress={() => {
                //   this._isMounted && this.setState({
                //     setVisibleBill: true,
                //     dateforEntry: new Date(),
                //   });
                //   this.billDataArray();
                // }}
              >
                <Text style={{ color: "#fff" }}>Generate Invoice</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.addNewButton}
                onPress={() => {
                  // if (this.state.newData.length != 0) {
                  this._isMounted && this.setState({ showError: false });
                  // this.getLastEntry();
                  this.filterForLedgerEntry();
                  // this.ledgerCalculations();
                  // } else {
                  //
                  // }
                }}
              >
                <Text style={{ color: "#fff" }}>New Ledger Entry</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ padding: 10, fontWeight: "bold", fontSize: 16 }}>
              Sales Statement
            </Text>
            <Picker
              selectedValue={this.state.fiscalyearForDMCall}
              style={{
                padding: 12,
                width: 180,
                borderWidth: 1,
                borderRadius: 5,
              }}
              onValueChange={(itemValue) =>
                this._isMounted &&
                this.setState(
                  {
                    fiscalyearForDMCall: itemValue,
                    fiscalyear: itemValue
                      .split("")
                      .slice(2, 7)
                      .concat("20" + itemValue.split("_")[1])
                      .join(""),
                  },
                  () => {}
                )
              }
            >
              <Picker.Item label="FY2022_23" value="FY2022_23" />
              <Picker.Item label="FY2021_22" value="FY2021_22" />

              {/* <Picker.Item
                          label={new Date().getUTCFullYear() - 2}
                          value={new Date().getUTCFullYear() - 2}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 3}
                          value={new Date().getUTCFullYear() - 3}
                        /> */}
            </Picker>
            <TouchableOpacity
              style={[
                styles.actionbtn,
                {
                  borderColor: "#0a60ff",
                  marginHorizontal: 10,
                },
              ]}
              onPress={() => {
                this._isMounted &&
                  this.setState({
                    isButtonLoading: true,
                  });
                this.callDataFromDB(false);
                this.getbillno(false);
              }}
            >
              {this.state.isButtonLoading ? (
                <ActivityIndicator size="small" color="#0a60ff" />
              ) : (
                <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                  View
                </Text>
              )}
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Text style={{ padding: 20 }}>From :</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 200,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        viewCalenderFrom: !this.state.viewCalenderFrom,
                      });
                  }}
                >
                  {/* {this.state.dateFrom.getDate()}/
                  {this.state.dateFrom.getMonth() + 1}/
                  {this.state.dateFrom.getFullYear()} */}
                  {this.state.dateFrom.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderFrom ? (
                    <Calendar
                      value={this.state.dateFrom}
                      onChange={(value) => {
                        this._isMounted &&
                          this.setState({ dateFrom: value }, () => {
                            this._isMounted &&
                              this.setState({ viewCalenderFrom: false });
                          });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>
            {/* to */}
            <Text style={{ padding: 20 }}>To :</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 200,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this._isMounted &&
                      this.setState({
                        viewCalenderTo: !this.state.viewCalenderTo,
                      });
                  }}
                >
                  {/* {this.state.dateTo.getDate()}/
                  {this.state.dateTo.getMonth() + 1}/
                  {this.state.dateTo.getFullYear()} */}
                  {this.state.dateTo.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderTo ? (
                    <Calendar
                      value={this.state.dateTo}
                      onChange={(value) => {
                        this._isMounted &&
                          this.setState({ dateTo: value }, () => {
                            this._isMounted &&
                              this.setState({ viewCalenderTo: false });
                          });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>

            <TouchableOpacity
              style={[
                styles.actionbtn,
                { borderColor: "#0a60ff", margin: 15, width: 100 },
              ]}
              onPress={() => {
                this._isMounted && this.setState({ isButtonLoading: true });
                this.callDataFromDB(false);
              }}
            >
              {this.state.isButtonLoading ? (
                <ActivityIndicator size="small" color="#0a60ff" />
              ) : (
                <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                  View
                </Text>
              )}
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.actionbtn,
                {
                  borderColor: this.state.errorColor
                    ? this.state.errorColor
                    : "#0a60ff",
                  margin: 15,
                },
              ]}
              onPress={() => {
                this._isMounted &&
                  this.setState({ salesStatementFilterModal: true });
                // if (this.state.paymentType) {
                //   this.filterArrayForStatement();
                // } else {
                //   this._isMounted && this.setState({
                //     errorColor: "#B50706",
                //   });
                // }
              }}
            >
              <Text
                style={{
                  color: this.state.errorColor
                    ? this.state.errorColor
                    : "#0a60ff",
                }}
              >
                Apply Filter
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.actionbtn, { borderColor: "#B50706", margin: 15 }]}
              onPress={() => {
                if (
                  this.state.paymentType &&
                  this.state.transportationIncluded
                ) {
                  this.filterArrayForStatement();
                } else {
                  this._isMounted &&
                    this.setState({
                      errorColor: "#B50706",
                    });
                }
              }}
            >
              <Text
                style={{
                  color: "#B50706",
                }}
              >
                PDF Download
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={{ maxHeight: 450, zIndex: -1 }}>
          <FlatList
            data={this.state.mainData}
            //data={this.state.statementArray}
            renderItem={({ item, index }) => {
              if (this.state.show_nrdm) {
                return (
                  <TouchableOpacity
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor:
                          parseFloat(item.balanceAmount) > 0
                            ? "#ffb3b3"
                            : "white",
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("salesReportViewScreen", {
                        customerId: this.state.customerId,
                        salesId: item.id,
                        fiscalyearForDMCall: this.state.fiscalyearForDMCall,
                      });
                    }}
                  >
                    <Text style={styles.columnRowTxt}>{item.date}</Text>
                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      {item.lorryNo}-
                      {item.transportIncluded != undefined
                        ? item.transportIncluded
                          ? "Y"
                          : "N"
                        : "N/A"}
                    </Text>
                    <Text style={styles.columnRowTxt}>{item.royaltyNo}</Text>
                    <Text style={styles.columnRowTxt}>{item.dmNo}</Text>
                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      {item.item}
                    </Text>
                    <Text style={styles.columnRowTxt}>{item.sellQtyCft}</Text>
                    <Text style={styles.columnRowTxt}>{item.paymentMode}</Text>

                    <Text style={styles.columnRowTxt}>
                      {item.paymentMode == "Credit" ? "-" : item.balanceAmount}
                    </Text>
                    {item.isactive == false ? (
                      <View style={{ width: "11.11%", alignItems: "center" }}>
                        <MaterialIcons
                          name="cancel"
                          size={24}
                          color="#B50706"
                        />
                      </View>
                    ) : (
                      <View
                        style={[
                          styles.columnRowTxt,
                          {
                            flexDirection: "row",
                            justifyContent: "space-around",
                          },
                        ]}
                      >
                        <AntDesign
                          name="checkcircle"
                          size={20}
                          color={
                            item.checkedByAuthPerson ? "#009C44" : "#c1c1c1"
                          }
                          style={{ alignSelf: "center" }}
                        />
                        {item.isLedgerEntryDone ? (
                          <MaterialCommunityIcons
                            name="book-account-outline"
                            size={22}
                            color="#009C44"
                          />
                        ) : (
                          <MaterialCommunityIcons
                            name="book-account-outline"
                            size={22}
                            color={
                              item.isMarkforLedgerGen ? "#F4B400" : "#c1c1c1"
                            }
                          />
                        )}
                        {item.isBillGenertd ? (
                          <FontAwesome5
                            name="receipt"
                            size={22}
                            color="#009C44"
                          />
                        ) : (
                          <FontAwesome5
                            name="receipt"
                            size={22}
                            color={
                              item.isMarkforBillGen ? "#F4B400" : "#c1c1c1"
                            }
                          />
                        )}
                      </View>
                    )}
                  </TouchableOpacity>
                );
              } else if (!this.state.show_nrdm) {
                return item.royaltyNo != "" ? (
                  <TouchableOpacity
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor:
                          parseFloat(item.balanceAmount) > 0
                            ? "#ffb3b3"
                            : "white",
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("salesReportViewScreen", {
                        customerId: this.state.customerId,
                        salesId: item.id,
                        fiscalyearForDMCall: this.state.fiscalyearForDMCall,
                      });
                    }}
                  >
                    <Text style={styles.columnRowTxt}>{item.date}</Text>
                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      {item.lorryNo}
                    </Text>
                    <Text style={styles.columnRowTxt}>{item.royaltyNo}</Text>
                    <Text style={styles.columnRowTxt}>{item.dmNo}</Text>
                    <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                      {item.item}
                    </Text>
                    <Text style={styles.columnRowTxt}>{item.sellQtyCft}</Text>
                    <Text style={styles.columnRowTxt}>{item.paymentMode}</Text>

                    <Text style={styles.columnRowTxt}>
                      {item.paymentMode == "Credit"
                        ? "-"
                        : item.totalAmount - item.receivedAmount}
                    </Text>
                    {item.isactive == false ? (
                      <View style={{ width: "11.11%", alignItems: "center" }}>
                        <MaterialIcons
                          name="cancel"
                          size={24}
                          color="#B50706"
                        />
                      </View>
                    ) : (
                      <View
                        style={[
                          styles.columnRowTxt,
                          {
                            flexDirection: "row",
                            justifyContent: "space-around",
                          },
                        ]}
                      >
                        <AntDesign
                          name="checkcircle"
                          size={20}
                          color={
                            item.checkedByAuthPerson ? "#009C44" : "#c1c1c1"
                          }
                          style={{ alignSelf: "center" }}
                        />
                        {item.isLedgerEntryDone ? (
                          <MaterialCommunityIcons
                            name="book-account-outline"
                            size={22}
                            color="#009C44"
                          />
                        ) : (
                          <MaterialCommunityIcons
                            name="book-account-outline"
                            size={22}
                            color={
                              item.isMarkforLedgerGen ? "#F4B400" : "#c1c1c1"
                            }
                          />
                        )}
                        {item.isBillGenertd ? (
                          <FontAwesome5
                            name="receipt"
                            size={22}
                            color="#009C44"
                          />
                        ) : (
                          <FontAwesome5
                            name="receipt"
                            size={22}
                            color={
                              item.isMarkforBillGen ? "#F4B400" : "#c1c1c1"
                            }
                          />
                        )}
                      </View>
                    )}
                  </TouchableOpacity>
                ) : null;
              }
            }}
            ListHeaderComponent={this.tableHeader}
            stickyHeaderIndices={[0]}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator size="large" color="#0a60ff" />
                ) : (
                  <Text>No Entries Found in Selected Date Range</Text>
                )}
              </View>
            }
            keyExtractor={(item) => JSON.stringify(item.id)}
          />
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <SalesReportTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "11.11%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "11.11%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
    zIndex: -1,
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  columnFooter: {
    width: "11.11%",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "#c1c1c1",
    paddingTop: 20,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
});
