import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  Picker,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class cashBookScreen extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      buttonLoading: false,
      SnackbarVisible: false,
      error: "",
      setVisibleHolidayModal: false,
      viewCalender: false,
      date: new Date(),
      modalOpened: null,
      setVisible: false,
      particular: "",
      creditAmt: 0,
      debitAmt: 0,
      lastEntry: {},
      OpeningsetVisible: false,
      openingBalance: "",
      viewCalenderTo: false,
      viewCalenderFrom: false,
      dataFromDate: new Date(),
      dataToDate: new Date(),
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: ["Date", "Particular", "Debit", "Credit"],
      searchQuery: "",
      isButtonLoading: false,
      uoid: "",
      isTransferToAdmin: false,
      filterdArray: [],
      creditTotalSearch: 0,
      debitTotalSearch: 0,
      unSelectedArray: [],
      openingBalanceAll: 0,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.callDataFromDB();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/ledger/FY2021_22`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.dateNumber > b.dateNumber) {
                return 1;
              }
              if (a.dateNumber < b.dateNumber) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            // this.setState({
            //   searchData: this.state.mainData,
            //   isButtonLoading: false,
            // });
            this.filterLedger();
            const reducerDebit = (accumulator, currentValue) =>
              accumulator +
              parseFloat(currentValue.debit ? currentValue.debit : 0);
            const reducerCredit = (accumulator, currentValue) =>
              accumulator +
              parseFloat(currentValue.credit ? currentValue.credit : 0);

            let creditTotal = this.state.mainData.reduce(reducerCredit, 0);
            let debitTotal = this.state.mainData.reduce(reducerDebit, 0);

            this._isMounted && this.setState({ creditTotal, debitTotal });
          }
        );
      } else {
        this.setState({ isLoading: false, isButtonLoading: false });
        this.setState({ searchData: [] });
      }
    });
  };
  filterLedger = () => {
    let filterdArray = this.state.mainData.filter(
      (item) =>
        item.dateNumber >= Date.parse(this.state.dataFromDate.toDateString()) &&
        item.dateNumber <=
          Date.parse(
            new Date(
              this.state.dataToDate.getFullYear(),
              this.state.dataToDate.getMonth(),
              this.state.dataToDate.getDate() + 1
            )
          )
    );

    this._isMounted &&
      this.setState({ searchData: filterdArray }, () => {
        const reducerDebit = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
        const reducerCredit = (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.credit ? currentValue.credit : 0);

        let creditTotalSearch = this.state.searchData.reduce(reducerCredit, 0);
        let debitTotalSearch = this.state.searchData.reduce(reducerDebit, 0);

        this._isMounted &&
          this.setState({
            debitTotalSearch,
            creditTotalSearch,
            isButtonLoading: false,
          });
      });

    let unSelectedDateArray = this.state.mainData.filter(
      (item) =>
        item.dateNumber < Date.parse(this.state.dataFromDate.toDateString())
    );

    this._isMounted &&
      this.setState({ unSelectedArray: unSelectedDateArray }, () => {
        const reducerDebit = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.debit ? currentValue.debit : 0);
        const reducerCredit = (accumulator, currentValue) =>
          accumulator +
          parseFloat(currentValue.credit ? currentValue.credit : 0);

        let creditTotal = this.state.unSelectedArray.reduce(reducerCredit, 0);
        let debitTotal = this.state.unSelectedArray.reduce(reducerDebit, 0);
        let openingBalanceAll = debitTotal - creditTotal;

        this._isMounted && this.setState({ openingBalanceAll });
      });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  makeEntryInLedger = () => {
    if (!this.state.debitAmt == 0 || !this.state.creditAmt == 0) {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${ledgerId}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateNumber: Date.parse(new Date()),
          dateStamp: Date.parse(new Date().toDateString()),
          transDate: this.state.date.toDateString(),
          discription: this.state.particular,
          credit: this.state.creditAmt,
          debit: this.state.debitAmt,
        })
        .then(() => {
          if (this.state.isTransferToAdmin == false) {
            this.updateAdminCashBook();
          } else {
            this.setState({ buttonLoading: false }, () => {
              this.setState({
                setVisible: false,
                SnackbarVisible: true,
                particular: "",
                creditAmt: 0,
                debitAmt: 0,
                date: new Date(),
                error: "Ledger Entry Successfully !",
                buttonLoading: false,
                isTransferToAdmin: false,
              });
              setTimeout(() => {
                this.setState({ SnackbarVisible: false });
              }, 2000);
            });
          }
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  updateAdminCashBook = () => {
    if (!this.state.creditAmt == 0 || !this.state.debitAmt == 0) {
      this.setState({ buttonLoading: true });
      var transactionId = this.randomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/adminCashInOut/FY2021_22/${transactionId}`
        );
      ledgerRef
        .set({
          transactionId: transactionId,
          dateStamp: Date.parse(new Date().toDateString()),
          timestamp: Date.parse(new Date()),
          date: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
          discription: `${this.state.particular}`,
          cashIn: parseFloat(this.state.creditAmt),
          cashOut: parseFloat(this.state.debitAmt),
          entryBy: "From Site",
        })
        .then(() => {
          this.setState({ buttonLoading: false }, () => {
            this.setState({
              setVisible: false,
              SnackbarVisible: true,
              particular: "",
              creditAmt: 0,
              debitAmt: 0,
              date: new Date(),
              error: "Ledger Entry Successfully !",
              buttonLoading: false,
              isTransferToAdmin: false,
            });
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 2000);
          });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  makeOpeningEnter = () => {
    if (!this.state.openingBalance == "") {
      this.setState({ buttonLoading: true });
      var ledgerId = this.randomString(
        15,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      let ledgerRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/erpData/organizationData/ledger/FY2021_22/${ledgerId}`
        );
      ledgerRef
        .set({
          ledgerId: ledgerId,
          dateNumber: Date.parse(new Date()),
          dateStamp: Date.parse(new Date().toDateString()),
          transDate: this.state.date.toDateString(),
          discription: "Opening Balance",
          credit: "-",
          debit: "-",
          balance: parseFloat(this.state.openingBalance).toFixed(2),
        })
        .then(() => {
          this.setState({ buttonLoading: false }, () => {
            this.setState({
              OpeningsetVisible: false,
              SnackbarVisible: true,
              openingBalance: "",
              date: new Date(),
              error: "Ledger Entry Successfully !",
              buttonLoading: false,
              OpeningsetVisible: false,
            });
            setTimeout(() => {
              this.setState({ SnackbarVisible: false });
            }, 2000);
          });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column} </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Company Cash Book" color1="#0a60ff" borderWidth1={3} />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Modal starts */}
              <Portal>
                <Modal
                  visible={this.state.OpeningsetVisible}
                  onDismiss={() => {
                    this.setState({ OpeningsetVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "#ffffff",
                    width: 650,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                    borderWidth: 3,
                    alignSelf: "center",
                    OpeningsetVisible: false,
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>Opening Balance</Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row", zIndex: 5 }}>
                      <View>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c",
                              borderRadius: 5,
                              margin: 22,
                              alignSelf: "center",
                              padding: 10,
                              width: 300,
                              textAlignVertical: "center",
                            },
                          ]}
                        >
                          {this.state.date.getDate()}/
                          {this.state.date.getMonth() + 1}/
                          {this.state.date.getFullYear()}
                        </Text>
                      </View>

                      <TextInput
                        mode="outlined"
                        label="Particular"
                        value="Opening Balance"
                        style={[styles.inputField, { width: 200 }]}
                        placeholder="Particular"
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <View style={{ flexDirection: "row", zIndex: -1 }}>
                        <Text style={{ alignSelf: "center" }}>
                          Amount in Rs. to {this.state.modalOpened}
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Amount"
                          value={this.state.openingBalance}
                          style={[styles.inputField, { width: 170 }]}
                          onChangeText={(text) => {
                            let regex = /^[0-9\.]*$/;
                            if (text.match(regex)) {
                              this.setState({ openingBalance: text });
                            }
                          }}
                          placeholder="Amount"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          disabled={this.state.buttonLoading}
                          style={[
                            styles.addNewButton,
                            {
                              width: 150,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.makeOpeningEnter();
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator
                              animating={true}
                              color="#fff"
                              size="small"
                            />
                          ) : (
                            <Text
                              style={{ color: "#fff", alignSelf: "center" }}
                            >
                              {" "}
                              Save
                            </Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              width: 80,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.setState({ OpeningsetVisible: false }, () => {
                              this.setState({
                                particular: "",
                                creditAmt: 0,
                                debitAmt: 0,
                                date: new Date(),
                                isTransferToAdmin: false,
                              });
                            });
                          }}
                        >
                          <Text
                            style={{ color: "#0a60ff", alignSelf: "center" }}
                          >
                            Cancel
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>
              </Portal>
              {/* Model */}
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState(
                      {
                        particular: "",
                        creditAmt: 0,
                        debitAmt: 0,
                        date: new Date(),
                        isTransferToAdmin: false,
                      },
                      () => {
                        this.setState({ setVisible: false });
                      }
                    );
                  }}
                  contentContainerStyle={{
                    backgroundColor:
                      this.state.modalOpened == "Credit"
                        ? "#B3E4D0"
                        : "#FFDDDD",
                    width: 850,
                    borderRadius: 5,
                    borderColor:
                      this.state.modalOpened === "Credit"
                        ? "#009C44"
                        : "#de5246",
                    borderWidth: 3,
                    alignSelf: "center",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Amount {this.state.modalOpened}
                    </Text>
                  </View>
                  <View style={styles.modelBody}>
                    <View style={{ flexDirection: "row", zIndex: 5 }}>
                      <View>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 22,
                              alignSelf: "center",
                              padding: 10,
                              width: 300,
                              textAlignVertical: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              viewCalender: !this.state.viewCalender,
                            });
                          }}
                        >
                          {this.state.date.getDate()}/
                          {this.state.date.getMonth() + 1}/
                          {this.state.date.getFullYear()}
                        </Text>

                        <View
                          style={{
                            paddingLeft: 20,
                            marginTop: 75,
                            zIndex: 5,
                            position: "absolute",
                          }}
                        >
                          {this.state.viewCalender ? (
                            <Calendar
                              value={this.state.date}
                              onChange={(value) => {
                                this.setState({ date: value }, () => {
                                  this.setState({ viewCalender: false });
                                });
                              }}
                            />
                          ) : null}
                        </View>
                      </View>

                      <TextInput
                        mode="outlined"
                        label="Particular"
                        value={this.state.particular}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ particular: text });
                        }}
                        placeholder="Particular"
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <View style={{ flexDirection: "row", zIndex: -1 }}>
                        <Text style={{ alignSelf: "center" }}>
                          Amount in Rs. to {this.state.modalOpened}
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="Amount"
                          value={
                            this.state.modalOpened == "Credit"
                              ? this.state.creditAmt
                              : this.state.debitAmt
                          }
                          style={[styles.inputField, { width: 170 }]}
                          onChangeText={(text) => {
                            let regex = /^[0-9]*$/;
                            if (text.match(regex)) {
                              this.state.modalOpened == "Credit"
                                ? this.setState({ creditAmt: text })
                                : this.setState({ debitAmt: text });
                            }
                          }}
                          placeholder="Amount"
                        />
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          disabled={this.state.buttonLoading}
                          style={[
                            styles.addNewButton,
                            {
                              width: 150,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.makeEntryInLedger();
                          }}
                        >
                          {this.state.buttonLoading ? (
                            <ActivityIndicator
                              animating={true}
                              color="#fff"
                              size="small"
                            />
                          ) : (
                            <Text
                              style={{ color: "#fff", alignSelf: "center" }}
                            >
                              Save {this.state.modalOpened}
                            </Text>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[
                            styles.addNewButton,
                            {
                              backgroundColor: "#fff",
                              width: 80,
                              marginHorizontal: 20,
                              height: 42,
                              marginTop: 26,
                            },
                          ]}
                          onPress={() => {
                            this.setState({ setVisible: false }, () => {
                              this.setState({
                                particular: "",
                                creditAmt: 0,
                                debitAmt: 0,
                                date: new Date(),
                              });
                            });
                          }}
                        >
                          <Text
                            style={{ color: "#0a60ff", alignSelf: "center" }}
                          >
                            Cancel
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>
              </Portal>
              <View style={{ flexDirection: "row" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 70,
                      width: 250,
                    }}
                  >
                    <Text
                      style={{
                        marginVertical: 22,
                        color: "#404040",
                        padding: 10,
                      }}
                    >
                      From
                    </Text>
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor: "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 22,
                          alignSelf: "flex-start",
                          padding: 10,
                          width: 150,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          viewCalenderFrom: !this.state.viewCalenderFrom,
                          viewCalenderTo: false,
                        });
                      }}
                    >
                      {this.state.dataFromDate.getDate()}/
                      {this.state.dataFromDate.getMonth() + 1}/
                      {this.state.dataFromDate.getFullYear()}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 60,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderFrom ? (
                      <Calendar
                        value={this.state.dataFromDate}
                        onChange={(value) => {
                          this.setState({ dataFromDate: value }, () => {
                            this.setState({ viewCalenderFrom: false });
                          });
                        }}
                      />
                    ) : null}
                  </View>
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 70,
                      width: 250,
                    }}
                  >
                    <Text
                      style={{
                        marginVertical: 22,
                        color: "#404040",
                        padding: 10,
                      }}
                    >
                      To
                    </Text>
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor: "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 22,
                          alignSelf: "flex-start",
                          padding: 10,
                          width: 150,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          viewCalenderTo: !this.state.viewCalenderTo,
                          viewCalenderFrom: false,
                        });
                      }}
                    >
                      {this.state.dataToDate.getDate()}/
                      {this.state.dataToDate.getMonth() + 1}/
                      {this.state.dataToDate.getFullYear()}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 60,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderTo ? (
                      <Calendar
                        value={this.state.dataToDate}
                        onChange={(value) => {
                          this.setState({ dataToDate: value }, () => {
                            this.setState({ viewCalenderTo: false });
                          });
                        }}
                      />
                    ) : null}
                  </View>
                </View>
                <TouchableOpacity
                  disabled={this.state.isButtonLoading}
                  style={[
                    styles.addNewButton,
                    { marginVertical: 22, width: 120 },
                  ]}
                  onPress={() => {
                    this.setState({ isButtonLoading: true });
                    this.filterLedger();
                  }}
                >
                  {this.state.isButtonLoading ? (
                    <ActivityIndicator size="small" color="#fff" />
                  ) : (
                    <Text style={{ color: "#fff", textAlign: "center" }}>
                      View
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
              <View style={{ zIndex: -1 }}>
                <View
                  style={[
                    styles.header,
                    {
                      marginHorizontal: 70,
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      zIndex: -1,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      alignSelf: "center",
                    }}
                  >
                    Ledger
                  </Text>
                  {this.state.lastEntry.length != 0 ? (
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            backgroundColor: "#fff",
                            borderColor: "#0a60ff",
                            paddingHorizontal: 20,
                            marginRight: 20,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ modalOpened: "Debit" }, () => {
                            this.setState({
                              setVisible: true,
                              particular: "Transfer to Admin",
                              isTransferToAdmin: true,
                            });
                          });
                        }}
                      >
                        <Text style={{ color: "#0a60ff" }}>
                          Transfer to Admin
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            marginRight: 20,
                            backgroundColor: "#009C44",
                            borderColor: "#009C44",
                            paddingHorizontal: 20,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ modalOpened: "Credit" }, () => {
                            this.setState({ setVisible: true });
                          });
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Credit</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.addNewButton,
                          {
                            backgroundColor: "#de5246",
                            borderColor: "#de5246",
                            paddingHorizontal: 20,
                          },
                        ]}
                        onPress={() => {
                          this.setState({ modalOpened: "Debit" }, () => {
                            this.setState({ setVisible: true });
                          });
                        }}
                      >
                        <Text style={{ color: "#fff" }}>Debit</Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          marginRight: 20,
                          backgroundColor: "#0a60ff",
                          borderColor: "#0a60ff",
                          paddingHorizontal: 20,
                        },
                      ]}
                      onPress={() => {
                        this.setState(
                          {
                            particular: "Opening Balance",
                          },
                          () => {
                            this.setState({ OpeningsetVisible: true });
                          }
                        );
                      }}
                    >
                      <Text style={{ color: "#fff" }}>Opening Balance</Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View
                  style={{
                    marginHorizontal: 70,
                    borderWidth: 1,
                    borderColor: "#c1c1c1",
                    borderRadius: 5,
                    zIndex: -1,
                    maxHeight: 450,
                  }}
                >
                  <FlatList
                    data={this.state.searchData}
                    renderItem={({ item, index }) => {
                      return (
                        <TouchableOpacity
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index % 2 == 1 ? "#F0FBFC" : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>
                            {item.transDate}
                          </Text>
                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold" },
                            ]}
                          >
                            {item.discription}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.debit ? item.debit : "-"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.credit ? item.credit : "-"}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                    ListFooterComponent={this.tableHeader}
                    stickyHeaderIndices={[0]}
                    ListEmptyComponent={
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 50,
                        }}
                      >
                        {this.state.isLoading ? (
                          <ActivityIndicator size="large" color="#0a60ff" />
                        ) : (
                          <Text>No Entries Found in Selected Date Range</Text>
                        )}
                      </View>
                    }
                    keyExtractor={(item) => JSON.stringify(item.dateNumber)}
                    inverted={true}
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 2,
                    paddingVertical: 15,
                    zIndex: -1,
                    marginHorizontal: 70,
                  }}
                >
                  <Text style={{ width: "25%" }}></Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    Opening Balance
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {this.state.openingBalanceAll > 0
                      ? this.state.openingBalanceAll.toFixed(2)
                      : ""}
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {this.state.openingBalanceAll < 0
                      ? this.state.openingBalanceAll.toFixed(2)
                      : ""}
                  </Text>
                  <Text style={{ width: "25%" }}></Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 2,
                    paddingVertical: 15,
                    zIndex: -1,
                    marginHorizontal: 70,
                  }}
                >
                  <Text style={{ width: "25%" }}></Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    Current Total
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {parseFloat(this.state.debitTotalSearch).toFixed(2)}
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {parseFloat(this.state.creditTotalSearch).toFixed(2)}
                  </Text>
                  <Text style={{ width: "25%" }}></Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 2,
                    paddingVertical: 15,
                    borderTopWidth: 1,
                    borderTopColor: "#000",
                    zIndex: -1,
                    marginHorizontal: 70,
                  }}
                >
                  <Text style={{ width: "25%" }}></Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    Closing Balance
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {(
                      this.state.openingBalanceAll +
                      parseFloat(this.state.debitTotalSearch) -
                      parseFloat(this.state.creditTotalSearch)
                    ).toFixed(2) > 0
                      ? (
                          this.state.openingBalanceAll +
                          parseFloat(this.state.debitTotalSearch) -
                          parseFloat(this.state.creditTotalSearch)
                        ).toFixed(2)
                      : ""}
                  </Text>
                  <Text style={[styles.columnRowTxt, { textAlign: "right" }]}>
                    {this.state.openingBalanceAll +
                      parseFloat(this.state.debitTotalSearch) -
                      parseFloat(this.state.creditTotalSearch) <
                    0
                      ? (
                          this.state.openingBalanceAll +
                          parseFloat(this.state.debitTotalSearch) -
                          parseFloat(this.state.creditTotalSearch)
                        ).toFixed(2)
                      : ""}
                  </Text>
                  <Text style={{ width: "25%" }}></Text>
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
});
