import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { jsPDF } from "jspdf";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
  Switch,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class vendorEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorId: "",
      vendorName: "",
      address: "",
      number: "",
      gst: "",
      addedDate: "",
      addedByname: "",
      email: "",
      //product Rate data//
      productArray: [],
      productRate: [],
      rateHolder: "",
      //
      dataLoading: true,
      buttonLoading: false,
      //Bill Data
      billdata: [],
      columns: ["Sr. no.", "Date", "Bill Number", "Amount", "Action"],
      setVisibleBill: false,
      billno: "",
      billDate: "",
      billArray: [],
      billCalculations: {},
      itemSplit: [],
      columnsBill: [
        "Sr no.",
        "Date",
        "Lorry No.",
        "Royalty No.",
        "D.M. No.",
        "Size in mm",
        "Total cft",
        "Pay. Mode",
        "Amount",
      ],
      bankName: "",
      accountNo: "none",
      ifsCode: "",
      branch: "",
      uoid: "",
      note: "",
    };
  }
  componentDidMount() {
    const { vendorId } = this.props.route.params;

    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState({ vendorId }, () => {
              this.getDataFromDb();
              //   this.getProductList();
              //   this.getbills();
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getbills = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/bills/${this.state.customerId}/FY2021_22`
      );

    dbRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        this.setState({ billdata: data });
      }
    });
  };
  getDataFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();

        this.setState(
          {
            vendorName: data.vendorName,
            address: data.address,
            number: data.number,
            email: data.email,
            gst: data.gst,
            addedDate: data.addedDate,
            addedByname: data.addedByname,
            // productRate: data.productRate ? data.productRate : [],
            bankName: data.bankName,
            accountNo: data.accountNo,
            ifsCode: data.ifsCode,
            branch: data.branch,
            note: data.note,
          },
          () => {
            this.setState({ dataLoading: false });
          }
        );
      }
    });
  };
  updateDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/vendorBasics/${this.state.vendorId}`
      );

    dbRef
      .update({
        vendorName: this.state.vendorName,
        address: this.state.address,
        gst: this.state.gst,
        number: this.state.number,
        email: this.state.email,
        // productRate: this.state.productRate,
        note: this.state.note,
        bankName: this.state.bankName,
        accountNo: this.state.accountNo,
        ifsCode: this.state.ifsCode,
        branch: this.state.branch,
      })
      .then(() => {
        this.setState({ buttonLoading: false });
      });
  };
  getProductList = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);
    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this.setState({ productArray: data });
      }
    });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableHeaderBill = () => (
    <View style={styles.tableHeader}>
      {this.state.columnsBill.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnHeader, { width: "11.11%" }]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={[styles.columnHeaderTxt]}>
                {column}{" "}
                {/* {column == "Name" ? (
                this.state.postSorterAce ? (
                  <AntDesign name="downcircle" size={14} color="black" />
                ) : (
                  <AntDesign name="upcircle" size={14} color="black" />
                )
              ) : (
                ""
              )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  tableFooterForBill = () => {
    // let itemSplit = []; //eg:[[],[],....]

    // for (let index = 0; index < this.state.uniqueItem.length; index++) {
    //   itemSplit.push([]);
    //   for (let index2 = 0; index2 < this.state.billData.length; index2++) {
    //     if (
    //       this.state.uniqueItem[index].item == this.state.billData[index2].item
    //     ) {
    //       itemSplit[index].push(this.state.billData[index2]);
    //     }
    //   }
    // }

    const reducer = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.sellQtyCft);

    let footerRow = [
      "Total",
      `Total DM : ${this.state.billArray.length}`,
      <View>
        {this.state.itemSplit.map((item1) => {
          return (
            <View
              style={{
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>{item1[0].item}:</Text>
                <Text>{item1.reduce(reducer, 0)}</Text>
              </View>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopWidth: 1,
            borderTopColor: "#c1c1c1",
          }}
        >
          <Text>Total:</Text>
          <Text>{this.state.billArray.reduce(reducer, 0)}</Text>
        </View>
      </View>,
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: 145,
        }}
      >
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total</Text>
          <Text>{this.state.billCalculations.billTotal}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% SGST</Text>

          <Text>{this.state.billCalculations.sgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>2.5% CGST</Text>

          <Text>{this.state.billCalculations.cgst}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Total GST</Text>

          <Text>
            {this.state.billCalculations.cgst +
              this.state.billCalculations.sgst}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",

            justifyContent: "space-between",
          }}
        >
          <Text>Grand Total</Text>

          <Text>{this.state.billCalculations.billGrandTotal}</Text>
        </View>
      </View>,
    ];

    return (
      <View style={[styles.tableHeader, { height: 120 }]}>
        {footerRow.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[styles.columnFooter, { paddingTop: 10 }]}
              //   onPress={() => {
              //     column == "Name"
              //       ? this.setState(
              //           { postSorterAce: !this.state.postSorterAce },
              //           () => {
              //             this.tableRowSorter();
              //           }
              //         )
              //       : null;
              //   }}
            >
              <Text style={[styles.columnHeaderTxt]}>
                {item}{" "}
                {/* {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title={this.state.vendorName} />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" vendor="underline" />
            <View style={styles.palyArea}>
              {/* ///////////////////////////////////////////////// */}
              <Portal>
                <Modal
                  visible={this.state.setVisibleBill}
                  onDismiss={() => {
                    this.setState({ setVisibleBill: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    borderWidth: 1,
                    alignSelf: "center",
                    width: 850,
                    borderRadius: 5,
                    borderColor: "#c1c1c1",
                  }}
                >
                  <View style={styles.modelHeader}>
                    <Text style={styles.modelHeaderText}>
                      Bill Number {this.state.billno}
                    </Text>
                  </View>
                  <View>
                    <View
                      style={{
                        padding: 8,
                        borderBottomWidth: 1,
                        borderBottomColor: "#c1c1c1",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            padding: 10,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          Customer Name : {this.state.customerName}
                        </Text>
                        <Text
                          style={{
                            padding: 10,
                            fontSize: 16,
                          }}
                        >
                          Bill no:{this.state.billno}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          Customer Address: {this.state.address}
                        </Text>
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          Total DM in Bill:
                          {this.state.billArray.length}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={{ padding: 10, fontSize: 16 }}>
                          GSTIN: {this.state.gst}
                        </Text>

                        <Text style={{ alignSelf: "center", fontSize: 16 }}>
                          Bill Date {this.state.billDate}
                        </Text>
                      </View>

                      <FlatList
                        data={this.state.billArray}
                        renderItem={({ item, index }) => {
                          return (
                            <View style={{ zIndex: -5 }}>
                              <View
                                style={[
                                  styles.tableRow,
                                  {
                                    backgroundColor:
                                      index % 2 == 1 ? "#F0FBFC" : "white",
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {index + 1}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.date}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold", width: "11.11%" },
                                  ]}
                                >
                                  {item.lorryNo}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.royaltyNo}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.dmNo}
                                </Text>

                                {/* {this.state.productRate.map((initem, index) => {
                                if (Object.keys(initem) == item.item) {
                                  return (
                                    <Text style={styles.columnRowTxt}>
                                      {initem[`${item.item}`]}
                                    </Text>
                                  );
                                }
                              })} */}

                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { fontWeight: "bold", width: "11.11%" },
                                  ]}
                                >
                                  {item.item}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.sellQtyCft}
                                </Text>
                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.paymentMode}
                                </Text>

                                <Text
                                  style={[
                                    styles.columnRowTxt,
                                    { width: "11.11%" },
                                  ]}
                                >
                                  {item.totalAmount}
                                </Text>
                              </View>
                            </View>
                          );
                        }}
                        ListHeaderComponent={this.tableHeaderBill}
                        ListFooterComponent={this.tableFooterForBill}
                        stickyHeaderIndices={[0]}
                        ListEmptyComponent={
                          <View style={{ padding: 50 }}>
                            <ActivityIndicator size="large" color="#0a60ff" />
                          </View>
                        }
                        keyExtractor={(item) => JSON.stringify(item.id)}
                      />
                    </View>
                  </View>
                </Modal>
              </Portal>

              {/* ///////////////////////////////////////////////// */}
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text>Basic Info</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TextInput
                    mode="outlined"
                    label="Vendor Name"
                    value={this.state.vendorName}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ vendorName: text });
                    }}
                    placeholder="Customer Name"
                  />
                  <TextInput
                    mode="outlined"
                    label="Address"
                    value={this.state.address}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ address: text });
                    }}
                    placeholder="Address"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="GSTIN"
                    value={this.state.gst}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ gst: text });
                    }}
                    placeholder="GSTIN"
                  />{" "}
                  <TextInput
                    mode="outlined"
                    label="Contact Number"
                    value={this.state.number}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ number: text }, () => {});
                    }}
                    placeholder="Contact Number"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="Email"
                    value={this.state.email}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ email: text });
                    }}
                    placeholder="Email"
                  />{" "}
                  <TextInput
                    mode="outlined"
                    label="Note"
                    value={this.state.note}
                    style={styles.inputField}
                    onChangeText={(text) => {
                      this.setState({ note: text });
                    }}
                    placeholder="Note"
                    multiline
                  />{" "}
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="Since"
                    disabled
                    value={this.state.addedDate}
                    style={styles.inputField}
                    placeholder="Since"
                  />
                  <TextInput
                    mode="outlined"
                    disabled
                    label="Created By"
                    value={this.state.addedByname}
                    style={styles.inputField}
                    placeholder="Created By"
                  />
                </View>
                {/*////////////////////  */}
                <Text style={{ textDecorationLine: "underline" }}>
                  Other details
                </Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TextInput
                    mode="outlined"
                    label="Name of Bank"
                    value={this.state.bankName}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ bankName: text }, () => {});
                    }}
                    placeholder="Bank Name"
                  />
                  <TextInput
                    mode="outlined"
                    label="Account No."
                    value={this.state.accountNo}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ accountNo: text }, () => {});
                    }}
                    placeholder="Account No."
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    mode="outlined"
                    label="IFS Code"
                    value={this.state.ifsCode}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ ifsCode: text }, () => {});
                    }}
                    placeholder="IFS Code"
                  />{" "}
                  <TextInput
                    mode="outlined"
                    label="Branch"
                    value={this.state.branch}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ branch: text }, () => {});
                    }}
                    placeholder="Branch"
                  />
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.props.navigation.navigate("ledgerVendorScreen", {
                          vendorId: this.state.vendorId,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff" }}>View Ledger</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.setState({ buttonLoading: true }, () => {
                          this.updateDb();
                        });
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator
                          size="small"
                          animating={true}
                          color="#fff"
                        />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save Changes</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.navigate("vendorScreen");
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>

                    <Snackbar
                      visible={this.state.SnackbarVisible}
                      onDismiss={() => {
                        this.setState({ SnackbarVisible: false });
                      }}
                      action={{
                        label: "OK",
                        onPress: () => {
                          this.setState({ SnackbarVisible: false });
                        },
                      }}
                    >
                      Changes Saved Successfully !
                    </Snackbar>
                  </View>
                  <Text
                    style={{
                      padding: 20,

                      color: "red",
                    }}
                  >
                    {JSON.stringify(this.state.error)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    // maxHeight: Dimensions.get("window").height - 55,
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "20%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
});
