import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  Picker,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import firebase from "firebase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

const stateList = [
  { stateName: "Andaman and Nicobar Islands", code: 25 },
  { stateName: "Andhra Pradesh", code: 28 },
  { stateName: "Arunachal Pradesh ", code: 12 },
  { stateName: "Assam", code: 28 },
  { stateName: "Bihar", code: 10 },
  { stateName: "Chandigarh", code: 4 },
  { stateName: "Chattisgarh", code: 22 },
  { stateName: "Dadra and Nagar Haveli ", code: 26 },
  { stateName: "Daman and Diu", code: 25 },
  { stateName: "Delhi", code: 7 },
  { stateName: "Goa", code: 30 },
  { stateName: "Gujarat", code: 24 },
  { stateName: "Haryana", code: 6 },
  { stateName: "Himachal Pradesh", code: 2 },
  { stateName: "Jammu and Kashmir", code: 1 },
  { stateName: "Jharkhand", code: 20 },
  { stateName: "Karnataka", code: 29 },
  { stateName: "Kerala", code: 32 },
  { stateName: "Lakshadweep Islands", code: 31 },
  { stateName: "Madhya Pradesh", code: 23 },
  { stateName: "Maharashtra", code: 27 },
  { stateName: "Manipur", code: 14 },
  { stateName: "Meghalaya", code: 17 },
  { stateName: "Mizoram", code: 15 },
  { stateName: "Nagaland", code: 13 },
  { stateName: "Odisha", code: 21 },
  { stateName: "Pondicherry", code: 34 },
  { stateName: "Punjab", code: 3 },
  { stateName: "Rajasthan", code: 8 },
  { stateName: "Sikkim", code: 11 },
  { stateName: "Tamil Nadu", code: 33 },
  { stateName: "Telangana", code: 36 },
  { stateName: "Tripura", code: 16 },
  { stateName: "Uttar Pradesh", code: 9 },
  { stateName: "Uttarakhand", code: 5 },
  { stateName: "West Bengal", code: 19 },
];

export default class cashBookScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: "",
      gstNo: "",
      atPost: "",
      addressOne: "",
      addressTwo: "",
      contactNo: "",
      email: "",
      buttonLoading: false,
      SnackbarVisible: false,
      error: "",
      employeCount: "",
      customerCount: "",
      vendorCount: "",
      uoid: "",
      setVisible: false,
      newDocTitle: "",
      newDocExpiry: new Date(),
      newDocContact: "",
      viewCalender: false,
      docSaveButtonLoading: false,
      documentArray: [],
      isLoading: false,
      //
      savedDocTitle: "",
      savedDocDate: new Date(),
      savedDocNote: "",
      docId: "",
      bank: "",
      account: "",
      ifsc: "",
      branch: "",
      admin: true,
      supplyState: "",
      stateCode: null,
    };
  }
  componentDidMount() {
    const { admin } = this.props.route.params;
    this.setState({ admin });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.getData();
            this.getDocData();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getDocData = () => {
    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/documents`);

    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this.setState({
          documentArray: data.sort(function (a, b) {
            if (a.expiryDate > b.expiryDate) {
              return 1;
            }
            if (a.expiryDate < b.expiryDate) {
              return -1;
            }
            return 0;
          }),
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };
  addDocToDB = () => {
    this.setState({ docSaveButtonLoading: true });
    let id = Date.parse(new Date());
    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/documents/${id}`);

    dbref
      .set({
        id: id,
        documentTitle: this.state.newDocTitle,
        expiryDate: Date.parse(this.state.newDocExpiry),
        note: this.state.newDocContact,
      })
      .then(() => {
        this.setState({ docSaveButtonLoading: false });
      });

    let dbrefAllDoc = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/fleetData/allDocuments/${id}`);

    dbrefAllDoc.set({
      id: id,
      documentTitle: this.state.newDocTitle,
      expiryDate: Date.parse(this.state.newDocExpiry),
      note: this.state.newDocContact,
    });
  };
  saveDocUpdate = () => {
    this.setState({ docSaveButtonLoading: true });
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/documents/${this.state.docId}`
      );

    dbref
      .update({
        note: this.state.savedDocNote,
        documentTitle: this.state.savedDocTitle,
        expiryDate: Date.parse(this.state.savedDocDate),
      })
      .then(() => {
        this.setState({ docSaveButtonLoading: false });
      });

    let dbrefAllDoc = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/allDocuments/${this.state.docId}`
      );
    dbrefAllDoc.update({
      note: this.state.savedDocNote,
      documentTitle: this.state.savedDocTitle,
      expiryDate: Date.parse(this.state.savedDocDate),
    });
  };
  getData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = dataSnapShot.val();
        this.setState({
          organizationName: data.organizationName,
          gstNo: data.gstNo ? data.gstNo : "",
          addressOne: data.addressOne ? data.addressOne : "",
          addressTwo: data.addressTwo ? data.addressTwo : "",
          contactNo: data.contactNo ? data.contactNo : "",
          email: data.email ? data.email : "",
          atPost: data.atPost ? data.atPost : "",
          bank: data.bank ? data.bank : "",
          account: data.account ? data.account : "",
          ifsc: data.ifsc ? data.ifsc : "",
          branch: data.branch ? data.branch : "",
          supplyState: data.supplyState ? data.supplyState : "",
          stateCode: data.stateCode ? data.stateCode : null,
        });
      }
    });
  };

  updateDB = async () => {
    if (this.state.organizationName != "") {
      this.setState({ buttonLoading: true });

      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/organizationData/basics`);

      await dbRef.update(
        {
          organizationName: this.state.organizationName,
          gstNo: this.state.gstNo,
          addressOne: this.state.addressOne,
          contactNo: this.state.contactNo,
          addressTwo: this.state.addressTwo,

          email: this.state.email,

          bank: this.state.bank,
          account: this.state.account,
          ifsc: this.state.ifsc,
          branch: this.state.branch,
          supplyState: this.state.supplyState,
          stateCode: this.state.stateCode,
        },
        (error) => {
          if (!error) {
            this.setState(
              {
                SnackbarVisible: true,
                buttonLoading: false,
                error: "Data Saved Successfully",
              },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          } else {
            this.setState(
              {
                SnackbarVisible: true,
                buttonLoading: false,
                error: "Error, Try After Sometime",
              },
              () => {
                setTimeout(() => {
                  this.setState({ SnackbarVisible: false });
                }, 5000);
              }
            );
          }
        }
      );
    } else {
      this.setState({ error: "Fields are Required" });
    }
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Company Details" />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            <View style={styles.palyArea}>
              {/* Modal starts */}
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    width: 900,
                    alignSelf: "center",
                  }}
                >
                  <View style={{ flexDirection: "row", zIndex: -1 }}>
                    <TextInput
                      mode="outlined"
                      label="Document Title"
                      value={this.state.savedDocTitle}
                      style={[styles.inputField, { width: 250 }]}
                      onChangeText={(text) => {
                        this.setState({ savedDocTitle: text });
                      }}
                      placeholder="Document Title"
                    />

                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor:
                            this.state.savedDocDate -
                              Date.parse(new Date().toDateString()) <
                              864000000 &&
                            this.state.savedDocDate -
                              Date.parse(new Date().toDateString()) >
                              0 //10 days
                              ? "#ffff99"
                              : Date.parse(new Date().toDateString()) >
                                this.state.savedDocDate
                              ? "#ffcccc"
                              : "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 14,
                          alignSelf: "center",
                          padding: 10,
                          width: 200,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          savedviewCalender: !this.state.savedviewCalender,
                        });
                      }}
                    >
                      {this.state.savedDocDate.toDateString()}
                    </Text>
                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: 50,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.savedviewCalender ? (
                        <Calendar
                          value={this.state.newDocExpiry}
                          onChange={(value) => {
                            this.setState({ savedDocDate: value }, () => {
                              this.setState({ savedviewCalender: false });
                            });
                          }}
                        />
                      ) : null}
                    </View>
                    <TextInput
                      mode="outlined"
                      label="Note"
                      value={this.state.savedDocNote}
                      style={[styles.inputField, { width: 250 }]}
                      onChangeText={(text) => {
                        this.setState({ savedDocNote: text });
                      }}
                      placeholder="Note"
                      multiline
                    />
                    <TouchableOpacity
                      style={[styles.actionbtn, { borderColor: "#075e54" }]}
                      onPress={() => {
                        this.saveDocUpdate();
                        this.setState({ setVisible: false });
                      }}
                    >
                      <Text style={{ color: "#075e54" }}>Update Details</Text>
                    </TouchableOpacity>
                  </View>
                </Modal>
              </Portal>
              {/* Model */}

              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Text style={{ fontSize: 16, textDecorationLine: "underline" }}>
                  Organization Profile
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Organization Name"
                        value={this.state.organizationName}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ organizationName: text });
                        }}
                        placeholder="Organization Name"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Atpost"
                        value={this.state.atPost}
                        style={[styles.inputField, { width: 180 }]}
                        onChangeText={(text) => {
                          this.setState({ atPost: text });
                        }}
                        placeholder="GSTIN"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="GSTIN"
                        value={this.state.gstNo}
                        style={[styles.inputField, { width: 230 }]}
                        onChangeText={(text) => {
                          this.setState({ gstNo: text.toUpperCase() });
                        }}
                        placeholder="GSTIN"
                      />
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Plant Address"
                        value={this.state.addressOne}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ addressOne: text });
                        }}
                        placeholder="Plant Address"
                        multiline
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Office Address"
                        value={this.state.addressTwo}
                        style={[styles.inputField]}
                        onChangeText={(text) => {
                          this.setState({ addressTwo: text });
                        }}
                        placeholder="Office Address"
                        multiline
                      />
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ padding: 24 }}>
                        <Picker
                          selectedValue={this.state.supplyState}
                          style={{
                            height: 40,
                            width: 170,
                          }}
                          onValueChange={(itemValue, itemIndex) => {
                            this.setState({
                              supplyState: itemValue,
                              stateCode: stateList[itemIndex - 1].code,
                            });
                          }}
                        >
                          <Picker.Item label="Select State" value="" />

                          <Picker.Item
                            label={
                              stateList[0].code + "-" + stateList[0].stateName
                            }
                            value={stateList[0].stateName}
                          />

                          <Picker.Item
                            label={
                              stateList[1].code + "-" + stateList[1].stateName
                            }
                            value={stateList[1].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[2].code + "-" + stateList[2].stateName
                            }
                            value={stateList[2].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[3].code + "-" + stateList[3].stateName
                            }
                            value={stateList[3].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[4].code + "-" + stateList[4].stateName
                            }
                            value={stateList[4].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[5].code + "-" + stateList[5].stateName
                            }
                            value={stateList[5].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[6].code + "-" + stateList[6].stateName
                            }
                            value={stateList[6].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[7].code + "-" + stateList[7].stateName
                            }
                            value={stateList[7].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[8].code + "-" + stateList[8].stateName
                            }
                            value={stateList[8].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[9].code + "-" + stateList[9].stateName
                            }
                            value={stateList[9].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[10].code + "-" + stateList[10].stateName
                            }
                            value={stateList[10].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[11].code + "-" + stateList[11].stateName
                            }
                            value={stateList[11].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[12].code + "-" + stateList[12].stateName
                            }
                            value={stateList[12].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[13].code + "-" + stateList[13].stateName
                            }
                            value={stateList[13].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[14].code + "-" + stateList[14].stateName
                            }
                            value={stateList[14].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[15].code + "-" + stateList[15].stateName
                            }
                            value={stateList[15].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[16].code + "-" + stateList[16].stateName
                            }
                            value={stateList[16].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[17].code + "-" + stateList[17].stateName
                            }
                            value={stateList[17].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[18].code + "-" + stateList[18].stateName
                            }
                            value={stateList[18].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[19].code + "-" + stateList[19].stateName
                            }
                            value={stateList[19].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[20].code + "-" + stateList[20].stateName
                            }
                            value={stateList[20].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[21].code + "-" + stateList[21].stateName
                            }
                            value={stateList[21].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[22].code + "-" + stateList[22].stateName
                            }
                            value={stateList[22].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[23].code + "-" + stateList[23].stateName
                            }
                            value={stateList[23].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[24].code + "-" + stateList[24].stateName
                            }
                            value={stateList[24].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[25].code + "-" + stateList[25].stateName
                            }
                            value={stateList[25].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[26].code + "-" + stateList[26].stateName
                            }
                            value={stateList[26].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[27].code + "-" + stateList[27].stateName
                            }
                            value={stateList[27].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[28].code + "-" + stateList[28].stateName
                            }
                            value={stateList[28].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[29].code + "-" + stateList[29].stateName
                            }
                            value={stateList[29].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[30].code + "-" + stateList[30].stateName
                            }
                            value={stateList[30].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[31].code + "-" + stateList[31].stateName
                            }
                            value={stateList[31].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[32].code + "-" + stateList[32].stateName
                            }
                            value={stateList[32].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[33].code + "-" + stateList[33].stateName
                            }
                            value={stateList[33].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[34].code + "-" + stateList[34].stateName
                            }
                            value={stateList[34].stateName}
                          />
                          <Picker.Item
                            label={
                              stateList[35].code + "-" + stateList[35].stateName
                            }
                            value={stateList[35].stateName}
                          />
                        </Picker>
                      </View>
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Contact No."
                        value={this.state.contactNo}
                        style={[styles.inputField, { width: 180 }]}
                        onChangeText={(text) => {
                          this.setState({ contactNo: text });
                        }}
                        placeholder="Mobile no."
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Email"
                        value={this.state.email}
                        style={styles.inputField}
                        onChangeText={(text) => {
                          this.setState({ email: text });
                        }}
                        placeholder="xyzopp@company.com"
                      />
                    </View>
                  </View>
                  <View>
                    <TextInput
                      disabled={!this.state.admin}
                      mode="outlined"
                      label="Bank Name"
                      value={this.state.bank}
                      style={styles.inputField}
                      onChangeText={(text) => {
                        this.setState({ bank: text });
                      }}
                      placeholder="Bank Name"
                    />
                    <TextInput
                      disabled={!this.state.admin}
                      mode="outlined"
                      label="Account No."
                      value={this.state.account}
                      style={styles.inputField}
                      onChangeText={(text) => {
                        this.setState({ account: text });
                      }}
                      placeholder="Account No."
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="IFS Code"
                        value={this.state.ifsc}
                        style={[styles.inputField, { width: 190 }]}
                        onChangeText={(text) => {
                          this.setState({ ifsc: text.toUpperCase() });
                        }}
                        placeholder="IFS Code"
                      />
                      <TextInput
                        disabled={!this.state.admin}
                        mode="outlined"
                        label="Branch"
                        value={this.state.branch}
                        style={[styles.inputField, { width: 190 }]}
                        onChangeText={(text) => {
                          this.setState({ branch: text });
                        }}
                        placeholder="Branch"
                      />
                    </View>
                  </View>
                </View>

                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        if (this.state.admin) {
                          this.updateDB();
                        } else {
                          this.setState(
                            {
                              SnackbarVisible: true,
                              error: "Not Allowed, Only Admin",
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({ SnackbarVisible: false });
                              }, 5000);
                            }
                          );
                        }
                      }}
                    >
                      {this.state.buttonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Save</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() =>
                        this.props.navigation.navigate("DashBoard")
                      }
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{ paddingLeft: 20 }}>
                <Text
                  style={{
                    padding: 12,
                    fontSize: 22,
                    fontWeight: "500",
                    justifyContent: "center",
                  }}
                >
                  Documents Details
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    zIndex: 5,
                  }}
                >
                  <View>
                    <Text>New Document Title</Text>
                    <TextInput
                      mode="outlined"
                      label="Document Title"
                      value={this.state.newDocTitle}
                      style={[styles.inputField, { width: 250, paddingTop: 0 }]}
                      onChangeText={(text) => {
                        this.setState({ newDocTitle: text });
                      }}
                      placeholder="Document Title"
                    />
                  </View>

                  <View style={{ flexDirection: "row", zIndex: 5 }}>
                    <View>
                      <Text>Document Expiry Date</Text>
                      <Text
                        style={[
                          styles.inputField,
                          {
                            backgroundColor: "#EAEAEA",
                            borderWidth: 1,
                            borderColor: "#c1c1c1",
                            borderRadius: 5,
                            margin: 14,
                            marginTop: 2,
                            alignSelf: "center",
                            padding: 10,

                            width: 200,
                            textAlignVertical: "center",
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            viewCalender: !this.state.viewCalender,
                          });
                        }}
                      >
                        {this.state.newDocExpiry.toDateString()}
                      </Text>

                      <View
                        style={{
                          paddingLeft: 20,
                          marginTop: 50,
                          zIndex: 5,
                          position: "absolute",
                        }}
                      >
                        {this.state.viewCalender ? (
                          <Calendar
                            value={this.state.newDocExpiry}
                            onChange={(value) => {
                              this.setState({ newDocExpiry: value }, () => {
                                this.setState({ viewCalender: false });
                              });
                            }}
                          />
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View>
                    <Text>Contact person Number</Text>
                    <TextInput
                      mode="outlined"
                      label="On Expiry Contact Number"
                      value={this.state.newDocContact}
                      style={[styles.inputField, { width: 250, paddingTop: 0 }]}
                      onChangeText={(text) => {
                        this.setState({ newDocContact: text });
                      }}
                      placeholder="On Expiry Contact Number"
                    />
                  </View>
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      {
                        height: 40,
                        marginTop: 0,
                        marginHorizontal: 20,
                        width: 150,
                        alignItems: "center",
                      },
                    ]}
                    onPress={() => {
                      if (this.state.newDocTitle != "") {
                        this.addDocToDB();
                      }
                    }}
                  >
                    {this.state.docSaveButtonLoading ? (
                      <ActivityIndicator size="small" color="#fff" />
                    ) : (
                      <Text style={{ color: "#fff" }}>Add New Document</Text>
                    )}
                  </TouchableOpacity>
                </View>
                <FlatList
                  data={this.state.documentArray}
                  renderItem={({ item }) => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          zIndex: -1,
                        }}
                      >
                        <TextInput
                          mode="outlined"
                          label="Document Title"
                          value={item.documentTitle}
                          style={[styles.inputField, { width: 250 }]}
                          editable={false}
                          placeholder="Document Title"
                        />
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor:
                                item.expiryDate -
                                  Date.parse(new Date().toDateString()) <
                                  864000000 &&
                                item.expiryDate -
                                  Date.parse(new Date().toDateString()) >
                                  0 //10 days
                                  ? "#ffff99"
                                  : Date.parse(new Date().toDateString()) >
                                    item.expiryDate
                                  ? "#ffcccc"
                                  : "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 14,
                              alignSelf: "center",
                              padding: 10,
                              width: 200,
                              textAlignVertical: "center",
                            },
                          ]}
                        >
                          {new Date(item.expiryDate).toDateString()}
                        </Text>
                        <TextInput
                          mode="outlined"
                          label="On Expiry Contact Number"
                          value={item.note}
                          style={[styles.inputField, { width: 250 }]}
                          editable={false}
                          placeholder="On Expiry Contact Number"
                        />
                        <TouchableOpacity
                          style={[styles.actionbtn, { borderColor: "#075e54" }]}
                          onPress={() => {
                            this.setState({ setVisible: true });
                            this.setState({
                              savedDocTitle: item.documentTitle,
                              savedDocDate: new Date(item.expiryDate),
                              savedDocNote: item.note,
                              docId: item.id,
                            });
                          }}
                        >
                          <Text style={{ color: "#075e54" }}>Edit</Text>
                        </TouchableOpacity>
                      </View>
                    );
                  }}
                  keyExtractor={(item) => JSON.stringify(item.id)}
                  ListHeaderComponent={() => (
                    <Text style={{ fontSize: 22, fontWeight: "500" }}>
                      Saved Documents
                    </Text>
                  )}
                  ListEmptyComponent={
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 50,
                      }}
                    >
                      {this.state.isLoading ? (
                        <ActivityIndicator size="small" color="#0a60ff" />
                      ) : (
                        <Text>No Documents are Added. Add one now!</Text>
                      )}
                    </View>
                  }
                />
              </View>

              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
    alignSelf: "center",
  },
});
