import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import { Modal, Provider, Portal, Searchbar } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";

class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: ["Sr. no.", "Product Name", "Avaliable", "Action"],
      searchQuery: "",
      postSorterAce: true,
      uoid: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.callDataFromDB();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/productVital`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.addedAt > b.addedAt) {
                return 1;
              }
              if (a.addedAt < b.addedAt) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.setState({ searchData: this.state.mainData });
          }
        );
      } else {
        this.setState({ isLoading: false });
      }
    });
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.productName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({ searchData: newdata });
    } else {
      this.setState({ searchData: this.state.mainData });
    }
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                // column == "Post/Designation"
                //   ? this.setState(
                //       { postSorterAce: !this.state.postSorterAce },
                //       () => {
                //         this.tableRowSorter();
                //       }
                //     )
                //   : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {/* {column == "Post/Designation" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )} */}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  //   tableRowSorter = () => {
  //     let direction = this.state.postSorterAce;
  //     this.setState(
  //       {
  //         mainData: this.state.mainData.sort(function (a, b) {
  //           if (a.post > b.post) {
  //             return direction ? 1 : -1;
  //           }
  //           if (a.post < b.post) {
  //             return direction ? -1 : 1;
  //           }
  //           return 0;
  //         }),
  //         isLoading: false,
  //       },
  //       () => {
  //         this.setState({ searchData: this.state.mainData });
  //       }
  //     );
  //   };
  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.container}>
        <View stle={styles.searchbarBody}>
          <Searchbar
            placeholder="Search by Product Name"
            onChangeText={(text) => {
              this.setState({ searchQuery: text }, () => {
                this.searchStringInArray(this.state.searchQuery);
              });
            }}
            value={this.state.searchQuery}
          />
        </View>

        <FlatList
          data={this.state.searchData}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                ]}
              >
                <Text style={styles.columnRowTxt}>{index + 1}</Text>
                <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                  {item.productName}
                </Text>
                <Text style={styles.columnRowTxt}>
                  {item.availableQtyCft} cft (
                  {(
                    (item.availableQtyCft * item.productDensity) /
                    1000
                  ).toFixed(2)}{" "}
                  tonne)
                </Text>

                <View
                  style={{
                    width: "25%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("productViewScreen", {
                        productId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>View</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
          ListHeaderComponent={this.tableHeader}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
              }}
            >
              {this.state.isLoading ? (
                <ActivityIndicator size="large" color="#0a60ff" />
              ) : (
                <Text>No Product Found, Go Forward and add One</Text>
              )}
            </View>
          }
          keyExtractor={(item) => JSON.stringify(item.id)}
        />
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <ProductTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
});
