import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { TextInput, Checkbox, Snackbar } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialIcons } from "@expo/vector-icons";
import Calendar from "react-calendar";
import { jsPDF } from "jspdf";
import "react-calendar/dist/Calendar.css";
import CalenderWField from "../basic_Components/CalenderWField";
class VST extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      date: new Date(),
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: Date.parse(new Date().toDateString()),
      itemSearch: "",
      issuedQty: "",
      viewCalender: false,
      particular: "",
      acceptCheckBox: false,
      //Update on item select
      avaliableQty: "",
      uom: "Unit",
      mainDataEmply: [],
      searchDataEmply: [],
      empySearch: "",
      post: "",
      viewDropDown: false,
      SnackbarVisible: false,
      productId: "",
      currentdayAvaliableQty: 0,
      currentdayUsedQty: 0,
      uoid: "",
      buttonLoading: false,
      receivedUsedPart: null,
      isRepairRequired: null,
      isReusable: false,
      canItBeReused: null,
      employeeId: "",
      receivedUnit: "",
      currentDayReceivedUnit: 0,
      currentDataOfMROIssuedToEmply: { pendingQty: 0 },
      isEmplopyeeSelected: false,
      disableItem: false,
      currentScrapList: {},
      currentRepairList: {},
      error: "",
      borderColor: "#c1c1c1",
      issuedList: [],
      columns: ["Time", "Item Name", "Issued Qty", "Received Unit"],
      MROIssuedList: [],
      MROListLoading: false,
      selectedItemIndex: null,
      verifiedTrans: false,
      companyName: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.getEmployeListFromDate();
              this.getCompanyData();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getEmployeListFromDate = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/dailyMROIssue/${Date.parse(
          new Date(this.state.date).toDateString()
        )}/employeeList`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = Object.values(snapShot.val());
        this._isMounted &&
          this.setState({ searchDataEmply: data, mainDataEmply: data });
      }
      else {
        this._isMounted &&
        this.setState({ searchDataEmply: [], mainDataEmply: [] });
      }
    });
  };
  getMROIssuedList = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/dailyMROIssue/${Date.parse(
          new Date(this.state.date).toDateString()
        )}/mroIssued/${this.state.employeeId}`
      );

    dbRef.on("value", (snapShot) => {
      if (snapShot.exists()) {
        let data = Object.values(snapShot.val());
        this._isMounted && this.setState({ MROIssuedList: data });
      }
    });
  };
  searchEmplyStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainDataEmply.filter((item) => {
        const itemData = item
          ? item.employeeName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchDataEmply: newdata });
    } else {
      this._isMounted &&
        this.setState({ searchDataEmply: this.state.mainDataEmply });
    }
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        return (
          <View>
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );

  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/erpData/organizationData/basics/organizationName`
      );
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this.setState({ companyName: data });
      }
    });
  };
  setVST = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/employeeData/dailyMROIssue/${Date.parse(
          new Date(this.state.date).toDateString()
        )}/employeeList/${this.state.employeeId}`
      );

    dbRef
      .update({
        verified: true,
      })
      .then(() => {
        this.printVSTReport();
        this.props.closeMROModal();
      });
  };
  printVSTReport = () => {
    var doc = new jsPDF("portrait", "mm", "a4");
    doc.rect(10, 10, 190, 273);

    doc.setFont("times", "italic");
    doc.setFontSize(23);
    doc.text(15, 20, `${this.state.companyName}`);
    doc.setFont("times", "bold");
    doc.setFontSize(20);
    doc.text(15, 30, "Store-Material Transaction Report");
    doc.setLineWidth(0.5);
    doc.line(15, 32, 122, 32);
    doc.setFontSize(14);
    doc.setFont("times", "normal");
    doc.text(195, 30, "Report No: 09999", "right");
    doc.text(
      192.5,
      40,
      `Date: ${this.state.date.getDate()}/${
        this.state.date.getMonth() + 1
      }/${this.state.date.getFullYear()}`,
      "right"
    );
    doc.text(15, 50, "From: Store");
    doc.text(55, 50, `To: ${this.state.empySearch}`);
    doc.text(
      15,
      65,
      doc.splitTextToSize(
        `I, ${this.state.empySearch} accepts that I have done below material transaction from store and have used it only for said purpose.`,
        180
      )
    );

    doc.setFontSize(14);
    doc.setDrawColor(193, 193, 193);
    doc.setLineWidth(0.1);
    doc.text(19, 85, doc.splitTextToSize("Time", 15));
    doc.text(39, 85, doc.splitTextToSize("Particular", 25));
    doc.text(122, 85, doc.splitTextToSize("Issued Qty", 25));
    doc.text(154, 85, doc.splitTextToSize("Deposited Qty", 32));
    doc.rect(15, 80, 174, 13);
    doc.rect(35, 80, 0, 13);
    doc.rect(120, 80, 0, 13);
    doc.rect(150, 80, 0, 13);
    let starty = 0;
    let totalpage = 1;
    doc.text(195, 290, "1");
    for (let index = 0; index < this.state.MROIssuedList.length; index++) {
      doc.text(
        16,
        98 + starty * 6.5,
        `${this.state.MROIssuedList[index].time}`
      );
      doc.setFont("times", "bold");
      let particularLimit = `${this.state.MROIssuedList[index].itemName}`
        .split("")
        .slice(0, 40)
        .join("");
      doc.text(39, 98 + starty * 6.5, `${particularLimit}`);
      doc.setFont("times", "normal");
      doc.text(
        122,
        98 + starty * 6.5,
        `${
          this.state.MROIssuedList[index].issuedQty
            ? this.state.MROIssuedList[index].issuedQty
            : "0"
        } ${this.state.MROIssuedList[index].uom}`
      );
      doc.text(
        154,
        98 + starty * 6.5,
        `${
          this.state.MROIssuedList[index].receivedQty
            ? this.state.MROIssuedList[index].receivedQty
            : "0"
        } ${this.state.MROIssuedList[index].uom}`
      );
      // index % 2 == 1
      //   ? doc.setFillColor(217, 217, 217)
      //   : doc.setFillColor(255, 255, 255);
      doc.rect(15, 93 + starty * 6.5, 174, 6.5);
      doc.rect(35, 93 + starty * 6.5, 0, 6.5);
      doc.rect(120, 93 + starty * 6.5, 0, 6.5);
      doc.rect(150, 93 + starty * 6.5, 0, 6.5);

      if (index == this.state.MROIssuedList.length - 1) {
        /*Here last element */
        doc.setFont("times", "bold");
        doc.text(15, 98 + (starty + 2) * 6.5, "Employee Signature");
        doc.text(15, 121 + (starty + 2) * 6.5, `${this.state.empySearch}`);
        doc.text(
          105,
          98 + (starty + 2) * 6.5,
          "Store In-Charge Signature",
          "center"
        );
        doc.text(
          195,
          98 + (starty + 2) * 6.5,
          "Supervisior Signature",
          "right"
        );
      }
      if (index % 25 == 0 && this.state.MROIssuedList.length - 1 != index) {
        /*Here last element */
        if (index == 0) {
          starty = starty + 1;
          // totalpage = totalpage + 1;
        } else {
          doc.addPage();
          doc.text(195, 290, `${doc.internal.getNumberOfPages()}`);
          doc.setDrawColor(0, 0, 0);
          doc.rect(10, 10, 190, 273);
          starty = 0;
          doc.setFont("times", "italic");
          doc.setFontSize(23);
          doc.text(15, 20, `${this.state.companyName}`);
          doc.setFont("times", "bold");
          doc.setFontSize(20);
          doc.text(15, 30, "Store-Material Transaction Report");
          doc.setLineWidth(0.5);
          doc.line(15, 32, 122, 32);
          doc.setFontSize(14);
          doc.setFont("times", "normal");
          doc.text(195, 30, "Report No: 09999", "right");
          doc.text(
            192.5,
            40,
            `Date: ${this.state.date.getDate()}/
          ${this.state.date.getMonth() + 1}/
          ${this.state.date.getFullYear()}`,
            "right"
          );
          doc.text(15, 50, "From: Store");
          doc.text(55, 50, `To: ${this.state.empySearch}`);
          // doc.text(
          //   15,
          //   65,
          //   doc.splitTextToSize(
          //     "I, Employee Name accepts that I have done below material transaction from store and have used it only for said purpose.",
          //     180
          //   )
          // );

          doc.setFontSize(14);
          doc.setDrawColor(193, 193, 193);
          doc.setLineWidth(0.1);
          doc.text(19, 85, doc.splitTextToSize("Time", 15));
          doc.text(39, 85, doc.splitTextToSize("Particular", 25));
          doc.text(122, 85, doc.splitTextToSize("Issued Qty", 25));
          doc.text(154, 85, doc.splitTextToSize("Deposited Qty", 32));
          doc.rect(15, 80, 174, 13);
          doc.rect(35, 80, 0, 13);
          doc.rect(120, 80, 0, 13);
          doc.rect(150, 80, 0, 13);
          totalpage = totalpage + 1;
        }
      } else {
        starty = starty + 1;
      }
    }
    // doc.text(16, 104.5, "12:10:20");//+6.5
    // doc.text(39, 104.5,"Pully 7.5 inch solid 3 groove");
    // doc.text(122, 104.5, "99");
    // doc.text(154, 104.5,"99");
    // doc.rect(15, 99.5, 174, 6.5);//+6.5
    // doc.rect(35, 99.5, 0, 6.5);
    // doc.rect(120, 99.5, 0,6.5);
    // doc.rect(150, 99.5, 0, 6.5);

    doc.save("VST_Sample.pdf");
  };
  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <Text
            style={{ fontSize: 26, fontWeight: "bold", alignSelf: "center" }}
          >
            Verify Store Transcation (VST)
          </Text>
        </View>
        <View style={styles.playAreaHolder}>
          <View>
            <TouchableWithoutFeedback
              onPress={() => {
                this._isMounted &&
                  this.setState({
                    viewDropDown: false,
                    viewDropDownEmployee: false,
                  });
              }}
            >
              <View style={styles.mainCard}>
                <View style={{ flexDirection: "row", zIndex: 6 }}>
                  <View style={{ padding: 20 }}>
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor: "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 10,
                          alignSelf: "center",
                          padding: 10,
                          width: 120,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          viewCalender: !this.state.viewCalender,
                        });
                      }}
                    >
                      {this.state.date.getDate()}/
                      {this.state.date.getMonth() + 1}/
                      {this.state.date.getFullYear()}
                    </Text>
                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: 60,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.viewCalender ? (
                        <Calendar
                          value={this.state.date}
                          onChange={(value) => {
                            this.setState({ date: value }, () => {
                              this.getEmployeListFromDate();
                              this.setState({
                                viewCalender: false,
                                verifiedTrans: false,
                                MROIssuedList: [],
                                empySearch: "",
                              });
                            });
                          }}
                        />
                      ) : null}
                    </View>
                  </View>
                  <TextInput
                    mode="outlined"
                    label="Submitted by"
                    value={this.state.empySearch}
                    style={[styles.inputField, { width: 300 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ empySearch: text }, () => {
                          this.searchEmplyStringInArray(this.state.empySearch);
                          this._isMounted &&
                            this.setState({ viewDropDownEmployee: true });
                        });
                    }}
                    placeholder="Submitted by"
                  />
                  {this.state.viewDropDownEmployee ? (
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 5,
                        position: "absolute",
                        marginTop: 70,
                        shadowOffset: {
                          width: 0,
                          height: 0,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 5,
                        zIndex: 5,
                        marginLeft: 200,
                        maxHeight: 180,
                      }}
                    >
                      <FlatList
                        data={this.state.searchDataEmply}
                        renderItem={({ item }) => {
                          return (
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#fff",
                                padding: 10,
                                borderWidth: 1,
                                borderColor: "#c1c1c1",
                                borderRadius: 5,
                                width: 320,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    empySearch: item.employeeName,
                                    employeeId: item.employeeId,
                                    verifiedTrans: item.verified,
                                  },
                                  () => {
                                    this.getMROIssuedList();
                                    this._isMounted &&
                                      this.setState({
                                        viewDropDownEmployee: false,
                                        isEmplopyeeSelected: true,
                                      });
                                  }
                                );
                              }}
                            >
                              <Text style={{ color: "#000" }}>
                                {item.employeeName}
                              </Text>
                              {item.verified ? (
                                <MaterialIcons
                                  name="verified"
                                  size={24}
                                  color="#009C44"
                                />
                              ) : (
                                <MaterialIcons
                                  name="verified"
                                  size={24}
                                  color="#c1c1c1"
                                />
                              )}
                            </TouchableOpacity>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.employeeId)}
                        ListEmptyComponent={
                          <View
                            style={{ padding: 50, backgroundColor: "#fff" }}
                          >
                            <Text style={{ textAlign: "center" }}>
                              No Item has be Issued to any employee Today
                            </Text>
                          </View>
                        }
                      />
                    </View>
                  ) : null}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    zIndex: 5,
                    borderWidth: StyleSheet.hairlineWidth,
                    borderColor: "#c1c1c1",
                    width: 550,
                    maxHeight: 200,
                  }}
                >
                  <FlatList
                    data={this.state.MROIssuedList}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor:
                                index == this.state.selectedItemIndex
                                  ? "#C2DBFF"
                                  : "white",
                            },
                          ]}
                        >
                          <Text style={styles.columnRowTxt}>{item.time}</Text>

                          <Text
                            style={[
                              styles.columnRowTxt,
                              { fontWeight: "bold", width: 130 },
                            ]}
                          >
                            {item.itemName}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.issuedQty} {item.uom ? item.uom : "Unit"}
                          </Text>
                          <Text style={styles.columnRowTxt}>
                            {item.receivedQty ? item.receivedQty : "0"}{" "}
                            {item.uom ? item.uom : "Unit"}
                          </Text>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.dateTimeNumber)}
                    ListEmptyComponent={
                      <View style={{ padding: 50 }}>
                        {this.state.MROListLoading ? (
                          <ActivityIndicator size="small" color="#0a60ff" />
                        ) : this.state.employeeId == "" ? (
                          <Text style={{ textAlign: "center" }}>
                            Please Select Employee Name to get Store Transaction
                          </Text>
                        ) : (
                          <Text style={{ textAlign: "center" }}>
                            No Item has be Issued by {this.state.empySearch}
                          </Text>
                        )}
                      </View>
                    }
                    ListHeaderComponent={this.tableHeader}
                  />
                </View>

                <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
                  <Checkbox
                    status={this.state.acceptCheckBox ? "checked" : "unchecked"}
                    onPress={() => {
                      this._isMounted &&
                        this.setState({
                          acceptCheckBox: !this.state.acceptCheckBox,
                        });
                    }}
                    color="#0a60ff"
                  />
                  <Text style={{ padding: 8, width: 500, fontSize: 12 }}>
                    By printing this store-transaction report, I agree that this
                    entries are true and will be verified by the respective
                    employee by signing the report
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    disabled={this.state.verifiedTrans}
                    style={[
                      styles.addNewButton,
                      {
                        backgroundColor: this.state.verifiedTrans
                          ? "#c1c1c1"
                          : "#0a60ff",
                      },
                    ]}
                    onPress={() => {
                      // this.printVSTReport();
                      if (
                        this.state.acceptCheckBox &&
                        this.state.employeeId != "" &&
                        this.state.verifiedTrans == false
                      ) {
                        // this.printVSTReport();
                        this.setVST(); //followed by VSTprint
                      } else {
                        console.log("Can't Access");
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator size="small" color="#fff" />
                    ) : (
                      <Text style={{ color: "#fff" }}>Print</Text>
                    )}
                  </TouchableOpacity>
                  {this.state.verifiedTrans ? (
                    <View
                      style={{
                        padding: 10,
                        marginTop: 28,
                        flexDirection: "row",
                      }}
                    >
                      <MaterialIcons
                        name="verified"
                        size={24}
                        color="#009C44"
                      />
                      <Text>Already Verified</Text>
                    </View>
                  ) : (
                    <MaterialIcons
                      name="verified"
                      size={24}
                      color="#c1c1c1"
                      style={{
                        padding: 10,

                        marginTop: 28,
                      }}
                    />
                  )}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    );
  }
}
export default function (props) {
  const navigation = useNavigation();

  return <VST {...props} navigation={navigation} />;
}
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,

    backgroundColor: "#fff",
  },
  headerContainer: {},
  playAreaHolder: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#c1c1c1",
    borderRadius: 5,
  },
  leftSideHolder: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
  },
  rightSideHolder: {
    flex: 1,
  },
  subText: {
    fontWeight: "normal",
    fontSize: 18,
    alignSelf: "center",
  },
  mainCard: {
    maxWidth: 740,

    padding: 20,
  },
  inputField: {
    padding: 20,
    height: 40,
  },

  addNewButton: {
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
    marginTop: 28,
    marginHorizontal: 20,
    width: 150,
    alignItems: "center",
  },
  checkBoxHolder: {
    flexDirection: "row",
    alignSelf: "flex-start",
    width: "80%",
    justifyContent: "space-between",
  },
  columnHeader: {
    width: "120%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
});
