import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  Modal,
  Portal,
  Provider,
  RadioButton,
  TextInput,
  Snackbar,
  Checkbox,
} from "react-native-paper";
import firebase from "firebase";
import { Ionicons } from "@expo/vector-icons";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import SalesReportTable from "../src/components/SalesReportTable";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class salesReportScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      customerName: "",
      address: "",
      gst: "",
      loadingBasic: true,
      SnackbarVisible: false,
      error: "",
      uoid: "",
      fiscalyear: "",
    };
  }
  componentDidMount() {
    const { customerId } = this.props.route.params;
    this.setState({ customerId });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid, customerId }, () => {
            this.getBasicsFromDb();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }

  getBasicsFromDb = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/customerData/customerBasics/${this.state.customerId}`
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = DataSnapShot.val();
        this.setState(
          {
            customerName: data.customerName,
            address: data.address,
            gst: data.gst,
          },
          () => {
            this.setState({ loadingBasic: false });
            // this.filterForLedgerEntry();
          }
        );
      }
    });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column} </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  snackbarVisibleFun = (x) => {
    this.setState({ SnackbarVisible: true, error: x }, () => {
      setTimeout(() => {
        this.setState({ SnackbarVisible: false });
      }, 2500);
    });
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Sales Report" />
          <View style={styles.mainConsole}>
            <LeftBar user="#c1c1c1" customer="underline" />
            {/* Modal Section Start------------------------ */}

            {/* Modal Section Ends------------------------- */}
            {/* {Modal Section 2 for bill start} */}

            {/* Ends modl */}
            <View style={styles.palyArea}>
              <View
                style={{
                  flexDirection: "row",
                  marginHorizontal: 55,
                  marginTop: 10,
                }}
              >
                <Ionicons
                  name="arrow-back"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                  size={24}
                  color="black"
                  style={{ marginRight: 30 }}
                />
                <View style={{ flexDirection: "column", flex: 3 }}>
                  <Text style={{ fontSize: 18 }}>
                    {this.state.customerName}, {this.state.address}
                  </Text>
                  <Text style={{ marginTop: 5, fontSize: 12 }}>
                    {this.state.gst}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("ledgerScreen", {
                        customerId: this.state.customerId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.addNewButton}
                    onPress={() => {
                      this.props.navigation.navigate("customerEdit", {
                        customerId: this.state.customerId,
                      });
                    }}
                  >
                    <Text style={{ color: "#fff" }}>Profile</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableBody}>
                  <SalesReportTable
                    customerId={this.state.customerId}
                    customerName={this.state.customerName}
                    address={this.state.address}
                    gst={this.state.gst}
                    snackbarVisibleFun={(x) => this.snackbarVisibleFun(x)}
                  />
                </View>
              </View>
              <Snackbar
                visible={this.state.SnackbarVisible}
                onDismiss={() => {
                  this.setState({ SnackbarVisible: false });
                }}
                action={{
                  label: "OK",
                  onPress: () => {
                    this.setState({ SnackbarVisible: false });
                  },
                }}
              >
                {this.state.error}
              </Snackbar>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    borderWidth: 1,
    borderColor: "#c1c1c1",
    marginTop: 50,
    marginHorizontal: 70,
    borderRadius: 4,
  },

  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
    height: 40,
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },

  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  columnHeader: {
    width: "11.11%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "11.11%",
    textAlign: "center",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
});
