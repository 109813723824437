import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Alert,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import {
  List,
  Modal,
  Portal,
  Provider,
  Divider,
  Badge,
  Snackbar,
} from "react-native-paper";

import defaultPic from "../images/defaultPic.jpg";

import {
  Feather,
  SimpleLineIcons,
  FontAwesome,
  Ionicons,
  AntDesign,
  EvilIcons,
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default function LeftBar({
  dashboardBar,
  user,
  customer,
  vendor,
  product,
  order,
  employee,
  leads,
  fleet,
  mroInvetory,
}) {
  const navigation = useNavigation();
  const [userData, setuserData] = useState({
    name: "",
    loginId: "",
    admin: false,
  });
  const [pending, setPending] = useState("");
  const [SnackbarVisible, setSnackbarVisible] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              let loginId = firebase.auth().currentUser.email
                ? firebase.auth().currentUser.email
                : user.phoneNumber;
              if (loginId != null) {
                let userid = loginId.split(".")[0];
                let dbRef = firebase
                  .database()
                  .ref(`${uoid}/erpData/users/${userid}`);

                dbRef.once("value", (DataSnapShot) => {
                  if (DataSnapShot.val()) {
                    setuserData(DataSnapShot.val());
                  }
                });
              }
            }
          });

          let dbref1 = firebase
            .database()
            .ref(`${uoid}/factoryData/ordersData/ordersbasics`)
            .orderByChild("approved")
            .equalTo(null);

          dbref1.on("value", (snapshot) => {
            if (snapshot.val()) {
              setPending(Object.values(snapshot.val()).length);
            } else {
              setPending(0);
            }
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <View style={[styles.leftBar]}>
      {/* Modal starts */}

      <View style={[styles.navigationBar]}>
        <View style={styles.userDataHolder}>
          <Image
            source={defaultPic}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
          {userData.name ? (
            <View style={{ justifyContent: "center", paddingHorizontal: 14 }}>
              <Text style={{ fontWeight: "600" }}>{userData.name}</Text>
              <Text>{userData.loginId}</Text>
              <Text>{userData.admin ? "Admin" : "None Admin"}</Text>
              <Text>{userData.isSiteLogin ? "Site(Plant) Account" : null}</Text>
            </View>
          ) : (
            <ActivityIndicator
              animating={true}
              color="#0a60ff"
              size="small"
              style={{ marginLeft: 20 }}
            />
          )}
        </View>
        <Divider style={{ width: 200, alignSelf: "center", marginTop: 20 }} />
        <View style={styles.navigationMenu}>
          <TouchableOpacity
            onPress={() => {
              // if (userData.canViewDashBoard) {
              navigation.navigate("DashBoard");
              // }
            }}
            style={[styles.navOptions, { backgroundColor: dashboardBar }]}
          >
            <SimpleLineIcons name="screen-desktop" size={22} color="black" />
            <Text style={{ paddingLeft: 14 }}>DashBoard</Text>
          </TouchableOpacity>

          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewCustomerManagement) {
                navigation.navigate("customerScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: user }]}
          >
            <FontAwesome name="user-o" size={22} color="black" />
            <Text style={{ paddingLeft: 14 }}>User Management</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.navOptions, { padding: 15, paddingLeft: 60 }]}
            onPress={() => {
              if (userData.canViewCustomerManagement) {
                navigation.navigate("customerScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
          >
            <Text
              style={{
                textDecorationLine: customer,
                color: userData.canViewCustomerManagement ? "#000" : "#8c8c8c",
              }}
            >
              Customer
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.navOptions,
              { backgroundColor: vendor, padding: 15, paddingLeft: 60 },
            ]}
            onPress={() => {
              if (userData.canViewVendorManagement) {
                navigation.navigate("vendorScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
          >
            <Text
              style={{
                textDecorationLine: vendor,
                color: userData.canViewVendorManagement ? "#000" : "#8c8c8c",
              }}
            >
              Vendor
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewMRO) {
                navigation.navigate("mroScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: mroInvetory }]}
          >
            <AntDesign name="inbox" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewMRO ? "#000" : "#8c8c8c",
              }}
            >
              MRO Inventory
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewOrderManagement) {
                navigation.navigate("orderScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: order }]}
          >
            <Ionicons name="ios-cart-outline" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewOrderManagement ? "#000" : "#8c8c8c",
              }}
            >
              Order Management
            </Text>
            <Badge
              style={{
                alignSelf: "center",
                marginLeft: 20,
                backgroundColor: pending == 0 ? "#009C44" : "#de5246",
              }}
            >
              {pending ? pending : "0"}
            </Badge>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewProductManagement) {
                navigation.navigate("productScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: product }]}
          >
            <Feather name="box" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewProductManagement ? "#000" : "#8c8c8c",
              }}
            >
              Product Management
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewEmployeeManagement) {
                navigation.navigate("employeeScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: employee }]}
          >
            <Feather name="users" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewEmployeeManagement
                  ? "#000000"
                  : "#8c8c8c",
              }}
            >
              Employee Management
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewLeadsManagement) {
                navigation.navigate("leadsScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: leads }]}
          >
            <Feather name="users" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewLeadsManagement ? "#000" : "#8c8c8c",
              }}
            >
              Leads Management
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            onPress={() => {
              if (userData.canViewFleetManagement) {
                navigation.navigate("fleetScreen");
              } else {
                setSnackbarVisible(true);
                setError("Not Allowed");
                setTimeout(() => {
                  setSnackbarVisible(false);
                }, 6000);
              }
            }}
            style={[styles.navOptions, { backgroundColor: fleet }]}
          >
            <Feather name="truck" size={22} color="black" />
            <Text
              style={{
                paddingLeft: 14,
                color: userData.canViewFleetManagement ? "#000" : "#8c8c8c",
              }}
            >
              Fleet Management
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Snackbar
        visible={SnackbarVisible}
        onDismiss={() => {}}
        action={{
          label: "OK",
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
      >
        {error}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  leftBar: {
    borderRightWidth: 1,
    borderRightColor: "#c1c1c1",
    width: Dimensions.get("screen").width * 0.15,
  },
  leftLogoHolder: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
    justifyContent: "center",
    alignItems: "center",
  },
  leftHolder: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 220,
  },
  navigationBar: {},
  userDataHolder: {
    flexDirection: "row",
    flex: 1,
    padding: 10,
  },
  navigationMenu: {
    flex: 10,
    marginTop: 20,
  },
  navOptions: {
    height: 50,
    paddingLeft: 16,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  modelText: {
    padding: 10,
    textAlign: "center",
    fontSize: 16,
  },
  navOptionsModal: {
    height: 50,
    paddingLeft: 16,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30%",
  },
});
