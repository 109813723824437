import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import firebase from "firebase";
import { Picker } from "@react-native-picker/picker";
import {
  Modal,
  Provider,
  Portal,
  Searchbar,
  TextInput,
  RadioButton,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";

const stateList = [
  { stateName: "Andaman and Nicobar Islands", code: 25 },
  { stateName: "Andhra Pradesh", code: 28 },
  { stateName: "Arunachal Pradesh ", code: 12 },
  { stateName: "Assam", code: 28 },
  { stateName: "Bihar", code: 10 },
  { stateName: "Chandigarh", code: 4 },
  { stateName: "Chattisgarh", code: 22 },
  { stateName: "Dadra and Nagar Haveli ", code: 26 },
  { stateName: "Daman and Diu", code: 25 },
  { stateName: "Delhi", code: 7 },
  { stateName: "Goa", code: 30 },
  { stateName: "Gujarat", code: 24 },
  { stateName: "Haryana", code: 6 },
  { stateName: "Himachal Pradesh", code: 2 },
  { stateName: "Jammu and Kashmir", code: 1 },
  { stateName: "Jharkhand", code: 20 },
  { stateName: "Karnataka", code: 29 },
  { stateName: "Kerala", code: 32 },
  { stateName: "Lakshadweep Islands", code: 31 },
  { stateName: "Madhya Pradesh", code: 23 },
  { stateName: "Maharashtra", code: 27 },
  { stateName: "Manipur", code: 14 },
  { stateName: "Meghalaya", code: 17 },
  { stateName: "Mizoram", code: 15 },
  { stateName: "Nagaland", code: 13 },
  { stateName: "Odisha", code: 21 },
  { stateName: "Pondicherry", code: 34 },
  { stateName: "Punjab", code: 3 },
  { stateName: "Rajasthan", code: 8 },
  { stateName: "Sikkim", code: 11 },
  { stateName: "Tamil Nadu", code: 33 },
  { stateName: "Telangana", code: 36 },
  { stateName: "Tripura", code: 16 },
  { stateName: "Uttar Pradesh", code: 9 },
  { stateName: "Uttarakhand", code: 5 },
  { stateName: "West Bengal", code: 19 },
];
class CustomerTable extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      columns: ["Sr. no.", "Name", "Unchecked DM's", "Action"],
      searchQuery: "",
      postSorterAce: true,
      uoid: "",

      //
      setVisible: false,
      customerName: "",
      address: "",
      number: "",
      email: "",
      gst: "",
      checked: null,
      openingCredit: "",
      openingDebit: "",
      //
      buttonLoading: false,
      operatorName: "",
      operatorId: "",
      check: null,
      message: null,
      supplyState: "",
      stateCode: null,
      companyName: "",
      companyAddress: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this._isMounted &&
            this.setState({ uoid }, () => {
              this.callDataFromDB();
              this.getLoginUserData();
              this.getCompanyData();
            });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getCompanyData = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/erpData/organizationData/basics`);
    dbRef.on("value", (SnapShot) => {
      if (SnapShot.val()) {
        let data = SnapShot.val();
        this._isMounted &&
          this.setState({
            companyName: data.organizationName,
            companyAddress: data.atPost,
          });
      }
    });
  };

  sendSMSViaMSG91 = () => {
    // app.get("/cors", (req, res) => {
    //   res.set("Access-Control-Allow-Origin", "http://localhost:19006/");
    // });
    const data = `{\n  \"flow_id\": \"624fe5972faac217b6715ef7\",\n \"mobiles\": \"91${
      this.state.number
    }\",\n  \"cutomerName\": \"${
      this.state.customerName
    }\",\n  \"clientCompanyName\": \"${this.state.companyName.toUpperCase()}\",\n  \"clientCompanyAdd\": \"${this.state.companyAddress.toUpperCase()}\"\n}`;

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });

    xhr.open(
      "POST",
      "https://cors-anywhere.herokuapp.com/https://api.msg91.com/api/v5/flow/"
    );

    xhr.setRequestHeader("authkey", "374421AhLkYQBV622ef928P1");
    xhr.setRequestHeader("content-type", "application/JSON");

    xhr.send(data);
  };
  getLoginUserData = () => {
    let loginId = firebase.auth().currentUser.email
      ? firebase.auth().currentUser.email
      : firebase.auth().currentUser.phoneNumber;
    if (loginId != null) {
      let dbRef = firebase
        .database()
        .ref(`${this.state.uoid}/erpData/users/`)
        .orderByChild("loginId")
        .equalTo(loginId);
      dbRef.once("value", (DataSnapShot) => {
        if (DataSnapShot.val()) {
          let data = Object.values(DataSnapShot.val());
          this._isMounted &&
            this.setState({
              operatorName: data[0].name,
              operatorId: data[0].loginId,
            });
        }
      });
    }
  };
  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/customerData/customerBasics`);

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.unCheckedDM > b.unCheckedDM) {
                return -1;
              }
              if (a.unCheckedDM < b.unCheckedDM) {
                return 1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this._isMounted &&
              this.setState({ searchData: this.state.mainData });
          }
        );
      } else {
        this._isMounted && this.setState({ isLoading: false });
      }
    });
  };
  checkifItemExist = () => {
    let finalArray = this.state.mainData.map((item) =>
      item.customerName.toUpperCase()
    );
    let check = finalArray.includes(this.state.customerName.toUpperCase());
    if (check) {
      this._isMounted &&
        this.setState({ message: "Customer Already Exists", check });
    } else {
      this._isMounted && this.setState({ message: null, check });
    }
  };
  searchStringInArray = (str) => {
    if (str) {
      const newdata = this.state.mainData.filter((item) => {
        const itemData = item
          ? item.customerName.toUpperCase()
          : "".toUpperCase();
        const textData = str.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this._isMounted && this.setState({ searchData: newdata });
    } else {
      this._isMounted && this.setState({ searchData: this.state.mainData });
    }
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                column == "Name"
                  ? this.setState(
                      { postSorterAce: !this.state.postSorterAce },
                      () => {
                        this.tableRowSorter();
                      }
                    )
                  : null;
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Name" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this.setState(
      {
        mainData: this.state.mainData.sort(function (a, b) {
          if (a.customerName > b.customerName) {
            return direction ? 1 : -1;
          }
          if (a.customerName < b.customerName) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        this._isMounted && this.setState({ searchData: this.state.mainData });
      }
    );
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  saveDataToDb = () => {
    if (
      this.state.customerName != "" &&
      this.state.address != "" &&
      this.state.number != ""
    ) {
      var rString = this.randomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_"
      );
      this._isMounted && this.setState({ buttonLoading: true });
      let dbRef = firebase
        .database()
        .ref(
          `${this.state.uoid}/factoryData/customerData/customerBasics/${rString}`
        );

      dbRef
        .set({
          id: rString,
          customerName: this.state.customerName,
          address: this.state.address,
          supplyState: this.state.supplyState,
          stateCode: this.state.stateCode,
          number: this.state.number,
          email: this.state.email,
          gst: this.state.gst,
          unCheckedDM: 0,
          addedByname: this.state.operatorName,
          addedByLoginId: this.state.operatorId,
          addedDate: `${new Date().getDate()}/${
            new Date().getMonth() + 1
          }/${new Date().getFullYear()},${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          isPOdispatch:false
        })
        .then(() => {
          var ledgerId = this.randomString(
            10,
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          );
          let ledgerTime = new Date();
          let ledgerRef = firebase
            .database()
            .ref(
              `${this.state.uoid}/factoryData/customerData/ledger/${rString}/${ledgerTime}`
            );
          ledgerRef
            .set({
              ledgerId: ledgerId,
              transDate: new Date().toDateString(),
              discription: "Opening Balance",
              credit: this.state.openingCredit
                ? parseFloat(this.state.openingCredit)
                : "0",
              debit: this.state.openingDebit
                ? parseFloat(this.state.openingDebit)
                : "0",
              balance: this.state.openingDebit - this.state.openingCredit,
              dateNumber: Date.parse(new Date()),
              timeStampId: `${ledgerTime}`,
              isCashEntry: false,
            })
            .then(() => {
              this.sendSMSViaMSG91();
              this._isMounted &&
                this.setState({
                  buttonLoading: false,
                  setVisible: false,
                  customerName: "",
                  address: "",
                  number: "",
                  email: "",
                  gst: "",
                  checked: null,
                  openingCredit: "",
                  openingDebit: "",
                });
            });
        })
        .catch(
          (error) => this._isMounted && this.setState({ error: error.message })
        );
    }
  };

  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "500",
              alignSelf: "center",
            }}
          >
            Customer Records
          </Text>
          <TouchableOpacity
            style={styles.addNewButton}
            onPress={() => {
              this._isMounted && this.setState({ setVisible: true });
            }}
          >
            <Text style={{ color: "#fff" }}>Add New Customer</Text>
          </TouchableOpacity>
        </View>
        <View stle={styles.searchbarBody}>
          <Searchbar
            placeholder="Search by Name"
            onChangeText={(text) => {
              this._isMounted &&
                this.setState({ searchQuery: text }, () => {
                  this.searchStringInArray(this.state.searchQuery);
                });
            }}
            value={this.state.searchQuery}
            // value={this.props.newAdduser}
          />
        </View>
        <Portal>
          <Modal
            visible={this.state.setVisible}
            onDismiss={() => {
              this._isMounted && this.setState({ setVisible: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              width: 900,
              borderRadius: 5,
              alignSelf: "center",
              position:"absolute",
              top:100
            }}
          >
            <View style={styles.modelHeader}>
              <Text style={styles.modelHeaderText}>New Customer</Text>
            </View>
            <View style={styles.modelBody}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 20 }}>
                  <TextInput
                    mode="outlined"
                    label="Name of Customer"
                    value={this.state.customerName}
                    style={[styles.inputField, { padding: 0, width: 330 }]}
                    onChangeText={(text) => {
                      this._isMounted &&
                        this.setState({ customerName: text }, () => {
                          this.checkifItemExist();
                        });
                    }}
                    placeholder="Name"
                  />
                  <Text style={{ color: "#de5246" }}>{this.state.message}</Text>
                </View>

                <TextInput
                  mode="outlined"
                  label="Address"
                  value={this.state.address}
                  style={[styles.inputField, { width: 280 }]}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ address: text });
                  }}
                  placeholder="Address"
                />
                <View style={{ padding: 24 }}>
                  <Picker
                    selectedValue={this.state.supplyState}
                    style={{
                      height: 40,
                      width: 200,
                    }}
                    onValueChange={(itemValue, itemIndex) => {
                      this.setState({
                        supplyState: itemValue,
                        stateCode: stateList[itemIndex - 1].code,
                      });
                    }}
                  >
                    <Picker.Item label="Select State" value="" />

                    <Picker.Item
                      label={stateList[0].code + "-" + stateList[0].stateName}
                      value={stateList[0].stateName}
                    />

                    <Picker.Item
                      label={stateList[1].code + "-" + stateList[1].stateName}
                      value={stateList[1].stateName}
                    />
                    <Picker.Item
                      label={stateList[2].code + "-" + stateList[2].stateName}
                      value={stateList[2].stateName}
                    />
                    <Picker.Item
                      label={stateList[3].code + "-" + stateList[3].stateName}
                      value={stateList[3].stateName}
                    />
                    <Picker.Item
                      label={stateList[4].code + "-" + stateList[4].stateName}
                      value={stateList[4].stateName}
                    />
                    <Picker.Item
                      label={stateList[5].code + "-" + stateList[5].stateName}
                      value={stateList[5].stateName}
                    />
                    <Picker.Item
                      label={stateList[6].code + "-" + stateList[6].stateName}
                      value={stateList[6].stateName}
                    />
                    <Picker.Item
                      label={stateList[7].code + "-" + stateList[7].stateName}
                      value={stateList[7].stateName}
                    />
                    <Picker.Item
                      label={stateList[8].code + "-" + stateList[8].stateName}
                      value={stateList[8].stateName}
                    />
                    <Picker.Item
                      label={stateList[9].code + "-" + stateList[9].stateName}
                      value={stateList[9].stateName}
                    />
                    <Picker.Item
                      label={stateList[10].code + "-" + stateList[10].stateName}
                      value={stateList[10].stateName}
                    />
                    <Picker.Item
                      label={stateList[11].code + "-" + stateList[11].stateName}
                      value={stateList[11].stateName}
                    />
                    <Picker.Item
                      label={stateList[12].code + "-" + stateList[12].stateName}
                      value={stateList[12].stateName}
                    />
                    <Picker.Item
                      label={stateList[13].code + "-" + stateList[13].stateName}
                      value={stateList[13].stateName}
                    />
                    <Picker.Item
                      label={stateList[14].code + "-" + stateList[14].stateName}
                      value={stateList[14].stateName}
                    />
                    <Picker.Item
                      label={stateList[15].code + "-" + stateList[15].stateName}
                      value={stateList[15].stateName}
                    />
                    <Picker.Item
                      label={stateList[16].code + "-" + stateList[16].stateName}
                      value={stateList[16].stateName}
                    />
                    <Picker.Item
                      label={stateList[17].code + "-" + stateList[17].stateName}
                      value={stateList[17].stateName}
                    />
                    <Picker.Item
                      label={stateList[18].code + "-" + stateList[18].stateName}
                      value={stateList[18].stateName}
                    />
                    <Picker.Item
                      label={stateList[19].code + "-" + stateList[19].stateName}
                      value={stateList[19].stateName}
                    />
                    <Picker.Item
                      label={stateList[20].code + "-" + stateList[20].stateName}
                      value={stateList[20].stateName}
                    />
                    <Picker.Item
                      label={stateList[21].code + "-" + stateList[21].stateName}
                      value={stateList[21].stateName}
                    />
                    <Picker.Item
                      label={stateList[22].code + "-" + stateList[22].stateName}
                      value={stateList[22].stateName}
                    />
                    <Picker.Item
                      label={stateList[23].code + "-" + stateList[23].stateName}
                      value={stateList[23].stateName}
                    />
                    <Picker.Item
                      label={stateList[24].code + "-" + stateList[24].stateName}
                      value={stateList[24].stateName}
                    />
                    <Picker.Item
                      label={stateList[25].code + "-" + stateList[25].stateName}
                      value={stateList[25].stateName}
                    />
                    <Picker.Item
                      label={stateList[26].code + "-" + stateList[26].stateName}
                      value={stateList[26].stateName}
                    />
                    <Picker.Item
                      label={stateList[27].code + "-" + stateList[27].stateName}
                      value={stateList[27].stateName}
                    />
                    <Picker.Item
                      label={stateList[28].code + "-" + stateList[28].stateName}
                      value={stateList[28].stateName}
                    />
                    <Picker.Item
                      label={stateList[29].code + "-" + stateList[29].stateName}
                      value={stateList[29].stateName}
                    />
                    <Picker.Item
                      label={stateList[30].code + "-" + stateList[30].stateName}
                      value={stateList[30].stateName}
                    />
                    <Picker.Item
                      label={stateList[31].code + "-" + stateList[31].stateName}
                      value={stateList[31].stateName}
                    />
                    <Picker.Item
                      label={stateList[32].code + "-" + stateList[32].stateName}
                      value={stateList[32].stateName}
                    />
                    <Picker.Item
                      label={stateList[33].code + "-" + stateList[33].stateName}
                      value={stateList[33].stateName}
                    />
                    <Picker.Item
                      label={stateList[34].code + "-" + stateList[34].stateName}
                      value={stateList[34].stateName}
                    />
                    <Picker.Item
                      label={stateList[35].code + "-" + stateList[35].stateName}
                      value={stateList[35].stateName}
                    />
                  </Picker>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  mode="outlined"
                  label="GSTIN"
                  value={this.state.gst}
                  style={[styles.inputField, { width: 300 }]}
                  onChangeText={(text) => {
                    this._isMounted &&
                      this.setState({ gst: text.toUpperCase() });
                  }}
                  placeholder="GSTIN"
                />
                <TextInput
                  mode="outlined"
                  label="Contact Number"
                  value={this.state.number}
                  style={[styles.inputField, { width: 250 }]}
                  onChangeText={(text) => {
                    let regex = /^[0-9]*$/;
                    if (text.match(regex)) {
                      this._isMounted && this.setState({ number: text });
                    }
                  }}
                  placeholder="Number"
                />

                <TextInput
                  mode="outlined"
                  label="Email"
                  value={this.state.email}
                  style={[styles.inputField, { width: 300 }]}
                  onChangeText={(text) => {
                    this._isMounted && this.setState({ email: text });
                  }}
                  placeholder="email"
                />
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ padding: 30 }}>Opening Balance</Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Debit (-)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Debit"
                        status={
                          this.state.checked === "first"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ checked: "first" });
                          this._isMounted &&
                            this.setState({ openingCredit: 0 });
                        }}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>Credit(+)</Text>
                      <RadioButton
                        color="#0a60ff"
                        value="Credit"
                        status={
                          this.state.checked === "second"
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() => {
                          this._isMounted &&
                            this.setState({ checked: "second" });
                          this._isMounted && this.setState({ openingDebit: 0 });
                        }}
                      />
                    </View>
                  </View>

                  <TextInput
                    mode="outlined"
                    label="Amount"
                    value={
                      this.state.checked === "first"
                        ? this.state.openingDebit
                        : this.state.openingCredit
                    }
                    style={[styles.inputField, { width: 170 }]}
                    onChangeText={(text) => {
                      this.state.checked === "first"
                        ? this._isMounted &&
                          this.setState({ openingDebit: text })
                        : this._isMounted &&
                          this.setState({ openingCredit: text });
                    }}
                    placeholder="Amount"
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <TouchableOpacity
                    disabled={this.state.buttonLoading && !this.state.check}
                    style={[
                      styles.addNewButton,
                      {
                        width: 150,
                        marginHorizontal: 20,
                        height: 42,
                        marginTop: 26,
                      },
                    ]}
                    onPress={() => {
                      if (this.state.check == false) {
                        this.saveDataToDb();
                      }
                    }}
                  >
                    {this.state.buttonLoading ? (
                      <ActivityIndicator
                        animating={true}
                        color="#fff"
                        size="small"
                      />
                    ) : (
                      <Text style={{ color: "#fff", alignSelf: "center" }}>
                        {" "}
                        Save Details
                      </Text>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.addNewButton,
                      {
                        backgroundColor: "#fff",
                        width: 80,
                        marginHorizontal: 20,
                        height: 42,
                        marginTop: 26,
                      },
                    ]}
                    onPress={() => {
                      this._isMounted && this.setState({ setVisible: false });
                    }}
                  >
                    <Text style={{ color: "#0a60ff", alignSelf: "center" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </Portal>

        <FlatList
          data={this.state.searchData}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                ]}
              >
                <Text style={styles.columnRowTxt}>{index + 1}</Text>
                <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                  {item.customerName}, {item.address}
                </Text>
                <Text style={styles.columnRowTxt}>{item.unCheckedDM}</Text>
                <View
                  style={{
                    width: "25%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("salesReportScreen", {
                        customerId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Sales Report</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#0a60ff" }]}
                    onPress={() => {
                      navigation.navigate("ledgerScreen", {
                        customerId: item.id,
                      });
                    }}
                  >
                    <Text style={{ color: "#0a60ff" }}>Ledger</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.actionbtn, { borderColor: "#075e54" }]}
                    onPress={() =>
                      navigation.navigate("customerEdit", {
                        customerId: item.id,
                      })
                    }
                  >
                    <Text style={{ color: "#075e54" }}>Edit</Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
          ListHeaderComponent={this.tableHeader}
          stickyHeaderIndices={[0]}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
              }}
            >
              {this.state.isLoading ? (
                <ActivityIndicator size="large" color="#0a60ff" />
              ) : (
                <Text>No Customer Added, Go Forward and add one !</Text>
              )}
            </View>
          }
          keyExtractor={(item) => JSON.stringify(item.id)}
        />
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <CustomerTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "25%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
});
