import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import firebase from "firebase";
import { jsPDF } from "jspdf";
import Calendar from "react-calendar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "react-calendar/dist/Calendar.css";

class PurchaseReportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: [],
      searchData: [],
      isLoading: true,
      isButtonLoading: false,
      columns: [
        "Sr. No.",
        "Date",
        "Particular",
        "Receipt No.",
        "Quantity",
        "Payment Mode",
        "Amount",
        "Action",
      ],
      searchQuery: "",
      postSorterAce: true,
      vendorId: "", //
      show_nrdm: false,
      filterArrayForLedger: [],

      statementArray: [],
      dateFrom: new Date(),
      viewCalenderFrom: false,
      dateTo: new Date(),
      viewCalenderTo: false,
      paymentType: "",
      productRate: [],
      total: 0,
      errorColor: "",
      uoid: "",
    };
  }
  componentDidMount() {
    this.setState({ vendorId: this.props.vendorId });
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            var msInDay = 86400000;
            var daysToSub = new Date().getDate() - 1;
            var now = new Date();
            var milliseconds = now.getTime();
            var newMillisecods = milliseconds - msInDay * daysToSub;
            var newDate = new Date(newMillisecods);
            this.setState({ dateFrom: newDate }, () => {
              this.callDataFromDB();
            });
          });
        }
      });
    } catch (e) {
      // error reading value
    }
    //to set date to 1st date of month
  }

  callDataFromDB = () => {
    let dbRef = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/vendorData/purchaseReport/${this.props.vendorId}/FY2021_22`
      )
      .orderByChild("dateStamp")
      .startAt(Date.parse(this.state.dateFrom.toDateString()))
      .endAt(
        Date.parse(
          new Date(
            `${this.state.dateTo.getFullYear()}-${
              this.state.dateTo.getMonth() + 1
            }-${this.state.dateTo.getDate()} 23:59:59`
          )
        )
      );

    dbRef.on("value", (DataSnapShot) => {
      if (DataSnapShot.val()) {
        let data = Object.values(DataSnapShot.val());
        this.setState(
          {
            mainData: data.sort(function (a, b) {
              if (a.receiptNo < b.receiptNo) {
                return 1;
              }
              if (a.receiptNo > b.receiptNo) {
                return -1;
              }
              return 0;
            }),
            isLoading: false,
          },
          () => {
            this.setState({
              searchData: this.state.mainData,
              vendorId: this.props.vendorId,
              isButtonLoading: false,
            });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          searchData: [],
          isButtonLoading: false,
        });
      }
    });
  };

  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity
              key={index}
              style={styles.columnHeader}
              onPress={() => {
                if (column == "Date") {
                  this.setState(
                    { postSorterAce: !this.state.postSorterAce },
                    () => {
                      this.tableRowSorter();
                    }
                  );
                }
              }}
            >
              <Text style={styles.columnHeaderTxt}>
                {column}{" "}
                {column == "Date" ? (
                  this.state.postSorterAce ? (
                    <AntDesign name="downcircle" size={14} color="black" />
                  ) : (
                    <AntDesign name="upcircle" size={14} color="black" />
                  )
                ) : (
                  ""
                )}
              </Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );

  tableRowSorter = () => {
    let direction = this.state.postSorterAce;
    this.setState(
      {
        mainData: this.state.mainData.sort(function (a, b) {
          if (a.date > b.date) {
            return direction ? 1 : -1;
          }
          if (a.date < b.date) {
            return direction ? -1 : 1;
          }
          return 0;
        }),
        isLoading: false,
      },
      () => {
        this.setState({ searchData: this.state.mainData });
      }
    );
  };
  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ padding: 10, fontWeight: "bold", fontSize: 16 }}>
            Purchase Statement
          </Text>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Text style={{ padding: 20 }}>From :</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 200,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this.setState({
                      viewCalenderFrom: !this.state.viewCalenderFrom,
                    });
                  }}
                >
                  {/* {this.state.dateFrom.getDate()}/
                  {this.state.dateFrom.getMonth() + 1}/
                  {this.state.dateFrom.getFullYear()} */}
                  {this.state.dateFrom.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderFrom ? (
                    <Calendar
                      value={this.state.dateFrom}
                      onChange={(value) => {
                        this.setState({ dateFrom: value }, () => {
                          this.setState({ viewCalenderFrom: false });
                        });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>
            {/* to */}
            <Text style={{ padding: 20 }}>To :</Text>
            <View style={{ flexDirection: "row", zIndex: 5 }}>
              <View>
                <Text
                  style={[
                    styles.inputField,
                    {
                      backgroundColor: "#EAEAEA",
                      borderWidth: 1,
                      borderColor: "#c1c1c1",
                      borderRadius: 5,
                      margin: 14,
                      alignSelf: "center",
                      padding: 10,
                      width: 200,
                      textAlignVertical: "center",
                    },
                  ]}
                  onPress={() => {
                    this.setState({
                      viewCalenderTo: !this.state.viewCalenderTo,
                    });
                  }}
                >
                  {/* {this.state.dateTo.getDate()}/
                  {this.state.dateTo.getMonth() + 1}/
                  {this.state.dateTo.getFullYear()} */}
                  {this.state.dateTo.toDateString()}
                </Text>

                <View
                  style={{
                    paddingLeft: 20,
                    marginTop: 75,
                    zIndex: 5,
                    position: "absolute",
                  }}
                >
                  {this.state.viewCalenderTo ? (
                    <Calendar
                      value={this.state.dateTo}
                      onChange={(value) => {
                        this.setState({ dateTo: value }, () => {
                          this.setState({ viewCalenderTo: false });
                        });
                      }}
                    />
                  ) : null}
                </View>
              </View>
            </View>

            <TouchableOpacity
              style={[
                styles.actionbtn,
                { borderColor: "#0a60ff", margin: 15, width: 100 },
              ]}
              onPress={() => {
                this.setState({ isButtonLoading: true });
                this.callDataFromDB();
              }}
            >
              {this.state.isButtonLoading ? (
                <ActivityIndicator size="small" color="#0a60ff" />
              ) : (
                <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                  View
                </Text>
              )}
            </TouchableOpacity>

            {/* <Picker
              selectedValue={this.state.paymentType}
              style={{
                height: 40,
                width: 180,
                marginHorizontal: 22,
                marginVertical: 15,
                borderWidth: 1,
                borderColor: this.state.errorColor,
                color: this.state.errorColor,
              }}
              onValueChange={(itemValue, itemIndex) =>
                this.setState({ paymentType: itemValue })
              }
            >
              <Picker.Item label="Payment Mode" value="" />
              <Picker.Item label="Cash" value="Cash" />
              <Picker.Item label="Credit" value="Credit" />
            </Picker> */}
            {/* <TouchableOpacity
              style={[styles.actionbtn, { borderColor: "#B50706", margin: 15 }]}
              onPress={() => {
                if (this.state.paymentType) {
                  this.filterArrayForStatement();
                } else {
                  this.setState({
                    errorColor: "#B50706",
                  });
                }
              }}
            >
              <Text
                style={{
                  color: "#B50706",
                }}
              >
                PDF Download
              </Text>
            </TouchableOpacity> */}
          </View>
        </View>

        <View style={{ maxHeight: 450, zIndex: -1 }}>
          <FlatList
            data={this.state.searchData}
            //data={this.state.statementArray}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" },
                  ]}
                  onPress={() => {
                    navigation.navigate("purchaseReportViewScreen", {
                      vendorId: this.props.vendorId,
                      purchaseId: item.id,
                    });
                  }}
                >
                  <Text style={styles.columnRowTxt}>{index + 1}</Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    {item.date}
                  </Text>
                  <Text style={styles.columnRowTxt}>{item.item}</Text>
                  <Text style={styles.columnRowTxt}>{item.receiptNo}</Text>
                  <Text style={[styles.columnRowTxt, { fontWeight: "bold" }]}>
                    {parseFloat(item.addQty).toFixed(2)}
                  </Text>
                  <Text style={styles.columnRowTxt}>{item.paymentMode}</Text>
                  <Text style={styles.columnRowTxt}>{item.otherAmount}</Text>
                  <View
                    style={[
                      styles.columnRowTxt,
                      {
                        flexDirection: "row",
                        justifyContent: "space-around",
                      },
                    ]}
                  >
                    <AntDesign
                      name="checkcircle"
                      size={20}
                      color={item.checkedByAuthPerson ? "#009C44" : "#c1c1c1"}
                      style={{ alignSelf: "center" }}
                    />
                    {item.isLedgerEntryDone == true &&
                    item.paymentMode ==
                      "Cash" ? null : item.isLedgerEntryDone ? (
                      <MaterialCommunityIcons
                        name="book-account-outline"
                        size={22}
                        color="#009C44"
                      />
                    ) : (
                      <MaterialCommunityIcons
                        name="book-account-outline"
                        size={22}
                        color={item.isMarkforLedgerGen ? "#F4B400" : "#c1c1c1"}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              );
            }}
            ListHeaderComponent={this.tableHeader}
            stickyHeaderIndices={[0]}
            ListEmptyComponent={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                }}
              >
                {this.state.isLoading ? (
                  <ActivityIndicator size="large" color="#0a60ff" />
                ) : (
                  <Text>No Entries Found in Selected Date Range</Text>
                )}
              </View>
            }
            keyExtractor={(item) => JSON.stringify(item.id)}
          />
        </View>
      </View>
    );
  }
}

export default function (props) {
  const navigation = useNavigation();

  return <PurchaseReportTable {...props} navigation={navigation} />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
  columnHeader: {
    width: "12.5%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "12.5%",
    textAlign: "center",
  },
  actionbtn: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
  },
  searchbarBody: {
    flexDirection: "row",
    zIndex: -1,
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
});
