import React, { Component } from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import {
  TextInput,
  Snackbar,
  RadioButton,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import firebase from "firebase";
export default class fleetViewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      vehicleName: "",
      plateNumber: "",
      type: "",
      //
      buttonLoading: false,
      error: "",
      disabled: false,
      ///
      documentArray: [],
      newDocTitle: "",
      newDocExpiry: new Date(),
      newDocContact: "",
      viewCalender: false,
      setVisible: false,
      savedDocTitle: "",
      savedDocDate: new Date(),
      savedDocNote: "",
      savedviewCalender: false,
      docId: "",
      uoid: "",
      docSaveButtonLoading: false,
    };
  }
  componentDidMount() {
    const { id } = this.props.route.params;
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.setState(
              {
                id: id,
              },
              () => {
                this.getFleetData();
                this.getDocData();
              }
            );
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getFleetData = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleVital/${this.state.id}`
      );

    dbref.on("value", (snapShot) => {
      if (snapShot.val()) {
        let data = snapShot.val();
        this.setState({
          vehicleName: data.vehicleName,
          plateNumber: data.plateNumber,
          type: data.type,
        });
      }
    });
  };
  updateDB = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleVital/${this.state.id}`
      );

    dbref.update({
      vehicleName: this.state.vehicleName,
      plateNumber: this.state.plateNumber,
      type: this.state.type,
    });
  };
  getDocData = () => {
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleDocument/${this.state.id}`
      );

    dbref.on("value", (dataSnapShot) => {
      if (dataSnapShot.val()) {
        let data = Object.values(dataSnapShot.val());
        this.setState({
          documentArray: data.sort(function (a, b) {
            if (a.expiryDate > b.expiryDate) {
              return 1;
            }
            if (a.expiryDate < b.expiryDate) {
              return -1;
            }
            return 0;
          }),
          isLoading: false,
        });
      }
    });
  };

  addDocToDB = () => {
    this.setState({ docSaveButtonLoading: true });
    let id = Date.parse(new Date());
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleDocument/${this.state.id}/${id}`
      );

    dbref
      .set({
        id: id,
        documentTitle: this.state.newDocTitle,
        expiryDate: Date.parse(this.state.newDocExpiry),
        note: this.state.newDocContact,
      })
      .then(() => {
        this.setState({ docSaveButtonLoading: false });
      });

    let dbrefAllDoc = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/fleetData/allDocuments/${id}`);

    dbrefAllDoc
      .set({
        id: id,
        documentTitle: this.state.newDocTitle,
        expiryDate: Date.parse(this.state.newDocExpiry),
        note: this.state.newDocContact,
        name: this.state.vehicleName,
        plateNumber: this.state.plateNumber,
        vehicelId: this.state.id,
      })
      .then(() => {
        this.setState({
          newDocTitle: "",
          newDocExpiry: new Date(),
          newDocContact: "",
        });
      });
  };
  saveDocUpdate = () => {
    this.setState({ docSaveButtonLoading: true });
    let dbref = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/vehicleDocument/${this.state.id}/${this.state.docId}`
      );

    dbref
      .update({
        note: this.state.savedDocNote,
        documentTitle: this.state.savedDocTitle,
        expiryDate: Date.parse(this.state.savedDocDate),
      })
      .then(() => {
        this.setState({ docSaveButtonLoading: false });
      });

    let dbrefAllDoc = firebase
      .database()
      .ref(
        `${this.state.uoid}/factoryData/fleetData/allDocuments/${this.state.docId}`
      );
    dbrefAllDoc.update({
      note: this.state.savedDocNote,
      documentTitle: this.state.savedDocTitle,
      expiryDate: Date.parse(this.state.savedDocDate),
    });
  };
  randomString = (length, chars) => {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  render() {
    return (
      <Provider>
        <View style={styles.container}>
          <Header title="Vehicle Details" />
          <View style={styles.mainConsole}>
            <LeftBar fleet="#c1c1c1" />
            <View style={styles.palyArea}>
              <Portal>
                <Modal
                  visible={this.state.setVisible}
                  onDismiss={() => {
                    this.setState({ setVisible: false });
                  }}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    padding: 20,
                    width: 900,
                    alignSelf: "center",
                  }}
                >
                  <View style={{ flexDirection: "row", zIndex: -1 }}>
                    <TextInput
                      mode="outlined"
                      label="Document Title"
                      value={this.state.savedDocTitle}
                      style={[styles.inputField, { width: 250 }]}
                      onChangeText={(text) => {
                        this.setState({ savedDocTitle: text });
                      }}
                      placeholder="Document Title"
                    />
                    <Text
                      style={[
                        styles.inputField,
                        {
                          backgroundColor:
                            this.state.savedDocDate -
                              Date.parse(new Date().toDateString()) <
                              864000000 &&
                            this.state.savedDocDate -
                              Date.parse(new Date().toDateString()) >
                              0 //10 days
                              ? "#ffff99"
                              : Date.parse(new Date().toDateString()) >
                                this.state.savedDocDate
                              ? "#ffcccc"
                              : "#EAEAEA",
                          borderWidth: 1,
                          borderColor: "#c1c1c1",
                          borderRadius: 5,
                          margin: 14,
                          alignSelf: "center",
                          padding: 10,
                          width: 200,
                          textAlignVertical: "center",
                        },
                      ]}
                      onPress={() => {
                        this.setState({
                          savedviewCalender: !this.state.savedviewCalender,
                        });
                      }}
                    >
                      {this.state.savedDocDate.toDateString()}
                    </Text>
                    <View
                      style={{
                        paddingLeft: 20,
                        marginTop: 50,
                        zIndex: 5,
                        position: "absolute",
                      }}
                    >
                      {this.state.savedviewCalender ? (
                        <Calendar
                          value={this.state.newDocExpiry}
                          onChange={(value) => {
                            this.setState({ savedDocDate: value }, () => {
                              this.setState({ savedviewCalender: false });
                            });
                          }}
                        />
                      ) : null}
                    </View>
                    <TextInput
                      mode="outlined"
                      label="Note"
                      value={this.state.savedDocNote}
                      style={[styles.inputField, { width: 250 }]}
                      onChangeText={(text) => {
                        this.setState({ savedDocNote: text });
                      }}
                      placeholder="Note"
                      multiline
                    />
                    <TouchableOpacity
                      style={[styles.actionbtn, { borderColor: "#075e54" }]}
                      onPress={() => {
                        this.saveDocUpdate();
                        this.setState({ setVisible: false });
                      }}
                    >
                      <Text style={{ color: "#075e54" }}>Update Details</Text>
                    </TouchableOpacity>
                  </View>
                </Modal>
              </Portal>
              {/* Model Ends */}
              <View
                style={{
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Vehicle Name"
                    value={this.state.vehicleName}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ vehicleName: text });
                    }}
                    placeholder="Vehicle Name"
                  />
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Registration Number"
                    value={this.state.plateNumber}
                    style={[styles.inputField]}
                    onChangeText={(text) => {
                      this.setState({ plateNumber: text });
                    }}
                    placeholder="Registration Number"
                  />
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <TextInput
                    disabled={this.state.disabled}
                    mode="outlined"
                    label="Type"
                    value={this.state.type}
                    style={[styles.inputField]}
                    placeholder="Type"
                  />
                </View>

                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      zIndex: -1,
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 28,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      disabled={this.state.buttonLoading}
                      onPress={() => {
                        this.state.disabled
                          ? this.setState({ disabled: false })
                          : this.updateDB();
                      }}
                    >
                      {this.state.docSaveButtonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : this.state.disabled ? (
                        <Text style={{ color: "#fff" }}>Edit</Text>
                      ) : (
                        <Text style={{ color: "#fff" }}>Save</Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          backgroundColor: "#fff",
                          height: 40,
                          marginTop: 28,
                          width: 100,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.goBack();
                      }}
                    >
                      <Text style={{ color: "#0a60ff" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>

                  <Text
                    style={{
                      padding: 20,

                      color: "red",
                    }}
                  >
                    {this.state.error}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      padding: 12,
                      fontSize: 22,
                      fontWeight: "500",
                      justifyContent: "center",
                    }}
                  >
                    Documents Details
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      zIndex: 5,
                    }}
                  >
                    <View>
                      <Text>New Document Title</Text>
                      <TextInput
                        disabled={this.state.disabled}
                        mode="outlined"
                        label="Document Title"
                        value={this.state.newDocTitle}
                        style={[
                          styles.inputField,
                          { width: 250, paddingTop: 0 },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ newDocTitle: text });
                        }}
                        placeholder="Document Title"
                      />
                    </View>
                    <View style={{ flexDirection: "row", zIndex: 5 }}>
                      <View>
                        <Text>Document Expiry Date</Text>
                        <Text
                          style={[
                            styles.inputField,
                            {
                              backgroundColor: "#EAEAEA",
                              borderWidth: 1,
                              borderColor: "#c1c1c1",
                              borderRadius: 5,
                              margin: 14,
                              marginTop: 2,
                              alignSelf: "center",
                              padding: 10,

                              width: 200,
                              textAlignVertical: "center",
                            },
                          ]}
                          onPress={() => {
                            this.setState({
                              viewCalender: !this.state.viewCalender,
                            });
                          }}
                        >
                          {this.state.newDocExpiry.toDateString()}
                        </Text>

                        <View
                          style={{
                            paddingLeft: 20,
                            marginTop: 50,
                            zIndex: 5,
                            position: "absolute",
                          }}
                        >
                          {this.state.viewCalender ? (
                            <Calendar
                              value={this.state.newDocExpiry}
                              onChange={(value) => {
                                this.setState({ newDocExpiry: value }, () => {
                                  this.setState({ viewCalender: false });
                                });
                              }}
                            />
                          ) : null}
                        </View>
                      </View>
                    </View>
                    <View>
                      <Text>Contact person Number</Text>
                      <TextInput
                        mode="outlined"
                        label="On Expiry Contact Number"
                        value={this.state.newDocContact}
                        style={[
                          styles.inputField,
                          { width: 250, paddingTop: 0 },
                        ]}
                        onChangeText={(text) => {
                          this.setState({ newDocContact: text });
                        }}
                        placeholder="On Expiry Contact Number"
                      />
                    </View>
                    <TouchableOpacity
                      style={[
                        styles.addNewButton,
                        {
                          height: 40,
                          marginTop: 0,
                          marginHorizontal: 20,
                          width: 150,
                          alignItems: "center",
                        },
                      ]}
                      onPress={() => {
                        if (this.state.newDocTitle != "") {
                          this.addDocToDB();
                        }
                      }}
                    >
                      {this.state.docSaveButtonLoading ? (
                        <ActivityIndicator size="small" color="#fff" />
                      ) : (
                        <Text style={{ color: "#fff" }}>Add New Document</Text>
                      )}
                    </TouchableOpacity>
                  </View>
                  <FlatList
                    data={this.state.documentArray}
                    renderItem={({ item }) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            zIndex: -1,
                          }}
                        >
                          <TextInput
                            mode="outlined"
                            label="Document Title"
                            value={item.documentTitle}
                            style={[styles.inputField, { width: 250 }]}
                            editable={false}
                            placeholder="Document Title"
                          />

                          <Text
                            style={[
                              styles.inputField,
                              {
                                backgroundColor:
                                  item.expiryDate -
                                    Date.parse(new Date().toDateString()) <
                                    864000000 &&
                                  item.expiryDate -
                                    Date.parse(new Date().toDateString()) >
                                    0 //10 days
                                    ? "#ffff99"
                                    : Date.parse(new Date().toDateString()) >
                                      item.expiryDate
                                    ? "#ffcccc"
                                    : "#EAEAEA",
                                borderWidth: 1,
                                borderColor: "#c1c1c",
                                borderRadius: 5,
                                margin: 14,
                                alignSelf: "center",
                                padding: 10,
                                width: 200,
                                textAlignVertical: "center",
                              },
                            ]}
                          >
                            {new Date(item.expiryDate).toDateString()}
                          </Text>
                          <TextInput
                            mode="outlined"
                            label="On Expiry Contact Number"
                            value={item.note}
                            style={[styles.inputField, { width: 250 }]}
                            editable={false}
                            placeholder="On Expiry Contact Number"
                          />
                          <TouchableOpacity
                            style={[
                              styles.actionbtn,
                              { borderColor: "#075e54" },
                            ]}
                            onPress={() => {
                              this.setState({ setVisible: true });
                              this.setState({
                                savedDocTitle: item.documentTitle,
                                savedDocDate: new Date(item.expiryDate),
                                savedDocNote: item.note,
                                docId: item.id,
                              });
                            }}
                          >
                            <Text style={{ color: "#075e54" }}>Edit</Text>
                          </TouchableOpacity>
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item.id)}
                    ListHeaderComponent={() => (
                      <Text style={{ fontSize: 20, fontWeight: "500" }}>
                        Saved Documents
                      </Text>
                    )}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  palyArea: {
    flex: 5,
    maxHeight: Dimensions.get("window").height - 55,
    overflow: "scroll",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  inputField: {
    width: 400,
    padding: 20,
    height: 40,
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  actionbtn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 5,
    alignSelf: "center",
  },
});
