import { StatusBar } from "expo-status-bar";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { Modal, Portal, Provider, TextInput } from "react-native-paper";
import LeftBar from "../src/components/LeftBar";
import Header from "../src/components/Header";
import { jsPDF } from "jspdf";
import "react-calendar/dist/Calendar.css";
import firebase from "firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const reducer3 = (accumulator, currentValue) =>
  parseFloat(accumulator) +
  parseFloat(currentValue.receivedAmount ? currentValue.receivedAmount : 0);
export default class fleetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      yearFrom: new Date().getFullYear(),
      isButtonLoading: false,
      chartData: [],
      count: "HERE",
      finalChartArray: [],
      flatListArray: [],
      max: 0,
      isScreenLoading: false,
      showYear: new Date().getFullYear(),
      selectedDateData: {},
      selectedArray: {},
      isTableShow: false,
      columns: ["Sr. no.", "Date", "Name", "Item", "Payment Mode", "Amount"],
      uoid: "",
      setVisibleRangeSales: false,
      viewCalenderTo: false,
      viewCalenderFrom: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      filteredArrayBulk: [],
      rawData: [],
      totalOfTRange: "",
    };
  }
  componentDidMount() {
    try {
      AsyncStorage.getItem("uoid").then((uoid) => {
        if (uoid !== null) {
          this.setState({ uoid }, () => {
            this.getLoginUser();
            this.callDataFromDB();
          });
        }
      });
    } catch (e) {
      // error reading value
    }
  }
  getLoginUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let loginId = firebase.auth().currentUser.email;
        if (loginId) {
          this.setState({ loginUser: loginId });
        }
      }
    });
  };
  callDataFromDB = () => {
    this.setState({ isScreenLoading: true });
    let dbref = firebase
      .database()
      .ref(`${this.state.uoid}/factoryData/productData/allSalesData`)
      .orderByKey()
      .startAt(
        `${Date.parse(new Date(`${this.state.yearFrom}-1-1`).toDateString())}`
      )
      .endAt(
        `${Date.parse(new Date(`${this.state.yearFrom}-12-31`).toDateString())}`
      );

    dbref.on("value", (snapshot) => {
      if (snapshot.val()) {
        let data = Object.values(snapshot.val());
        const reducer1 = (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue.totalQtySales);

        let arrayData = data.map((item) => {
          return Object.values(item);
        });
        let finalArray = arrayData.map((item) => {
          return (item = {
            count: item.reduce(reducer1, 0),
            date: `${Date.parse(new Date(item[0].date))}`,
            itemdata: item,
          });
        });
        this.setState({ chartData: finalArray }, () => {
          this.makeFinalArray();
          var max = this.state.chartData.reduce(function (a, b) {
            return Math.max(a, b.count);
          }, 0);
          this.setState({ max });
        });
      } else {
        this.setState({ chartData: [] }, () => {
          this.makeFinalArray();
          var max = this.state.chartData.reduce(function (a, b) {
            return Math.max(a, b.count);
          }, 0);
          this.setState({ max });
        });
      }
    });
  };

  makeFinalArray = () => {
    function days_of_a_year(year) {
      return isLeapYear(year) ? 366 : 365;
    }

    function isLeapYear(year) {
      return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }
    let yearArray = new Array(
      days_of_a_year(new Date(`${this.state.yearFrom}-1-1`).getFullYear())
    ).fill({});
    let finalChartArray = yearArray.map((item1, index1) => {
      item1 = {
        dateStamp:
          Date.parse(new Date(`${this.state.yearFrom}-1-1`)) + //TODO: Selective Year here
          index1 * 86400000,
      };
      this.state.chartData.map((item2, index2) => {
        if (item1.dateStamp == item2.date) {
          item1 = {
            count: item2.count,
            dateStamp:
              Date.parse(new Date(`${this.state.yearFrom}-1-1`)) + //TODO: Selective Year here
              index1 * 86400000,
            itemData: item2,
          };
        }
      });
      return item1;
    });
    this.setState({ finalChartArray }, () => {
      this.prepareForFlatlist();
    });
  };
  prepareForFlatlist = () => {
    let monthArray = [];
    let month0 = [];
    let month1 = [];
    let month2 = [];
    let month3 = [];
    let month4 = [];
    let month5 = [];
    let month6 = [];
    let month7 = [];
    let month8 = [];
    let month9 = [];
    let month10 = [];
    let month11 = [];
    // let array = monthArray.map((item1, index1) => {
    this.state.finalChartArray.map((item2, index2) => {
      if (new Date(item2.dateStamp).getMonth() == 0) {
        month0.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 1) {
        month1.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 2) {
        month2.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 3) {
        month3.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 4) {
        month4.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 5) {
        month5.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 6) {
        month6.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 7) {
        month7.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 8) {
        month8.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 9) {
        month9.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 10) {
        month10.push(item2);
      } else if (new Date(item2.dateStamp).getMonth() == 11) {
        month11.push(item2);
      }
    });

    // });
    monthArray.splice(0, 0, month0);
    monthArray.splice(1, 0, month1);
    monthArray.splice(2, 0, month2);
    monthArray.splice(3, 0, month3);
    monthArray.splice(4, 0, month4);
    monthArray.splice(5, 0, month5);
    monthArray.splice(6, 0, month6);
    monthArray.splice(7, 0, month7);
    monthArray.splice(8, 0, month8);
    monthArray.splice(9, 0, month9);
    monthArray.splice(10, 0, month10);
    monthArray.splice(11, 0, month11);
    this.setState({ flatListArray: monthArray }, () => {
      this.setState({ isScreenLoading: false, isButtonLoading: false });
    });
  };
  filterArrayForBulkView = () => {
    let filteredArray = this.state.chartData.filter(
      (item) =>
        parseFloat(item.date) >=
          Date.parse(this.state.dateFrom.toDateString()) &&
        parseFloat(item.date) <= Date.parse(this.state.dateTo.toDateString())
    );
    this.setState({ filteredArrayBulk: filteredArray });
    const reducer1 = (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.count);
    let total = filteredArray.reduce(reducer1, 0);

    this.setState({ totalOfTRange: total });
  };
  tableHeader = () => (
    <View style={styles.tableHeader}>
      {this.state.columns.map((column, index) => {
        {
          return (
            <TouchableOpacity key={index} style={styles.columnHeader}>
              <Text style={styles.columnHeaderTxt}>{column}</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
  render() {
    return (
      <Provider>
        <Portal>
          <Modal
            visible={this.state.setVisibleRangeSales}
            onDismiss={() => {
              this.setState({ setVisibleRangeSales: false });
            }}
            contentContainerStyle={{
              backgroundColor: "white",
              padding: 20,
              borderWidth: 1,
              alignSelf: "center",
              width: 650,
              borderRadius: 5,
              borderColor: "#c1c1c1",
            }}
          >
            <Text style={{ textDecorationLine: "underline" }}>
              Select Date for Year ONLY {this.state.yearFrom}
            </Text>

            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={{ padding: 20 }}>From :</Text>
              <View style={{ flexDirection: "row", zIndex: 5 }}>
                <View>
                  <Text
                    style={[
                      styles.inputField,
                      {
                        backgroundColor: "#EAEAEA",
                        borderWidth: 1,
                        borderColor: "#c1c1c1",
                        borderRadius: 5,
                        margin: 14,
                        alignSelf: "center",
                        padding: 10,
                        width: 200,
                        textAlignVertical: "center",
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        viewCalenderFrom: !this.state.viewCalenderFrom,
                      });
                    }}
                  >
                    {this.state.dateFrom.getDate()}/
                    {this.state.dateFrom.getMonth() + 1}/
                    {this.state.dateFrom.getFullYear()}
                  </Text>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 75,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderFrom ? (
                      <Calendar
                        value={this.state.dateFrom}
                        onChange={(value) => {
                          this.setState({ dateFrom: value }, () => {
                            this.setState({ viewCalenderFrom: false });
                          });
                        }}
                        maxDate={new Date(`dec 31, ${this.state.yearFrom}`)}
                        minDate={new Date(`jan 1, ${this.state.yearFrom}`)}
                      />
                    ) : null}
                  </View>
                </View>
              </View>
              {/* to */}
              <Text style={{ padding: 20 }}>To :</Text>
              <View style={{ flexDirection: "row", zIndex: 5 }}>
                <View>
                  <Text
                    style={[
                      styles.inputField,
                      {
                        backgroundColor: "#EAEAEA",
                        borderWidth: 1,
                        borderColor: "#c1c1c1",
                        borderRadius: 5,
                        margin: 14,
                        alignSelf: "center",
                        padding: 10,
                        width: 200,
                        textAlignVertical: "center",
                      },
                    ]}
                    onPress={() => {
                      this.setState({
                        viewCalenderTo: !this.state.viewCalenderTo,
                      });
                    }}
                  >
                    {this.state.dateTo.getDate()}/
                    {this.state.dateTo.getMonth() + 1}/
                    {this.state.dateTo.getFullYear()}
                  </Text>

                  <View
                    style={{
                      paddingLeft: 20,
                      marginTop: 75,
                      zIndex: 5,
                      position: "absolute",
                    }}
                  >
                    {this.state.viewCalenderTo ? (
                      <Calendar
                        value={this.state.dateTo}
                        onChange={(value) => {
                          this.setState({ dateTo: value }, () => {
                            this.setState({ viewCalenderTo: false });
                          });
                        }}
                        maxDate={new Date(`dec 31, ${this.state.yearFrom}`)}
                        minDate={new Date(`jan 1, ${this.state.yearFrom}`)}
                      />
                    ) : null}
                  </View>
                </View>
              </View>

              <TouchableOpacity
                style={[
                  styles.actionbtn,
                  { borderColor: "#0a60ff", margin: 15, width: 100 },
                ]}
                onPress={() => {
                  this.filterArrayForBulkView();
                }}
              >
                <Text style={{ color: "#0a60ff", textAlign: "center" }}>
                  View
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                zIndex: -1,
                maxHeight: 300,
                width: 600,
                flexDirection: "row",
              }}
            >
              <FlatList
                data={this.state.filteredArrayBulk}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white",
                        },
                      ]}
                    >
                      <Text style={[styles.columnRowTxt, { width: "33.33%" }]}>
                        {index + 1}
                      </Text>
                      <Text style={[styles.columnRowTxt, { width: "33.33%" }]}>
                        {new Date(parseFloat(item.date)).toDateString()}
                      </Text>
                      <Text
                        style={[
                          styles.columnRowTxt,
                          { fontWeight: "bold", width: "33.33%" },
                        ]}
                      >
                        {item.count}
                      </Text>
                    </View>
                  );
                }}
                keyExtractor={(item) => JSON.stringify(item.date)}
                ListEmptyComponent={
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 50,
                    }}
                  >
                    <Text>No Entries Found in Selected Date Range</Text>
                  </View>
                }
              />
              <View>
                <Text style={{ fontSize: 18, fontWeight: "800" }}>
                  Total Quantity Sales {this.state.totalOfTRange} cft
                </Text>
              </View>
            </View>
          </Modal>
        </Portal>
        <View style={styles.container}>
          <Header title="Reports" />
          <View style={styles.mainConsole}>
            <LeftBar dashboardBar="#c1c1c1" />
            {this.state.isScreenLoading ? (
              <View
                style={[
                  styles.palyArea,
                  { justifyContent: "center", alignItems: "center" },
                ]}
              >
                <ActivityIndicator color="#0a60ff" size="large" />
              </View>
            ) : (
              <View style={styles.palyArea}>
                <View style={styles.table}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <Picker
                        selectedValue={this.state.yearFrom}
                        style={{
                          padding: 12,
                          width: 180,
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        onValueChange={(itemValue) =>
                          this.setState({ yearFrom: itemValue })
                        }
                      >
                        <Picker.Item
                          label={new Date().getUTCFullYear()}
                          value={new Date().getUTCFullYear()}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 1}
                          value={new Date().getUTCFullYear() - 1}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 2}
                          value={new Date().getUTCFullYear() - 2}
                        />
                        <Picker.Item
                          label={new Date().getUTCFullYear() - 3}
                          value={new Date().getUTCFullYear() - 3}
                        />
                      </Picker>
                      <TouchableOpacity
                        style={[
                          styles.actionbtn,
                          {
                            borderColor: "#0a60ff",
                            marginHorizontal: 10,
                          },
                        ]}
                        onPress={() => {
                          this.setState({
                            isButtonLoading: true,
                            showYear: this.state.yearFrom,
                          });
                          this.callDataFromDB();
                        }}
                      >
                        {this.state.isButtonLoading ? (
                          <ActivityIndicator size="small" color="#0a60ff" />
                        ) : (
                          <Text
                            style={{ color: "#0a60ff", textAlign: "center" }}
                          >
                            View
                          </Text>
                        )}
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <TouchableOpacity
                        style={[
                          styles.actionbtn,
                          {
                            borderColor: "#0a60ff",
                            marginHorizontal: 10,
                            backgroundColor: "#0a60ff",
                          },
                        ]}
                        onPress={() => {
                          this.props.navigation.navigate("bulkInvoice");
                        }}
                      >
                        <Text style={{ color: "#fff", textAlign: "center" }}>
                          Bulk Invoice
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.actionbtn,
                          {
                            borderColor: "#0a60ff",
                            marginHorizontal: 10,
                            backgroundColor: "#0a60ff",
                          },
                        ]}
                        onPress={() => {
                          this.filterArrayForBulkView();
                          this.setState({ setVisibleRangeSales: true });
                        }}
                      >
                        <Text style={{ color: "#fff", textAlign: "center" }}>
                          Date Range Sales
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <Text style={[styles.subHeader, { marginVertical: 15 }]}>
                    For Year {this.state.showYear}
                  </Text>
                  <FlatList
                    data={this.state.flatListArray}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={{
                            marginHorizontal: 2.5,
                          }}
                        >
                          <Text>
                            {month[new Date(item[0].dateStamp).getMonth()]}
                          </Text>
                          <FlatList
                            data={item}
                            renderItem={({ item }) => {
                              return (
                                <TouchableOpacity
                                  onPress={() => {
                                    this.setState({ isTableShow: true }, () => {
                                      this.setState({
                                        selectedDateData: item,
                                        selectedArray: item.itemData,
                                      });
                                      console.log(this.state.chartData);
                                    });
                                  }}
                                  style={{
                                    backgroundColor: item.count
                                      ? `rgba(10, 96, 255,${
                                          item.count / this.state.max
                                        })`
                                      : "rgba(0,0,0,0.1)",
                                    width: 16,
                                    height: 16,
                                    marginVertical: 0.7,
                                    marginHorizontal: 0.7,
                                  }}
                                ></TouchableOpacity>
                              );
                            }}
                            keyExtractor={(item1) =>
                              JSON.stringify(item1.dateStamp)
                            }
                            numColumns={5}
                          />
                        </View>
                      );
                    }}
                    keyExtractor={(item) => JSON.stringify(item[0].dateStamp)}
                    horizontal={true}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: 6,
                    }}
                  >
                    <Text style={{ marginHorizontal: 5 }}>
                      Min. Quantity Sales
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          backgroundColor: "rgba(10, 96, 255,0.25)",
                          width: 16,
                          height: 16,
                          marginHorizontal: 1,
                        }}
                      />
                      <View
                        style={{
                          backgroundColor: "rgba(10, 96, 255,0.5)",
                          width: 16,
                          height: 16,
                          marginHorizontal: 0.7,
                        }}
                      />
                      <View
                        style={{
                          backgroundColor: "rgba(10, 96, 255,0.75)",
                          width: 16,
                          height: 16,
                          marginHorizontal: 0.7,
                        }}
                      />
                      <View
                        style={{
                          backgroundColor: "rgba(10, 96, 255,1)",
                          width: 16,
                          height: 16,
                          marginHorizontal: 1,
                        }}
                      />
                    </View>
                    <Text style={{ marginHorizontal: 5 }}>
                      Max. Quantity Sales
                    </Text>
                  </View>
                </View>
                {/* Starts here */}
                {this.state.isTableShow ? (
                  <View>
                    <View
                      style={[
                        styles.table,
                        {
                          backgroundColor: "#f2f2f2",
                          flexDirection: "row",
                          marginTop: 40,
                          padding: 20,
                        },
                      ]}
                    >
                      <View style={{ padding: 20 }}>
                        <Text style={{ paddingVertical: 5 }}>Date</Text>
                        <Text style={styles.subHeader}>
                          {new Date(
                            this.state.selectedDateData.dateStamp
                          ).toDateString()}
                        </Text>
                      </View>
                      <View
                        style={{
                          padding: 20,
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          borderColor: "#c1c1c1",
                        }}
                      >
                        <Text style={{ paddingVertical: 5 }}>
                          Sales Quantity
                        </Text>
                        <Text style={styles.subHeader}>
                          {this.state.selectedDateData.count
                            ? this.state.selectedDateData.count
                            : 0}
                          cft
                        </Text>
                      </View>
                      <View style={{ padding: 20 }}>
                        <Text style={{ paddingVertical: 5 }}>Cash Sales</Text>
                        <Text style={styles.subHeader}>
                          ₹
                          {this.state.selectedArray
                            ? this.state.selectedArray.itemdata
                              ? isNaN(
                                  this.state.selectedArray.itemdata.reduce(
                                    reducer3,
                                    0
                                  )
                                )
                                ? 0
                                : this.state.selectedArray.itemdata.reduce(
                                    reducer3,
                                    0
                                  )
                              : 0
                            : 0}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <FlatList
                        data={
                          this.state.selectedArray
                            ? this.state.selectedArray.itemdata
                            : []
                        }
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={[
                                styles.tableRow,
                                {
                                  backgroundColor:
                                    index % 2 == 1 ? "#F0FBFC" : "white",
                                },
                              ]}
                            >
                              <Text style={styles.columnRowTxt}>
                                {index + 1}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.timeStamp ? item.timeStamp : "-"}
                              </Text>
                              <Text
                                style={[
                                  styles.columnRowTxt,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                {item.customer ? item.customer : "-"}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.item ? item.item : "-"}
                              </Text>
                              <Text style={styles.columnRowTxt}>
                                {item.paymentMode ? item.paymentMode : "-"}
                              </Text>
                              <Text
                                style={[
                                  styles.columnRowTxt,
                                  { fontWeight: "bold" },
                                ]}
                              >
                                {item.receivedAmount
                                  ? item.receivedAmount
                                  : "-"}
                              </Text>
                            </View>
                          );
                        }}
                        keyExtractor={(item) => JSON.stringify(item.timeStamp)}
                        ListHeaderComponent={this.tableHeader}
                        ListEmptyComponent={
                          <View
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 50,
                            }}
                          >
                            <Text>No Entries Found in Selected Date Range</Text>
                          </View>
                        }
                      />
                    </View>
                  </View>
                ) : (
                  <View style={styles.table}>
                    <Text style={{ fontSize: 20 }}>
                      Click on each Square tile to view the details
                    </Text>
                  </View>
                )}

                {/* ends Here */}
              </View>
            )}
          </View>
        </View>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  mainConsole: {
    flex: 5,
    flexDirection: "row",
  },
  mainHeader: {
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    flex: 0.8,
  },
  headertitle: {
    padding: 12,
    fontSize: 22,
    fontWeight: "500",
    justifyContent: "center",
  },
  palyArea: {
    flex: 5,
  },
  table: {
    marginTop: 25,
    alignSelf: "center",
    borderRadius: 4,

    zIndex: -1,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    padding: 10,
    justifyContent: "space-between",
  },
  addNewButton: {
    backgroundColor: "#0a60ff",
    borderWidth: 1,
    borderColor: "#0a60ff",
    padding: 10,
    borderRadius: 5,
  },
  model: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeader: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    justifyContent: "center",
    alignItems: "center",
  },
  modelHeaderText: {
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
  },
  modelBody: {},
  inputField: {
    width: 350,
    padding: 20,
    height: 40,
  },
  actionbtn: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: 20,
  },
  tableRow: {
    flexDirection: "row",
    height: 50,
    alignItems: "center",
  },
  columnRowTxt: {
    width: "16.66%",
    textAlign: "center",
  },
  columnHeader: {
    width: "16.66%",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#c1c1c1",
    paddingBottom: 20,
  },
  columnHeaderTxt: {
    color: "#000",
    fontWeight: "bold",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    height: 50,
  },
});
